<template>
  <v-app>
    <v-main>
      <Theme v-if="loggedIn" />
      <PDFGeneratorView v-else-if="pdfGenerator" />
      <PDFTrialReportView v-else-if="pdfTrialReport" />
      <PDFSoilLabel v-else-if="qrCodeGenerator" />
      <Login v-else />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex"
import Theme from "@/components/layout/Theme"
import router from "@/router"
import { User } from "@/store/modules"
import Login from "@/views/Login"
import PDFGeneratorView from "@/views/PDFGeneratorView"
import PDFTrialReportView from "@/views/PDFTrialReportView"
import LeafAPI from "@/api/LeafAPI"
import PDFSoilLabel from "@/views/PDFSoilLabel"

export default {
  components: {
    Theme,
    PDFGeneratorView,
    PDFTrialReportView,
    PDFSoilLabel,
    Login,
  },

  computed: {
    ...mapState({
      userOrgNodeId: state => state.User.orgNodeId,
      userToken: state => state.User.token,
    }),

    loggedIn() {
      return !!this.userToken
    },

    pdfGenerator() {
      return router.history.current.name === "arva-pdf-generator"
    },

    pdfTrialReport() {
      return (
        router.history.current.name === "trial-title-page" ||
        router.history.current.name === "trial-report-generator" ||
        router.history.current.name === "trial-env-generator" ||
        router.history.current.name === "trial-microclimate" ||
        router.history.current.name === "trial-rollup-generator"
      )
    },

    qrCodeGenerator() {
      return router.history.current.name === "soil-label-generator"
    },
  },

  methods: {
    ...mapActions({
      fetchUser: User.Actions.fetchUser,
    }),
  },

  async mounted() {
    if (this.loggedIn) {
      await this.fetchUser()
      // checks for code for Leaf API
      if (this.$route.query.code) {
        const code = this.$route.query.code
        const targetOrgNodeId = this.$route.query.target_org
        // const orgId = this.$store.state.Organization.organization.id
        LeafAPI.postLeafCode({ code, org_node_id: targetOrgNodeId })
      }
    } else if (
      !this.pdfGenerator &&
      !this.pdfTrialReport &&
      !this.qrCodeGenerator
    )
      router.push("/login")
  },
}
</script>
<style>
.v-slider__tick-label {
  font-size: 12px;
}
</style>