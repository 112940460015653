<template>
  <ArvaModal
    name="addNewUserModal"
    title="Add New User"
    @close-modal="handleCloseModal"
  >
    <b-alert v-model="showErrorAlert" variant="danger" dismissible>
      <span> Failed To Add New User:</span>
      <div
        class="error-message ml-5 p-1"
        v-for="message in errorMessage"
        :key="message"
      >
        <li>{{ message }}</li>
      </div>
    </b-alert>

    <div class="row ml-3 mt-3">
      <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
        <span class="default-input-title">First Name</span>
        <div class="input-group">
          <input type="text" class="form-control" v-model.trim="firstName" />
        </div>
      </div>

      <div class="ml-15 col-lg-5 col-md-6 col-sm-6 col-xs-12">
        <span class="default-input-title">Last Name</span>
        <div class="input-group">
          <input type="text" class="form-control" v-model.trim="lastName" />
        </div>
      </div>
    </div>

    <div class="row ml-3">
      <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
        <span class="default-input-title">Email</span>
        <div class="input-group">
          <input type="text" class="form-control" v-model.trim="userEmail" />
        </div>
      </div>
    </div>

    <div class="row ml-3">
      <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
        <span class="default-input-title">Status</span>
        <div class="input-group">
          <v-select
            v-model.trim="selectedStatus"
            :items="statusOptions"
            solo
            label="Select Status"
          ></v-select>
        </div>
      </div>

      <div class="ml-15 col-lg-5 col-md-6 col-sm-6 col-xs-12">
        <span class="default-input-title">Role</span>
        <div class="input-group">
          <v-select
            v-model.trim="selectedRole"
            :items="roleOptions"
            solo
            label="Select Role"
          ></v-select>
        </div>
      </div>
    </div>

    <div class="row ml-3">
      <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
        <span class="default-input-title">Corporation</span>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model.trim="corporation.name"
            readonly
          />
        </div>
      </div>
    </div>

    <div class="row ml-3">
      <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
        <span class="default-input-title">Organization</span>
        <div class="input-group">
          <v-select
            v-model="selectedOrg"
            :items="organizationOptions"
            item-text="name"
            return-object
            solo
            label="Select Organization"
          >
            <template #item="{ item, attrs, on }">
              <div :style="locationItemStyle(item)" v-bind="attrs" v-on="on">
                {{ item.name }}
              </div>
            </template>
          </v-select>
        </div>
      </div>
    </div>

    <div class="row ml-3">
      <ArvaModalSaveFooter
        confirmText="Add"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="confirmChanges"
      />
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "AddNewUserModal",
  props: ["adminUserId", "corporation", "organizationOptions"],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
  },

  data() {
    return {
      roleOptions: ["Organization Admin", "Member"],
      statusOptions: ["Active", "Inactive"],
      firstName: null,
      lastName: null,
      userEmail: null,
      selectedRole: "Member",
      selectedStatus: "Active",
      selectedOrg: null,
      showErrorAlert: false,
      noDataAvailableText: "No Data Available",
      errorMessage: [],
      email_reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, //eslint-disable-line
    }
  },

  computed: {},

  methods: {
    locationItemStyle(item) {
      if (!item.depth) return null

      return item.depth > 1
        ? { "padding-left": item.depth + 1 + ".75em" }
        : null
    },

    handleCloseModal() {
      this.$emit("close-modal")
    },

    confirmChanges() {
      const hasError = this.validateInputs()
      if (!hasError) {
        const payload = {
          admin_user_id: this.adminUserId,
          corporation: this.corporation,
          first_name: this.firstName,
          last_name: this.lastName,
          user_email: this.userEmail,
          selected_role: this.selectedRole,
          selected_status: this.selectedStatus,
          selected_org: this.selectedOrg,
        }
        this.$emit("save-changes", payload)
      }
    },

    // Validate all the inputs
    //  Return True if found at least 1 error
    //  Return False if found no error
    validateInputs() {
      this.resetError()

      if (!this.firstName)
        this.errorMessage.push("First Name must be filled out.")
      if (!this.lastName)
        this.errorMessage.push("Last Name must be filled out.")
      if (!this.selectedRole) this.errorMessage.push("Role must be selected.")
      if (!this.selectedStatus)
        this.errorMessage.push("Status must be selected.")
      if (!this.selectedOrg)
        this.errorMessage.push("Organization must be selected.")
      if (!this.userEmail) this.errorMessage.push("Email must be filled out.")
      else if (!this.email_reg.test(this.userEmail)) {
        this.errorMessage.push("Please enter a valid email address.")
      }

      if (this.errorMessage.length > 0) {
        this.showErrorAlert = true
        return true
      }
      return false
    },

    resetError() {
      this.showErrorAlert = false
      this.errorMessage = []
    },
  },
}
</script>

<style scoped>
.org-options-style {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
