export const Dashboard = {
  Actions: {
    fetchAgts: "Dashboard/fetchAgts",
    fetchAgtPerformance: "Dashboard/fetchAgtPerformance",
    fetchAgtSummary: "Dashboard/fetchAgtSummary",
    fetchSeedCompanies: "Dashboard/fetchSeedCompanies",
    setSelectedHybrid: "Dashboard/setSelectedHybrid",
    setSelectedCrop: "Dashboard/setSelectedCrop",
    setTargetYield: "Dashboard/setTargetYield",
    updateSeedingRate: "Dashboard/updateSeedingRate",
    updateCarbonCreditDollarValue: "Dashboard/updateCarbonCreditDollarValue",
  },
  Getters: {
    getRoiSummary: "Dashboard/getRoiSummary",
    getAgtPerformanceSummary: "Dashboard/getAgtPerformanceSummary",
    getAgtSummary: "Dashboard/getAgtSummary",
    getCropDesignerSummary: "Dashboard/getCropDesignerSummary",
    getCropAgtVarietySummary: "Dashboard/getCropAgtVarietySummary",
    getTopPerformingVarieties: "Dashboard/getTopPerformingVarieties",
  },
  Mutations: {
    initializeCropDesignerSettings: "Dashboard/initializeCropDesignerSettings",
    setAgts: "Dashboard/setAgts",
    setAgtSelectedCrop: "Dashboard/setAgtSelectedCrop",
    setAgtPerformance: "Dashboard/setAgtPerformance",
    setAgtSummary: "Dashboard/setAgtSummary",
    setCropDesignerHybrid: "Dashboard/setCropDesignerHybrid",
    setNextCrop: "Dashboard/setNextCrop",
    setSeedCompanies: "Dashboard/setSeedCompanies",
    setSeedingRate: "Dashboard/setSeedingRate",
    setTargetYield: "Dashboard/setTargetYield",
    setCarbonCreditDollarValue: "Dashboard/setCarbonCreditDollarValue",
    setCIScore: "Dashboard/setCIScore"
  },
}

export const Defaults = {
  Actions: {
    fetchDefaults: "Defaults/fetchDefaults",
    fetchFieldSettings: "Defaults/fetchFieldSettings",
    fetchNewFieldSettings: "Defaults/fetchNewFieldSettings",
    fetchCarbonSettingsByField: "Defaults/fetchCarbonSettingsByField",
    fetchPlantingSettings: "Defaults/fetchPlantingSettings",
    fetchOpsSettings: "Defaults/fetchOpsSettings",
    fetchFertSettings: "Defaults/fetchFertSettings",
    fetchPracticeChoices: "Defaults/fetchPracticeChoices",
    updateMultipleFieldSettings: "Defaults/updateMultipleFieldSettings",
    updateFieldSettingsByFieldYear: "Defaults/updateFieldSettingsByFieldYear",
    saveDataSpreadsheet: "Defaults/saveDataSpreadsheet",
    updateLiming: "Defaults/updateLiming",
    updateTillage: "Defaults/updateTillage",
    updateIrrigation: "Defaults/updateIrrigation",
    updateFertilizer: "Defaults/updateFertilizer",
    updateProtection: "Defaults/updateProtection",
    updateCoverCropping: "Defaults/updateCoverCropping",
  },
  Getters: {
    getDefaultsList: "Defaults/getDefaultsList",
    getOneFieldSettings: "Defaults/getOneFieldSettings",
    getNewFieldSettings: "Defaults/getNewFieldSettings",
    getRecentWizardData: "Defaults/getRecentWizardData",
    getPracticeChoices: "Defaults/getPracticeChoices",
  },
  Mutations: {
    setDefaults: "Defaults/setDefaults",
    setFieldSettings: "Defaults/setFieldSettings",
    setNewFieldSettings: "Defaults/setNewFieldSettings",
    addToFieldSettings: "Defaults/addToFieldSettings",
    cleanFieldSettings: "Defaults/cleanFieldSettings",
    setPlantingSettings: "Defaults/setPlantingSettings",
    setOpsSettings: "Defaults/setOpsSettings",
    setFertSettings: "Defaults/setFertSettings",
    addFieldSettingRow: "Defaults/addFieldSettingRow",
    setPracticeChoices: "Defaults/setPracticeChoices",
    updateFieldSetting: "Defaults/updateFieldSetting",
    deleteNewFieldSetting: "Defaults/deleteNewFieldSetting",
    updateNewFieldSetting: "Defaults/updateNewFieldSetting",
    saveDataSpreadsheet: "Defaults/saveDataSpreadsheet",
    updateMultipleNewFieldSetting: "Defaults/updateMultipleNewFieldSetting",
    updatePlanting: "Defaults/updatePlanting",
    spreadsheetMutate: "Defaults/spreadsheetMutate",
    resetNeedsSave: "Defaults/resetNeedsSave",
    setRecentWizardData: "Defaults/setRecentWizardData",
    resetRecentWizardData: "Defaults/resetRecentWizardData",
  },
}

export const Enrollment = {
  Mutations: {
    setPreviousEnrollment: "Enrollment/setPreviousEnrollment",
    clearPreviousEnrollment: "Enrollment/clearPreviousEnrollment",
  },
}

export const Fertilizer = {
  Actions: {
    fetchFertilizers: "Fertilizer/fetchFertilizers",
  },
  Getters: {
    getFertilizers: "Fertilizer/getFertilizers",
  },
  Mutations: {
    setFertilizers: "Fertilizer/setFertilizers",
  },
}

export const Fields = {
  Actions: {
    batchDelete: "Fields/batchDelete",
    batchUpdate: "Fields/batchUpdate",
    ignoreGeoPackages: "Fields/ignoreGeoPackages",
    fetchField: "Fields/fetchField",
    fetchFields: "Fields/fetchFields",
    fetchFieldsSkinny: "Fields/fetchFieldsSkinny",
    fetchGeoPkgsByDataType: "Fields/fetchGeoPkgsByDataType",
    updateRegenPractice: "Fields/updateRegenPractice",
    updateUserSustainability: "Fields/updateUserSustainability",
    clearUserSustainability: "Fields/clearUserSustainability",
  },
  Getters: {
    getFields: "Fields/getFields",
  },
  Mutations: {
    setFields: "Fields/setFields",
    updateFields: "Fields/updateFields",
    updateRegenPractice: "Fields/updateRegenPractice",
    updateUserSustainability: "Fields/updateUserSustainability",
    clearUserSustainability: "Fields/clearUserSustainability",
  },
}

export const Filter = {
  Getters: {
    getCrops: "Filter/getCrops",
    getDashboardCrops: "Filter/getDashboardCrops",
    getSelectedCrops: "Filter/getSelectedCrops",
    getSelectedCropsCount: "Filter/getSelectedCropsCount",
    getOrgNodes: "Filter/getOrgNodes",
    getOpen: "Filter/getOpen",
    getSelectedOrgNodeCount: "Filter/getSelectedOrgNodeCount",
    getClients: "Filter/getClients",
    getSelectedClientsCount: "Filter/getSelectedClientsCount",
    getFarms: "Filter/getFarms",
    getSelectedFarmsCount: "Filter/getSelectedFarmsCount",
    getFields: "Filter/getFields",
    getFieldForCheck: "Filter/getFieldForCheck",
    getSelectedFields: "Filter/getSelectedFields",
    getSelectedFieldsCount: "Filter/getSelectedFieldsCount",
    getSelectedFieldsAcreage: "Filter/getSelectedFieldsAcreage",
  },
  Mutations: {
    clearAll: "Filter/clearAll",
    closeDropdowns: "Filter/closeDropdowns",
    initialize: "Filter/initialize",
    selectAll: "Filter/selectAll",
    selectAllOfType: "Filter/selectAllOfType",
    clearAllOfType: "Filter/clearAllOfType",
    setClosed: "Filter/setClosed",
    setOrgNodes: "Filter/setOrgNodes",
    setSelectedFields: "Filter/setSelectedFields",
    setFields: "Filter/setFields",
    toggleItem: "Filter/toggleItem",
    toggleOpen: "Filter/toggleOpen",
  },
}

export const Map = {
  Actions: {
    fetchMapData: "Map/fetchMapData",
    updateAgtCrops: "Map/updateAgtCropsAction",
  },
  Getters: {
    getIncludeDefaults: "Map/getIncludeDefaults",
    getArvaLayers: "Map/getArvaLayers",
    getDataLayers: "Map/getDataLayers",
    getAgtCrops: "Map/getAgtCrops",
    getShowCropIcons: "Map/getShowCropIcons",
    getCropIconOpacity: "Map/getCropIconOpacity",
    getOpacities: "Map/getOpacities",
    getElevationScale: "Map/getElevationScale",
    getActiveFerts: "Map/getActiveFerts",
    getShowFert: "Map/getShowFert",
    getShowNewFertOptions: "Map/getShowNewFertOptions",
    getActiveSeeds: "Map/getActiveSeeds",
    getShowSeed: "Map/getShowSeed",
    getShowNewSeedOptions: "Map/getShowNewSeedOptions",
    getActiveHarvests: "Map/getActiveHarvests",
    getShowHarvest: "Map/getShowHarvest",
    getShowNewHarvestOptions: "Map/getShowNewHarvestOptions",
    getShowMicroclimate: "Map/getShowMicroclimate",
    getMicroclimateOpacity: "Map/getMicroclimateOpacity",
  },
  Mutations: {
    resetState: "Map/resetState",
    autoZoom: "Map/autoZoom",
    disableCropIcons: "Map/disableCropIcons",
    setActiveLayerIds: "Map/setActiveLayerIds",
    setDeck: "Map/setDeck",
    setFieldBounds: "Map/setFieldBounds",
    setIsFetching: "Map/setIsFetching",
    setVoxelBounds: "Map/setVoxelBounds",
    setMicroclimates: "Map/setMicroclimates",
    setMVTYear: "Map/setMVTYear",
    setMVTURLs: "Map/setMVTURLs",

    setDeactivatedDataLayers: "Map/setDeactivatedDataLayers",
    setOrgAgtCrops: "Map/setOrgAgtCrops",
    setOrganizationId: "Map/setOrganizationId",
    setHoverData: "Map/setHoverData",
    setRightClickData: "Map/setRightClickData",
    setShowFert: "Map/setShowFert",
    setShowSeed: "Map/setShowSeed",
    setShowHarvest: "Map/setShowHarvest",
    togglePitchMode: "Map/togglePitchMode",
    updateIncludeDefaults: "Map/updateIncludeDefaults",
    updateAgtCrops: "Map/updateAgtCrops",
    updateMap: "Map/updateMap",
    updateSingleFieldExtentBounds: "Map/updateSingleFieldExtentBounds",
    updateShowCropIcons: "Map/updateShowCropIcons",
    updateCropIconOpacity: "Map/updateCropIconOpacity",
    updateOpacity: "Map/updateOpacity",
    updateElevationScale: "Map/updateElevationScale",
    updateBearingPitch: "Map/updateBearingPitch",
    updateZoom: "Map/updateZoom",
    updateShowMicroclimate: "Map/updateShowMicroclimate",
    updateMicroclimateOpacity: "Map/updateMicroclimateOpacity",
  },
}

export const Notifications = {
  Actions: {
    resetNotificationsState: "Notifications/resetNotificationsState",
    retrieveNotifications: "Notifications/retrieveNotifications",
    markNotificationsAsRead: "Notifications/markNotificationsAsRead",
  },
  Getters: { unread: "Notifications/unread" },
  Mutations: {
    resetState: "Notifications/resetState",
    setList: "Notifications/setList",
    setToastNotification: "Notifications/setToastNotification",
    clearToastNotification: "Notifications/clearToastNotification",
  },
}

export const Organization = {
  Actions: {
    updateYear: "Organization/updateYear",
    initialFetch: "Organization/initialFetch",
  },
  Getters: {
    getYear: "Organization/getYear",
  },
  Mutations: {
    setOrganization: "Organization/setOrganization",
    setYear: "Organization/setYear",
  },
}

export const Placements = {
  Actions: {
    fetchCropsWithTrials: "Placements/fetchCropsWithTrials",
    fetchSeedPlacementPlans: "Placements/fetchSeedPlacementPlans",
  },
  Getters: {
    getPlacementsList: "Placements/getPlacementsList",
  },
  Mutations: {
    setCropsWithTrials: "Placements/setCropsWithTrials",
    setPlacements: "Placements/setPlacements",
  },
}

export const SoilSampling = {
  Actions: {
    fetchSamplePlans: "SoilSampling/fetchSamplePlans",
  },
  Mutations: {
    setSamplingPlans: "SoilSampling/setSamplingPlans",
  },
}

export const Theme = {
  Actions: {
    resetThemeState: "Theme/resetThemeState",
  },
  Getters: {},
  Mutations: {
    resetState: "Theme/resetState",
    setSidePanelOpen: "Theme/setSidePanelOpen",
    setSideNavOpen: "Theme/setSideNavOpen",
    setInitialDataFetch: "Theme/setInitialDataFetch",
  },
}

export const Uploads = {
  Actions: {
    create: "Uploads/create",
    addNewBoundary: "Uploads/addNewBoundary",
    editExistingBoundary: "Uploads/editExistingBoundary",
    fetch: "Uploads/fetch",
    fetchJohnDeereStage: "Uploads/fetchJohnDeereStage",
    listDatasetTypes: "Uploads/listDatasetTypes",
  },
  Mutations: {
    setAccountConnections: "Uploads/setAccountConnections",
    setDatasetTypes: "Uploads/setDatasetTypes",
    setDetailsModalUploadID: "Uploads/setDetailsModalUploadID",
    setDetailsModalUploadFieldID: "Uploads/setDetailsModalUploadFieldID",
    setJohnDeereStage: "Uploads/setJohnDeereStage",
    setMappingModalUploadID: "Uploads/setMappingModalUploadID",
    setUploadPercentage: "Uploads/setUploadPercentage",
    setUploads: "Uploads/setUploads",
  },
}

export const User = {
  Actions: {
    authenticate: "User/authenticate",
    logInToAdmin: "User/logInToAdmin",
    fetchUser: "User/fetchUser",
  },
  Getters: {},
  Mutations: {
    setToken: "User/setToken",
    setUser: "User/setUser",
    setAuthErrors: "User/setAuthErrors",
    setLoading: "User/setLoading",
    setDataOpen: "User/setDataOpen",
    setFieldsOpen: "User/setFieldsOpen",
    setPlanningOpen: "User/setPlanningOpen",
    setInsightsOpen: "User/setInsightsOpen",
    setImageryOpen: "User/setImageryOpen",
    setSustainOpen: "User/setSustainOpen",
    setZonesOpen: "User/setZonesOpen",
    setUserManagement: "User/setUserManagement",
    setOrgSharingTransactions: "User/setOrgSharingTransactions",
    removeSelectedAssignedOrg: "User/removeSelectedAssignedOrg",
    addSelectedAssignedOrg: "User/addSelectedAssignedOrg",
    updateSelectedAssignedOrg: "User/updateSelectedAssignedOrg",
    setUserRole: "User/setUserRole",
    setUserAssignedOrg: "User/setUserAssignedOrg",
    setUserSharedOrg: "User/setUserSharedOrg",
    logout: "User/logout",
  },
}
