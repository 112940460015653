<template>
  <div>
    <div
      v-if="!isReadOnly"
      class="title-row row d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center">
        <h2>{{ placementPlan.name }}</h2>
        <div class="edit-btn" @click="handleOpenEditName">
          Edit Name
        </div>
      </div>
      <div>
        <button class="btn btn-outline-danger" @click="deletePlan">
          Delete Template
        </button>
      </div>
    </div>

    <div class="row d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <h2>Seeds</h2>
      </div>
      <div class="col-12">
        <v-simple-table>
          <thead>
            <tr>
              <th>Seed Product</th>
              <th>Bags</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in placementPlan.bags_per_seed" :key="row.idx">
              <td>{{ row.seed.name }}</td>
              <td>{{ row.bags }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>

    <div class="row d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <h2>Placement by Field</h2>
      </div>
      <div v-if="!isReadOnly">
        <button class="btn btn-primary" @click="exportPlan">
          Download <i class="fa fa-arrow-circle-o-down" />
        </button>
      </div>
      <div class="col-12">
        <v-simple-table v-if="Object.keys(placementResults).length !== 0">
          <thead>
            <tr>
              <th>Field</th>
              <th>Acres</th>
              <th>Seed</th>
              <th>Bags</th>
              <!-- <th>Baseline Yield</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="fieldId in Object.keys(placementResults)" :key="fieldId">
              <td>{{ fields[fieldId]["name"] }}</td>
              <td>{{ fields[fieldId]["acreage"] | prettyInteger }}</td>
              <td>
                <div v-for="seed in placementResults[fieldId]" :key="seed.idx">
                  {{ seed.product }}
                </div>
              </td>
              <td>
                <div v-for="seed in placementResults[fieldId]" :key="seed.idx">
                  {{ seed.bags_per_field | floatTenth }}
                </div>
              </td>

              <!-- <td>
                <div v-for="seed in placementResults[fieldId]" :key="seed.idx">
                  {{ seed.baseline_yield | integer }}
                </div>
              </td> -->
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>

    <div
      class="row d-flex align-items-center justify-content-between mt-3 mb-4 mx-0 alert alert-danger"
      v-if="alerts"
    >
      <div class="d-flex align-items-center">
        <h2><i class="fa fa-exclamation-circle" /> Alert</h2>
      </div>
      <div class="col-12">
        A full seed placement plan could not be calculated from the inputted
        seeds. You will need to create a new plan with more bags for a full
        calculation.
        <div class="text-bold pt-1">Fields without seed placement:</div>
        <div v-for="field in unplannedFields" :key="field.id">
          {{ field.name }}
        </div>
      </div>
    </div>

    <ArvaModal
      v-if="showEditNameModal"
      name="editPlacementNameModal"
      title="Edit Placement Plan Name"
      @close-modal="showEditNameModal = false"
    >
      <div class="row">
        <div class="col-12">
          <form @submit.prevent="handleEditPlacementName">
            <div class="name-edit-form form-group">
              <label>Name</label>
              <input
                class="form-control"
                type="text"
                v-model="editPlacementName"
              />
            </div>
          </form>
        </div>
      </div>
      <ArvaModalSaveFooter
        confirmText="Save"
        cancelText="Cancel"
        @on-cancel="handleCancelEditName"
        @on-confirm="handleEditTemplateName"
      />
    </ArvaModal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"

import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import SeedSelectorAPI from "@/api/SeedSelectorAPI"
import { Placements } from "@/store/modules"

export default {
  name: "SeedPlacementPlan",
  props: ["placementPlanId"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      bagAmount: null,
      companyFocus: null,
      cropType: null,
      editPlacementName: null,
      isCalculating: false,
      relativeMaturity: null,
      seedChoices: [],
      seedForAdd: null,
      userInputs: [],
      showAddSeedModal: false,
      showEditNameModal: false,
    }
  },

  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      fields: state => state.Fields.fields,
      seedCompanyOptions: state => state.Dashboard.seedCompanies,
      seedPlacementPlans: state => state.Placements.seedPlacementPlans,
    }),

    alerts() {
      if (this.placementPlan) {
        const results = JSON.parse(this.placementPlan["placement_results"])
        const fields = this.placementPlan["field_list"]

        if (Object.keys(results).length < fields.length) {
          return true
        }
        return false
      }
      return false
    },

    placementPlan() {
      if (!this.seedPlacementPlans[this.placementPlanId]) return {}
      return this.seedPlacementPlans[this.placementPlanId]
    },

    placementResults() {
      if (this.seedPlacementPlans[this.placementPlanId]["placement_results"]) {
        const res = JSON.parse(
          this.seedPlacementPlans[this.placementPlanId]["placement_results"]
        )

        const filter_res = Object.keys(res)
          .filter(key => key in this.fields)
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: res[key],
            })
          }, {})

        return filter_res
      }
      return null
    },

    unplannedFields() {
      if (this.placementPlan) {
        const resultsFieldIds = Object.keys(
          JSON.parse(this.placementPlan["placement_results"])
        )
        const fields = this.placementPlan["field_list"].map(fieldObj => ({
          id: fieldObj.id,
          name: fieldObj.name,
        }))

        const unplannedFields = fields.filter(
          field => !resultsFieldIds.includes(field.id.toString())
        )

        return unplannedFields
      }
      return null
    },
  },

  methods: {
    ...mapActions({
      fetchSeedPlacementPlans: Placements.Actions.fetchSeedPlacementPlans,
    }),

    toggleCalculating() {
      this.isCalculating = !this.isCalculating
    },

    handleAddPlanSeed() {
      const row = {
        seedId: this.seedForAdd.value,
        seedName: this.seedForAdd.display_name,
        bagAmount: this.bagAmount,
      }
      this.userInputs.push(row)
      this.seedForAdd = null
      this.bagAmount = null
      this.companyFocus = null
      this.showAddSeedModal = false
    },

    handleOpenEditName() {
      this.editPlacementName = this.placementPlan.name
      this.showEditNameModal = true
    },

    handleCancelEditName() {
      this.showEditNameModal = false
    },

    async handleEditTemplateName() {
      if (this.editPlacementName) {
        await SeedSelectorAPI.updateSeedPlan(this.placementPlanId, {
          name: this.editPlacementName,
        })

        await this.fetchSeedPlacementPlans()
        this.showEditNameModal = false
      }
    },

    handleOpenAddSeed() {
      this.showAddSeedModal = true
    },

    async fetchSeeds() {
      await SeedSelectorAPI.fetchSeedsByCompany(
        this.companyFocus,
        this.cropType
      ).then(response => {
        this.seedChoices = response.data
      })
    },

    async deletePlan() {
      await SeedSelectorAPI.deleteSeedPlan(this.placementPlanId)
      await this.fetchSeedPlacementPlans()
      this.$router.push("/seed-placements/")
    },

    exportPlan() {
      const fieldInfo = {}
      Object.keys(this.placementResults).forEach(
        fieldId =>
          (fieldInfo[fieldId] = (({ name, acreage }) => ({ name, acreage }))(
            this.fields[fieldId]
          ))
      )
      const pdfData = {
        placement_results: this.placementResults,
        field_info: fieldInfo,
      }
      SeedSelectorAPI.fetchSeedPlanPdf(pdfData).then(response => {
        const url = response.data
        window.open(url, "_blank")
      })
    },
  },
}
</script>
<style scoped>
.alert-placement {
  background-color: #dc3545;
}

.title-row {
  margin-bottom: 15px;
}

.edit-btn {
  font-size: 11px;
  font-weight: 500;
  margin-left: 10px;
  color: #0095ff;
  cursor: pointer;
}

.edit-btn:hover {
  color: #888;
}

h2 {
  margin: 0;
}

.name-edit-form {
  margin: 25px 70px;
}

.planwide-inputs {
  margin-left: 30px;
  width: 150px;
}
</style>
