<template>
  <div class="row reverse-margin">
    <div class="col-5 scroll-col">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Order Trial Report
          </v-toolbar-title>
          <template v-slot:extension>
            <v-tabs grow v-model="tab">
              <v-tabs-slider color="blue"></v-tabs-slider>
              <v-tab v-for="item in tabTitles" :key="item.index">
                {{ item.name }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabTitles" :key="item.index">
            <v-card flat>
              <div v-if="item.name === 'Fields'" class="pt-3">
                <FieldImageryListView :tabView="true" :zonesView="true" />
                <div class="px-3">
                  <b-button @click="handleOrder">Order Report</b-button>
                </div>
              </div>
              <div v-if="item.name === 'Order'" class="pt-3">
                <div class="px-3" v-if="years">
                  <h2>Select Trial Parameters</h2>
                  <!-- years -->
                  <div>Year</div>
                  <v-select-old
                    class="pb-3"
                    v-if="years"
                    :options="years"
                    v-model="yearSelected"
                    :appendToBody="true"
                  />
                  <!-- crops -->
                  <div v-if="crops">Crop</div>
                  <v-select-old
                    class="pb-3"
                    v-if="crops"
                    :options="crops"
                    v-model="cropSelected"
                    :appendToBody="true"
                  />
                  <div v-if="cropSelected == 'OM'">Soil Comparison Year</div>
                  <v-select-old
                    class="pb-3"
                    v-if="years && cropSelected == 'OM'"
                    :options="years"
                    v-model="yearSelectedComparison"
                    :appendToBody="true"
                  />
                  <div class="pb-3" v-if="cropSelected">
                    Voxel Size
                    <v-slider
                      class="pb-3"
                      v-model="voxelResolution"
                      :min="3"
                      :max="33"
                    />
                  </div>
                  <!-- treatment product -->
                  <div v-if="productOptions">
                    Treatment Product
                    <v-select-old
                      class="pb-3"
                      :options="productOptions"
                      v-model="treatmentProduct"
                      :appendToBody="true"
                    />
                    <div
                      class="d-flex"
                      v-if="treatmentProduct && treatmentProduct !== 'None'"
                    >
                      <div class="mr-2">
                        <label for="min-treatment">Min</label>
                        <input
                          class="form-control"
                          type="number"
                          id="min-treatment"
                          v-model="treatmentRange[0]"
                        />
                      </div>
                      <div class="ml-2">
                        <label for="max-treatment">Max</label>
                        <input
                          class="form-control"
                          type="number"
                          id="max-treatment"
                          v-model="treatmentRange[1]"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="productOptions">
                    Control Product
                    <v-select-old
                      class="pb-3"
                      :options="productOptions"
                      v-model="controlProduct"
                      :appendToBody="true"
                    />
                    <div
                      class="d-flex"
                      v-if="controlProduct && controlProduct !== 'None'"
                    >
                      <div class="mr-2">
                        <label for="min-treatment">Min</label>
                        <input
                          class="form-control"
                          type="number"
                          id="min-treatment"
                          v-model="controlRange[0]"
                        />
                      </div>
                      <div class="ml-2">
                        <label for="max-treatment">Max</label>
                        <input
                          class="form-control"
                          type="number"
                          id="max-treatment"
                          v-model="controlRange[1]"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="pb-3" v-if="treatmentProduct && controlProduct">
                    Comparison Distance
                    <v-slider
                      class="pb-3"
                      strict
                      v-model="comparisonDistance"
                      :min="0"
                      :max="4000"
                      :tick-labels="[0, 4000]"
                      :step="5"
                    />
                  </div>
                  <div class="pb-3" v-if="treatmentProduct && controlProduct">
                    Min Acreage
                    <v-slider
                      class="pb-3"
                      strict
                      v-model="minAcres"
                      :min="0"
                      :max="10"
                      :step="0.5"
                    />
                  </div>

                  <!-- use interpolation -->
                  <div
                    class="d-flex pb-3"
                    v-if="treatmentProduct && controlProduct"
                  >
                    <input type="checkbox" v-model="interpolation" />
                    <span class="ml-2">Use Interpolation</span>
                  </div>
                  <div class="pb-3" v-if="treatmentProduct && controlProduct">
                    <label for="execSummary">Executive Summary</label>
                    <textarea
                      class="form-control"
                      id="execSummary"
                      rows="3"
                      v-model="execSummary"
                    ></textarea>
                  </div>
                  <!-- yield externalities -->
                  <div class="pb-3" v-if="treatmentProduct && controlProduct">
                    <label for="yieldExternalities">Externalities</label>
                    <input
                      class="form-control"
                      id="yieldExternalities"
                      type="text"
                      v-model="yieldExternalities"
                    />
                  </div>
                  <!-- report title -->
                  <div class="pb-3" v-if="treatmentProduct && controlProduct">
                    <label for="reportTitle">Report Title</label>
                    <input
                      class="form-control"
                      id="reportTitle"
                      type="text"
                      v-model="reportTitle"
                    />
                  </div>
                </div>

                <div
                  class="pb-2 d-flex ml-3"
                  v-if="treatmentProduct && controlProduct"
                >
                  <b-button @click="handleSubmitReport">
                    Submit Trial Report
                    <div
                      v-if="isOrderSubmitting"
                      class="spinner-border spinner-border-sm text-light ml-1"
                      role="status"
                    ></div>
                  </b-button>
                  <div
                    class="response-message ml-2 text-bold"
                    :class="
                      submitMessage === 'Trial Order Submitted'
                        ? 'submit-success'
                        : 'submit-error'
                    "
                    v-if="submitMessage"
                  >
                    {{ submitMessage }}
                  </div>
                </div>
              </div>
              <div v-if="item.name === 'Reports'" class="pt-3">
                <div class="px-3" v-if="trialReportsToList.length > 0">
                  <v-simple-table class="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Created</th>
                        <th>Name</th>
                        <th>PDF</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="report in trialReportsToList"
                        :key="report.index"
                      >
                        <td>{{ report.id }}</td>
                        <td>
                          {{ new Date(report.created_at).toDateString() }}
                        </td>
                        <td @click="editReportOrder(report)">
                          <a target="_blank">{{
                            report.order_manifest.report_title
                          }}</a>
                        </td>
                        <td v-if="report.report_pdf">
                          <i class="fa fa-file-pdf-o" /><a
                            class="report-link"
                            :href="report.report_pdf"
                            target="blank"
                            >{{
                              report["order_manifest"]["report_title"]
                                ? report["order_manifest"]["report_title"]
                                : "Missing Title"
                            }}</a
                          >
                        </td>
                        <td v-else>{{ report.status }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
                <div
                  v-else
                  class="my-3 d-flex justify-content-center"
                  justify="center"
                >
                  <div class="text-bold mb-0 mt-1">
                    Reports for Currently Selected Fields will be Available Here
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <div class="col-7 map-col">
      <div class="map-wrapper" oncontextmenu="return false">
        <LeafletMap
          :boundaries="fieldBoundaries"
          :fields="fields"
          :isYield="isYield"
          :zoneGeoJSON="zoneGeoJSON"
          :mapParams="mapParams"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from "vuex"
//import MapAPI from "@/api/MapAPI"
import OrderTrialAPI from "@/api/OrderTrialAPI"

import FieldImageryListView from "@/views/FieldImageryListView"
import LeafletMap from "@/components/map/LeafletMap"

import { Filter } from "@/store/modules"

export default {
  name: "OrderTrialReportView",
  components: {
    FieldImageryListView,
    LeafletMap,
  },
  data() {
    return {
      formData: null,
      isYield: false,
      tab: 0,
      tabTitles: [
        {
          index: 0,
          name: "Fields",
        },
        {
          index: 1,
          name: "Order",
        },
        {
          index: 2,
          name: "Reports",
        },
      ],
      yearSelected: null,
      yearSelectedComparison: null,
      cropSelected: null,
      voxelResolution: 10,
      treatmentProduct: null,
      treatmentRange: [0, 1],
      controlProduct: null,
      controlRange: [0, 1],
      comparisonDistance: [0, 4000],
      minAcres: 3,
      interpolation: false,
      execSummary: null,
      yieldExternalities: null,
      reportTitle: null,
      zoneGeoJSON: null,
      isOrderSubmitting: false,
      submitMessage: null,
      listReports: null,
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      selectedFields: state => state.Filter.selectedFields,
      fieldBoundaries: state => state.Map.fieldBoundaries,
    }),

    mapParams() {
      let tempParams = {}
      tempParams["year"] = this.yearSelected
      tempParams["crop_id"] = this.cropSelected
      tempParams["category"] = "trial_report"

      if (this.yearSelected != null) {
        tempParams["useMap"] = true
      } else {
        tempParams["useMap"] = false
      }
      return tempParams
    },

    fields() {
      const activeFieldIds = this.activeFields.map(field => field.id)
      const filteredBounds =
        activeFieldIds.length > 0
          ? this.fieldBoundaries.filter(row =>
              activeFieldIds.includes(row.properties.field.id)
            )
          : this.fieldBoundaries
      return filteredBounds
    },

    years() {
      if (this.formData) {
        return Object.keys(this.formData)
      }
      return null
    },

    crops() {
      if (this.formData && this.yearSelected) {
        return this.formData[this.yearSelected]["crops"]
      }
      return null
    },

    products() {
      if (this.formData && this.yearSelected) {
        return this.formData[this.yearSelected]["products"]
      }
      return null
    },

    productOptions() {
      if (this.formData && this.cropSelected) {
        const keys = Object.keys(this.formData[this.yearSelected]["products"])
        const filteredProds = keys.filter(
          d => d !== "control_product" && d !== "treatment_product"
        )
        filteredProds.push("None")
        return filteredProds
      }
      return null
    },

    treatmentProdSlideOptions() {
      if (this.treatmentProduct && this.treatmentProduct !== "None") {
        return [
          this.products[this.treatmentProduct][0],
          this.products[this.treatmentProduct][1],
        ]
      }
      return null
    },

    controlProdSlideOptions() {
      if (this.controlProduct && this.controlProduct !== "None") {
        return [
          this.products[this.controlProduct][0],
          this.products[this.controlProduct][1],
        ]
      }
      return null
    },
    trialReportsToList() {
      if (this.listReports) {
        return this.listReports.filter(location =>
          location.fields.some(element =>
            this.activeFields.map(field => field.id).includes(element)
          )
        )
      } else {
        return []
      }
    },
  },

  methods: {
    ...mapMutations({
      setSelectedFields: Filter.Mutations.setSelectedFields,
    }),

    async editReportOrder(report) {
      this.toggleZoneFields(report.fields)

      await OrderTrialAPI.get({
        field_ids: report.fields,
      }).then(response => {
        this.formData = response.data.form_data
        this.tab = 1
      })

      this.yearSelected = report.order_manifest.year
      this.yearSelectedComparison = report.order_manifest.yearComparsion
      this.cropSelected = report.order_manifest.crop
      this.voxelResolution = report.order_manifest.target_resolution
      this.treatmentProduct = Object.keys(
        report.order_manifest.treatment_lut
      )[0]
      this.treatmentRange = report.order_manifest.treatment_lut
      if (Object.keys(report.order_manifest.control_lut).length > 0) {
        this.controlProduct = Object.keys(report.order_manifest.control_lut)[0]
        this.controlRange = report.order_manifest.control_lut
      } else {
        this.controlProduct = "None"
      }

      this.comparisonDistance = [
        report.order_manifest.min_control_treatment_comparison_distance,
        report.order_manifest.max_control_treatment_comparison_distance,
      ]
      this.minAcres = 3
      this.interpolation = report.order_manifest.use_interpolation
      this.execSummary = report.order_manifest.execSummary
      this.yieldExternalities = report.order_manifest.yieldExternalities
      this.reportTitle = report.order_manifest.report_title
    },

    async handleOrder() {
      const activeFieldIds = this.activeFields.map(field => field.id)
      await OrderTrialAPI.get({
        field_ids: activeFieldIds,
      }).then(response => {
        console.log("response data: ", response.data)
        this.formData = response.data.form_data
        this.tab = 1
      })
    },

    handleSubmitReport() {
      this.isOrderSubmitting = true
      let treatmentObj = {}
      treatmentObj[this.treatmentProduct] = this.treatmentRange
      if (this.treatmentProduct === "None") {
        treatmentObj = []
      }

      let controlObj = {}
      controlObj[this.controlProduct] = this.controlRange
      if (this.controlProduct === "None") {
        controlObj = []
      }

      const activeFieldIds = this.activeFields.map(field => field.id)

      const payload = {
        year: parseInt(this.yearSelected),
        comparsion_year: parseInt(this.yearSelectedComparison),
        crop: this.cropSelected,
        target_resolution: this.voxelResolution,
        treatment_lut: treatmentObj,
        control_lut: controlObj,
        min_control_treatment_comparison_distance: this.comparisonDistance[0],
        max_control_treatment_comparison_distance: this.comparisonDistance[1],
        use_interpolation: this.interpolation,
        execSummary: this.execSummary,
        yieldExternalities: this.yieldExternalities,
        report_title: this.reportTitle,
      }
      OrderTrialAPI.createReport({
        info: payload,
        field_ids: activeFieldIds,
      })
        .then(response => {
          this.isOrderSubmitting = false
          const successVal = response.data
          this.submitMessage =
            successVal === "Success"
              ? "Trial Order Submitted"
              : "Error submitting data"
        })
        .catch(err => {
          console.log("Error: ", err)
          this.submitMessage = "Error submitting data"
        })
    },

    toggleZoneFields(fieldIds) {
      const selFields = this.selectedFields
      for (const fieldId in selFields) {
        if (fieldIds.includes(Number(fieldId))) {
          selFields[fieldId] = true
        } else {
          selFields[fieldId] = false
        }
      }

      this.setSelectedFields(fieldIds)
    },
  },

  watch: {
    treatmentProdSlideOptions: function(newVal) {
      this.treatmentRange = newVal
    },

    controlProdSlideOptions: function(newVal) {
      this.controlRange = newVal
    },

    async tab(newTab, oldTab) {
      if (oldTab === 1 || newTab !== 1) {
        this.yearSelected = null
        this.cropSelected = null
        this.voxelResolution = 10
        this.treatmentProduct = null
        this.treatmentRange = [0, 1]
        this.controlProduct = null
        this.controlRange = [0, 1]
        this.comparisonDistance = [30, 4000]
        this.interpolation = false
        this.execSummary = null
        this.yieldExternalities = null
        this.reportTitle = null
        this.zoneGeoJSON = null
        this.submitMessage = null
      }
      if (newTab === 2) {
        await OrderTrialAPI.list().then(response => {
          this.listReports = response.data
        })
      }
    },
  },
}
</script>
<style scoped>
.map-wrapper {
  height: calc(100vh - 65px);
  width: 100%;
  margin-top: -30px;
  margin-bottom: -17px;
  position: relative;
  background: #1b1b1d;
}

.scroll-col {
  max-height: 85vh;
  overflow: scroll;
  padding-right: 40px;
}

.reverse-margin {
  margin-bottom: -60px;
}

.submit-success {
  color: green;
}

.submit-error {
  color: red;
}

.response-message {
  margin-top: 12px;
}

.fa-file-pdf-o {
  margin-right: 8px;
}

.report-link {
  font-weight: 500;
}
</style>
