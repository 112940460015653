import API from "./API"

const defaultsEndpoint = "/defaults/"
const fieldSettingsEndpoint = "/field-settings/"
const newCarbonSettingsEndpoint = "/new-carbon-settings/"

const list = () => API.get(defaultsEndpoint)

const listFieldSettings = () => API.get(fieldSettingsEndpoint)

const create = payload => API.post(defaultsEndpoint, payload)

const update = (endpoint, defaultsId, payload) => {
  for (const key in payload) {
    if (payload[key] === "") payload[key] = null
  }
  return API.put(`/${endpoint}/${defaultsId}/`, payload)
}

const deleteSettingsTemplate = defaultsId =>
  API.delete(`${defaultsEndpoint}${defaultsId}/`)

const applyFieldDefaults = ({ id, field_ids }) =>
  API.put(`${defaultsEndpoint}${id}/apply_fields_defaults/`, { field_ids })

const getNewFieldSettings = () => API.get(newCarbonSettingsEndpoint)

const getCarbonSettingsByField = payload =>
  API.post(newCarbonSettingsEndpoint, payload, { timeout: 240000 })

const getCarbonPlantingsByField = payload =>
  API.post("/planting-carbon-settings/", payload, { timeout: 240000 })

const getCarbonOpsByField = payload =>
  API.post("/ops-carbon-settings/", payload, { timeout: 240000 })

const getCarbonFertByField = payload =>
  API.post("/fert-carbon-settings/", payload, { timeout: 240000 })

const updateFieldSetting = (endpoint, defaultsId, payload, queryParams) => {
  return API.put(`/${endpoint}/${defaultsId}/`, payload, {
    params: queryParams,
  })
}

const addFieldCrops = payload => API.post("/field-crop-user/", payload)

const getCarbonDataCompletion = payload =>
  API.post("/cready-data-completion/", payload)

const updateFieldCropByFieldYear = payload =>
  API.post("/field-crop-update/", payload)

const createDataVersion = payload => API.post("/data-version/", payload)

const exportPracticesCSV = payload => API.post(`export-practice-csv/`, payload)

const getPracticeChoices = () => API.get("/practices-schema/")

export default {
  list,
  listFieldSettings,
  create,
  update,
  deleteSettingsTemplate,
  applyFieldDefaults,
  getNewFieldSettings,
  getCarbonSettingsByField,
  updateFieldSetting,
  addFieldCrops,
  getCarbonPlantingsByField,
  getCarbonOpsByField,
  getCarbonFertByField,
  getCarbonDataCompletion,
  updateFieldCropByFieldYear,
  createDataVersion,
  exportPracticesCSV,
  getPracticeChoices
}
