<template>
  <div class="confirmations-uploader-view">
    <file-upload
      ref="upload"
      v-model="files"
      @input="updatedFile"
      @input-filter="fileInputFilter"
      accept=".zip, .csv, .jpg, .jpeg, .png, .pdf"
      drop
      multiple
    />

    <ConfirmationsUploadModal
      v-if="showUploadModal"
      :confirmation="selectedConfirmationForUpload"
      :confirmationNumber="currentConfirmationNumber"
      :isUploading="isUploading"
      @close-modal="handleCloseModal"
      @open-upload="$refs.upload.$el.children[0].click()"
    />

    <h1 v-if="receivedClient != undefined">{{ receivedClient }} | 2023 Confirmations</h1>

    <div class="welcome-wrapper">
      <p>
        Welcome to the documentation uploader for the 2023 enrollment year. Here, you can add all required documentation associated with your enrolled fields for this year. Ensure that each document corresponds to the 2023 enrollment period. You may add documents for all of your farms here. Should you have any questions or need help with the upload process, please reach out to our support team at <a href="mailto:support@arvaintelligence.com">support@arvaintelligence.com</a>
      </p>
    </div>

    <div class="code-receiver">
      <label>Your confirmations code</label>

      <div class="code-receiver-inputs">
        <v-text-field v-model="confirmationCode" outlined hide-details />
        <v-btn outlined height="56" :disabled="confirmationCode.length != 10" @click="fetchUploaderSpec">Update</v-btn>
      </div>
    </div>

    <div class="mt-6">
      <a @click="isUploadingWithoutConfirmationCode=true" class="no-code-trigger">I don't have a code.</a>
    </div>

    <div v-if="isUploadingWithoutConfirmationCode" class="uploader-wrapper">
      <div class="uploader-entry uploader-for-attestation">
        <div class="uploader-title">
          <div class="uploader-entry-content-wrapper mb-0">
            <div class="uploader-entry-copy">
              <p class="mr-4">Please select the client this confirmation pertains to.</p>
            </div>

            <div>
              <v-select
                class="mt-0 pt-0"
                v-model="noConfirmationCodeClientSelection"
                placeholder="Client (required)"
                item-text="name"
                item-value="id"
                :items="clients"
                clearable
              />
            </div>

            <div class="uploader-entry-buttons">
              <v-btn :disabled="noConfirmationCodeClientSelection == undefined" @click="handleOpenModal('General Confirmation')" height="40">Start Upload</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="uploader-wrapper">
      <v-progress-circular v-if="loading" indeterminate :size="100" :width="6" />

      <div class="error-note" v-else-if="failedToFetch">
        <p class="text-danger">Something went wrong attempting to fetch your uploads.</p>
        <p class="text-danger">Are you sure you entered the right code?</p>
      </div>

      <div class="uploader-entry-wrapper" v-else-if="practicesForUploads != undefined && practicesForUploads.length > 0">
        <!-- <div class="uploader-entry uploader-for-attestation">
          <div class="uploader-title">
            <div class="uploader-entry-content-wrapper mb-0">
              <div class="uploader-entry-copy">
                <h3>Attestation</h3>
                <p>Please download your attestation form, sign it and re-upload here</p>
              </div>

              <div class="uploader-entry-buttons">
                <v-btn height="40">Upload SIGNED FORM</v-btn>
              </div>
            </div>
          </div>
        </div> -->

        <div
          class="uploader-entry uploader-for-practice"
          v-for="(practice, idx) in practicesForUploads"
          :key="`${practice['label']}-${idx}`"
        >
          <div class="uploader-entry-content-wrapper">
            <div class="uploader-entry-copy">
              <h3>{{ practice['label'] }}</h3>

              <p>
                Add any {{ practice['label'].toLowerCase() }} documentation here to manually confirm using images, plans, and documents. 
                {{ practice['machineData'] ? 'If you haven’t connected your machine data files - do that here.' : '' }}
              </p>

              <div class="uploader-entry-uploads-wrapper" v-if="practice['uploads'].length > 0">
                <h4>{{ practice['uploads'].length }} Upload{{ practice['uploads'].length > 1 ? 's' : '' }}</h4>

                <div>
                  <a v-for="(u, idx) in practice['uploads']" :key="u[0] + '-key-' + idx" :href="u[0]">{{ getFormattedDate(u[1]) }}</a>
                </div>
              </div>
            </div>

            <div class="uploader-entry-buttons">
              <v-btn
                :class="practice['uploads'].length > 0 ? 'has-uploads-already' : ''"
                @click="handleOpenModal(practice['label'], practice['confirmationNumber'])"
                height="40"
              >
                Upload {{ practice['label'] }} Files
              </v-btn>

              <v-btn
                v-if="practice['machineData']"
                class="machine-data-uploader"
                @click="$router.push('/uploads')"
                height="40"
              >
                Add Machine Data
              </v-btn>
            </div>
          </div>

          <v-textarea label="Optional notes" outlined rows="2" hide-details />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import JSZip from "jszip";
import moment from "moment";
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import { Uploads, Filter } from "@/store/modules";
import { getUploaderSpec } from "@/api/PracticeConfirmationsAPI";
import { PRACTICE_CONFIRMATIONS_LABELS } from "@/constants";

import ConfirmationsUploadModal from "@/components/modals/ConfirmationsUploadModal";

export default {
  name: "PracticeConfirmationsUploader",
  components: { ConfirmationsUploadModal },
  data() {
    return {
      files: [],
      confirmationCode: '',
      loading: false,
      failedToFetch: false,
      practicesForUploads: [],
      receivedClient: null,
      receivedOrgId: null,
      receivedOrgName: null,
      currentConfirmationNumber: null,
      showUploadModal: false,
      selectedConfirmationForUpload: 'AWD Irrigation',
      isUploading: false,
      isUploadingWithoutConfirmationCode: false,
      noConfirmationCodeClientSelection: ''
    }
  },
  computed: {
    ...mapGetters({
      clients: Filter.Getters.getClients,
    }),
    ...mapState({
      organization: state => state.Organization.organization,
    }),
    noConfirmationCodeChoices() {
      return [];
    }
  },
  methods: {
    ...mapActions({
      createUpload: Uploads.Actions.create
    }),
    ...mapMutations({
      setUploadPercentage: Uploads.Mutations.setUploadPercentage,
    }),
    getFormattedDate(date) {
      return new Date(date).toLocaleDateString('en-us', { year: 'numeric', month: '2-digit', day: 'numeric' });
    },
    handleOpenModal(confirmationLabel, confirmationNumber=undefined) {
      this.showUploadModal = true;
      this.selectedConfirmationForUpload = confirmationLabel;
      this.currentConfirmationNumber = confirmationNumber;
    },
    handleCloseModal() {
      this.showUploadModal = false;
      this.selectedConfirmationForUpload = null;
      this.currentConfirmationNumber = null;
      this.setUploadPercentage(0);
    },
    resetDataProperties() {
      this.receivedClient = null;
      this.receviedOrgId = null;
      this.practicesForUploads = [];
    },
    async fetchUploaderSpec() {
      // the user has input a confirmation code, reset this back to false to hide the no-code uploader
      this.isUploadingWithoutConfirmationCode = false;

      if (this.confirmationCode.length == 10) {
        // we possibly have a correct code, hit our server to see if we can fetch an uploader spec
        try {
          this.resetDataProperties();
          this.loading = true;
          this.failedToFetch = false;

          const { data } = await getUploaderSpec({ confirmationCode: this.confirmationCode.toUpperCase() });

          this.loading = false;

          const spec = data['spec'];

          for (const practice in spec) {
            this.practicesForUploads.push({
              id: spec[practice]['id'],
              label: PRACTICE_CONFIRMATIONS_LABELS[practice],
              confirmationNumber: spec[practice]['confirmation_number'],
              machineData: spec[practice]['machine_data'],
              fileUploads: spec[practice]['file_uploads'],
              uploads: spec[practice]['uploads']
            });
          }

          if (data['client'] != undefined) this.receivedClient = data['client'];
          if (data['org_id'] != undefined) this.receivedOrgId = data['org_id'];
          if (data['org_name'] != undefined) this.receivedOrgName = data['org_name'];
        }
        catch (e) {
          this.loading = false;
          this.failedToFetch = true;
        }
      }
    },
    async updatedFile(files) {
      this.isUploading = true;

      const uploadSpec = {
        orgId: this.receivedOrgId,
        datasetType: 'practice-confirmations',
        confirmationClientSpecs: { confirmationNumber: this.currentConfirmationNumber }
      };

      if (this.isUploadingWithoutConfirmationCode) {
        uploadSpec['orgId'] = this.organization.id;
        uploadSpec['confirmationClientSpecs']['type'] = 'general_confirmation';
        uploadSpec['confirmationClientSpecs']['client'] = this.noConfirmationCodeClientSelection;
        uploadSpec['confirmationClientSpecs']['year'] = new Date().getFullYear();
      }

      if (files.length == 1 && files[0].name.includes("zip")) {
        const { file } = files[0];
        await this.createUpload({ file, ...uploadSpec });
        this.handleCompletedUpload();
      }
      else if (files.length > 0) {
        const zip = new JSZip();
        for (const file of files) zip.file(file.name, file.file);
        const result = await zip.generateAsync({ type: "blob" });
        const datetimeStr = moment().format("YYYY-MM-DD_h:mm:ss");
        const orgName = this.isUploadingWithoutConfirmationCode ? this.organization.name : this.receivedOrgName;
        const newZipName = `${orgName}-practice-confirmations-${datetimeStr}.zip`;
        const file = new File([result], newZipName);
        await this.createUpload({ file, ...uploadSpec });
        this.handleCompletedUpload();
      }

      this.$router.go();
    },
    fileInputFilter(newFile, prevent) {
      if (!/\.(zip?|jpg?|jpeg?|png?|pdf?)$/i.test(newFile.name)) return prevent();
    },
    handleCompletedUpload() {
      this.isUploading = false;
      setTimeout(() => this.showUploadModal = false, 2000);
      this.files = [];
    }
  },
  async mounted() {
    const { code } = this.$route.query;

    if (code != undefined) {
      this.confirmationCode = code;
      await this.fetchUploaderSpec();
    }
  }
}
</script>

<style scoped>
.confirmations-uploader-view {
  margin-left: 75px;
  max-width: 975px;
}
.confirmations-uploader-view>h1 {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin: 72px 0 32px;
}
.welcome-wrapper {
  padding: 24px 32px 16px;
  background-color: rgba(25, 118, 210, 0.06);
}
.welcome-wrapper p {
  color: #054789;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.welcome-wrapper p a {
  text-decoration: underline;
  color: #054789;
}
.code-receiver {
  margin-top: 20px;
}
.code-receiver>label {
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.code-receiver-inputs {
  display: flex;
}
.code-receiver-inputs>.v-input {
  max-width: 150px;
  margin-right: 12px;
}
.code-receiver-inputs>.v-input /deep/ input {
  text-transform: uppercase;
}
.uploader-wrapper {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  margin-top: 36px;
}
.uploader-entry-wrapper {
  width: 100%;
}
.uploader-entry {
  padding: 24px 32px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  width: 100%;
  margin-bottom: 36px;
}
.uploader-entry .uploader-entry-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 52px;
}
.uploader-entry .uploader-entry-copy {
  width: 70%;
}
.uploader-entry .uploader-entry-copy > h3 {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
}
.uploader-entry .uploader-entry-copy > p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
  margin-right: 96px;
}
.uploader-entry .uploader-entry-buttons {
  display: flex;
  width: 30%;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.uploader-entry .uploader-entry-buttons > button {
  padding: 0 16px;
  background-color: #67AC5B;
  text-transform: uppercase;
  color: #FFFFFF;
  box-shadow: none;
}
.uploader-entry .uploader-entry-buttons > button.machine-data-uploader,
.uploader-entry .uploader-entry-buttons > button.has-uploads-already {
  margin-top: 20px;
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
  outline: 1px solid #C5C9D2;
}
.uploader-entry .uploader-entry-uploads-wrapper {
  display: flex;
}
.uploader-entry .uploader-entry-uploads-wrapper > h4 {
  font-size: 14px;
  line-height: 21px;
  margin: 0 12px 0 0;
}
.uploader-entry .uploader-entry-uploads-wrapper > div {
  display: flex;
  flex-wrap: wrap;
}
.uploader-entry .uploader-entry-uploads-wrapper > div > a {
  width: 100%;
}
.error-note>p {
  font-size: 24px;
  line-height: 1;
  text-align: center;
  margin: 0 auto 12px;
}
.no-code-trigger {
  text-decoration: underline !important;
  color: #054789 !important;
}
</style>
  