import API from "./API"

const resource = "/notifications/"

const get = () => {
  return API.get(`${resource}`)
}

const patchBulkUpdate = listOfIds => {
  return API.patch(`${resource}bulkupdate/`, { pk: listOfIds })
}

const getUnread = () => {
  return API.get(`${resource}/?read=false`)
}

export default {
  get,
  patchBulkUpdate,
  getUnread,
}
