<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <table class="table table-lg table-hover">
          <thead>
            <tr>
              <th width="120" />
              <th class="hover" @click="handleSortFieldName">
                Field
                <i
                  class="fa hover"
                  :class="sortIcon(sortByFieldName)"
                  @click="handleSortFieldName"
                />
              </th>
              <th class="hover" @click="handleSortAcreage">
                Acreage
                <i
                  class="fa hover"
                  :class="sortIcon(sortByAcreage)"
                  @click="handleSortAcreage"
                />
              </th>
              <th class="hover" @click="handleSortFarm">
                Farm
                <i
                  class="fa hover"
                  :class="sortIcon(sortByFarm)"
                  @click="handleSortFarm"
                />
              </th>
              <th>Reports</th>
            </tr>
          </thead>
          <tbody>
            <FieldReportsListRow
              v-for="field in sortFields"
              :key="field.id"
              :field="field"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import FieldReportsListRow from "@/components/fields/FieldReportsListRow"
import { Filter } from "@/store/modules"

export default {
  name: "FieldReportsList",

  components: { FieldReportsListRow },

  data() {
    return {
      sortByAcreage: null,
      sortByFarm: null,
      sortByFieldName: null,
    }
  },

  computed: {
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
    }),

    sortFields() {
      const sortValues = {
        acreage: this.sortByAcreage,
        "farm.name": this.sortByFarm,
        "defaults.name": this.sortBySettings,
        name: this.sortByFieldName,
      }
      let sortColumns = []
      let sortDirections = []
      for (const column in sortValues) {
        if (sortValues[column] !== null) {
          sortColumns.push(column)
          sortDirections.push(sortValues[column])
        }
      }
      const res = _.orderBy(this.selectedFields, sortColumns, sortDirections)
      return res
    },
  },

  methods: {
    handleSortAcreage() {
      if (!this.sortByAcreage) this.sortByAcreage = "asc"
      else if (this.sortByAcreage === "asc") this.sortByAcreage = "desc"
      else if (this.sortByAcreage === "desc") this.sortByAcreage = null
    },

    handleSortFarm() {
      if (!this.sortByFarm) this.sortByFarm = "asc"
      else if (this.sortByFarm === "asc") this.sortByFarm = "desc"
      else if (this.sortByFarm === "desc") this.sortByFarm = null
    },

    handleSortFieldName() {
      if (!this.sortByFieldName) this.sortByFieldName = "asc"
      else if (this.sortByFieldName === "asc") this.sortByFieldName = "desc"
      else if (this.sortByFieldName === "desc") this.sortByFieldName = null
    },

    handleSortSettings() {
      if (!this.sortBySettings) this.sortBySettings = "asc"
      else if (this.sortBySettings === "asc") this.sortBySettings = "desc"
      else if (this.sortBySettings === "desc") this.sortBySettings = null
    },

    sortIcon(sortValue) {
      if (sortValue === "asc") return "fa-sort-up"
      else if (sortValue === "desc") return "fa-sort-down"
      return "fa-sort"
    },
  },
}
</script>
