<template>
  <div class="map-wrapper">
    <button @click="showSidebar = !showSidebar" :class="`custom-btn custom-btn-list ${showSidebar ? 'hide-custom-btn' : ''}`">
      <img :src="fileImg" />
    </button>

    <button @click="handleUploadRoute" :class="`custom-btn custom-btn-add ${showSidebar ? 'hide-custom-btn' : ''}`">
      <img :src="uploadImg" />
    </button>

    <div id="confirmations-map" />

    <div class="sidebar-container" v-if="showSidebar">
      <button class="sidebar-container-closer" @click="showSidebar = false">
        <i class="fa fa-chevron-left" />
      </button>

      <div class="sidebar-container-overlay" @click="showSidebar = false" />

      <div class="sidebar-container-header-wrapper">
        <h2>Confirmation History</h2>
  
        <v-btn @click="handleUploadRoute" class="in-list-view-add-button">
          Add confirmation
        </v-btn>
      </div>

      <div class="confirmation-table-wrapper" v-if="confirmationsFiltered.length>0">
        <v-btn-toggle v-model="confirmationGroup" mandatory tile color="#000000"
          class="d-flex flex-wrap mb-2 confirmation-toggler" group>
          <v-btn height="32px" value="all">All</v-btn>
        </v-btn-toggle>

        <div class="confirmation-group" v-if="confirmationGroup == 'all'">
          <table>
            <thead>
              <tr>
                <th class="text-left">Action Item</th>
                <th class="text-left">Crop</th>
                <th class="text-left">Date</th>
                <th class="text-left">Farm</th>
                <th class="text-left">Fields</th>
                <th class="text-left">Type</th>
                <th class="text-left">Status</th>
              </tr>
            </thead>
  
            <tbody>
              <tr
                v-for="c in confirmationsFiltered"
                :key="c['id'] + c['confirmation_number']"
                :class="getHighlightedClassState(c)"
              >
                <td class="text-nowrap confirmation-number-column">{{ c['confirmation_number'] || 'None' }}</td>
                <td>{{ getFormattedCrop(c['crops']) }}</td>
                <td>{{ c['year'] }}</td>
                <td>{{ getConfirmationFarm(c) }}</td>
                <td :class="c['fields'] && c['fields'].length>0 ? 'field-has-tooltip' : ''">
                  {{ c['fields'] && c['fields'].length>0 ? `${c['fields'].length} Fields` : 'Farm-Wide' }}
                  <div v-if="c['fields'] && c['fields'].length>0" class="confirmation-fields-tooltip">
                    <p v-for="field in c['fields']" :key="'field' + field.id">{{ field.name }}</p>
                  </div>
                </td>
                <td>
                  <a
                    :download="c['uploads'] && c['uploads'].length > 0"
                    :href="c['uploads'].length>0 ? c['uploads'][0]['raw_data'] : null"
                    :class="c['uploads'].length==0 ? 'no-cursor-link' : ''"
                  >
                    {{ c['confirmation_type'] || 'None' }}
                  </a>
                </td>
                <td>
                  <a
                    :href="getConfirmationUploadHref(c)"
                    :class="c['uploads'] && c['uploads'].length>0 ? 'confirmation-added' : 'needs-confirmation'"
                    class="confirmation-upload-status"
                  >
                    {{ c['uploads'] && c['uploads'].length>0 ? 'Added' : 'Add Confirmation' }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <p>No confirmations found for this year. Select another in the side menu.</p>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import { MAPBOX_TOKEN } from "@/constants/map";
import { Filter } from "@/store/modules";
import { multiPolygon, point } from "@turf/helpers";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { mapGetters, mapMutations, mapState } from "vuex";
import { getAllConfirmations } from "@/api/PracticeConfirmationsAPI";
import { CONFIRMATION_TYPE_CHOICES } from "@/constants/defaults";
import { DROPDOWN } from "@/constants";
import file from "@/assets/images/file-icon.svg";
import upload from "@/assets/images/plus.svg";

export default {
  name: "PracticeConfirmationsView",
  data() {
    return {
      showSidebar: false,
      confirmationGroup: 'all',
      confirmationData: [],
      fieldMarkerMap: {},
      markerList: [],
      highlightedField: null,
      map: null,
      zoomControlEl: null
    }
  },
  methods: {
    ...mapMutations({
      toggleField: Filter.Mutations.toggleItem,
    }),
    getConfirmationUploadHref(confirmation) {
      if (confirmation['uploads'].length == 0) {
        const code = confirmation['practice_confirmation_group'].length > 0
          ? confirmation['practice_confirmation_group'][0]['confirmation_code']
          : null;
        return `/practice-confirmations-uploader/${code != undefined ? `?code=${code}` : ''}`;
      }
      return null
    },
    getConfirmationFarm(confirmation) {
      if (confirmation['farm'] != undefined) return confirmation['farm']['name'];
      else if (confirmation['farm'] == undefined && confirmation['fields'].length == 0) return 'All Farms';
    },
    createMap() {
      if (this.map) return;
      
      if (this.fieldBoundaries.length == 0 && this.map) {
        // if we don't have any boundaries, remove map layers
        this.map.eachLayer(layer => layer.options.boundary && this.map.removeLayer(layer));
        return
      }

      // create our base map layer
      const osmLayer = L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
        {
          attribution:
            '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          minZoom: 0,
          maxZoom: 16,
        }
      );

      // create our map
      this.map = L.map(document.querySelector("#confirmations-map"), {
        layers: [osmLayer],
        zoomControl: false
      })
      .on("click", this.handleMapClick);

      // move zoom controls to bottom left, fit our map to our field bounds and zoom in
      this.zoomControl = L.control.zoom({ position: 'bottomleft' })
      this.zoomControl.addTo(this.map);
      this.fitMapToBounds();

      // our map was just created, update it with appropriate markers + field bounds
      this.updateMap();
    },
    updateMap() {
      if (!this.map) return;

      this.calculateFieldMarkers();

      // clear our existing markers so we can repopulate the map with new ones
      this.markerList.forEach(marker => this.map.removeLayer(marker));
      this.markerList = [];

      // clear our existing bounds layers
      this.map.eachLayer((layer) => {
        if (layer.myTag && layer.myTag === "boundsGeoJSON")
          this.map.removeLayer(layer);
      });

      // create our layers from our field geojson
      if (this.fieldBoundaries) {
        // add our bounds layers
        this.fieldBoundaries.forEach(boundary => {
          const { centroid: { coordinates }, properties: { field } } = boundary;
          const boundsAreActive = this.activeFields.find(({ id }) => field['id'] == id);

          const layer = new L.GeoJSON(boundary, {
            style: {
              color: "#FFCC00",
              weight: 1,
              opacity: 0.85,
              fill: boundsAreActive
            },
            onEachFeature: (_feature, layer) => (layer.myTag = "boundsGeoJSON"),
          });

          this.map.addLayer(layer);

          // add our markers
          const fieldId = field['id'] && field['id'] in this.fieldMarkerMap ? field['id'] : null;
          if (!fieldId || !boundsAreActive) return;

          const completeCount = this.fieldMarkerMap[field['id']]['completed'];
          const incompleteCount = this.fieldMarkerMap[field['id']]['incomplete'];
          const markerHtml = `
            <span>${incompleteCount ? '+' : completeCount}</span>${this.iconSvg}
          `

          const newMark = L.marker(
            [coordinates[1], coordinates[0]],
            { icon: L.divIcon({
                html: markerHtml,
                iconSize: [27, 42],
                className: `confirmations-div-icon ${completeCount ? 'complete-icon' : ''} ${incompleteCount ? 'incomplete-icon' : ''}`,
                popupAnchor: [184, 128]
            })}
          );

          newMark
          .bindPopup(this.buildPopupContent({ field }))
          .on('click', () => this.highlightTable({ fieldId }))
          .on('mouseover', function() { this.openPopup() }) // 'this' is the marker instance
          .on('mouseout', function() { this.closePopup() })
          .addTo(this.map);

          this.markerList.push(newMark);
        });
      }
    },
    buildPopupContent({ field }) {
      let confirmationsTemplate = ``;

      const complete = { detail: 'Completed Confirmation', confirmations: [] };
      const incomplete = { detail: 'Incomplete Confirmation', confirmations: [] };

      this.fieldMarkerMap[field['id']]['confirmations'].forEach(confirmation => {
        if (confirmation['hasUploads']) complete['confirmations'].push(confirmation);
        else incomplete['confirmations'].push(confirmation);
      });

      [complete, incomplete].forEach(({ detail, confirmations }) => {
        if (confirmations.length == 0) return;

        // we're making a complete marker popup
        confirmationsTemplate += `
        <b>${confirmations.length} ${detail}${confirmations.length == 1 ? '' : 's'}</b><br>
        `;

        const confirmationsMap = {};
        confirmations.forEach(({ confirmation_type }) => {
          if (confirmation_type in confirmationsMap) {
            confirmationsMap[confirmation_type] += 1;
          }
          else {
            confirmationsMap[confirmation_type] = 1;
          }
        });

        confirmationsTemplate += '<ul>';
        for (const confType in confirmationsMap) {
          if (confirmationsMap[confType] > 1) {
            confirmationsTemplate += `<li>${confType} (${confirmationsMap[confType]})</li>`
          }
          else {
            confirmationsTemplate += `<li>${confType}</li>`;
          }
        }
        confirmationsTemplate += '</ul>';
      });

      return `
        <b>Field ${field['name']}</b>
        <br><ul>
          <li>${field['farm']['name']}
          <li>${field['acreage']} AC
        </ul

        <br>${confirmationsTemplate}
      `;
    },
    calculateFieldMarkers() {
      // reset our field marker map and recalculate what fields should have markers
      this.fieldMarkerMap = {};

      this.confirmationsFiltered.forEach(confirmation => {
        const fieldsByFarm = [];
        const fieldsByClient = [];

        if (confirmation['fields'].length == 0) {
          if (confirmation['farm'] != undefined) {
            // we don't have the field ids, get our farm id and crops and infer which field ids should be represented with markers
            // filter the active fields by farm id, then those fields' crops by confirmation-specific year and crop id
            if (this.activeFields) {
              this.activeFields.forEach(({ farm, id, field_crops }) => {
                if (farm['id'] == confirmation['farm']['id']) {
                  const relevantCropsByYear = field_crops.filter(({ year }) => year == confirmation['year']);
                  if (confirmation['crops'].every(crop => relevantCropsByYear.find(({ crop_id }) => crop['id'] == crop_id))) {
                    // we found all of the specified crops in this fields field_crop list
                    fieldsByFarm.push({ id });
                  }
                }
              });
            }
          }
          else if (confirmation['client'] != undefined) {
            // no farm is specified, no fields, but we have a client, so this is a client-wide confirmation
            // set markers to all fields in all farms related to the client
            if (this.activeFarms && this.activeFields) {
              const relevantFarms = this.activeFarms.filter(f => f['clientId'] == confirmation['client']).map(f => f['id']);
              const relevantFields = this.activeFields.filter(f => relevantFarms.includes(f['farm']['id']));
              fieldsByClient.push(...relevantFields);
            }
          }
        }

        // if we had fields tied to the confirmations, fieldsByFarm will be null
        // thus this array will only be the field-specific selections or the entirety of a farm's fields
        const fieldsNeedingMarkers = [...confirmation['fields'], ...fieldsByFarm, ...fieldsByClient];

        for (const { id } of fieldsNeedingMarkers) {
          if (id in this.fieldMarkerMap) {
            this.fieldMarkerMap[id]['completed'] += confirmation['hasUploads'] ? 1 : 0;
            this.fieldMarkerMap[id]['incomplete'] += confirmation['hasUploads'] ? 0 : 1;
            this.fieldMarkerMap[id]['confirmations'].push(confirmation);
          }
          else {
            this.fieldMarkerMap[id] = {
              completed: confirmation['hasUploads'] ? 1 : 0,
              incomplete: confirmation['hasUploads'] ? 0 : 1,
              confirmations: [confirmation]
            };
          }
        }
      });
    },
    findFieldFromLatLng(latlng) {
      const { lat, lng } = latlng
      const pt = point([lng, lat])
      return this.fieldBoundaries.find(field => {
        const fieldMPoly = multiPolygon(field.geometry.coordinates)
        return booleanPointInPolygon(pt, fieldMPoly)
      })
    },
    handleMapClick(e) {
      const { latlng } = e;
      const clickedField = this.findFieldFromLatLng(latlng)
      if (clickedField) {
        const { field } = clickedField.properties
        this.toggleField({
          id: field.id,
          dropdownType: DROPDOWN.Field,
          preventAutozoom: true,
        })
        this.lastMapAction = "click"
      }
    },
    fitMapToBounds() {
      if (this.map && this.fieldBoundaries.length) {
        this.map.fitBounds(L.geoJSON(this.fieldBoundaries).getBounds());
      }
    },
    highlightTable({ fieldId = null }) {
      this.showSidebar = true;
      this.highlightedField = fieldId;
    },
    getFormattedCrop(crops) {
      let cropStr = '';

      for (let i=0; i<crops.length; i++) {
        const { name } = crops[i];
        cropStr += `${name[0].toUpperCase()}${name.slice(1)}`;
        if (i != crops.length-1) cropStr += '\n';
      }

      return cropStr;
    },
    getFormattedFields(fields) {
      let fieldStr = '';

      for (let i=0; i<fields.length; i++) {
        const { name } = fields[i];
        fieldStr += `${name}`;
        if (i != fields.length-1) fieldStr += '\n';
      }

      return fieldStr;
    },
    getFormattedCreatedDate(dateStr) {
      return dateStr
        ? new Date(dateStr).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })  
        : 'None';
    },
    getHighlightedClassState(confirmation) {
      return this.highlightedField
        && confirmation['fields'].find(({ id }) => id == this.highlightedField)
          ? 'highlighted'
          : ''
    },
    handleUploadRoute() {
      this.$router.push(`/practice-confirmations-uploader`);
    },
    async fetchPracticeConfirmations() {
      // these checks for organization/boundaries/confirmationdata are in case the user navigates directly
      // to this page first. in most cases the vuex state won't be ready, so check here to prevent errors
      // and let the state watchers below handle the updates as they come
      try {
        if (this.organization && this.organization.id) {

          const { data } = await getAllConfirmations({ org_node_id: this.organization.id });
    
          this.confirmationData = data.map(confirmation => {
            const formattedType = CONFIRMATION_TYPE_CHOICES.find(ct => ct['value'] == confirmation['confirmation_type']);
            confirmation['confirmation_type'] = formattedType ? formattedType['name'] : 'None';
            confirmation['hasUploads'] = confirmation['uploads'].length > 0;
            return confirmation
          });
        }
      }
      catch(e) {
        console.log('ope', e);
      }
    }
  },
  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
      activeFarms: Filter.Getters.getFarms,
      activeClients: Filter.Getters.getClients
    }),
    ...mapState({
      fieldBoundaries: state => state.Map.fieldBoundaries,
      organization: state => state.Organization.organization,
      year: state => state.Organization.year,
    }),
    confirmationsFiltered() {
      return this.confirmationData.
      filter(c => {
        if (c['year'] == this.year) {
          if (c['fields'] != undefined && c['fields'].length>0) {
            // check we have fields, check if all fields are found in 
            // the activeFields, which is the nav + fieldbounds on the map
            return c['fields'].every(({ id }) => this.activeFields.find(field => id == field['id']));
          }
          else if (c['farm'] != undefined) {
            // no fields are specified, see if the farm is included in the nav
            return this.activeFarms.find(farm => farm['selected'] && farm['id'] == c['farm']['id']);
          }
          else if (c['client'] != undefined) {
            // no fields and no farms, but a client, so it's a client-level confirmation
            // include the confirmation if the client is included in active clients
            return this.activeClients.find(client => client['selected'] && client['id'] == c['client']);
          }
        }
      });
    },
    fileImg() {
      return file;
    },
    uploadImg() {
      return upload;
    },
    iconSvg() {
      return `
        <svg viewBox="0 0 384 512">
          <path d="M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z"/>
        </svg>
      `;
    }
  },
  async mounted() {
    await this.fetchPracticeConfirmations();

    // given we've parsed (or failed to parse) our confirmations data
    // create or update the map in case field boundaries are already loaded
    if (this.confirmationData.length > 0 && this.fieldBoundaries.length) {
      if (!this.map) {
        this.createMap();
        return
      }

      this.updateMap();
    }
  },
  watch: {
    fieldBoundaries() {
      // we have boundaries to work with, create our map instance
      if (!this.map) {
        this.createMap();
        return
      }

      // if the map is already created, just update it
      this.updateMap();
    },
    activeFields() {
      this.updateMap();
    },
    async organization(curr) {
      if (curr && curr['id']) {
        this.fetchPracticeConfirmations();
      }
    },
    showSidebar(curr) {
      if (curr && this.zoomControl) {
        this.map.removeControl(this.zoomControl);
        this.zoomControl = null;
        // this.map.removeControl(L.control.zoom({ position: 'bottomleft' }));
      }
      else {
        if (!this.zoomControl) {
          this.zoomControl = L.control.zoom({ position: 'bottomleft' });
          this.zoomControl.addTo(this.map);
        }
      }
    }
  }
}
</script>

<style scoped>
.map-wrapper {
  height: calc(100vh - 65px);
  width: 100%;
  margin-top: -30px;
  margin-bottom: -17px;
  position: relative;
  background: #1b1b1d;
}

.custom-btn {
  position: absolute;
  z-index: 450;
  left: -30px;
  width: 44px;
  height: 44px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  background-color: white !important;
  border-radius: 4px !important;
  margin: 12px 0 0 12px !important;
  color: black !important;
  opacity: 1;
}

.custom-btn::after {
  display: flex;
  justify-content: center;
  align-items: center;
  content: '';
  position: absolute;
  top: 0;
  left: 56px;
  height: 44px;
  width: 150px;
  color: white;
  background-color: #054789;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.125s;
  pointer-events: none;
}

.custom-btn:hover::after {
  opacity: 1;
}

.custom-btn-list {
  top: 0;
}

.custom-btn-list::after {
  content: 'List view';
}

.custom-btn-add {
  top: 56px;
}

.custom-btn-add::after {
  content: 'Add confirmation';
}

.sidebar-container {
  position: absolute;
  left: -30px;
  top: 0;
  height: 100%;
  width: 940px;
  background-color: white;
  z-index: 400;
  padding: 42px 56px;
}

.sidebar-container .sidebar-container-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-container .sidebar-container-header-wrapper > button {
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  letter-spacing: normal;
  padding: 16px 12px;
  color: white;
  height: 54px;
}

.sidebar-container .confirmation-table-wrapper {
  height: calc(100% - 54px); /* parent height - title block height */
}

.sidebar-container-closer {
  position: absolute;
  top: 24px;
  right: -22.5px;
  width: 45px;
  height: 55px;
  background: white;
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.25);
}

.sidebar-container-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 940px;
  /* same as sidebar width */
  cursor: pointer;
}

.sidebar-container .in-list-view-add-button {
  background: #67AC5B;
}

.confirmation-group {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100% - 54px);
}

.confirmation-toggler>button {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 4px !important;
}

table {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 16px;
}

table tr {
  vertical-align: top;
}

thead th,
table td {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  padding: 8px 16px;
}

thead th {
  text-transform: uppercase;
  color: #757575;
}

table td {
  white-space: pre-wrap;
}

table td.field-has-tooltip {
  color: #054789;
  position: relative;
  cursor: pointer;
}

table td.field-has-tooltip:hover .confirmation-fields-tooltip {
  opacity: 1;
  pointer-events: all;
}

table td.field-has-tooltip .confirmation-fields-tooltip {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  z-index: 1;
  top: -50%;
  right: -200px;
  background: white;
  border-radius: 4px;
  padding: 16px 12px;
  width: 200px;
  max-height: 400px;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
  transition: opacity 0.25s;
  opacity: 0;
  pointer-events: none;
  overflow-y: scroll;
  overflow-x: hidden;
}

table td.field-has-tooltip .confirmation-fields-tooltip:hover {
  opacity: 1;
}

table td.field-has-tooltip .confirmation-fields-tooltip > p {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
}

table td a.confirmation-upload-status {
  display: flex;
  align-items: center;
  width: min-content;
  padding: 2px 12px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  border-radius: 4px;
  white-space: nowrap;
}

table td a.confirmation-upload-status.needs-confirmation {
  color: #D22F19;
  background: rgba(255, 122, 0, 0.2);
}

table td a.confirmation-upload-status.confirmation-added {
  color: rgba(0, 0, 0, 0.87);
  background: #C7E3C1;
  cursor: default;
}

table td.confirmation-number-column {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-cursor-link {
  cursor: default;
}

.hide-custom-btn {
  opacity: 0;
}

@media screen and (max-width: 992px) {

  .custom-btn,
  .sidebar-container {
    left: -20px;
  }
}

.highlighted {
  background: rgba(25, 118, 210, 0.10);
}

#confirmations-map {
  height: calc(100vh - 65px);
  position: relative;
  background: #1b1b1d;
  margin: -17px -29px -17px -29px;
}

#confirmations-map /deep/ .leaflet-popup-tip-container,
#confirmations-map /deep/ .leaflet-popup-close-button {
  display: none;
}

#confirmations-map /deep/ .leaflet-popup .leaflet-popup-content-wrapper {
  background: rgba(4,20,35,0.89);
  border-radius: 0;
  pointer-events: none;
  width: 300px;
}

#confirmations-map /deep/ .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  color: white;
  font-family: 'Roboto';
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
}

#confirmations-map /deep/ .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content b {
  font-size: 16px;
  margin-bottom: 6px;
  width: 100%;
}

#confirmations-map /deep/ .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content ul {
  margin-bottom: 24px;
}

</style>

<style>
.confirmations-div-icon {
  position: absolute;
}

.confirmations-div-icon > span {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  font-size: 16px;
  color: white;
  position: relative;
  z-index: 1;
}

.confirmations-div-icon > svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.confirmations-div-icon.complete-icon > svg {
  fill: #67AC5B;
}

.confirmations-div-icon.complete-icon.incomplete-icon > svg {
  fill: #357F8F;
}

.confirmations-div-icon.incomplete-icon > svg {
  fill: #054789;
}

.confirmations-div-icon:hover > svg {
  fill: #1976D2 !important;
}

.hide-zoom-controls .leaflet-control-zoom {
  opacity: 0;
}
</style>
