import DatasetTypesAPI from "@/api/DatasetTypesAPI"
import JohnDeereAPI from "@/api/JohnDeereAPI"
import UploadsAPI from "@/api/UploadsAPI"
import { Uploads } from "."

const state = {
  accountConnections: {},
  datasetTypes: [],
  johnDeereStatus: null,
  uploads: null,
  uploadPercentage: 0,
  detailsModalUploadID: null,
  detailsModalUploadFieldID: null,
  mappingModalUploadID: null,
}

const getters = {}

const mutations = {
  [Uploads.Mutations.setAccountConnections](state, connections) {
    const connectionsDict = {}
    connections.forEach(item => (connectionsDict[item.connection] = item))
    state.accountConnections = connectionsDict
  },

  [Uploads.Mutations.setDatasetTypes](state, datasetTypes) {
    state.datasetTypes = [...datasetTypes]
  },

  [Uploads.Mutations.setDetailsModalUploadID](state, id) {
    state.detailsModalUploadID = id
  },

  [Uploads.Mutations.setDetailsModalUploadFieldID](state, id) {
    state.detailsModalUploadFieldID = id
  },

  [Uploads.Mutations.setJohnDeereStage](state, payload) {
    state.johnDeereStatus = payload
  },

  [Uploads.Mutations.setMappingModalUploadID](state, id) {
    state.mappingModalUploadID = id
  },

  [Uploads.Mutations.setUploads](state, uploads) {
    state.uploads = [...uploads]
  },

  [Uploads.Mutations.setUploadPercentage](state, uploadPercentage) {
    state.uploadPercentage = uploadPercentage
  },
}

const actions = {
  [Uploads.Actions.create](_, { orgId, datasetType, file, confirmationClientSpecs = null }) {
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append("organization_node_id", orgId);
      formData.append("dataset_type", datasetType);
      formData.append("raw_data", file);

      if (confirmationClientSpecs != null) {
        const { confirmationNumber, type, crops, year, farm=null, fields=null, client=null } = confirmationClientSpecs;
        formData.append("confirmation_type", type);
        formData.append("year", year);

        if (confirmationNumber != undefined) formData.append("confirmation_number", confirmationNumber);
        if (fields != undefined) formData.append("fields", fields);
        if (farm != undefined) formData.append("farm", farm);
        if (client != undefined) formData.append("client", client);
        if (crops != undefined) formData.append("crops", crops);
      }

      UploadsAPI.post(formData).then(resolve);
    })
  },

  [Uploads.Actions.addNewBoundary](_, { orgId, datasetType, file, fieldName }) {
    return new Promise(resolve => {
      const formData = new FormData()
      formData.append("organization_node_id", orgId)
      formData.append("dataset_type", datasetType)
      formData.append("raw_data", file)
      formData.append("field_name", fieldName)
      UploadsAPI.addNewBoundary(formData).then(resolve)
    })
  },

  [Uploads.Actions.editExistingBoundary](
    _,
    { orgId, datasetType, file, fieldId }
  ) {
    return new Promise(resolve => {
      const formData = new FormData()
      formData.append("organization_node_id", orgId)
      formData.append("dataset_type", datasetType)
      formData.append("raw_data", file)
      formData.append("field_id", fieldId)
      UploadsAPI.editExistingBoundary(formData).then(resolve)
    })
  },

  [Uploads.Actions.fetch]({ commit }, currentPage) {
    let payload = {
      importSource: localStorage.getItem("dataImportSource"),
      currentPage: currentPage,
    }
    console.log(payload)
    return new Promise(resolve => {
      UploadsAPI.get(payload).then(response => {
        // Github ticket 478: Data import page switches back from MyJD to MyComputer on the list of data imports
        //  Added this check to make sure we only setUploads for the correct tab that we're on.
        if (payload.importSource === localStorage.getItem("dataImportSource")) {
          commit(Uploads.Mutations.setUploads, response.data.results)
          resolve()
        }
      })
    })
  },

  [Uploads.Actions.fetchJohnDeereStage]({ commit }) {
    return new Promise(resolve => {
      JohnDeereAPI.fetchJohnDeereStage().then(response => {
        commit(Uploads.Mutations.setJohnDeereStage, response.data)
        resolve()
      })
    })
  },

  [Uploads.Actions.listDatasetTypes]({ commit }) {
    return new Promise(resolve => {
      DatasetTypesAPI.get().then(response => {
        commit(Uploads.Mutations.setDatasetTypes, response.data)
        resolve()
      })
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
