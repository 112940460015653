<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <!-- Crop Protection Info -->
      <div id="input-card-container">
        <v-card class="my-auto mx-auto" v-if="showProtectionInfo">
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-3">
              <v-btn
                class="mr-2"
                :input-value="protectionData.length > 0"
                depressed
                @click="handleYesProtectionClick"
              >
                Applied Protection <v-icon>mdi-check-bold</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                :input-value="protectionData.length == 0"
                depressed
                @click="handleNoProtectionClick"
              >
                No Protection <v-icon>mdi-close-thick</v-icon>
              </v-btn>
              <v-btn class="ml-auto" @click="handleInfoReset(true)">
                Reset Protection
              </v-btn>
              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="allProtectionComplete().length > 0"
                @click="handleSave()"
                >Save and Continue</v-btn
              >
            </v-row>
            <!-- <v-row class="mx-1" v-if="protectionData.length > 0">
            <div>
              <b class="text-h5">Add your crop protection applications:</b>
            </div>
          </v-row> -->
            <v-row v-if="protectionData.length > 0">
              <v-col md="12">
                <v-card
                  v-for="cp in protectionData"
                  :key="cp.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                    @click="cp.showAllInfo = !cp.showAllInfo"
                  >
                    Protection #{{ protectionData.indexOf(cp) + 1 }}
                    <v-icon
                      v-if="!cp.showAllInfo"
                      @click="cp.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="cp.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isCPComplete(cp) ? 'green accent-4' : 'red darken-1'
                          "
                        >
                          {{
                            isCPComplete(cp)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isCPComplete(cp)
                          ? "Application is good to go!"
                          : "You're missing a few fields, please complete this application to save and continue!"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click="handleRemoveCP(protectionData.indexOf(cp))"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="cp.showAllInfo">
                    <!-- crop and date -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >On which crop was this applied?<span
                              class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Crop"
                            :disabled="
                              cropSelect ? cropSelect.length == 1 : false
                            "
                            :items="cropChoicesFiltered"
                            v-model="cp.crop_id"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="mt-3 mb-2">
                          <b>Protection Date<span class="red--text">*</span></b>
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="cp.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="cp.date"
                                label="Protection Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="cp.date"
                              :picker-date.sync="cp.pickerDate"
                              :min="year + '-01-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="cp.menu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Method, Type, and Product -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7">
                            Describe your protection method, type, and
                            product:<span class="red--text">*</span>
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Method"
                            :items="methodChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="cp.method"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Type"
                            :items="typeChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="cp.type"
                            clearable
                          ></v-select>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            :disabled="cp.type ? false : true"
                            :label="cp.type ? 'Product' : 'Select a Type'"
                            :items="productsByType(cp.type)"
                            item-text="display_name"
                            item-value="value"
                            v-model="cp.product"
                            clearable
                          ></v-select>
                          <!-- <v-text-field
                            dense
                            outlined
                            hide-details
                            label="Product"
                            v-model="cp.product"
                          ></v-text-field> -->
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Rate and Units -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7"
                            >What was your protection application rate?<span
                              class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            label="Rate"
                            :rules="[numberValidator]"
                            v-model="cp.rate"
                            type="number"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="input-holder">
                          <b class="text-h7"></b>
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Units"
                            :items="unitChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="cp.unit"
                          >
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn class="mt-4" block @click="handleAddEventClick">
                  Add Crop Protection Application<v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="protectionData.length == 0">
              <v-card class="mx-auto mt-4" elevation="0">
                <v-card-text>
                  <div class="text-center">
                    <b class="text-h5 text-bold">
                      No Crop Protection Applied for {{ this.year }}
                    </b>
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your crop protection information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddMoreProtection">
                Add More Protection
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Crop Protection Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in summaryData" :key="data.idx">
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplay[data[key].value]
                      }}</span>
                      <span v-else>
                        {{
                          data[key].value
                            ? data[key].value
                            : "None" | cleanSnake
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <!-- Fields, Data Comp, and Add Practices -->
      <v-row class="mt-4 mb-2 row-margin-correct">
        <div>
          <span class="text-h5 ml-1"
            >{{ protectionSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              class="mx-1"
              text
              v-for="y in yearListModified"
              :key="y.idx"
              @click="handleSeasonSelection(y)"
              :color="year == y ? 'green' : ''"
              >{{ y }}</v-btn
            >
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn
              :class="showProtectionInfo ? 'black--text' : 'white--text'"
              :color="showProtectionInfo ? 'white' : 'green'"
              x-large
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>

      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn class="mr-3" @click="handleSelectAll(true)" text>
          <span>All</span>
          <span>({{ protectionSettingsByField.length }})</span>
        </v-btn>
        <v-btn
          class="mr-3"
          text
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          :color="
            JSON.stringify(cropSelect) ==
            JSON.stringify(sharedCropPlan.crops.split(','))
              ? 'green'
              : ''
          "
          @click="handleCropFilterButton(sharedCropPlan)"
        >
          <span>{{ mapCropNames(sharedCropPlan.crops) }}</span>
          <span>({{ sharedCropPlan.fieldIds.length }})</span>
        </v-btn>
      </v-row>

      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        >
                        </v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th>Crop Protection</th>
                    <th class="three">
                      <div>
                        Copy
                        <!-- <v-tooltip top
                        ><template v-slot:activator="{ on, attrs }"
                          ><v-icon v-bind="attrs" v-on="on"
                            >mdi-information</v-icon
                          ></template
                        ><span
                          >Click copy to copy a field's settings quickly to set
                          multiple fields at once.</span
                        ></v-tooltip
                      > -->
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in protectionSettingsByFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="
                          disableCheckbox(field.noYearData) || field.fieldLocked
                        "
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>{{ field.fieldName }}</td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td v-if="'cropNames' in field">
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <td v-else>
                      <div>No Crop</div>
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                      <v-row
                        class="mx-0"
                        :class="
                          field.protectionInfo.indexOf(protectionSingle) !=
                          field.protectionInfo.length - 1
                            ? 'row-bottom'
                            : ''
                        "
                        dense
                        v-for="protectionSingle in field.protectionInfo"
                        :key="protectionSingle.idx"
                      >
                        <v-col md="2">
                          <span
                            :class="
                              protectionSingle.date.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              protectionSingle.date.value
                                ? protectionSingle.date.value
                                : "No Date"
                            }}
                          </span>
                        </v-col>
                        <v-col md="3">
                          <span
                            :class="
                              protectionSingle.method.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                            >{{
                              protectionSingle.method.value
                                ? protectionSingle.method.value
                                : "No Method"
                            }}
                          </span>
                          <span
                            :class="
                              protectionSingle.type.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              protectionSingle.type.value
                                ? protectionSingle.type.value
                                : "No Type"
                            }}
                          </span>
                        </v-col>
                        <v-col md="2">
                          <span
                            :class="
                              protectionSingle.product.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              protectionSingle.product.value
                                ? protectionSingle.product.value
                                : "No Product"
                            }}
                          </span>
                        </v-col>
                        <v-col md="3">
                          <span
                            :class="
                              protectionSingle.rate.value !== null
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              protectionSingle.rate.value !== null
                                ? protectionSingle.rate.value
                                : "No Rate"
                            }}
                          </span>
                          <span
                            :class="
                              protectionSingle.unit.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                            >{{
                              protectionSingle.unit.value
                                ? protectionSingle.unit.value
                                : "No Unit"
                            }}</span
                          >
                        </v-col>
                        <v-col md="2"
                          ><!-- Data Complete -->
                          <div
                            class="d-flex"
                            v-if="protectionComplete(protectionSingle)"
                          >
                            <v-tooltip top
                              ><template v-slot:activator="{ on, attrs }"
                                ><v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  color="success"
                                  >mdi-check-circle</v-icon
                                ></template
                              ><span
                                >You've filled out enough information for Arva
                                data models to run. Thank you!</span
                              ></v-tooltip
                            >
                            <span class="ml-2">Data Complete</span>
                          </div>
                          <!-- Needs Confirmation -->
                          <div
                            class="d-flex"
                            v-else-if="protectionNeedsConfirm(protectionSingle)"
                          >
                            <v-tooltip top
                              ><template v-slot:activator="{ on, attrs }"
                                ><v-icon v-bind="attrs" v-on="on" color="yellow"
                                  >mdi-alert-rhombus</v-icon
                                ></template
                              ><span
                                >This information is coming from a public
                                source. Please include this field when setting
                                information to confirm it's data!</span
                              ></v-tooltip
                            >
                            <span
                              class="ml-2 cursor blue--text lighten-1 text-bold"
                              @click="handleConfirm(field)"
                              >Confirm Data</span
                            >
                          </div>
                          <!-- Missing Data -->
                          <div
                            class="d-flex"
                            v-else-if="protectionMissingData(protectionSingle)"
                          >
                            <v-tooltip top
                              ><template v-slot:activator="{ on, attrs }"
                                ><v-icon v-bind="attrs" v-on="on" color="red"
                                  >mdi-alert-circle</v-icon
                                ></template
                              ><span
                                >This field is missing data. Please fill in its
                                data using the wizard!</span
                              ></v-tooltip
                            >
                            <span class="ml-2">Missing Data</span>
                          </div></v-col
                        >
                      </v-row>
                    </td>
                    <td class="py-2" v-else>
                      <div @click="handleShowRedirect(field)">
                        <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on" color="red"
                              >mdi-alert-circle</v-icon
                            ></template
                          ><span
                            >This field doesn't have data for this year. Add
                            another year in the data spreadsheet or upload data
                            from this year.</span
                          ></v-tooltip
                        ><span class="ml-2 cursor red--text text-bold"
                          >No Data for Year</span
                        >
                      </div>
                    </td>
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <!-- Wizard Confirm Modal -->
      <WizardConfirmModal
        v-if="showConfirmModal"
        :confirmationField="confirmationField"
        :confirmationData="confirmationApplications"
        :wantedKeys="[
          'crop_id',
          'date',
          'product',
          'method',
          'type',
          'rate',
          'unit',
        ]"
        :year="year"
        :loadState="loadState"
        @close-template-modal="handleCloseConfirm"
        @confirm-data="handleModalConfirm"
      />

      <!-- Redirect -->
      <WizardRedirectModal
        v-if="showRedirectModal"
        :redirectField="redirectField"
        :year="year"
        @close-template-modal="handleCloseRedirect"
        @redirect-confirm="handleRedirectConfirm"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapMutations, mapState } from "vuex"
import {
  CROP_PROTECTION_METHOD_CHOICES,
  CROP_PROTECTION_TYPE_CHOICES,
  CROP_PROTECTION_FUNGICIDE_CHOICES,
  CROP_PROTECTION_INSECTICIDE_CHOICES,
  CROP_PROTECTION_HERBICIDE_CHOICES,
  CROP_PROTECTION_UNIT_CHOICES,
  MAX_DEFAULT_FIELDS,
} from "@/constants/defaults"
import { Defaults, Organization } from "@/store/modules"
import {
  CROPS,
  CROP_KEY_DISPLAY_NAME,
  CROP_DISPLAY_NAME,
  CROP_KEY,
} from "@/constants"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardConfirmModal from "@/components/modals/WizardConfirmModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import { numberValidator } from "@/utility"

export default {
  name: "ProtectionWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletion: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    WizardConfirmModal,
    WizardRedirectModal,
  },
  data() {
    return {
      cropDisplay: CROP_DISPLAY_NAME,
      cropChoices: Object.values(CROP_KEY),
      methodChoices: CROP_PROTECTION_METHOD_CHOICES,
      typeChoices: CROP_PROTECTION_TYPE_CHOICES,
      fungicideChoices: CROP_PROTECTION_FUNGICIDE_CHOICES,
      herbicideChoices: CROP_PROTECTION_HERBICIDE_CHOICES,
      insecticideChoices: CROP_PROTECTION_INSECTICIDE_CHOICES,
      unitChoices: CROP_PROTECTION_UNIT_CHOICES,
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,

      //   crop protection data
      protectionData: [],
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      filterFarms: [],
      filterFarmsReflection: [],
      tableFilterText: "",
      tableFilterFields: null,
      selectAllBox: true,
      // full validation for application completeness
      showIncompleteModal: false,
      incompleteProtections: null,
      alertErrorModalOpen: false,
      // confirmation
      confirmationField: null,
      confirmationApplications: null,
      showConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // table year for copying from historical info
      tableYear: null,
      priorTableYears: [2018, 2019, 2020, 2021],
      rules: {
        date: value => {
          var dateRegex = /^\d{4}-\d{2}-\d{2}$/
          return dateRegex.test(value) || "YYYY-MM-DD"
        },
      },
      numberValidator,
      //   select crop type UX, disables selecting new fields when selecting by crop tile
      cropSelect: null,
      //   new UI
      showProtectionInfo: false,
      showPracticesView: true,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      summaryKeys: null,
      summaryData: null,
    }
  },
  computed: {
    ...mapState({
      protectionSettings: state => state.Defaults.newFieldSettings,
    }),
    cropChoicesFiltered() {
      if (this.cropSelect != null) {
        const filteredCropList = Object.entries(CROP_KEY)
          .filter(o => {
            if (this.cropSelect.length > 1) {
              return this.cropSelect.includes(o[0])
            } else {
              return true
            }
          })
          .map(list => list[1])
        return filteredCropList
      } else {
        const selectedProtSettings = this.protectionSettingsByField.filter(
          field => this.fieldsInSelection.includes(parseInt(field.fieldId))
        )
        const selectedCrops = new Set(
          selectedProtSettings.flatMap(field => field.crops)
        )
        const selectedCropNames = Array.from(selectedCrops).map(
          c => CROP_KEY[c]
        )
        return selectedCropNames.filter(i => i != undefined)
      }
    },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      let yearDataComps = this.dataCompletionMaster["Crop Protection"].map(
        o => {
          if ("by_year" in o.data_comp) {
            let temp = o.data_comp.by_year.find(
              yearObj => yearObj.year == this.year
            )
            if (temp) {
              return temp.avg_for_year
            }
          } else {
            return 0
          }
        }
      )
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.ceil(average(yearDataComps))
      } else {
        return 0
      }
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.protectionSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    protectionSettingsByField() {
      let protectionDict = {}
      let yearInConsideration = this.year
      Object.entries(this.protectionSettings).forEach(protDefault => {
        const fieldKey = protDefault[0]
        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        protectionDict[fieldKey] = {}
        const fieldSettingObj = protDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
        }
        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsideration
        )
        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            protectionDict[fieldKey] = idSettings
            protectionDict[fieldKey]["crops"] = ["No Data"]
            protectionDict[fieldKey]["cropNames"] = ["No Data"]
            protectionDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsideration) {
            protectionDict[fieldKey] = idSettings
            protectionDict[fieldKey]["crops"] = []
            protectionDict[fieldKey]["crops"] = []
            protectionDict[fieldKey]["cropNames"] = []
            protectionDict[fieldKey]["cropSearch"] = ""
            protectionDict[fieldKey]["plantings"] = []
            protectionDict[fieldKey]["protectionInfo"] = []

            protectionDict[fieldKey]["isSelected"] =
              this.fieldsInSelection.includes(parseInt(fieldKey)) ? true : false

            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            cultivations.forEach(cultivation => {
              protectionDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              protectionDict[fieldKey]["cropNames"].push(
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              )
              protectionDict[fieldKey]["cropSearch"] =
                protectionDict[fieldKey]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              protectionDict[fieldKey]["plantings"] = protectionDict[fieldKey][
                "plantings"
              ].concat(cultivation.plantings)
              cultivation.crop_protection.forEach(cp =>
                protectionDict[fieldKey]["protectionInfo"].push(cp)
              )

              protectionDict[fieldKey]["crops"].sort()
              protectionDict[fieldKey]["cropNames"].sort()
              protectionDict[fieldKey]["fieldLocked"] =
                cultivation.year_data_locked
              protectionDict[fieldKey]["noYearData"] = false
            })
          }
        })
      })
      return Object.values(protectionDict)
    },
    protectionSettingsByFieldFiltered() {
      let arrayToReturn = _.cloneDeep(this.protectionSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["isSelected", "fieldName"]
      const sortDirections = ["desc", "asc"]

      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const plantingsGrouped = _(this.protectionSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
          noYearData: _.map(items, "noYearData")[0],
        }))
        .value()
      return plantingsGrouped
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateProtection: Defaults.Actions.updateProtection,
    }),
    ...mapMutations({
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (
        e == true &&
        this.fieldsInSelection.indexOf(parseInt(field.fieldId)) == -1
      ) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    handleAddEventClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.protectionData.push({
        crop_id: cropValue,
        date: this.year + "-01-01",
        rate: null,
        unit: null,
        method: null,
        product: null,
        type: null,
        showAllInfo: true,
        // menu is boolean for v-menu model
        menu: false,
        pickerDate: this.year + "-01",
      })
    },
    handleAddMoreProtection() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
    handleAddPracticesClick() {
      if (this.showProtectionInfo == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        this.showProtectionInfo = true
        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        this.protectionData = [
          {
            crop_id: cropValue,
            date: this.year + "-01-01",
            rate: null,
            unit: null,
            method: null,
            product: null,
            type: null,
            showAllInfo: true,
            // menu is boolean for v-menu model
            menu: false,
            pickerDate: this.year + "-01",
          },
        ]
      } else {
        this.showProtectionInfo = false
        this.protectionData = []
      }
    },
    handleCopy(field) {
      this.handleInfoReset(false)

      const numFieldProtections = field.protectionInfo.length

      for (let i = 0; i < numFieldProtections; i++) {
        this.protectionData.push({
          crop_id: CROP_KEY[field.protectionInfo[i].crop_id.value],
          date: field.protectionInfo[i].date.value,
          rate: field.protectionInfo[i].rate.value,
          unit: field.protectionInfo[i].unit.value,
          method: field.protectionInfo[i].method.value,
          product: field.protectionInfo[i].product.value,
          type: field.protectionInfo[i].type.value,
          showAllInfo: true,
          menu: false,
          pickerDate: this.year + "-01",
        })
      }

      this.showProtectionInfo = true
      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
        if (this.protectionData.length > 0) {
          const cropValue =
            this.cropSelect != null && this.cropSelect.length == 1
              ? CROP_KEY[parseInt(this.cropSelect[0])]
              : null
          this.protectionData.forEach(o => {
            o.crop_id = cropValue
          })
        }
      }
    },
    handleInfoReset(resetProtectionData = false) {
      this.cropSelect = null
      this.protectionData = []
      if (resetProtectionData) {
        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        this.protectionData.push({
          crop_id: cropValue,
          date: this.year + "-01-01",
          rate: null,
          unit: null,
          method: null,
          product: null,
          type: null,
          showAllInfo: true,
          // menu is boolean for v-menu model
          menu: false,
          pickerDate: this.year + "-01",
        })
      }
    },
    handleRemoveCP(cpIndex) {
      this.protectionData.splice(cpIndex, 1)
    },
    async handleSave() {
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }

      const cpCompleteness = this.protectionData.map(cp =>
        this.isCPComplete(cp)
      )
      const cpMissing = cpCompleteness.map((value, index) =>
        value == null ? index : ""
      )
      const indexOfMissing = cpMissing.filter(String)

      //   which are missing info
      if (indexOfMissing.length > 0) {
        this.incompleteProtections = indexOfMissing
        this.showIncompleteModal = true
      } else {
        // save cp to db
        let dataClone = _.cloneDeep(this.protectionData)
        let protectionPayload = null
        if (this.protectionData.length > 0) {
          protectionPayload = dataClone.map(cp => {
            // remove UI properties
            delete cp["showAllInfo"]
            delete cp["menu"]
            delete cp["pickerDate"]

            Object.keys(cp).forEach(cpKey => {
              if (cpKey == "crop_id") {
                cp[cpKey] = {
                  value: CROPS[CROP_KEY_DISPLAY_NAME[cp[cpKey]]],
                  source: "User Input",
                }
              } else {
                cp[cpKey] = {
                  value: cp[cpKey],
                  source: "User Input",
                }
              }
            })
            return cp
          })
        } else {
          const fieldsBeingSaved =
            this.protectionSettingsByFieldFiltered.filter(f =>
              this.fieldsInSelection.includes(Number(f.fieldId))
            )
          const cropsBeingSaved = fieldsBeingSaved
            .map(f => f.crops)
            .reduce((acc, arr) => acc.concat(arr), [])
            .filter((item, index, self) => self.indexOf(item) === index)
          protectionPayload = []
          cropsBeingSaved.forEach(cropId => {
            protectionPayload.push({
              crop_id: { value: cropId, source: "User Input" },
              date: { value: this.year + "-01-01", source: "User Input" },
              rate: { value: 0, source: "User Input" },
              unit: { value: "none", source: "User Input" },
              method: { value: "none", source: "User Input" },
              product: { value: "none", source: "User Input" },
              type: { value: "none", source: "User Input" },
            })
          })
        }

        this.showPracticesView = false
        this.showSaveView = true

        let protectionUpdates = []
        this.protectionSettingsByFieldFiltered.forEach(async field => {
          if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
            if (!field.noYearData && !field.fieldLocked) {
              protectionUpdates.push(
                this.updateProtection({
                  fieldId: field.fieldId,
                  year: this.year,
                  protectionPayload: protectionPayload,
                })
              )
            } else {
              console.log("no year data for ", field)
            }
          }
        })

        await Promise.all(protectionUpdates)
        this.$emit("update-data-complete")

        const fieldNames = this.protectionSettingsByFieldFiltered
          .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
          .map(f => f.fieldName)
        const protWantedKeys = [
          "crop_id",
          "date",
          "method",
          "product",
          "type",
          "rate",
          "unit",
        ]
        this.setRecentWizardData({
          wizard: "protection",
          data: this.protectionData,
        })

        this.summaryKeys = protWantedKeys
        this.summaryData = protectionPayload
        this.summaryFields = fieldNames
        this.showSaveView = false
        this.showSummaryView = true
      }
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleNoProtectionClick() {
      this.protectionData = []
    },
    handleYesProtectionClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.protectionData = [
        {
          crop_id: cropValue,
          date: this.year + "-01-01",
          rate: null,
          unit: null,
          method: null,
          product: null,
          type: null,
          showAllInfo: true,
          // menu is boolean for v-menu model
          menu: false,
          pickerDate: this.year + "-01",
        },
      ]
    },
    isCPComplete(cp) {
      return (
        cp.crop_id &&
        cp.date &&
        cp.rate !== null &&
        cp.unit &&
        cp.method &&
        cp.product &&
        cp.type
      )
    },
    allProtectionComplete() {
      if (!this.protectionData) return [0]
      const protCompleteness = this.protectionData.map(cp =>
        this.isCPComplete(cp)
      )
      const protMissing = protCompleteness.map((value, index) =>
        value == null ? index : ""
      )
      const indexOfMissing = protMissing.filter(String)
      return indexOfMissing
    },
    protectionComplete(cp) {
      if (!cp) return null
      return (
        cp.date.source == "User Input" &&
        cp.date.value != null &&
        cp.rate.source == "User Input" &&
        cp.rate.value !== null &&
        cp.unit.source == "User Input" &&
        cp.unit.value != null &&
        cp.method.source == "User Input" &&
        cp.method.value != null &&
        cp.product.source == "User Input" &&
        cp.product.value != null &&
        cp.type.source == "User Input" &&
        cp.type.value != null
      )
    },
    protectionNeedsConfirm(cp) {
      if (!cp) return null
      return (
        cp.date.source != "User Input" &&
        cp.date.value != null &&
        cp.rate.source != "User Input" &&
        cp.rate.value != null &&
        cp.unit.source != "User Input" &&
        cp.unit.value != null &&
        cp.method.source != "User Input" &&
        cp.method.value != null &&
        cp.product.source != "User Input" &&
        cp.product.value != null &&
        cp.type.source != "User Input" &&
        cp.type.value != null
      )
    },
    protectionMissingData(cp) {
      if (!cp) return null
      return (
        cp.date.source != "User Input" ||
        cp.date.value == null ||
        cp.rate.source != "User Input" ||
        cp.rate.value == null ||
        cp.unit.source != "User Input" ||
        cp.unit.value == null ||
        cp.method.source != "User Input" ||
        cp.method.value == null ||
        cp.product.source != "User Input" ||
        cp.product.value == null ||
        cp.type.source != "User Input" ||
        cp.type.value == null
      )
    },
    handleConfirm(field) {
      this.confirmationField = _.cloneDeep(field)
      this.confirmationApplications = _.cloneDeep(field.protectionInfo)
      this.confirmationApplications.forEach(cp => {
        for (const key in cp) {
          cp[key].source = "User Input"
        }
      })
      this.showConfirmModal = true
    },
    async handleModalConfirm() {
      this.loadState = "loading"
      await this.updateProtection({
        fieldId: this.confirmationField.fieldId,
        year: this.year,
        protectionPayload: this.confirmationApplications,
      })
      this.loadState = "confirmed"
      this.$emit("update-data-complete")
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.handleCloseConfirm()
    },
    handleCloseConfirm() {
      this.showConfirmModal = false
      this.confirmationField = null
      this.confirmationApplications = null
      this.loadState = null
    },
    productsByType(type) {
      const otherNoneOptions = [
        { display_name: "Other", value: "other" },
        { display_name: "None", value: "none" },
      ]
      if (type === "herbicide") {
        return this.herbicideChoices.concat(otherNoneOptions)
      }
      if (type === "fungicide") {
        return this.fungicideChoices.concat(otherNoneOptions)
      }
      if (type === "insecticide") {
        return this.insecticideChoices.concat(otherNoneOptions)
      }
      return otherNoneOptions
    },
  },
  mounted() {
    this.tableYear = this.year
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f])
  },
  watch: {
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.protectionSettingsByField)
        if (this.filterFarmsReflection.length > 0) {
          arrayToFilter = arrayToFilter.filter(field =>
            this.filterFarms.includes(field.farmName)
          )
        }
        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
  },
}
</script>
<style scoped>
.input-holder {
  width: 320px;
}
.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}
.min-step-height {
  min-height: 400px;
}

.text-container {
  width: 200px;
}

.all-button {
  margin-left: 86px;
}

.three {
  width: 3%;
}
.ten {
  width: 10%;
}

.cursor {
  cursor: pointer;
}
</style>
