<template>
  <div class="col-12">
    <b-alert v-model="showSuccessAlert" variant="success" dismissible>
      <div v-for="message in messages" :key="message">
        {{ message }}
      </div>
    </b-alert>

    <div class="nameSearchBar-header mb-2">
      <SearchBar :searchString="searchString" @searching="handleSearch" />
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="isReadOnly" id="field-select-controls">
          <h2>Fields</h2>
        </div>

        <div v-else id="field-select-controls">
          <h2>Fields</h2>
          <!-- <button
            :class="
              checkedCount === 0
                ? 'btn btn-primary disabled'
                : 'btn btn-primary'
            "
            :disabled="fieldsToEdit.length === 0"
            @click="applyTemplateModalOpen = true"
          >
            <span v-if="checkedCount === 0">Apply Field Settings</span>
            <span v-else>Apply Field Settings ({{ checkedCount }})</span>
          </button> -->

          <button
            v-if="isCustomerAdmin && checkedCount > 0"
            class="btn btn-outline-danger pull-right"
            @click="confirmDeleteModalOpen = true"
          >
            Delete Fields ({{ checkedCount }})
          </button>

          <button
            v-if="checkedCount > 0"
            class="btn btn-outline-danger pull-right"
            @click="confirmLockModalOpen = true"
          >
            Lock Fields ({{ checkedCount }})
          </button>

          <button
            v-if="checkedCount > 0"
            class="btn btn-outline-danger pull-right"
            @click="confirmUnlockModalOpen = true"
          >
            Unlock Fields ({{ checkedCount }})
          </button>

          <button
            v-if="checkedDataCount > 0"
            class="btn btn-outline-danger pull-right"
            @click="confirmDeleteDataModalOpen = true"
          >
            Delete Fields Data ({{ checkedDataCount }})
          </button>
        </div>

        <ConfirmModal
          v-if="confirmLockModalOpen"
          titleText="Confirm Field Locking"
          :confirmText="lockConfirmText"
          @confirm="lockFields"
          @close-modal="confirmLockModalOpen = false"
        />

        <ConfirmModal
          v-if="confirmUnlockModalOpen"
          titleText="Confirm Field Unlocking"
          :confirmText="unlockConfirmText"
          @confirm="unlockFields"
          @close-modal="confirmUnlockModalOpen = false"
        />

        <ConfirmModal
          v-if="confirmDeleteModalOpen"
          titleText="Confirm Field Deletion"
          :confirmText="deleteConfirmText"
          @confirm="deleteFields"
          @close-modal="confirmDeleteModalOpen = false"
        />

        <!-- <ApplyFieldDefaultsModal
          v-if="applyTemplateModalOpen"
          :selectedFields="fieldsToEdit"
          :templates="defaultsList"
          @clear-boxes="deselectAllCheckbox"
          @close-template-modal="closeApplyDefaultsModal"
        /> -->

        <FieldDataTypeDeletionModal
          v-if="fieldDataTypeDeletionModalOpen"
          :geoPkgsByDataType="geoPkgsByDataType"
          :selectFieldId="selectFieldId"
          :selectDataType="selectedDataType"
          @selectedGpkgsFromModal="selectedGpkgsFromModal"
          @close-modal="fieldDataTypeDeletionModalOpen = false"
        />

        <table class="table table-lg table-hover">
          <thead>
            <tr>
              <th v-if="!isReadOnly">
                <input
                  @click="selectAllCheckBox"
                  type="checkbox"
                  name="selected"
                  ref="fieldSelectAllCheckbox"
                />
              </th>
              <th />
              <th class="hover header-width" @click="handleSortFieldName">
                Field
                <i
                  class="fa hover mr-1"
                  :class="sortIcon(sortByFieldName)"
                  @click="handleSortFieldName"
                />
                <i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Field names are displayed with Hyperlink to open a field level information editor allowing specific field settings for this field."
                />
              </th>
              <th class="hover header-width">
                Locked
                <i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="This column indicates that Field(s) are being locked and are not available for update."
                />
              </th>
              <th class="hover header-width" @click="handleSortAcreage">
                Acreage
                <i
                  class="fa hover"
                  :class="sortIcon(sortByAcreage)"
                  @click="handleSortAcreage"
                />
              </th>
              <th class="hover header-width" @click="handleSortFarm">
                Farm
                <i
                  class="fa hover"
                  :class="sortIcon(sortByFarm)"
                  @click="handleSortFarm"
                />
              </th>
              <th v-if="showSustainability" class="mp-header-width">
                Management Practices
              </th>
              <th v-else class="hover mp-header-width" @click="handleSortSettings">
                Management Practices
                <!-- <i
                  class="fa hover mr-1"
                  :class="sortIcon(sortBySettings)"
                  @click="handleSortSettings"
                />
                <i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-html="true"
                  title="Field Level information template that is applied to each field. Clicking on template name opens the template for display of the field level information. Note: Templates may be edited and saved by selecting <i>Field Level Information</i> from the left menu then <i>+ add New Settings</i> Template, then in this page <i>Apply Field Settings selection</i> to apply template to one or more fields."
                /> -->
              </th>
              <th v-if="!isReadOnly" />
              <th class="data-completion-header-width">
                <div class="d-flex justify-content-between">
                  <div>
                    {{includeFieldLevelInfo ? 'Sustainability' : 'Agronomic'}} Completion
                    <i
                      class="fa fa-info-circle"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Snapshot of completeness of data per field. Use Field Level information selection button to toggle between measured data and measured data with additional field level information applied."
                    />
                  </div>
                  <div
                    class="
                      toggle-checkbox toggle-sm toggle-success
                      mr-2
                      d-flex
                      align-items-center
                    "
                  >
                    <span>Include Field Level Info</span>
                    <input
                      type="checkbox"
                      class="checked mx-3"
                      :checked="includeFieldLevelInfo"
                      id="completionDefaultsCheckbox"
                      @click="updateIncludeDefaults(!includeFieldLevelInfo)"
                    />
                    <label
                      for="completionDefaultsCheckbox"
                      class="form-check-label"
                    />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <FieldListRow
              v-for="field in searchFilteredItems"
              :key="field.id"
              :field="field"
              :organization="organization"
              :completionIncludeDefaults="includeFieldLevelInfo"
              :showSustainability="showSustainability"
              @checked="checked"
              @data-checked="dataChecked"
              @unchecked="unchecked"
              ref="fieldListRows"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import _ from "lodash"
import FieldListRow from "@/components/fields/FieldListRow"
import ConfirmModal from "@/components/modals/ConfirmModal"
import FieldDataTypeDeletionModal from "@/components/modals/FieldDataTypeDeletionModal"
import { BASE_URL } from "@/constants"
import { Fields, Filter, Map } from "@/store/modules"
import SearchBar from "@/components/misc/SearchBar"
import FieldsAPI from "@/api/FieldsAPI"

export default {
  name: "FieldList",

  components: {
    FieldDataTypeDeletionModal,
    ConfirmModal,
    FieldListRow,
    SearchBar,
  },

  data() {
    return {
      showSuccessAlert: false,
      messages: [],
      fieldsToEdit: [],
      fieldsDataToEdit: {},
      geoPkgsByDataType: {},
      selectFieldId: "",
      selectedDataType: "",
      fieldDataTypeDeletionModalOpen: false,
      applyTemplateModalOpen: false,
      confirmUnlockModalOpen: false,
      confirmLockModalOpen: false,
      confirmDeleteModalOpen: false,
      editTemplateModalOpen: false,
      sortByAcreage: null,
      sortByFarm: null,
      sortByFieldName: null,
      sortBySettings: null,
      searchString: "",
      fieldSettingsTitle:
        "Field Level information template that is applied to each field. Clicking on template name opens the template for display of the field level information. Note: Templates may be edited and saved by selecting <em>Field Level Information</em> from the left menu then <em>+ add New Settings</em> Template, then in this page <em>Apply Field Settings selection</em> to apply template to one or more fields.",
    }
  },

  computed: {
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
      includeFieldLevelInfo: Map.Getters.getIncludeDefaults,
    }),

    ...mapState({
      organization: state => state.Organization.organization,
      userRole: state => state.User.userRole,
      isReadOnly: state => state.User.user.permission === "Read-Only",
    }),

    isCustomerAdmin() {
      if (
        this.userRole === "Corporation Admin" ||
        this.userRole === "Organization Admin"
      )
        return true
      return false
    },

    searchFilteredItems() {
      if (!this.searchString) return this.sortFields
      const searchString = this.searchString.toLocaleLowerCase()
      return this.sortFields.filter(function(field) {
        return field.name.toLowerCase().includes(searchString)
      })
    },

    corporationPackage() {
      return (
        this.organization &&
        this.organization.corporation &&
        this.organization.corporation.package
      )
    },

    exportURL() {
      const idsToExport = this.fieldsToEdit.join(",")
      return `${BASE_URL}api/v1/fields/exportedit/?pk=${idsToExport}&organization_node_id=${this.organization.id}`
    },

    checkedCount() {
      return this.fieldsToEdit.length
    },

    checkedDataCount() {
      var count = 0
      for (const [key] of Object.entries(this.fieldsDataToEdit)) {
        count = count + this.fieldsDataToEdit[key].length
      }
      return count
    },

    unlockConfirmText() {
      return this.checkedCount === 1
        ? "Are you sure you want to unlock this field from the system?"
        : `Are you sure you want to unlock ${this.checkedCount} fields from the system?`
    },

    lockConfirmText() {
      return this.checkedCount === 1
        ? "Are you sure you want to lock this field from the system?"
        : `Are you sure you want to lock ${this.checkedCount} fields from the system?`
    },

    deleteConfirmText() {
      return this.checkedCount === 1
        ? "Are you sure you want to delete this field from the system? This action cannot be undone."
        : `Are you sure you want to delete ${this.checkedCount} fields from the system? This action cannot be undone.`
    },

    deleteDataConfirmText() {
      return this.checkedDataCount === 1
        ? "Are you sure you want to delete this field data from the system? This action cannot be undone."
        : `Are you sure you want to delete ${this.checkedDataCount} field data from the system? This action cannot be undone.`
    },

    sortFields() {
      const sortValues = {
        acreage: this.sortByAcreage,
        "farm.name": this.sortByFarm,
        "defaults.name": this.sortBySettings,
        name: this.sortByFieldName,
      }
      let sortColumns = []
      let sortDirections = []
      for (const column in sortValues) {
        if (sortValues[column] !== null) {
          sortColumns.push(column)
          sortDirections.push(sortValues[column])
        }
      }

      const res = _.orderBy(this.selectedFields, sortColumns, sortDirections)
      return res
    },

    showSustainability() {
      return (
        this.organization &&
        this.organization.app_views &&
        this.corporationPackage.includes("sustainability")
      )
    },
  },

  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
      batchDeleteFields: Fields.Actions.batchDelete,
      batchUpdateFields: Fields.Actions.batchUpdate,
      ignoreGeoPackages: Fields.Actions.ignoreGeoPackages,
    }),

    ...mapMutations({
      updateIncludeDefaults: Map.Mutations.updateIncludeDefaults,
    }),

    closeApplyDefaultsModal() {
      this.applyTemplateModalOpen = false
    },

    closeTemplateModal() {
      this.editTemplateModalOpen = false
    },

    checked(id) {
      this.fieldsToEdit.push(id)
    },

    handleSearch(searchString) {
      this.searchString = searchString
    },

    async dataChecked(field_id, field_data_type) {
      this.selectFieldId = field_id
      this.selectedDataType = field_data_type

      const payload = {
        field_id: field_id,
        field_data_type: this.selectedDataType,
      }
      await FieldsAPI.fetchGeoPkgsByDataType(payload).then(response => {
        this.geoPkgsByDataType = response.data
      })

      this.fieldDataTypeDeletionModalOpen = true
    },

    handleSortAcreage() {
      if (!this.sortByAcreage) this.sortByAcreage = "asc"
      else if (this.sortByAcreage === "asc") this.sortByAcreage = "desc"
      else if (this.sortByAcreage === "desc") this.sortByAcreage = null
    },

    handleSortFarm() {
      if (!this.sortByFarm) this.sortByFarm = "asc"
      else if (this.sortByFarm === "asc") this.sortByFarm = "desc"
      else if (this.sortByFarm === "desc") this.sortByFarm = null
    },

    handleSortFieldName() {
      if (!this.sortByFieldName) this.sortByFieldName = "asc"
      else if (this.sortByFieldName === "asc") this.sortByFieldName = "desc"
      else if (this.sortByFieldName === "desc") this.sortByFieldName = null
    },

    handleSortSettings() {
      if (!this.sortBySettings) this.sortBySettings = "asc"
      else if (this.sortBySettings === "asc") this.sortBySettings = "desc"
      else if (this.sortBySettings === "desc") this.sortBySettings = null
    },

    sortIcon(sortValue) {
      if (sortValue === "asc") return "fa-sort-up"
      else if (sortValue === "desc") return "fa-sort-down"
      return "fa-sort"
    },

    unchecked(id) {
      const index = this.fieldsToEdit.indexOf(id)
      if (index > -1) this.fieldsToEdit.splice(index, 1)
    },

    selectAllCheckBox() {
      const checked = this.$refs.fieldSelectAllCheckbox.checked
      this.$refs.fieldListRows.forEach(fieldListRow =>
        fieldListRow.setCheckbox(checked)
      )

      if (!checked) this.fieldsToEdit = []
    },

    deselectAllCheckbox() {
      this.$refs.fieldListRows.forEach(fieldListRow =>
        fieldListRow.setCheckbox(false)
      )
      this.$refs.fieldSelectAllCheckbox.checked = false
    },

    async unlockFields() {
      const payload = {
        field_ids: this.fieldsToEdit,
        lock_flag: false,
      }
      await this.batchUpdateFields(payload)
      this.confirmUnlockModalOpen = false

      this.deselectAllCheckbox()
      this.fieldsToEdit = []
    },

    async lockFields() {
      const payload = {
        field_ids: this.fieldsToEdit,
        lock_flag: true,
      }
      await this.batchUpdateFields(payload)
      this.confirmLockModalOpen = false

      this.deselectAllCheckbox()
      this.fieldsToEdit = []
    },

    deleteFields() {
      this.batchDeleteFields(this.fieldsToEdit).then(() => {
        this.messages = [
          "Field Deletion Request Received. Please Refresh The Application In A Few Minutes.",
          "Fields in confirmed enrollments, have field imagery orders, or have collected sample locations will not be deleted.",
        ]
        this.showSuccessAlert = true
        setTimeout(() => {
          this.fetchFields()
        }, 300000)
      })
      this.confirmDeleteModalOpen = false
      this.deselectAllCheckbox()
      this.fieldsToEdit = []
    },

    async selectedGpkgsFromModal(selectedGeoPackages) {
      const ignore_gpkgs_payload = {
        field_id: this.selectFieldId,
        field_data_type: this.selectedDataType,
        geo_packages: selectedGeoPackages,
      }

      await this.ignoreGeoPackages(ignore_gpkgs_payload)

      const fetch_gpkgs_payload = {
        field_id: this.selectFieldId,
        field_data_type: this.selectedDataType,
      }
      await FieldsAPI.fetchGeoPkgsByDataType(fetch_gpkgs_payload).then(
        response => {
          this.geoPkgsByDataType = response.data
        }
      )
    },
  },

  mounted() {
    const { $ } = window
    $(function() {
      $('[data-toggle="tooltip"]').tooltip()
    })
  },
}
</script>

<style>
#field-select-controls {
  width: 100%;
}
</style>

<style scoped>
h2 {
  font-size: 21px;
  font-weight: 500;
}

.toggle-checkbox {
  font-size: 12px;
}

.toggle-checkbox > span {
  margin-right: 10px;
  font-weight: 500;
}

.header-width {
  width: 8%;
}

.mp-header-width {
  width: 16%;
}

.data-completion-header-width {
  width: 32%;
}

.hover {
  cursor: pointer;
}
</style>
