<template>
  <div class="row">
    <form @submit.prevent="handleSubmit" novalidate>
      <div class="card">
        <h2
          class="card-heading default-title"
          @click="showInputs = !showInputs"
        >
          Operation Plan
          <i class="fa fa-chevron-down" v-if="!showInputs" />
          <i class="fa fa-chevron-up" v-else />
        </h2>
        <div class="card-body" v-if="showInputs">
          <div class="row">
            <div class="col">
              <span class="default-input-title">Irrigation Type</span>
              <v-select-old
                class="select-style"
                :options="irrigationChoices"
                :reduce="o => o.value"
                v-model="operationData.irrigation"
                label="display_name"
              />
            </div>
            <div class="col">
              <span class="default-input-title">Double Cropping</span>
              <v-select-old
                class="select-style"
                :options="doubleCropChoices"
                :reduce="o => o.value"
                v-model="operationData.double_cropping"
                label="display_name"
              />
            </div>
            <div class="col">
              <span class="default-input-title">Drainage</span>
              <v-select-old
                class="select-style"
                :options="drainageChoices"
                :reduce="o => o.value"
                label="display_name"
                v-model="operationData.drainage"
              />
            </div>
            <div class="col" />
            <div class="col" />
          </div>

          <div class="row">
            <div class="col">
              <span class="default-input-title">Tillage Practice</span>
              <v-select-old
                class="select-style"
                :options="tillagePracticeChoices"
                :reduce="o => o.value"
                label="display_name"
                v-model="operationData.tillage_practice"
              />
            </div>

            <div class="col">
              <span class="default-input-title">Tillage Date</span>
              <datepicker v-model="operationData.tillage_date" />
            </div>

            <div class="col" />
            <div class="col" />
            <div class="col">
              <button
                type="submit"
                class="btn btn-primary update-btn"
                :class="{ disabled: !canUpdate }"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import DefaultsAPI from "@/api/DefaultsAPI"
import {
  IRRIGATION_CHOICES,
  DOUBLE_CROP_CHOICES,
  DRAINAGE_CHOICES,
  TILLAGE_PRACTICE_CHOICES,
} from "@/constants/defaults"
import { Fields } from "@/store/modules"
import { successMessage } from "@/utility"

export default {
  name: "OperationDefaults",
  props: {
    operationData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showInputs: true,
      doubleCropChoices: DOUBLE_CROP_CHOICES,
      drainageChoices: DRAINAGE_CHOICES,
      irrigationChoices: IRRIGATION_CHOICES,
      tillagePracticeChoices: TILLAGE_PRACTICE_CHOICES,
      // update button highlighting
      initialIrrigation: null,
      initialDoubleCrop: null,
      initialDrainage: null,
      initialTillagePractice: null,
      initialTillageDate: null,
    }
  },

  computed: {
    canUpdate() {
      const {
        irrigation,
        double_cropping,
        drainage,
        tillage_practice,
        tillage_date,
      } = this.operationData
      const {
        initialIrrigation,
        initialDoubleCrop,
        initialDrainage,
        initialTillagePractice,
        initialTillageDate,
      } = this
      return (
        irrigation !== initialIrrigation ||
        double_cropping !== initialDoubleCrop ||
        drainage !== initialDrainage ||
        tillage_practice !== initialTillagePractice ||
        tillage_date !== initialTillageDate
      )
    },
  },

  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFields,
    }),

    async handleSubmit() {
      await DefaultsAPI.update(
        "operation-defaults",
        this.operationData.id,
        this.operationData
      )
      successMessage(this, "Operation Defaults Updated", "top-right")
      this.fetchFields()
    },
  },

  mounted() {
    const {
      irrigation,
      double_cropping,
      drainage,
      tillage_practice,
      tillage_date,
    } = this.operationData
    this.initialIrrigation = irrigation
    this.initialDoubleCrop = double_cropping
    this.initialDrainage = drainage
    this.initialTillagePractice = tillage_practice
    this.initialTillageDate = tillage_date
  },
}
</script>

<style scoped>
.defaults-container {
  background-color: white;
}

.default-input-title {
  font-size: 14px;
}

.default-title {
  font-size: 16px;
  font-weight: bold;
  color: #515365;
  margin: 0;
  padding-bottom: 0;
  cursor: pointer;
}

.default-title i {
  margin-left: 5px;
}

form {
  width: 100%;
}

.update-btn {
  width: 100%;
  margin: 0;
  margin-top: 17px;
}
</style>
