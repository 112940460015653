<template>
  <tr :class="showColorText()">
    <td>{{ job.client.name }}</td>
    <td>{{ job.source_org_node ? job.source_org_node.name : null }}</td>
    <td>{{ job.target_org_node ? job.target_org_node.name : null }}</td>
    <td>{{ jobStatus }}</td>
    <td>
      <v-btn v-if="isJobPending" small @click="handleCancel">
        Cancel
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  name: "MoveClientJobRow",

  props: ["job"],

  computed: {
    jobStatus() {
      if (this.job.failed_at) return "Failed"
      else if (this.job.completed_at) return "Completed"
      else if (this.job.started_at) return "Started"
      else return "Pending"
    },
    isJobPending() {
      return this.jobStatus === "Pending"
    },
    isJobCompleted() {
      return this.jobStatus === "Completed"
    },
    isJobFailed() {
      return this.jobStatus === "Failed"
    },
  },

  methods: {
    handleCancel() {
      this.$emit("cancel-job", this.job.id)
    },
    showColorText() {
      if (this.isJobCompleted) return "showCompletedText"
      else if (this.isJobFailed) return "showFailedText"
      else if (this.isJobStarted) return "showStartedText"
      else return "showPendingText"
    },
  },
}
</script>

<style scoped>
.showCompletedText {
  color: green;
}

.showFailedText {
  color: red;
}

.showStartedText {
  color: orange;
}
.showPendingText {
  color: blue;
}
</style>
