export const MAPBOX_TOKEN =
  "pk.eyJ1IjoibGVsYW5kYiIsImEiOiJjbGVpd2tkcXcwNW9jNDBwNzgxc2NwbnFtIn0.Vyn1sbWRtBEflIsKT9P64Q"

const CENTER = [-89, 32]

const INITIAL_VIEW_STATE = {
  latitude: CENTER[1],
  longitude: CENTER[0],
  zoom: 3,
  bearing: 0,
  pitch: 0,
}

export const DECKGL_SETTINGS = {
  canvas: "deck-canvas",
  width: "100%",
  height: "100%",
  controller: true,
  useDevicePixels: false,
  viewState: INITIAL_VIEW_STATE,
}

export const MAP_STYLES = {
  satellite: "mapbox://styles/mapbox/satellite-v9",
  dark: "mapbox://styles/mapbox/dark-v10",
}

export const MAPBOX_SETTINGS = {
  container: "map",
  width: "100%",
  height: "100%",
  style: MAP_STYLES.satellite,
  interactive: false,
  center: [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude],
  zoom: INITIAL_VIEW_STATE.zoom,
  bearing: INITIAL_VIEW_STATE.bearing,
  pitch: INITIAL_VIEW_STATE.pitch,
}

export const TIFF_DATA_SCALAR = 100

export const TIFF_COLORSCALES = [
  { value: "rgb", name: "RdYlGn" },
  { value: "viridis", name: "Viridis" },
  { value: "inferno", name: "Inferno" },
  { value: "turbo", name: "Turbo" },
  { value: "rainbow", name: "Rainbow" },
  { value: "jet", name: "Jet" },
  { value: "hsv", name: "HSV" },
  { value: "hot", name: "Hot" },
  { value: "cool", name: "Cool" },
  { value: "spring", name: "Spring" },
  { value: "summer", name: "Summer" },
  { value: "winter", name: "Winter" },
  { value: "autumn", name: "Autumn" },
  { value: "bone", name: "Bone" },
  { value: "copper", name: "Copper" },
  { value: "greys", name: "Greys" },
  { value: "yignbu", name: "Yignbu" },
  { value: "greens", name: "Greens" },
  { value: "yiorrd", name: "Yiorrd" },
  { value: "bluered", name: "Bluered" },
  { value: "rdbu", name: "RDBU" },
  { value: "picnic", name: "Picnic" },
  { value: "portland", name: "Portland" },
  { value: "blackbody", name: "Blackbody" },
  { value: "earth", name: "Earth" },
  { value: "electric", name: "Electric" },
  { value: "magma", name: "Magma" },
  { value: "plasma", name: "Plasma" },
]

export function percentile(arr, p) {
  if (arr.length === 0) return 0
  if (typeof p !== "number") throw new TypeError("p must be a number")
  if (p <= 0) return arr[0]
  if (p >= 1) return arr[arr.length - 1]

  var index = (arr.length - 1) * p,
    lower = Math.floor(index),
    upper = lower + 1,
    weight = index % 1

  if (upper >= arr.length) return arr[lower]
  return arr[lower] * (1 - weight) + arr[upper] * weight
}

export const ZONES_PRODUCT_UNITS = [
  "fl oz/ac",
  "gal/ac",
  "L/ac",
  "lbs/ac",
  "seeds/ac",
  "tons/ac",
]
