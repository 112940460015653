<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <!-- Tillage Info -->
      <div id="input-card-container">
        <v-card class="my-auto mx-auto" v-if="showTillageInfo">
          <!-- Add Practices View -->
          <v-card-text
            class="min-step-height overflow-auto"
            v-if="showPracticesView"
          >
            <v-row class="mx-1 mt-3">
              <v-btn
                class="mr-2"
                :input-value="noTillage == false"
                depressed
                @click="handleYesTillageClick"
              >
                Tilled Fields <v-icon>mdi-check-bold</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                :input-value="noTillage == true"
                depressed
                @click="handleNoTillageClick"
              >
                No Tillage <v-icon>mdi-close-thick</v-icon>
              </v-btn>
              <v-btn class="ml-auto" @click="handleFullReset()">
                Reset Tillages
              </v-btn>
              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="allTillsComplete().length > 0"
                @click="handleSave()"
              >
                Save and Continue
              </v-btn>
            </v-row>
            <v-row v-if="noTillage == false">
              <v-col md="12">
                <v-card
                  v-for="till in tillageData"
                  :key="till.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor"
                    @click="till.showAllInfo = !till.showAllInfo"
                    >Tillage #{{ tillageData.indexOf(till) + 1 }}
                    <v-icon v-if="!till.showAllInfo">mdi-chevron-right</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isTillComplete(till)
                              ? 'green accent-4'
                              : 'red darken-1'
                          "
                        >
                          {{
                            isTillComplete(till)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isTillComplete(till)
                          ? "Application is good to go!"
                          : "You're missing a few fields, please complete this application to save and continue!"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click="handleRemoveTill(tillageData.indexOf(till))"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="till.showAllInfo">
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b>Tillage Date:</b>
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="till.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="till.date"
                                label="Tillage Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="till.date"
                              :picker-date.sync="till.pickerDate"
                              :min="yearPriorOne + '-01-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="till.menu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7">Describe your tillage method:</b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Method"
                            :items="methodChoices"
                            v-model="till.method"
                            item-text="display_name"
                            item-value="value"
                          ></v-select>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7">What was your tillage depth?</b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Depth"
                            v-model="till.depth"
                            :rules="[numberValidator]"
                            type="number"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7">What was your implement?</b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Implement"
                            :items="implementChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="till.implement"
                          ></v-select>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn class="mt-4" block @click="handleAddEventClick"
                  >Add Tillage Event<v-icon>mdi-plus</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="noTillage == true">
              <v-card class="mx-auto mt-4" elevation="0">
                <v-card-text>
                  <div class="text-center">
                    <b class="text-h5 text-bold"
                      >No Tillage for {{ this.year }}</b
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your tillage information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddMoreTillages">
                Add More Tillages
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Tillage Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in summaryData" :key="data.idx">
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplay[data[key].value]
                      }}</span>
                      <span v-else>
                        {{
                          data[key].value
                            ? data[key].value
                            : "None" | cleanSnake
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <!-- Fields, Data Comp, and Add Practices -->
      <v-row class="mt-4 mb-2 row-margin-correct">
        <div>
          <span class="text-h5 ml-1"
            >{{ opsSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              class="mx-1"
              text
              v-for="y in yearListModified"
              :key="y.idx"
              @click="handleSeasonSelection(y)"
              :color="year == y ? 'green' : ''"
              >{{ y }}</v-btn
            >
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn
              :class="showTillageInfo ? 'black--text' : 'white--text'"
              :color="showTillageInfo ? 'white' : 'green'"
              x-large
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>

      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn class="mr-3" @click="handleSelectAll(true, true)" text>
          <span>All</span>
          <span>({{ opsSettingsByField.length }})</span>
        </v-btn>
        <v-btn
          class="mr-3"
          text
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          :color="
            JSON.stringify(cropSelect) ==
            JSON.stringify(sharedCropPlan.crops.split(','))
              ? 'green'
              : ''
          "
          @click="handleCropFilterButton(sharedCropPlan)"
        >
          <span>{{ mapCropNames(sharedCropPlan.crops) }}</span>
          <span>({{ sharedCropPlan.fieldIds.length }})</span>
        </v-btn>
      </v-row>

      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        ></v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th>Tillages</th>
                    <th class="three">
                      <div>
                        Copy
                        <!-- <v-tooltip top
                        ><template v-slot:activator="{ on, attrs }"
                          ><v-icon v-bind="attrs" v-on="on"
                            >mdi-information</v-icon
                          ></template
                        ><span
                          >Click copy to copy a field's settings quickly to set
                          multiple fields at once.</span
                        ></v-tooltip
                      > -->
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in opsSettingsbyFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="
                          disableCheckbox(field.noYearData) || field.fieldLocked
                        "
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>{{ field.fieldName }}</td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td v-if="'cropNames' in field">
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <td v-else>
                      <div>No Crop</div>
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                      <div
                        v-for="opsSingle in field.opsInfo"
                        :key="opsSingle.idx"
                      >
                        <v-row
                          class="mx-0"
                          :class="
                            opsSingle.tillages.indexOf(tillageInfo) !=
                            opsSingle.length - 1
                              ? 'row-bottom'
                              : ''
                          "
                          dense
                          v-for="tillageInfo in opsSingle.tillages.sort(
                            (a, b) => a.crop_id.value - b.crop_id.value
                          )"
                          :key="tillageInfo.idx"
                        >
                          <v-col md="2">{{ tillageInfo.date.value }}</v-col>
                          <v-col md="2">
                            <span
                              :class="
                                tillageInfo.method.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                tillageInfo.method.value
                                  ? tillageInfo.method.value
                                  : "No Method" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col md="2">
                            <span
                              :class="
                                tillageInfo.implement.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                tillageInfo.implement.value
                                  ? tillageInfo.implement.value
                                  : "No Implement" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col md="2">
                            <span
                              :class="
                                tillageInfo.depth.value !== null
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                tillageInfo.depth.value !== null
                                  ? tillageInfo.depth.value + '"'
                                  : "No Depth" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col>
                            <!-- Data Complete -->
                            <div
                              class="d-flex"
                              v-if="tillageComplete(tillageInfo)"
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="success"
                                    >mdi-check-circle</v-icon
                                  ></template
                                ><span
                                  >You've filled out enough information for Arva
                                  data models to run. Thank you!</span
                                ></v-tooltip
                              >
                              <span class="ml-2">Data Complete</span>
                            </div>
                            <!-- Needs Confirmation -->
                            <div
                              class="d-flex"
                              v-else-if="tillageNeedsConfirm(tillageInfo)"
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="yellow"
                                    >mdi-alert-rhombus</v-icon
                                  ></template
                                ><span
                                  >This information is coming from a public
                                  source. Please include this field when setting
                                  information to confirm it's data!</span
                                ></v-tooltip
                              >
                              <span
                                class="ml-2 cursor blue--text lighten-1 text-bold"
                                @click="handleConfirm(field)"
                                >Confirm Data</span
                              >
                            </div>
                            <!-- Missing Data -->
                            <div
                              class="d-flex"
                              v-else-if="tillageMissingData(tillageInfo)"
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon v-bind="attrs" v-on="on" color="red"
                                    >mdi-alert-circle</v-icon
                                  ></template
                                ><span
                                  >This field is missing data. Please fill in
                                  its data using the wizard!</span
                                ></v-tooltip
                              >
                              <span class="ml-2">Missing Data</span>
                            </div></v-col
                          >
                        </v-row>
                      </div>
                    </td>
                    <td class="py-2" v-else>
                      <div @click="handleShowRedirect(field)">
                        <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on" color="red"
                              >mdi-alert-circle</v-icon
                            ></template
                          ><span
                            >This field doesn't have data for this year. Add
                            another year in the data spreadsheet or upload data
                            from this year.</span
                          ></v-tooltip
                        ><span class="ml-2 cursor red--text text-bold"
                          >No Data for Year</span
                        >
                      </div>
                    </td>
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Incomplete Application Modal -->
      <IncompleteFertAppModal
        v-if="showIncompleteModal"
        :incompleteApplications="incompleteTillages"
        dataCategory="tillage"
        @close-template-modal="showIncompleteModal = false"
      />

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <!-- Wizard Confirm Modal -->
      <WizardConfirmModal
        v-if="showConfirmModal"
        :confirmationField="confirmationField"
        :confirmationData="confirmationTillages"
        :wantedKeys="['date', 'depth', 'method', 'implement']"
        :year="year"
        :loadState="loadState"
        @close-template-modal="handleCloseConfirm"
        @confirm-data="handleModalConfirm"
      />

      <!-- Redirect -->
      <WizardRedirectModal
        v-if="showRedirectModal"
        :redirectField="redirectField"
        :year="year"
        @close-template-modal="handleCloseRedirect"
        @redirect-confirm="handleRedirectConfirm"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Organization } from "@/store/modules"
import {
  CROPS,
  CROP_KEY_DISPLAY_NAME,
  CROP_DISPLAY_NAME,
  CROP_KEY,
} from "@/constants"
import {
  TILLAGE_IMPLEMENT_CHOICES,
  TILLAGE_PRACTICE_CHOICES,
  MAX_DEFAULT_FIELDS,
} from "@/constants/defaults"
import IncompleteFertAppModal from "@/components/modals/IncompleteFertAppModal"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardConfirmModal from "@/components/modals/WizardConfirmModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import { numberValidator } from "@/utility"

export default {
  name: "NewTillageWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletion: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    IncompleteFertAppModal,
    WizardConfirmModal,
    WizardRedirectModal,
  },
  data() {
    return {
      el: 1,
      // constants
      cropDisplay: CROP_DISPLAY_NAME,
      cropChoices: Object.values(CROP_KEY),
      implementChoices: TILLAGE_IMPLEMENT_CHOICES,
      methodChoices: TILLAGE_PRACTICE_CHOICES,
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,

      // no tillage
      noTillage: false,
      // all tillage data
      numTillages: 0,
      tillageData: [],
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      filterFarms: [],
      filterFarmsReflection: [],
      tableFilterText: "",
      tableFilterFields: null,
      selectAllBox: true,
      // input rules for date
      rules: {
        date: value => {
          var dateRegex = /^\d{4}-\d{2}-\d{2}$/
          return dateRegex.test(value) || "YYYY-MM-DD"
        },
      },
      numberValidator,
      // full validation for tillage completeness
      showIncompleteModal: false,
      incompleteTillages: null,
      alertErrorModalOpen: false,
      // confirmation
      confirmationField: null,
      confirmationTillages: null,
      showConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // select crop type UX, disables selecting new fields when selecting by crop tile
      cropSelect: null,
      // new UI
      showTillageInfo: false,
      showPracticesView: true,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      summaryKeys: null,
      summaryData: null,
    }
  },
  computed: {
    ...mapState({
      opsSettings: state => state.Defaults.newFieldSettings,
    }),
    ...mapGetters({
      recentWizardData: Defaults.Getters.getRecentWizardData,
    }),
    cropChoicesFiltered() {
      if (this.cropSelect != null) {
        const filteredCropList = Object.entries(CROP_KEY)
          .filter(o => {
            if (this.cropSelect.length > 1) {
              return this.cropSelect.includes(o[0])
            } else {
              return true
            }
          })
          .map(list => list[1])
        return filteredCropList
      } else {
        const selectedOpSettings = this.opsSettingsByField.filter(field =>
          this.fieldsInSelection.includes(parseInt(field.fieldId))
        )
        const selectedCrops = new Set(
          selectedOpSettings.flatMap(field => field.crops)
        )
        const selectedCropNames = Array.from(selectedCrops).map(
          c => CROP_KEY[c]
        )
        return selectedCropNames.filter(i => i != undefined)
      }
    },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      let yearDataComps = this.dataCompletionMaster["Tillage"].map(o => {
        if ("by_year" in o.data_comp) {
          let temp = o.data_comp.by_year.find(
            yearObj => yearObj.year == this.year
          )
          if (temp) {
            return temp.avg_for_year
          }
        } else {
          return 0
        }
      })
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.ceil(average(yearDataComps))
      } else {
        return 0
      }
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.opsSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    opsSettingsByField() {
      let opsDict = {}
      let yearInConsideration = this.year
      Object.entries(this.opsSettings).forEach(opsDefault => {
        const fieldKey = opsDefault[0]

        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        opsDict[fieldKey] = {}
        const fieldSettingObj = opsDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
        }
        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsideration
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = ["No Data"]
            opsDict[fieldKey]["cropNames"] = ["No Data"]
            opsDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsideration) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = []
            opsDict[fieldKey]["cropNames"] = []
            opsDict[fieldKey]["cropSearch"] = ""
            opsDict[fieldKey]["plantings"] = []
            opsDict[fieldKey]["opsInfo"] = []

            opsDict[fieldKey]["isSelected"] = this.fieldsInSelection.includes(
              parseInt(fieldKey)
            )
              ? true
              : false

            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            cultivations.forEach(cultivation => {
              opsDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              opsDict[fieldKey]["cropNames"].push(
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              )
              opsDict[fieldKey]["cropSearch"] =
                opsDict[fieldKey]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              opsDict[fieldKey]["plantings"] = opsDict[fieldKey][
                "plantings"
              ].concat(cultivation.plantings)
              opsDict[fieldKey]["opsInfo"].push(cultivation.operations)

              opsDict[fieldKey]["crops"].sort()
              opsDict[fieldKey]["cropNames"].sort()
              opsDict[fieldKey]["fieldLocked"] = cultivation.year_data_locked
              opsDict[fieldKey]["noYearData"] = false
            })
          }
        })
      })
      return Object.values(opsDict)
    },
    opsSettingsbyFieldFiltered() {
      let arrayToReturn = _.cloneDeep(this.opsSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["isSelected", "fieldName"]
      const sortDirections = ["desc", "asc"]

      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const plantingsGrouped = _(this.opsSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
          noYearData: _.map(items, "noYearData")[0],
        }))
        .value()
      return plantingsGrouped
    },
    fieldFarms() {
      return [...new Set(this.opsSettingsByField.map(f => f.farmName))]
    },
    filterCropList() {
      return [
        ...new Set(
          this.opsSettingsByField.map(f => {
            if (_.has(f, "cropNames")) {
              return f.cropNames.join(", ")
            } else {
              return ""
            }
          })
        ),
      ]
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateTillage: Defaults.Actions.updateTillage,
    }),
    ...mapMutations({
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    handleCopy(field) {
      this.handleInfoReset()

      const fieldTillages = []
      field.opsInfo.forEach(opsData => {
        opsData.tillages.forEach(till => fieldTillages.push(till))
      })
      const numFieldTillages = fieldTillages.length
      this.numTillages = numFieldTillages

      // translates swapped display_name and value inputted methods
      let methodMapper = method => {
        let displayArray = this.methodChoices.map(mc => mc.display_name)
        if (displayArray.includes(method)) {
          return this.methodChoices.find(mc => mc.display_name == method).value
        } else {
          return method
        }
      }
      fieldTillages.forEach(till => {
        if (till.method.value == "no-till") {
          this.noTillage = true
        } else {
          this.noTillage = false
        }
        this.tillageData.push({
          crop_id: CROP_KEY[till.crop_id.value],
          date: till.date.value,
          depth: till.depth.value,
          implement: till.implement.value,
          method: methodMapper(till.method.value),
          residue: till.residue.value,
          showAllInfo: true,
          menu: false,
          pickerDate: this.year + "-01",
        })
      })

      this.showTillageInfo = true
      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },
    handleInfoReset() {
      this.noTillage = false
      this.cropSelect = null
      this.numTillages = null
      this.tillageData = []
    },
    handleFullReset() {
      this.tillageData = []
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null

      this.tillageData.push({
        date: null,
        depth: null,
        crop_id: cropValue,
        method: null,
        residue: null,
        implement: null,
        // for UI control
        showAllInfo: false,
        menu: false, // boolean for v-menu
        pickerDate: this.year + "-01",
      })
    },
    isTillComplete(till) {
      return till.date && till.depth !== null && till.method && till.implement
    },
    allTillsComplete() {
      if (!this.tillageData) return [0]
      const tillCompleteness = this.tillageData.map(till =>
        this.isTillComplete(till)
      )
      const tillsMissing = tillCompleteness.map((value, index) =>
        value == null ? index : ""
      )
      const indexOfMissing = tillsMissing.filter(String)
      return indexOfMissing
    },
    handleRemoveTill(tillIndex) {
      this.tillageData.splice(tillIndex, 1)
      if (this.tillageData.length == 0) {
        this.noTillage = true
      }
    },
    handleConfirm(field) {
      this.confirmationField = _.cloneDeep(field)
      this.confirmationTillages = []
      field.opsInfo.forEach(ops => {
        ops.tillages.forEach(till => {
          this.confirmationTillages.push(_.cloneDeep(till))
        })
      })
      this.confirmationTillages.forEach(till => {
        for (const key in till) {
          till[key].source = "User Input"
        }
      })
      this.showConfirmModal = true
    },
    async handleModalConfirm() {
      this.loadState = "loading"
      await this.updateTillage({
        fieldId: this.confirmationField.fieldId,
        year: this.year,
        tillages: this.confirmationTillages,
      })
      this.loadState = "confirmed"
      this.$emit("update-data-complete")
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.handleCloseConfirm()
    },
    async handleSave() {
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }
      let tillagePayload = null
      const fieldsBeingSaved = this.opsSettingsbyFieldFiltered.filter(f =>
        this.fieldsInSelection.includes(Number(f.fieldId))
      )
      const cropsBeingSaved = fieldsBeingSaved
        .map(f => f.crops)
        .reduce((acc, arr) => acc.concat(arr), [])
        .filter((item, index, self) => self.indexOf(item) === index)

      if (this.noTillage == true) {
        tillagePayload = []
        cropsBeingSaved.forEach(cropId => {
          tillagePayload.push({
            crop_id: { value: cropId, source: "User Input" },
            date: { value: "", source: "User Input" },
            depth: { value: 0, source: "User Input" },
            implement: { value: "none", source: "User Input" },
            method: { value: "no-till", source: "User Input" },
            residue: { value: null, source: "User Input" },
          })
        })
      } else {
        const tillCompleteness = this.tillageData.map(till =>
          this.isTillComplete(till)
        )
        const tillsMissing = tillCompleteness.map((value, index) =>
          value == null ? index : ""
        )
        const indexOfMissing = tillsMissing.filter(String)

        // find which tills are missing info
        if (indexOfMissing.length > 0) {
          this.incompleteTillages = indexOfMissing
          this.showIncompleteModal = true
          return
        } else {
          // save to operation data
          // switch to loading view

          if (this.tillageData.length > 0) {
            // clone data for mapping to allow users to backtrack to data
            let dataClone = _.cloneDeep(this.tillageData)
            tillagePayload = []
            let tillagesCleaned = dataClone.map(till => {
              delete till["showAllInfo"]
              delete till["menu"]
              delete till["pickerDate"]
              Object.keys(till).forEach(tillKey => {
                if (tillKey == "showAllInfo" || tillKey == "menu") {
                  return
                } else if (tillKey == "crop_id") {
                  till[tillKey] = {
                    value: CROPS[CROP_KEY_DISPLAY_NAME[till[tillKey]]],
                    source: "User Input",
                  }
                } else {
                  till[tillKey] = {
                    value: till[tillKey],
                    source: "User Input",
                  }
                }
              })
              return till
            })
            cropsBeingSaved.forEach(cropId => {
              tillagesCleaned.forEach(till => {
                let tillClone = _.cloneDeep(till)
                tillClone["crop_id"]["value"] = cropId
                tillagePayload.push(tillClone)
              })
            })
          } else {
            tillagePayload = [
              {
                crop_id: { value: null, source: "User Input" },
                date: { value: "", source: "User Input" },
                depth: { value: 0, source: "User Input" },
                implement: { value: "none", source: "User Input" },
                method: { value: "no-till", source: "User Input" },
                residue: { value: null, source: "User Input" },
              },
            ]
          }
        }
      }

      this.showPracticesView = false
      this.showSaveView = true

      const saveTillagePromises = this.opsSettingsbyFieldFiltered.map(field => {
        if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
          if (!field.noYearData && !field.fieldLocked) {
            return this.updateTillage({
              fieldId: field.fieldId,
              year: this.year,
              tillages: tillagePayload,
            })
          }
        }
        return Promise.resolve()
      })
      await Promise.all(saveTillagePromises)
      this.$emit("update-data-complete")

      // end loading view, return to irrigation
      const fieldNames = this.opsSettingsbyFieldFiltered
        .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
        .map(f => f.fieldName)
      const tillageWantedKeys = [
        "crop_id",
        "date",
        "depth",
        "method",
        "implement",
      ]
      this.setRecentWizardData({
        wizard: "tillage",
        data: this.tillageData,
      })

      this.summaryKeys = tillageWantedKeys
      this.summaryData = tillagePayload
      this.summaryFields = fieldNames
      this.showSaveView = false
      this.showSummaryView = true
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    tillageComplete(tillage) {
      if (!tillage) return null
      return (
        tillage.method.source == "User Input" &&
        tillage.method.value != null &&
        tillage.date.source == "User Input" &&
        tillage.date.value != null &&
        tillage.depth.source == "User Input" &&
        tillage.depth.value !== null &&
        tillage.implement.source == "User Input" &&
        tillage.implement.value != null
      )
    },
    tillageNeedsConfirm(tillage) {
      if (!tillage) return null
      return (
        tillage.method.source != "User Input" &&
        tillage.method.value != null &&
        tillage.date.source != "User Input" &&
        tillage.date.value != null &&
        tillage.depth.source != "User Input" &&
        tillage.depth.value !== null &&
        tillage.implement.source != "User Input" &&
        tillage.implement.value != null
      )
    },
    tillageMissingData(tillage) {
      if (!tillage) return null
      return (
        tillage.method.source != "User Input" ||
        tillage.method.value == null ||
        tillage.date.source != "User Input" ||
        tillage.date.value == null ||
        tillage.depth.source != "User Input" ||
        tillage.depth.value == null ||
        tillage.implement.source != "User Input" ||
        tillage.implement.value == null
      )
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (e == true) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    handleCloseConfirm() {
      this.showConfirmModal = false
      this.confirmationField = null
      this.confirmationTillages = null
      this.loadState = null
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleAddPracticesClick() {
      if (this.showTillageInfo == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        this.showTillageInfo = true
        this.tillageData = [
          {
            date: null,
            depth: null,
            crop_id: null,
            method: null,
            residue: null,
            implement: null,
            // for UI control
            showAllInfo: true,
            menu: false, // boolean for v-menu
            pickerDate: this.year + "-01",
          },
        ]
      } else {
        this.showTillageInfo = false
        this.tillageData = []
      }
    },
    handleAddEventClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null

      this.tillageData.push({
        date: null,
        depth: null,
        crop_id: cropValue,
        method: null,
        residue: null,
        implement: null,
        // for UI control
        showAllInfo: false,
        menu: false, // boolean for v-menu
        pickerDate: this.year + "-01",
      })
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
      }
    },
    handleNoTillageClick() {
      this.tillageData = []
      this.noTillage = true
    },
    handleYesTillageClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null

      this.tillageData = [
        {
          date: null,
          depth: null,
          crop_id: cropValue,
          method: null,
          residue: null,
          implement: null,
          // for UI control
          showAllInfo: true,
          menu: false, // boolean for v-menu
          pickerDate: this.year + "-01",
        },
      ]
      this.noTillage = false
    },
    handleAddMoreTillages() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
  },
  mounted() {
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f])
    if (this.recentWizardData.wizard == "tillage") {
      this.numTillages = this.recentWizardData.data.length
      this.tillageData = _.cloneDeep(this.recentWizardData.data)
      this.el = 4
    }
  },
  watch: {
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.opsSettingsByField)
        if (this.filterFarmsReflection.length > 0) {
          arrayToFilter = arrayToFilter.filter(field =>
            this.filterFarms.includes(field.farmName)
          )
        }
        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
    filterFarms: {
      deep: true,
      handler(myArray) {
        this.filterFarmsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.opsSettingsByField)
        if (this.filterFarmsReflection.length > 0) {
          arrayToFilter = arrayToFilter.filter(field =>
            this.filterFarms.includes(field.farmName)
          )
        }
        if (this.filterCropsReflection.length > 0) {
          arrayToFilter = arrayToFilter.filter(field =>
            this.filterCropsReflection.includes(field.cropNames.join(", "))
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
    el(newEl) {
      if (newEl == 1) {
        this.cropSelect = null
      }
    },
  },
}
</script>
<style scoped>
.input-holder {
  width: 320px;
}
.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.step-height {
  min-height: 400px;
  max-height: 400px;
  height: 400px;
}

.min-step-height {
  min-height: 400px;
}

.text-container {
  width: 200px;
}

.fixed-table {
  table-layout: fixed;
}

.all-button {
  margin-left: 86px;
}

.three {
  width: 3%;
}
.ten {
  width: 10%;
}

.twenty {
  width: 20%;
}

.cursor {
  cursor: pointer;
}
</style>
