import axios from "axios"
import { BASE_URL } from "@/constants"
import store from "@/store"

const baseURL = `${BASE_URL}api/v1/`

const axio = axios.create({ baseURL })

axio.interceptors.request.use(config => {
  config.headers["Authorization"] = `Token ${store.state.User.token}`
  config.maxBodyLength = Infinity
  config.maxContentLength = Infinity
  return config
})

export default axio
