import NotificationsAPI from "@/api/NotificationsAPI"
import { Notifications } from "@/store/modules"

const getDefaultState = () => {
  return {
    list: [
      {
        id: null,
        created: "",
        message: "",
        read: null,
        notification_type: "",
        user: null,
      },
    ],
    toastNotification: { message: null, link: null },
  }
}

const state = getDefaultState()

const getters = {
  [Notifications.Getters.unread]: state =>
    state.list.filter(n => n.read === false),
}

const mutations = {
  [Notifications.Mutations.resetState](state) {
    Object.assign(state, getDefaultState())
  },

  [Notifications.Mutations.setList](state, val) {
    state.list = val
  },

  [Notifications.Mutations.setToastNotification](state, val) {
    state.toastNotification = val
  },

  [Notifications.Mutations.clearToastNotification](state) {
    state.toastNotification = null
  },
}

const actions = {
  [Notifications.Actions.resetNotificationsState]({ commit }) {
    commit(Notifications.Mutations.resetState)
  },

  [Notifications.Actions.retrieveNotifications]({ commit }) {
    NotificationsAPI.get().then(response =>
      commit(Notifications.Mutations.setList, response.data)
    )
  },

  [Notifications.Actions.markNotificationsAsRead](_, listOfIds) {
    NotificationsAPI.patchBulkUpdate(listOfIds)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
