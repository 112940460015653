<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <div id="input-card-container">
        <v-card class="my-auto mx-auto" v-if="showFertilizerInfo">
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-3">
              <v-btn
                class="mr-2"
                :input-value="applicationData.length > 0"
                depressed
                @click="handleYesFertilizerClick"
              >
                Applied Fertilizer <v-icon>mdi-check-bold</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                :input-value="applicationData.length == 0"
                depressed
                @click="handleNoFertilizerClick"
              >
                No Fertilizers <v-icon>mdi-close-thick</v-icon>
              </v-btn>
              <v-btn class="ml-auto" @click="handleInfoReset(true)">
                Reset Fertilizers
              </v-btn>
              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="allAppsComplete().length > 0"
                @click="handleSave()"
                >Save and Continue</v-btn
              >
            </v-row>
            <v-row v-if="applicationData.length > 0">
              <v-col md="12">
                <v-card
                  v-for="(app, index) in applicationData"
                  :key="app.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                    @click="app.showAllInfo = !app.showAllInfo"
                  >
                    Application #{{ applicationData.indexOf(app) + 1 }}
                    <v-icon
                      v-if="!app.showAllInfo"
                      @click="app.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="app.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isAppComplete(app)
                              ? 'green accent-4'
                              : 'red darken-1'
                          "
                        >
                          {{
                            isAppComplete(app)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isAppComplete(app)
                          ? "Application is good to go!"
                          : "You're missing a few fields, please complete this application to save and continue!"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click="handleRemoveApp(applicationData.indexOf(app))"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="app.showAllInfo">
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >On which crop was this applied?<span
                              class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Crop"
                            :disabled="
                              cropSelect ? cropSelect.length == 1 : false
                            "
                            :items="cropChoicesFiltered"
                            v-model="app.crop_id"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="mt-3 mb-2">
                          <b
                            >Application Date<span class="red--text">*</span></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="app.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="app.date"
                                label="Application Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="app.date"
                              :picker-date.sync="app.pickerDate"
                              :min="yearPriorOne + '-01-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="app.menu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Application Method and Product -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7">
                            Describe your application method and product:<span
                              class="red--text"
                              >*</span
                            >
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Method"
                            :items="methodChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="app.method"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            :disabled="app.method ? false : true"
                            :label="app.method ? 'Product' : 'Select a Method'"
                            :items="productsByMethod(app.method)"
                            v-model="app.product"
                            item-text="display_name"
                            item-value="value"
                            @change="calculateNPKforApplication(index)"
                          >
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Application Rate Row -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7">
                            What was your application rate?<span
                              class="red--text"
                              >*</span
                            >
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            label="Rate"
                            :rules="[numberValidator]"
                            v-model="app.amount"
                            type="number"
                            @change="calculateNPKforApplication(index)"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="input-holder">
                          <b class="text-h7"></b>
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Rate Units"
                            :items="rateChoices"
                            v-model="app.unit"
                            @change="calculateNPKforApplication(index)"
                          ></v-select>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <div v-if="app.product && app.product == 'other'">
                          <div class="mb-2">
                            <b class="text-h7"> Nutrient ratio </b>
                          </div>
                          <div class="input-holder d-flex">
                            <v-text-field
                              dense
                              outlined
                              hide-details
                              label="N"
                              :rules="[numberValidator]"
                              :min="0"
                              :max="100"
                              v-model="app.manualNPK.N"
                              type="number"
                              @change="calculateNPKforApplication(index)"
                            ></v-text-field>
                            <v-text-field
                              dense
                              outlined
                              hide-details
                              label="P"
                              :rules="[numberValidator]"
                              :min="0"
                              :max="100"
                              v-model="app.manualNPK.P"
                              type="number"
                              @change="calculateNPKforApplication(index)"
                            ></v-text-field>
                            <v-text-field
                              dense
                              outlined
                              hide-details
                              label="K"
                              :rules="[numberValidator]"
                              :min="0"
                              :max="100"
                              v-model="app.manualNPK.K"
                              type="number"
                              @change="calculateNPKforApplication(index)"
                            ></v-text-field>
                            <v-text-field
                              dense
                              outlined
                              hide-details
                              label="S"
                              :rules="[numberValidator]"
                              :min="0"
                              :max="100"
                              v-model="app.manualNPK.S"
                              type="number"
                              @change="calculateNPKforApplication(index)"
                            ></v-text-field>
                          </div>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="mt-3 mb-2 text-caption">
                          Nutrient lb/ac:
                        </div>
                        <p class="text-subtitle-1">
                          <span class="font-weight-bold">N: </span>{{ app.N }}
                          <span class="font-weight-bold">P: </span>{{ app.P }}
                          <span class="font-weight-bold">K: </span>{{ app.K }}
                          <span class="font-weight-bold">S: </span>{{ app.S }}
                        </p>
                      </v-col>
                      <v-col>
                        <div>
                          <b class="text-h7">
                            Did you use a slow-release fertilizer?<span
                              class="red--text"
                              >*</span
                            >
                          </b>
                          <div class="pt-2">
                            <v-btn
                              rounded
                              outlined
                              @click="app.slow_release = true"
                              :input-value="app.slow_release === true"
                              >Yes</v-btn
                            >
                            <v-btn
                              class="ml-4"
                              rounded
                              outlined
                              @click="app.slow_release = false"
                              :input-value="app.slow_release === false"
                              >No</v-btn
                            >
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Bottom Row -->
                    <v-row>
                      <v-col md="4">
                        <b class="text-h7">
                          Did you use a nitrogen (urease) inhibitor?<span
                            class="red--text"
                            >*</span
                          >
                        </b>
                        <div class="pt-2">
                          <v-btn
                            rounded
                            outlined
                            @click="app.inhibitor = true"
                            :input-value="app.inhibitor === true"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="app.inhibitor = false"
                            :input-value="app.inhibitor === false"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                      <v-col md="4">
                        <b class="text-h7">
                          Did you use a management plan?<span class="red--text"
                            >*</span
                          >
                        </b>
                        <div class="pt-2">
                          <v-btn
                            rounded
                            outlined
                            @click="app.management_plan = true"
                            :input-value="app.management_plan === true"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="app.management_plan = false"
                            :input-value="app.management_plan === false"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                      <v-col md="4">
                        <b class="text-h7">
                          Did you use Variable Rate Technology (VRT)?<span
                            class="red--text"
                            >*</span
                          >
                        </b>
                        <div class="pt-2">
                          <v-btn
                            rounded
                            outlined
                            @click="app.vrt = true"
                            :input-value="app.vrt === true"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="app.vrt = false"
                            :input-value="app.vrt === false"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn class="mt-4" block @click="handleAddEventClick">
                  Add Fertilizer Event<v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="applicationData.length == 0">
              <v-card class="mx-auto mt-4" elevation="0">
                <v-card-text>
                  <div class="text-center">
                    <b class="text-h5 text-bold"
                      >No Fertilizers Applied for {{ this.year }}</b
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your fertilizer information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddMoreFertilizer">
                Add More Fertilizer
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Fertilizer Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in summaryData" :key="data.idx">
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplay[data[key].value]
                      }}</span>
                      <span v-else>
                        {{
                          data[key].value
                            ? data[key].value
                            : "None" | cleanSnake
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <!-- Fields, Data Comp, and Add Practices -->
      <v-row class="mt-4 mb-2 row-margin-correct">
        <div>
          <span class="text-h5 ml-1"
            >{{ fertSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              class="mx-1"
              text
              v-for="y in yearListModified"
              :key="y.idx"
              @click="handleSeasonSelection(y)"
              :color="year == y ? 'green' : ''"
              >{{ y }}</v-btn
            >
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn
              :class="showFertilizerInfo ? 'black--text' : 'white--text'"
              :color="showFertilizerInfo ? 'white' : 'green'"
              x-large
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>
      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn
          class="mr-3 all-button"
          @click="handleSelectAll(true, true)"
          text
        >
          <span>All</span>
          <span>({{ fertSettingsByField.length }})</span>
        </v-btn>
        <v-btn
          class="mr-3"
          text
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          :color="
            JSON.stringify(cropSelect) ==
            JSON.stringify(sharedCropPlan.crops.split(','))
              ? 'green'
              : ''
          "
          @click="handleCropFilterButton(sharedCropPlan)"
        >
          <span>{{ mapCropNames(sharedCropPlan.crops) }}</span>
          <span>({{ sharedCropPlan.fieldIds.length }})</span>
        </v-btn>
      </v-row>
      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        ></v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th>Applications</th>
                    <th class="three">
                      <div>
                        Copy
                        <!-- <v-tooltip top
                        ><template v-slot:activator="{ on, attrs }"
                          ><v-icon v-bind="attrs" v-on="on"
                            >mdi-information</v-icon
                          ></template
                        ><span
                          >Click copy to copy a field's settings quickly to set
                          multiple fields at once.</span
                        ></v-tooltip
                      > -->
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in fertSettingsbyFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="
                          disableCheckbox(field.noYearData) || field.fieldLocked
                        "
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>{{ field.fieldName }}</td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td>
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <!-- Fert Applications Cell -->
                    <td class="py-2" v-if="!field.noYearData">
                      <v-row
                        class="mx-0"
                        :class="
                          field.fertInfo.indexOf(fertSingle) !=
                          field.fertInfo.length - 1
                            ? 'row-bottom'
                            : ''
                        "
                        dense
                        v-for="fertSingle in field.fertInfo.sort(
                          (a, b) => a.crop_id.value - b.crop_id.value
                        )"
                        :key="fertSingle.idx"
                      >
                        <v-col md="2">{{ fertSingle.date.value }}</v-col>
                        <v-col md="4">
                          <span
                            :class="
                              fertSingle.product.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              fertSingle.product.value
                                ? fertSingle.product.value
                                : " No Product" | cleanSnake
                            }}
                          </span>
                        </v-col>
                        <v-col md="3">
                          <span
                            :class="
                              fertSingle.amount.value !== null
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              fertSingle.amount.value !== null
                                ? fertSingle.amount.value
                                : "No Amount" | cleanSnake
                            }}
                          </span>
                          <span
                            :class="
                              fertSingle.unit.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              fertSingle.unit.value
                                ? mapOldUnits(fertSingle.unit.value)
                                : "Missing Units" | cleanSnake
                            }}
                          </span>
                        </v-col>
                        <v-col md="3">
                          <!-- Data Complete -->
                          <div
                            class="d-flex"
                            v-if="fertilizerComplete(fertSingle)"
                          >
                            <v-tooltip top
                              ><template v-slot:activator="{ on, attrs }"
                                ><v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  color="success"
                                  >mdi-check-circle</v-icon
                                ></template
                              ><span
                                >You've filled out enough information for Arva
                                data models to run. Thank you!</span
                              ></v-tooltip
                            >
                            <span class="ml-2">Data Complete</span>
                          </div>
                          <!-- Needs Confirmation -->
                          <div
                            class="d-flex"
                            v-else-if="fertilizerNeedsConfirm(fertSingle)"
                          >
                            <v-tooltip top
                              ><template v-slot:activator="{ on, attrs }"
                                ><v-icon v-bind="attrs" v-on="on" color="yellow"
                                  >mdi-alert-rhombus</v-icon
                                ></template
                              ><span
                                >This information is coming from a public
                                source. Please include this field when setting
                                information to confirm it's data!</span
                              ></v-tooltip
                            >
                            <span
                              class="ml-2 cursor blue--text lighten-1 text-bold"
                              @click="handleConfirm(field)"
                              >Confirm Data</span
                            >
                          </div>
                          <!-- Missing Data -->
                          <div
                            class="d-flex"
                            v-else-if="fertilizerMissingData(fertSingle)"
                          >
                            <v-tooltip top
                              ><template v-slot:activator="{ on, attrs }"
                                ><v-icon v-bind="attrs" v-on="on" color="red"
                                  >mdi-alert-circle</v-icon
                                ></template
                              ><span
                                >This field is missing data. Please fill in its
                                data using the wizard!</span
                              ></v-tooltip
                            >
                            <span class="ml-2">Missing Data</span>
                          </div>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="py-2" v-else>
                      <div @click="handleShowRedirect(field)">
                        <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on" color="red"
                              >mdi-alert-circle</v-icon
                            ></template
                          ><span
                            >This field doesn't have data for this year. Add
                            another year in the data spreadsheet or upload data
                            from this year.</span
                          ></v-tooltip
                        ><span class="ml-2 cursor red--text text-bold"
                          >No Data for Year</span
                        >
                      </div>
                    </td>
                    <!-- Copy Cell -->
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Incomplete Application Modal -->
      <IncompleteFertAppModal
        v-if="showIncompleteModal"
        :incompleteApplications="incompleteApplications"
        dataCategory="application"
        @close-template-modal="showIncompleteModal = false"
      />

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <!-- Wizard Confirm Modal -->
      <WizardConfirmModal
        v-if="showConfirmModal"
        :confirmationField="confirmationField"
        :confirmationData="confirmationApplications"
        :wantedKeys="['date', 'product', 'amount', 'unit']"
        :year="year"
        :loadState="loadState"
        @close-template-modal="handleCloseConfirm"
        @confirm-data="handleModalConfirm"
      />

      <!-- Redirect -->
      <WizardRedirectModal
        v-if="showRedirectModal"
        :redirectField="redirectField"
        :year="year"
        @close-template-modal="handleCloseRedirect"
        @redirect-confirm="handleRedirectConfirm"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Fertilizer, Organization } from "@/store/modules"
import {
  CROPS,
  CROP_DISPLAY_NAME,
  CROP_KEY,
  CROP_KEY_DISPLAY_NAME,
} from "@/constants"
import { MAX_DEFAULT_FIELDS } from "@/constants/defaults"
import IncompleteFertAppModal from "@/components/modals/IncompleteFertAppModal"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardConfirmModal from "@/components/modals/WizardConfirmModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import { numberValidator } from "@/utility"

export default {
  name: "NewFertilizerWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletion: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    IncompleteFertAppModal,
    WizardConfirmModal,
    WizardRedirectModal,
  },
  data() {
    return {
      el: 1,
      // constants
      cropDisplay: CROP_DISPLAY_NAME,
      // data values
      numApplications: 0,
      applicationData: [],
      // choices
      cropChoices: Object.values(CROP_KEY),
      rateChoices: ["lb/ac", "gal/acre", "lb", "gal"],
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      filterFarms: [],
      filterFarmsReflection: [],
      tableFilterText: "",
      tableFilterFields: null,
      selectAllBox: true,
      numberValidator,
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,
      // full validation for application completeness
      showIncompleteModal: false,
      incompleteApplications: null,
      alertErrorModalOpen: false,
      // confirmation
      confirmationField: null,
      confirmationApplications: null,
      showConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // select crop type UX, disables selecting new fields when selecting by crop tile
      cropSelect: null,
      //   new UI
      showFertilizerInfo: false,
      showPracticesView: true,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      summaryKeys: null,
      summaryData: null,
    }
  },
  computed: {
    ...mapGetters({
      fertilizerOptions: Fertilizer.Getters.getFertilizers,
      recentWizardData: Defaults.Getters.getRecentWizardData,
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    ...mapState({
      fertSettings: state => state.Defaults.newFieldSettings,
    }),
    methodChoices() {
      return this.practiceChoices["fertilizer_method"]
    },
    cropChoicesFiltered() {
      if (this.cropSelect != null) {
        const filteredCropList = Object.entries(CROP_KEY)
          .filter(o => {
            if (this.cropSelect.length > 1) {
              return this.cropSelect.includes(o[0])
            } else {
              return true
            }
          })
          .map(list => list[1])
        return filteredCropList
      } else {
        const selectedOpSettings = this.fertSettingsByField.filter(field =>
          this.fieldsInSelection.includes(parseInt(field.fieldId))
        )
        const selectedCrops = new Set(
          selectedOpSettings.flatMap(field => field.crops)
        )
        const selectedCropNames = Array.from(selectedCrops).map(
          c => CROP_KEY[c]
        )
        return selectedCropNames.filter(i => i != undefined)
      }
    },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      let yearDataComps = this.dataCompletionMaster["Fertilizer"].map(o => {
        if ("by_year" in o.data_comp) {
          let temp = o.data_comp.by_year.find(
            yearObj => yearObj.year == this.year
          )
          if (temp) {
            return temp.avg_for_year
          }
        } else {
          return 0
        }
      })
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.ceil(average(yearDataComps))
      } else {
        return 0
      }
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.fertSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    fertSettingsByField() {
      let fertDict = {}
      let yearInConsderation = this.year
      Object.entries(this.fertSettings).forEach(fertDefault => {
        const fieldKey = fertDefault[0]

        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        fertDict[fieldKey] = {}
        const fieldSettingObj = fertDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          fertId: fieldSettingObj.fertilizer_id,
        }
        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsderation
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            fertDict[fieldKey] = idSettings
            fertDict[fieldKey]["crops"] = ["No Data"]
            fertDict[fieldKey]["cropNames"] = ["No Data"]
            fertDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsderation) {
            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            const hasFertAppsArray = "fert_applications" in cultivations[0]
            if (!hasFertAppsArray) {
              fertDict[fieldKey] = idSettings
              fertDict[fieldKey]["crops"] = ["No Data"]
              fertDict[fieldKey]["cropNames"] = ["No Data"]
              fertDict[fieldKey]["noYearData"] = true
            } else {
              fertDict[fieldKey] = idSettings
              fertDict[fieldKey]["crops"] = []
              fertDict[fieldKey]["cropNames"] = []
              fertDict[fieldKey]["cropSearch"] = ""
              fertDict[fieldKey]["plantings"] = []
              fertDict[fieldKey]["fertInfo"] = []

              fertDict[fieldKey]["isSelected"] =
                this.fieldsInSelection.includes(parseInt(fieldKey))
                  ? true
                  : false

              cultivations.forEach(cultivation => {
                fertDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
                fertDict[fieldKey]["cropNames"].push(
                  CROP_DISPLAY_NAME[cultivation.arva_crop_id]
                )
                fertDict[fieldKey]["cropSearch"] =
                  fertDict[fieldKey]["cropSearch"] +
                  CROP_DISPLAY_NAME[cultivation.arva_crop_id]
                fertDict[fieldKey]["plantings"] = fertDict[fieldKey][
                  "plantings"
                ].concat(cultivation.plantings)
                cultivation.fert_applications.forEach(fertApp =>
                  fertDict[fieldKey]["fertInfo"].push(fertApp)
                )

                fertDict[fieldKey]["crops"].sort()
                fertDict[fieldKey]["cropNames"].sort()
                fertDict[fieldKey]["fieldLocked"] = cultivation.year_data_locked
                fertDict[fieldKey]["noYearData"] = false
              })
            }
          }
        })
      })
      return Object.values(fertDict)
    },
    fertSettingsbyFieldFiltered() {
      let arrayToReturn = _.cloneDeep(this.fertSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["isSelected", "fieldName"]
      const sortDirections = ["desc", "asc"]

      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const plantingsGrouped = _(this.fertSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
          noYearData: _.map(items, "noYearData")[0],
        }))
        .value()
      return plantingsGrouped
    },
    fieldFarms() {
      return [...new Set(this.fertSettingsByField.map(f => f.farmName))]
    },
    filterCropList() {
      return [
        ...new Set(this.fertSettingsByField.map(f => f.cropNames.join(", "))),
      ]
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateFertilizer: Defaults.Actions.updateFertilizer,
    }),
    ...mapMutations({
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    calculateNPKforApplication(index) {
      const app = this.applicationData[index]

      if (!app.amount || !app.product || !app.unit) {
        app.N = null
        app.P = null
        app.K = null
        app.S = null
        return
      }

      if (app.product == "other") {
        // "Other" only appears for solid applications, so gallon conversion is not needed (or available)
        if (
          app.manualNPK.N == null ||
          app.manualNPK.P == null ||
          app.manualNPK.K == null ||
          app.manualNPK.S == null
        ) {
          app.N = null
          app.P = null
          app.K = null
          app.S = null
          return
        }
        app.N = ((app.manualNPK.N * app.amount) / 100).toFixed(3)
        app.P = ((app.manualNPK.P * app.amount) / 100).toFixed(3)
        app.K = ((app.manualNPK.K * app.amount) / 100).toFixed(3)
        app.S = ((app.manualNPK.S * app.amount) / 100).toFixed(3)
      } else if (app.product == "none") {
        app.N = 0
        app.P = 0
        app.K = 0
        app.S = 0
      } else {
        const fertGettingSet = this.fertilizerOptions.find(
          f => f.fert == app.product
        )
        const chemistryVals = { N: 0, P: 0, K: 0, S: 0 }
        let gallonConversion = 1

        if (
          (app.method === "injected" || app.method === "sprayed") &&
          app.unit.includes("gal")
        ) {
          gallonConversion = fertGettingSet.lbs_per_gallon
        }
        chemistryVals["N"] =
          fertGettingSet.pct_n * app.amount * gallonConversion
        chemistryVals["P"] =
          fertGettingSet.pct_p2o5 * app.amount * gallonConversion
        chemistryVals["K"] =
          fertGettingSet.pct_k2o * app.amount * gallonConversion
        chemistryVals["S"] =
          fertGettingSet.pct_s * app.amount * gallonConversion

        app.N = chemistryVals["N"].toFixed(3)
        app.P = chemistryVals["P"].toFixed(3)
        app.K = chemistryVals["K"].toFixed(3)
        app.S = chemistryVals["S"].toFixed(3)
      }
    },
    handleCopy(field) {
      this.handleInfoReset(false)

      const numFieldApplications = field.fertInfo.length
      this.numApplications = numFieldApplications

      for (let i = 0; i < this.numApplications; i++) {
        this.applicationData.push({
          N: field.fertInfo[i].N.value,
          P: field.fertInfo[i].P.value,
          K: field.fertInfo[i].K.value,
          S: field.fertInfo[i].S.value,
          ammonium_pct: field.fertInfo[i].ammonium_pct
            ? field.fertInfo[i].ammonium_pct.value
            : null,
          amount: field.fertInfo[i].amount.value,
          cost: field.fertInfo[i].cost.value,
          crop_id: CROP_KEY[field.fertInfo[i].crop_id.value],
          date: field.fertInfo[i].date.value,
          inhibitor: field.fertInfo[i].inhibitor.value,
          method: field.fertInfo[i].method.value,
          product: field.fertInfo[i].product.value,
          slow_release: field.fertInfo[i].slow_release.value,
          unit: field.fertInfo[i].unit.value,
          vrt: field.fertInfo[i].vrt.value,
          management_plan: field.fertInfo[i].management_plan.value,
          showAllInfo: true,
          menu: false,
          pickerDate: this.year + "-01",
          manualNPK: { N: null, P: null, K: null, S: null },
        })
      }
      this.showFertilizerInfo = true
      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },
    handleNumApplications(num) {
      // reset number of fert applications then push data
      this.numApplications = 0
      this.applicationData = []
      const difference = num - this.numApplications
      this.numApplications = num
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null

      for (let i = 0; i < difference; i++) {
        this.applicationData.push(this._blankApplicationData(cropValue))
      }
    },
    handleInfoReset(resetApplicationData = false) {
      this.cropSelect = null
      this.numApplications = null
      this.applicationData = []
      if (resetApplicationData) {
        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        this.applicationData.push(this._blankApplicationData(cropValue))
      }
    },
    isAppComplete(app) {
      const complete =
        app.crop_id &&
        app.method &&
        app.product &&
        app.amount !== null &&
        app.unit &&
        app.date &&
        app.slow_release != null &&
        app.vrt != null &&
        app.management_plan != null &&
        app.inhibitor != null &&
        app.N != null &&
        app.P != null &&
        app.K != null &&
        app.S != null
      return complete
    },
    allAppsComplete() {
      if (!this.applicationData) return [0]
      const appCompleteness = this.applicationData.map(fertApp =>
        this.isAppComplete(fertApp)
      )
      const appsMissing = appCompleteness.map((value, index) =>
        value == null || value == false ? index : ""
      )
      const indexOfMissing = appsMissing.filter(String)
      return indexOfMissing
    },
    handleRemoveApp(appIndex) {
      this.applicationData.splice(appIndex, 1)
    },
    async handleSave() {
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }

      const appCompleteness = this.applicationData.map(fertApp =>
        this.isAppComplete(fertApp)
      )
      const appsMissing = appCompleteness.map((value, index) =>
        value == null ? index : ""
      )
      const indexOfMissing = appsMissing.filter(String)

      // find which fert applications are missing info
      if (indexOfMissing.length > 0) {
        this.incompleteApplications = indexOfMissing
        this.showIncompleteModal = true
        return
      } else {
        // save fertilizer to db
        let dataClone = _.cloneDeep(this.applicationData)
        let fertApplicationPayload = null
        if (this.applicationData.length > 0) {
          fertApplicationPayload = dataClone.map(fertApp => {
            // remove showAllInfo and menu to prevent issues with replace_existing in the backend
            delete fertApp["showAllInfo"]
            delete fertApp["menu"]
            delete fertApp["pickerDate"]
            delete fertApp["manualNPK"]

            Object.keys(fertApp).forEach(fertKey => {
              if (fertKey == "crop_id") {
                fertApp[fertKey] = {
                  value: CROPS[CROP_KEY_DISPLAY_NAME[fertApp[fertKey]]],
                  source: "User Input",
                }
              } else if (fertKey == "product") {
                if (typeof fertApp[fertKey] === "object") {
                  // prevents product from being set as an object from the combobox
                  fertApp["product"] = {
                    value: fertApp[fertKey],
                    source: "User Input",
                  }
                } else {
                  fertApp[fertKey] = {
                    value: fertApp[fertKey],
                    source: "User Input",
                  }
                }
              } else {
                fertApp[fertKey] = {
                  value: fertApp[fertKey],
                  source: "User Input",
                }
              }
            })
            return fertApp
          })
        } else {
          const fieldsBeingSaved = this.fertSettingsbyFieldFiltered.filter(f =>
            this.fieldsInSelection.includes(Number(f.fieldId))
          )
          const cropsBeingSaved = fieldsBeingSaved
            .map(f => f.crops)
            .reduce((acc, arr) => acc.concat(arr), [])
            .filter((item, index, self) => self.indexOf(item) === index)
          fertApplicationPayload = []
          cropsBeingSaved.forEach(cropId => {
            fertApplicationPayload.push({
              N: { value: 0, source: "User Input" },
              P: { value: 0, source: "User Input" },
              K: { value: 0, source: "User Input" },
              S: { value: 0, source: "User Input" },
              ammonium_pct: { value: 0, source: "User Input" },
              amount: { value: 0, source: "User Input" },
              cost: { value: null, source: "User Input" },
              crop_id: { value: cropId, source: "User Input" },
              date: { value: this.year + "-01-01", source: "User Input" },
              inhibitor: { value: false, source: "User Input" },
              method: { value: "none", source: "User Input" },
              product: { value: "none", source: "User Input" },
              slow_release: { value: false, source: "User Input" },
              unit: { value: "none", source: "User Input" },
              vrt: { value: false, source: "User Input" },
              management_plan: { value: false, source: "User Input" },
            })
          })
        }

        // this.$emit("submit-fertilizer")
        this.showPracticesView = false
        this.showSaveView = true

        let fertilizerUpdates = []
        this.fertSettingsbyFieldFiltered.forEach(async field => {
          if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
            if (!field.noYearData && !field.fieldLocked) {
              fertilizerUpdates.push(
                this.updateFertilizer({
                  fieldId: field.fieldId,
                  year: this.year,
                  fertApplications: fertApplicationPayload,
                })
              )
            } else {
              console.log("no year data for ", field)
            }
          }
          return Promise.resolve()
        })
        await Promise.all(fertilizerUpdates)
        this.$emit("update-data-complete")

        const fieldNames = this.fertSettingsbyFieldFiltered
          .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
          .map(f => f.fieldName)
        const fertWantedKeys = [
          "crop_id",
          "date",
          "product",
          "amount",
          "unit",
          "inhibitor",
          "management_plan",
          "slow_release",
          "vrt",
        ]
        this.setRecentWizardData({
          wizard: "fertilizer",
          data: this.applicationData,
        })

        this.summaryKeys = fertWantedKeys
        this.summaryData = fertApplicationPayload
        this.summaryFields = fieldNames
        this.showSaveView = false
        this.showSummaryView = true
      }
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    productsByMethod(method) {
      const otherNoneOptions = [
        { display_name: "Other", value: "other" },
        { display_name: "None", value: "none" },
      ]
      if (
        method === "broadcast" ||
        method === "incorporated" ||
        method === "strip till" ||
        method === "banded"
      ) {
        const dryOptions = this.fertilizerOptions.filter(
          f => f.fert_application == "dry"
        )
        return dryOptions.concat(otherNoneOptions)
      }
      if (method === "injected" || method === "sprayed") {
        const liquidOptions = this.fertilizerOptions.filter(
          f => f.fert_application == "liquid"
        )
        return liquidOptions.concat(otherNoneOptions)
      }
      if (method === "manure") {
        const manureOptions = this.fertilizerOptions.filter(
          f => f.fert_application == "manure"
        )
        return manureOptions.concat(otherNoneOptions)
      }
      return otherNoneOptions
    },
    fertilizerComplete(fert) {
      if (!fert) return null
      return (
        fert.date.source == "User Input" &&
        fert.date.value != null &&
        fert.unit.source == "User Input" &&
        fert.unit.value != null &&
        fert.amount.source == "User Input" &&
        fert.amount.value !== null &&
        fert.method.source == "User Input" &&
        fert.method.value != null &&
        fert.product.source == "User Input" &&
        fert.product.value != null &&
        fert.inhibitor.source == "User Input" &&
        fert.inhibitor.value != null &&
        fert.vrt.source == "User Input" &&
        fert.vrt.value != null &&
        fert.management_plan.source == "User Input" &&
        fert.management_plan.value != null &&
        fert.N.source == "User Input" &&
        fert.N.value != null &&
        fert.P.source == "User Input" &&
        fert.P.value != null &&
        fert.K.source == "User Input" &&
        fert.K.value != null &&
        fert.S.source == "User Input" &&
        fert.S.value != null
      )
    },
    fertilizerNeedsConfirm(fert) {
      if (!fert) return null
      return (
        fert.date.source != "User Input" &&
        fert.date.value != null &&
        fert.unit.source != "User Input" &&
        fert.unit.value != null &&
        fert.amount.source != "User Input" &&
        fert.amount.value != null &&
        fert.method.source != "User Input" &&
        fert.method.value != null &&
        fert.product.source != "User Input" &&
        fert.product.value != null &&
        fert.inhibitor.source != "User Input" &&
        fert.inhibitor.value != null &&
        fert.vrt.source != "User Input" &&
        fert.vrt.value != null &&
        fert.management_plan.source != "User Input" &&
        fert.management_plan.value != null &&
        fert.N.source != "User Input" &&
        fert.N.value != null &&
        fert.P.source != "User Input" &&
        fert.P.value != null &&
        fert.K.source != "User Input" &&
        fert.K.value != null &&
        fert.S.source != "User Input" &&
        fert.S.value != null
      )
    },
    fertilizerMissingData(fert) {
      if (!fert) return null
      return (
        fert.date.source != "User Input" ||
        fert.date.value == null ||
        fert.date.value == "" ||
        fert.unit.source != "User Input" ||
        fert.unit.value == null ||
        fert.unit.value == "" ||
        fert.amount.source != "User Input" ||
        fert.amount.value == null ||
        fert.amount.value == "" ||
        fert.method.source != "User Input" ||
        fert.method.value == null ||
        fert.method.value == "" ||
        fert.product.source != "User Input" ||
        fert.product.value == null ||
        fert.product.value == "" ||
        fert.vrt.source != "User Input" ||
        fert.vrt.value == null ||
        fert.management_plan.source != "User Input" ||
        (fert.management_plan.value == null &&
          fert.N.source != "User Input" &&
          fert.N.value == null &&
          fert.P.source != "User Input" &&
          fert.P.value == null &&
          fert.K.source != "User Input" &&
          fert.K.value == null &&
          fert.S.source != "User Input" &&
          fert.S.value == null)
      )
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (
        e == true &&
        this.fieldsInSelection.indexOf(parseInt(field.fieldId)) == -1
      ) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    mapOldUnits(unit) {
      if (unit == "lb") {
        return "lb/ac"
      } else if (unit == "gal") {
        return "gal/acre"
      }
      return unit
    },
    handleConfirm(field) {
      this.confirmationField = _.cloneDeep(field)
      this.confirmationApplications = _.cloneDeep(field.fertInfo)
      this.confirmationApplications.forEach(fert => {
        for (const key in fert) {
          fert[key].source = "User Input"
        }
      })
      this.showConfirmModal = true
    },
    async handleModalConfirm() {
      this.loadState = "loading"
      await this.updateFertilizer({
        fieldId: this.confirmationField.fieldId,
        year: this.year,
        fertApplications: this.confirmationApplications,
      })
      this.loadState = "confirmed"
      this.$emit("update-data-complete")
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.handleCloseConfirm()
    },
    handleCloseConfirm() {
      this.showConfirmModal = false
      this.confirmationField = null
      this.confirmationApplications = null
      this.loadState = null
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
        if (this.applicationData.length > 0) {
          const cropValue =
            this.cropSelect != null && this.cropSelect.length == 1
              ? CROP_KEY[parseInt(this.cropSelect[0])]
              : null
          this.applicationData.forEach(o => {
            o.crop_id = cropValue
          })
        }
      }
    },
    handleAddPracticesClick() {
      if (this.showFertilizerInfo == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        this.showFertilizerInfo = true
        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        this.applicationData = [this._blankApplicationData(cropValue)]
      } else {
        this.showFertilizerInfo = false
        this.applicationData = []
      }
    },
    handleNoFertilizerClick() {
      this.applicationData = []
    },
    _blankApplicationData(cropValue) {
      return {
        N: null,
        P: null,
        K: null,
        S: null,
        ammonium_pct: 0,
        amount: null,
        cost: null,
        crop_id: cropValue,
        date: this.year + "-01-01",
        inhibitor: false,
        method: null,
        product: null,
        slow_release: false,
        unit: null,
        vrt: false,
        management_plan: false,
        showAllInfo: true,
        // menu is boolean for v-menu model
        menu: false,
        pickerDate: this.year + "-01",
        manualNPK: { N: null, P: null, K: null, S: null },
      }
    },
    handleYesFertilizerClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.applicationData = [this._blankApplicationData(cropValue)]
    },
    handleAddEventClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.applicationData.push(this._blankApplicationData(cropValue))
    },
    handleAddMoreFertilizer() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
  },
  mounted() {
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f])
    if (this.recentWizardData.wizard == "fertilizer") {
      this.numApplications = this.recentWizardData.data.length
      this.applicationData = _.cloneDeep(this.recentWizardData.data)
      this.el = 4
    }
  },
  watch: {
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.fertSettingsByField)
        if (this.filterFarmsReflection.length > 0) {
          arrayToFilter = arrayToFilter.filter(field =>
            this.filterFarms.includes(field.farmName)
          )
        }
        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
    filterFarms: {
      deep: true,
      handler(myArray) {
        this.filterFarmsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.fertSettingsByField)
        if (this.filterFarmsReflection.length > 0) {
          arrayToFilter = arrayToFilter.filter(field =>
            this.filterFarms.includes(field.farmName)
          )
        }
        if (this.filterCropsReflection.length > 0) {
          arrayToFilter = arrayToFilter.filter(field =>
            this.filterCropsReflection.includes(field.cropNames.join(", "))
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
    el(newEl) {
      if (newEl == 1) {
        this.cropSelect = null
      }
    },
  },
}
</script>
<style scoped>
.input-holder {
  width: 320px;
}

.step-height {
  min-height: 400px;
  max-height: 400px;
  height: 400px;
}

.min-step-height {
  min-height: 400px;
}

.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.three {
  width: 3%;
}

.ten {
  width: 10%;
}

.twenty {
  width: 20%;
}

.cursor {
  cursor: pointer;
}

.margin-hundred {
  margin-top: 100px;
}
</style>
