import API from "./API"

const getEnrolledFields = () => API.get("/enrollment-fields/")
const getEnrollmentHistoryByField = payload =>
  API.get("/enrollment-history/", {
    params: payload,
  })
const listEnrollments = () => API.get("/enroll/")
const enrollFields = payload => API.post("/enroll/", payload)
const unenrollField = payload => API.put(`/enroll/${payload.id}/`, { payload })
const triggerDaycentSimulation = "/trigger-daycent-simulation/"
const additionalSpreadsheetYear = "/add-carbon-spreadsheet-year/"
const postTriggerDaycentSimulation = payload =>
  API.post(triggerDaycentSimulation, payload)
const postAdditionalSpreadsheetYear = payload =>
  API.post(additionalSpreadsheetYear, payload)
const fieldSelectionPdf = fieldSelectionJson =>
  API.post("/field-selection-pdf-data/", {
    field_selection_data: fieldSelectionJson,
  })
const getRegenPractice = () => API.get("/regen-practice/")
const postPracticePreference = payload =>
  API.post("/update-practices/", payload)

const deleteRequestedEnrollment = payload =>
  API.delete(`/enroll/${payload.id}`, { payload })

const postProjectReports = payload => API.post("/project-report/", payload)
const postOrderRerun = payload => API.post("/request-plan-cp-rerun/", payload)
const getEnrollmentSamplingSummary = payload =>
  API.get("/request-plan-cp-rerun/", { params: payload })

export default {
  getEnrolledFields,
  getEnrollmentHistoryByField,
  getRegenPractice,
  enrollFields,
  unenrollField,
  postTriggerDaycentSimulation,
  postAdditionalSpreadsheetYear,
  postPracticePreference,
  fieldSelectionPdf,
  listEnrollments,
  deleteRequestedEnrollment,
  postProjectReports,
  postOrderRerun,
  getEnrollmentSamplingSummary,
}
