<template>
  <div>
    <FieldImageryMap
      :boundaries="fieldBoundaries"
      :fields="fields"
      :order="orders"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import MapAPI from "@/api/MapAPI"
import FieldImageryMap from "@/components/fieldImageryMap/FieldImageryMap"

import { Filter } from "@/store/modules"

export default {
  name: "FieldImageryView",
  components: {
    FieldImageryMap,
  },

  data() {
    return {
      fieldBoundaries: [],
      orders: [],
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),

    fields() {
      const activeFieldIds = this.activeFields.map(field => field.id)
      return this.fieldBoundaries.filter(row =>
        activeFieldIds.includes(row.properties.field.id)
      )
    },
  },

  mounted() {
    MapAPI.fetchBoundaries().then(response => {
      this.fieldBoundaries = response.data.features
    })
  },
}
</script>
