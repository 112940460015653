<template>
  <div>
    <DashboardTitle
      title="Planning Profits and Losses"
      faClass="fa-usd"
      :beta="true"
      tooltip="Planning P & L is based on estimated yields per crop type derived from Arva's AI prediction engine for the selected fields in view."
    />

    <div class="row">
      <div class="col">
        <h3 class="pt-1">Summary</h3>
      </div>
      <div class="col">
        <div
          v-if="!isReadOnly"
          class="btn btn-primary btn-sm float-right mt-4"
          @click="csvSummaryExport(cropDesignerSummary)"
        >
          Download CSV
        </div>
      </div>
    </div>

    <v-simple-table>
      <thead>
        <tr>
          <th />
          <th>Crop</th>
          <th class="text-right">Total Acres</th>
          <th class="text-right">Estimated Yield / Ac.</th>
          <th class="text-right">Total Estimated Yield</th>
          <th class="text-right">Total Target Yield</th>
          <th class="text-right">Revenue</th>
          <th class="text-right">Seed Costs</th>
          <th class="text-right">Fertilizer Costs</th>
          <th class="text-right">
            Estimated Op Costs
            <i
              class="fa fa-info-circle"
              data-toggle="tooltip"
              data-placement="top"
              title="Est. Operating Costs include chemicals, irrigation, labor, and fuel. It excludes land rent and equipment recovery costs."
            />
          </th>
          <th class="text-right">Gross Profit</th>
          <th class="text-right gross-profit">Gross Profit / Ac.</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in cropDesignerSummary" :key="row.crop">
          <td><img class="crop-icon" :src="cropIconUrls[row.crop]" /></td>
          <td>{{ row.crop | crop }}</td>
          <td class="text-right">{{ row.acreage | prettyInteger }}</td>
          <td class="text-right">
            {{ (row.predictedYield / row.acreage) | prettyInteger }}
          </td>
          <td class="text-right">{{ row.predictedYield | prettyInteger }}</td>
          <td class="text-right">{{ row.targetYield | prettyInteger }}</td>
          <td class="text-right">{{ row.revenue | intCurrency }}</td>
          <td class="text-right">{{ row.seedCost | intCurrency }}</td>
          <td class="text-right">{{ row.fertCost | intCurrency }}</td>
          <td class="text-right">{{ row.opCost | intCurrency }}</td>
          <td class="text-right">{{ row.grossProfit | intCurrency }}</td>
          <td class="text-right gross-profit">
            {{ row.grossProfitPerAcre | intCurrency }}
          </td>
        </tr>
        <tr class="total-row">
          <td />
          <td>Total</td>
          <td class="text-right">{{ totalAcres | prettyInteger }}</td>
          <td class="text-right">
            {{ (totalPredictedYield / totalAcres) | prettyInteger }}
          </td>
          <td class="text-right">{{ totalPredictedYield | prettyInteger }}</td>
          <td class="text-right">{{ totalTargetYield | prettyInteger }}</td>
          <td class="text-right">{{ totalRevenue | intCurrency }}</td>
          <td class="text-right">{{ totalSeedCost | intCurrency }}</td>
          <td class="text-right">{{ totalFertCost | intCurrency }}</td>
          <td class="text-right">{{ totalOpCost | intCurrency }}</td>
          <td class="text-right">{{ totalGrossProfit | intCurrency }}</td>
          <td class="text-right gross-profit">
            {{ totalGrossProfitPerAcre | intCurrency }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <div class="row">
      <div class="col">
        <h3 class="pt-1">By Field</h3>
      </div>
      <div class="col">
        <div
          v-if="!isReadOnly"
          class="btn btn-primary btn-sm float-right mt-4"
          @click="csvFieldExport(fieldRows)"
        >
          Download CSV
        </div>
      </div>
    </div>

    <v-simple-table fixed-header height="calc(100vh - 470px)">
      <thead>
        <tr>
          <th />
          <th>Field</th>
          <th class="text-right">Acreage</th>
          <th>Next Year Crop</th>
          <th class="text-right">Estimated Yield / Ac.</th>
          <th class="text-right">Estimated Total Yield</th>
          <th>
            Confidence
            <i
              class="fa fa-info-circle"
              data-toggle="tooltip"
              data-placement="top"
              title="Confidence of Arva's estimated yield for this field. Fields with no measured yield data will have low confidence predictions. Fields with measured yield data will have higher confidence predictions."
            />
          </th>
          <th class="text-right">Target Yield / Ac.</th>
          <th class="text-right">Revenue</th>
          <th class="text-right">Seed Costs</th>
          <th class="text-right">Fertilizer Costs</th>
          <th class="text-right">
            Estimated Op Costs
            <i
              class="fa fa-info-circle"
              data-toggle="tooltip"
              data-placement="top"
              title="Est. Operating Costs include chemicals, irrigation, labor, and fuel. It excludes land rent and equipment recovery costs."
            />
          </th>
          <th class="text-right">Gross Profit</th>
          <th class="text-right gross-profit">Gross Profit / Ac.</th>
        </tr>
      </thead>
      <tbody>
        <ProfitAndLossRow
          v-for="field in fieldRows"
          :key="field.id"
          :field="field"
          :nextCropSelection="nextCropSelections[field.id]"
          :targetYields="targetYields[field.id]"
        />
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import ProfitAndLossRow from "@/components/profitAndLoss/ProfitAndLossRow"
import { CROP_DISPLAY_NAME, CROP_ID_TO_ICON_URL } from "@/constants"
import { Dashboard, Filter } from "@/store/modules"
import { fertScaleFactor, yieldScaleFactor } from "@/utility"

export default {
  name: "ProfitsAndLosses",

  components: { DashboardTitle, ProfitAndLossRow },

  data() {
    return {
      cropIconUrls: CROP_ID_TO_ICON_URL,
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
      cropDesignerSummary: Dashboard.Getters.getCropDesignerSummary,
    }),

    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      cropDesign: state => state.Dashboard.cropDesign,
      nextCropSelections: state => state.Dashboard.nextCropSelections,
      targetYields: state => state.Dashboard.targetYields,
    }),

    fieldRows() {
      return this.activeFields.filter(field => field.crop_designer)
    },

    totalAcres() {
      let totalAcres = 0
      for (const row of this.cropDesignerSummary) totalAcres += row.acreage
      return totalAcres
    },

    totalPredictedYield() {
      let totalPredictedYield = 0
      for (const row of this.cropDesignerSummary)
        totalPredictedYield += row.predictedYield
      return totalPredictedYield
    },

    totalTargetYield() {
      let totalTargetYield = 0
      for (const row of this.cropDesignerSummary)
        totalTargetYield += row.targetYield
      return totalTargetYield
    },

    totalRevenue() {
      let totalRevenue = 0
      for (const row of this.cropDesignerSummary) totalRevenue += row.revenue
      return totalRevenue
    },

    totalSeedCost() {
      let totalSeedCost = 0
      for (const row of this.cropDesignerSummary) totalSeedCost += row.seedCost
      return totalSeedCost
    },

    totalFertCost() {
      let totalFertCost = 0
      for (const row of this.cropDesignerSummary) totalFertCost += row.fertCost
      return totalFertCost
    },

    totalOpCost() {
      let totalOpCost = 0
      for (const row of this.cropDesignerSummary) totalOpCost += row.opCost
      return totalOpCost
    },

    totalGrossProfit() {
      let totalGrossProfit = 0
      for (const row of this.cropDesignerSummary)
        totalGrossProfit += row.grossProfit
      return totalGrossProfit
    },

    totalGrossProfitPerAcre() {
      if (!this.totalAcres) return
      return this.totalGrossProfit / this.totalAcres
    },
  },

  methods: {
    csvFieldExport(fieldData) {
      const headers = [
        "Field",
        "Acreage",
        "Next Year Crop",
        "Estimated Yield",
        "Estimated Yield/Ac",
        "Target Yield/Ac",
        "Revenue",
        "Seed Costs",
        "Fertilizer Costs",
        "Estimated Op Costs",
        "Gross Profit",
        "Gross Profit/Ac",
      ].join(",")

      const tableData = fieldData.map(field => {
        const nextCrop = this.nextCropSelections[field.id]
        const cropInfo = field.crop_designer.crops[nextCrop]
        if (!cropInfo) {
          return [
            field.name,
            parseInt(field.acreage),
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ].join(",")
        }
        const {
          predicted_yield,
          revenue,
          seed_cost,
          fert_cost,
          est_op_cost,
        } = cropInfo

        const predictedYieldPerAcre = predicted_yield / field.acreage
        const nextCropId = parseInt(nextCrop)
        const fieldTargetYields = this.targetYields[field.id]
        let targetYield = fieldTargetYields[nextCropId]
        if (typeof targetYield === "undefined")
          targetYield = predictedYieldPerAcre

        const intDollar = num => `$${num.toFixed(2)}`
        const fieldYieldScaleFactor = yieldScaleFactor(
          predictedYieldPerAcre,
          targetYield
        )
        const fertRecScaleFactor = fertScaleFactor(
          nextCropId,
          predictedYieldPerAcre,
          targetYield
        )

        const scaledRevenue = revenue * fieldYieldScaleFactor
        const fertCost = fert_cost * fertRecScaleFactor
        const grossProfit = scaledRevenue - seed_cost - fertCost - est_op_cost
        const grossProfitPerAcre = grossProfit / field.acreage

        const arrayToJoin = [
          field.name,
          parseInt(field.acreage),
          CROP_DISPLAY_NAME[nextCropId],
          parseInt(predicted_yield),
          predictedYieldPerAcre,
          targetYield,
          intDollar(scaledRevenue),
          intDollar(seed_cost),
          intDollar(fertCost),
          intDollar(est_op_cost),
          intDollar(grossProfit),
          intDollar(grossProfitPerAcre),
        ].join(",")
        return arrayToJoin
      })
      const csvContent = [headers, ...tableData]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
      const csvData = new Blob([csvContent], { type: "text/csv" })
      const link = document.createElement("a")
      link.setAttribute("href", URL.createObjectURL(csvData))
      link.setAttribute("download", `Planning_Profit_and_Loss_Field.csv`)
      link.click()
    },

    csvSummaryExport(summaryData) {
      const headers = [
        "Crop",
        "Total Acres",
        "Estimated Yield",
        "Estimated Yield/Ac",
        "Target Yield/Ac",
        "Revenue",
        "Seed Costs",
        "Fertilizer Costs",
        "Estimated Op Costs",
        "Gross Profit",
        "Gross Profit/Ac",
      ].join(",")
      const tableData = summaryData.map(summary => {
        const {
          crop,
          acreage,
          predictedYield,
          targetYield,
          revenue,
          seedCost,
          fertCost,
          opCost,
          grossProfit,
          grossProfitPerAcre,
        } = summary

        const predictedYieldPerAcre = predictedYield / acreage
        const cropId = parseInt(crop)

        const intDollar = num => `$${num.toFixed(2)}`

        const arrayToJoin = [
          CROP_DISPLAY_NAME[cropId],
          parseInt(acreage),
          parseInt(predictedYield),
          parseInt(predictedYieldPerAcre),
          parseInt(targetYield),
          intDollar(revenue),
          intDollar(seedCost),
          intDollar(fertCost),
          intDollar(opCost),
          intDollar(grossProfit),
          intDollar(grossProfitPerAcre),
        ].join(",")
        return arrayToJoin
      })
      const csvContent = [headers, ...tableData]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
      const csvData = new Blob([csvContent], { type: "text/csv" })
      const link = document.createElement("a")
      link.setAttribute("href", URL.createObjectURL(csvData))
      link.setAttribute("download", `Planning_Profit_and_Loss_Summary.csv`)
      link.click()
    },
  },

  mounted() {
    const { $ } = window
    $(function() {
      $('[data-toggle="tooltip"]').tooltip()
    })
  },
}
</script>

<style scoped>
h3 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 10px;
}

.total-row {
  border-top: 2pt solid #ccc;
}

.crop-icon {
  width: 30px;
  height: 30px;
}

thead > tr > th {
  white-space: nowrap;
}

.gross-profit {
  background: #d1ecfe !important;
}
</style>
