<template>
  <v-stepper v-model="el" non-linear>
    <v-stepper-header>
      <!-- <v-stepper-step :complete="e1 > 1" step="1"> -->
      <v-stepper-step :complete="el > 1" step="1">
        Fields
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="el > 2" step="2">
        Products
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="my-auto mx-auto">
          <v-card-text class="text-center">
            <v-row>
              <v-btn class="ml-auto" @click="el = 2">Continue</v-btn>
            </v-row>
            <b class="text-h5">Current Fields for Consideration:</b>
            <div class="pt-3">
              <v-row>
                <v-col>
                  Crops
                  <div v-for="key in Object.keys(cropSelected)" :key="key">
                    {{ key in cropDisplayName ? cropDisplayName[key] : key }}:
                    {{ cropSelected[key] }}
                  </div>
                </v-col>
                <v-col
                  >Clients: {{ hoverClient.id ? hoverClient.name : "" }}
                  <div
                    v-for="client in clientSelected"
                    :key="client.id"
                    :class="
                      client.id == hoverClient.id ? 'font-weight-bold' : ''
                    "
                    @mouseover="handleClientHovered(client)"
                    @mouseleave="handleClientMouseLeave()"
                  >
                    {{ client.name }}
                  </div></v-col
                >
                <v-col
                  >Farms
                  <div
                    v-for="farm in farmSelected"
                    :key="farm.id"
                    :class="
                      hoverFarms.includes(farm.id) ? 'font-weight-bold' : ''
                    "
                    @mouseover="handleFarmHovered(farm)"
                    @mouseleave="handleFarmMouseLeave()"
                  >
                    {{ farm.name }}
                  </div>
                </v-col>
                <v-col
                  >Fields
                  <div
                    v-for="field in selectedFields"
                    :key="field.id"
                    :class="
                      hoverFields.includes(field.id) ? 'font-weight-bold' : ''
                    "
                    @mouseover="handleFieldHovered(field)"
                    @mouseleave="handleFieldMouseLeave()"
                  >
                    {{ field.name }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-card class="my-auto mx-auto">
          <v-card-text class="text-center">
            <v-row>
              <v-btn
                class="ml-auto"
                :class="leaveInitWizard ? 'green accent-4' : ''"
                @submit.prevent
                @click="handleCompleteWizard"
                >Continue</v-btn
              >
            </v-row>
            <b class="text-h5">Select what Arva Products you want to power!</b>
            <v-row class="pt-3">
              <v-col
                ><v-card
                  ><v-card-title
                    ><v-checkbox v-model="carbonReady"></v-checkbox>Carbon
                    Ready</v-card-title
                  ></v-card
                ></v-col
              >
              <v-col
                ><v-card
                  ><v-card-title
                    ><v-checkbox v-model="cropPlanner"></v-checkbox>Crop
                    Planner</v-card-title
                  ></v-card
                ></v-col
              >
            </v-row>
            <v-row>
              <v-col
                ><v-card
                  ><v-card-title
                    ><v-checkbox v-model="seedSelector"></v-checkbox>Seed
                    Selector</v-card-title
                  ></v-card
                ></v-col
              >
              <v-col
                ><v-card
                  ><v-card-title
                    ><v-checkbox v-model="imagery"></v-checkbox
                    >Imagery</v-card-title
                  ></v-card
                ></v-col
              >
            </v-row>
            <v-row>
              <v-col
                ><v-card
                  ><v-card-title
                    ><v-checkbox v-model="rxZones"></v-checkbox>Prescription
                    Zones</v-card-title
                  ></v-card
                ></v-col
              >
              <v-col></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { mapGetters, mapState } from "vuex"
import { Filter, Organization } from "@/store/modules"
import { CROP_DISPLAY_NAME } from "@/constants"

export default {
  name: "InitialWizard",
  data() {
    return {
      el: 1,
      selCrop: null,
      hoverClient: { id: null },
      hoverFarms: [],
      hoverFields: [],
      cropDisplayName: CROP_DISPLAY_NAME,
      // products
      carbonReady: false,
      cropPlanner: false,
      seedSelector: false,
      imagery: false,
      rxZones: false,
    }
  },
  computed: {
    ...mapGetters({
      getOpen: Filter.Getters.getOpen,
      orgNodes: Filter.Getters.getOrgNodes,
      selectedOrgNodeCount: Filter.Getters.getSelectedOrgNodeCount,
      crops: Filter.Getters.getCrops,
      selectedCropsCount: Filter.Getters.getSelectedCropsCount,
      clients: Filter.Getters.getClients,
      selectedClientsCount: Filter.Getters.getSelectedClientsCount,
      farms: Filter.Getters.getFarms,
      selectedFarmsCount: Filter.Getters.getSelectedFarmsCount,
      // fields: Filter.Getters.getFields,
      selectedFieldsCount: Filter.Getters.getSelectedFieldsCount,
      selectedFieldsAcreage: Filter.Getters.getSelectedFieldsAcreage,
      // selected fields
      selectedFields: Filter.Getters.getSelectedFields,
      // current year
      currentYear: Organization.Getters.getYear,
    }),
    ...mapState({
      years: state => state.Organization.organization.years,
    }),
    cropSelected() {
      console.log("selectedFIelds: ", this.selectedFields)
      const cropsForYear = this.selectedFields.map(f =>
        f.field_crops.find(c => c.year == this.currentYear)
      )
      // in case you need to double check
      // const uniqueArray = cropsForYear.filter((value, index) => {
      //   const _value = JSON.stringify(value)
      //   return (
      //     index ===
      //     cropsForYear.findIndex(obj => {
      //       return JSON.stringify(obj) === _value
      //     })
      //   )
      // })
      // console.log("cropsForYear: ", uniqueArray)

      let cropOccurences = { missingCrop: 0 }
      cropsForYear.forEach(c => {
        if (c == undefined) {
          cropOccurences.missingCrop += 1
          return
        }
        const fieldCrop = c.crop_id
        if (!(fieldCrop in cropOccurences)) {
          cropOccurences[fieldCrop] = 1
        } else if (fieldCrop in cropOccurences) {
          cropOccurences[fieldCrop] += 1
        }
      })

      return cropOccurences
    },
    clientSelected() {
      console.log("clients: ", this.clients)
      return this.clients.filter(client => client.selected === true)
    },
    farmSelected() {
      console.log("farms: ", this.farms)
      return this.farms.filter(farm => farm.selected === true)
    },
    fieldSelected() {
      return this.selectedFields.filter(field => field.selected === true)
    },
    leaveInitWizard() {
      return (
        this.carbonReady ||
        this.cropPlanner ||
        this.seedSelector ||
        this.imagery ||
        this.rxZones
      )
    },
  },
  methods: {
    // toggleClients() {
    //   this.$nextTick(() => {
    //     if (this.hasAllClients) {
    //       this.selClients = []
    //     } else {
    //       this.selClients = this.clients.slice()
    //     }
    //   })
    // },
    handleClientHovered(client) {
      const clientId = client.id
      this.hoverClient = client
      this.hoverFarms = this.farmSelected
        .filter(farm => farm.clientId === clientId)
        .map(farm => farm.id)
      this.hoverFields = this.selectedFields
        .filter(field => this.hoverFarms.includes(field.farm.id))
        .map(field => field.id)
    },
    handleClientMouseLeave() {
      this.hoverClient = { id: null }
      this.hoverFarms = []
      this.hoverFields = []
    },
    handleFarmHovered(farm) {
      const clientId = farm.clientId
      this.hoverClient = { id: clientId }
      this.hoverFarms = [farm.id]
      this.hoverFields = this.selectedFields
        .filter(field => this.hoverFarms.includes(field.farm.id))
        .map(field => field.id)
    },
    handleFarmMouseLeave() {
      this.hoverClient = { id: null }
      this.hoverFarms = []
      this.hoverFields = []
    },
    handleFieldHovered(field) {
      const farmId = field.farm.id
      const farmForField = this.farmSelected.find(farm => farm.id === farmId)
      this.hoverClient = { id: farmForField.clientId }
      this.hoverFarms = [farmForField.id]
      this.hoverFields = [field.id]
    },
    handleFieldMouseLeave() {
      this.hoverClient = { id: null }
      this.hoverFarms = []
      this.hoverFields = []
    },
    handleCompleteWizard() {
      if (this.leaveInitWizard) {
        console.log("handleCompleteWizard: ", this.leaveInitWizard)
        this.$emit("complete-wizard")
      }
    },
  },
}
</script>
<style scoped>
.second-q {
  padding-top: 48px;
}

.input-date-text {
  font-weight: 700;
  font-size: 22px;
}
</style>
