<template>
  <div
    v-if="rightClickData"
    class="map-context-menu"
    :style="tooltipPlacementForRightClick"
  >
    <ul>
      <li
        v-for="option in rightClickData.options"
        :key="option.value"
        @click="handleMenuOptionClick(option.value)"
      >
        {{ option.text }}
      </li>
    </ul>
  </div>
  <div v-else-if="hoverData" id="hover" class="card" :style="tooltipPlacement">
    <div class="card-body">
      <div class="card-title">
        <div class="upper-right">
          <dt>{{ hoverData.cropId | crop }}</dt>
          <div class="product">{{ hoverData.seedVariety }}</div>
          <div class="product">
            {{ fertList }}
          </div>
        </div>
        <dl>
          <div class="height-sizer">
            <dt>
              {{ hoverData.fieldName }}
            </dt>
            <div class="sub-title">
              <div>{{ hoverData.farmName }}</div>
              <div>{{ hoverData.acreage | integer }} ac</div>
              <div>{{ cropNames }}</div>
            </div>
          </div>
        </dl>
      </div>
      <div class="data-row">
        <div v-if="hoverData.agt">
          <div>AGT</div>
          <div class="agt" :style="{ background: agtColor }">
            {{ hoverData.agt }}
          </div>
        </div>
        <div v-if="hoverData.elevation">
          <div>Elevation</div>
          <div>
            {{ hoverData.elevation | floatTenth }}
            <i class="unit">m</i>
          </div>
        </div>
        <div v-if="hoverData.profit">
          <div>Profit / Ac.</div>
          <div v-if="hoverData.profit >= 0" class="profit profit-pos">
            {{ (hoverData.profit / hoverData.voxelAcres) | currency }}
          </div>
          <div v-else class="profit profit-neg">
            ({{ Math.abs(hoverData.profit) | currency }})
          </div>
        </div>
        <div v-if="yieldHover">
          <div>Yield</div>
          <div>
            {{ yieldHover | floatTenth }}
            <i class="unit">{{ hoverData.cropId | yieldUnits }}</i>
          </div>
        </div>
        <div v-if="seedsHover">
          <div>Planting</div>
          <div v-if="hoverData.cropId === wheatCropId">
            {{ seedsHover | prettyInteger }}
            <i class="unit">lb/ac</i>
          </div>
          <div v-else-if="hoverData.cropId === riceCropId">
            <div v-if="seedsHover >= 2000">
              {{ seedsHover | thousands }}
              <i class="unit">seeds/ac</i>
            </div>
            <div v-else>
              {{ seedsHover | prettyInteger }}
              <i class="unit">lb/ac</i>
            </div>
          </div>
          <div v-else>
            {{ seedsHover | thousands }}
            <i class="unit">seeds/ac</i>
          </div>
        </div>
        <div v-if="hoverData.ndviValue">
          <div>NDVI</div>
          <div>{{ hoverData.ndviValue - tiffDataScalar }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { flattenDeep } from "lodash"
import { mapState } from "vuex"
import { CROPS } from "@/constants"
import { TIFF_DATA_SCALAR } from "@/constants/map"
import { getAgtColor } from "@/utility"

export default {
  name: "Tooltip",

  data() {
    return {
      tiffDataScalar: TIFF_DATA_SCALAR,
    }
  },

  computed: {
    ...mapState({
      cropValue: state => state.Crop.cropValue,
      rightClickData: state => state.Map.rightClickData,
      hoverData: state => state.Map.hoverData,
    }),

    wheatCropId() {
      return CROPS.Wheat
    },

    riceCropId() {
      return CROPS.Rice
    },

    agtColor() {
      return getAgtColor(this.hoverData.agt)
    },

    fertList() {
      if (this.hoverData.fertAmountPerAcreNew) {
        return flattenDeep([
          ...new Set(
            this.hoverData.fertAmountPerAcreNew.map(obj => Object.keys(obj))
          ),
        ]).join(", ")
      }
      if (this.hoverData.fertAmountPerAcre) {
        return this.hoverData.fertProduct
      }
      return null
    },

    seedsHover() {
      if (Array.isArray(this.hoverData.plantingSeedsPerAcre)) {
        let result = this.hoverData.plantingSeedsPerAcre.filter(e => {
          if (Object.keys(e).includes(String(this.hoverData.cropId))) {
            return e
          }
        })

        if (result.length < 1) {
          result = null
          //result = Object.values(this.hoverData.plantingSeedsPerAcre[0])[0]
        } else {
          result = result[0][String(this.hoverData.cropId)]
        }

        return result
      }

      return this.hoverData.plantingSeedsPerAcre
    },

    cropNames() {
      if (this.hoverData.crops) {
        return Object.entries(CROPS)
          .filter(([key, value]) => {
            if (this.hoverData.crops.includes(value)) {
              return key
            }
          })
          .map(e => {
            return e[0]
          })
          .join(", ")
      } else {
        return ""
      }
    },

    yieldHover() {
      if (Array.isArray(this.hoverData.yieldPerAcre)) {
        let result = this.hoverData.yieldPerAcre.filter(e => {
          if (Object.keys(e).includes(String(this.hoverData.cropId))) {
            return e
          }
        })

        if (result.length < 1) {
          result = null
          //result = Object.values(this.hoverData.yieldPerAcre[0])[0]
        } else {
          result = result[0][String(this.hoverData.cropId)]
        }

        return result
      }
      return this.hoverData.yieldPerAcre
    },

    tooltipPlacement() {
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth
      const diffTop = windowHeight - this.hoverData.y
      const diffLeft = windowWidth - this.hoverData.x
      const toolTop =
        diffTop < windowHeight / 4 ? this.hoverData.y - 150 : this.hoverData.y
      const toolLeft =
        diffLeft < windowWidth / 2 ? this.hoverData.x - 500 : this.hoverData.x
      return { top: toolTop + "px", left: toolLeft + "px" }
    },

    tooltipPlacementForRightClick() {
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth
      const diffTop = windowHeight - this.rightClickData.y
      const diffLeft = windowWidth - this.rightClickData.x
      const toolTop =
        diffTop < windowHeight / 4
          ? this.rightClickData.y + 150
          : this.rightClickData.y
      const toolLeft =
        diffLeft < windowWidth / 2
          ? this.rightClickData.x + 500
          : this.rightClickData.x
      return { top: toolTop + "px", left: toolLeft + "px" }
    },
  },
  methods: {
    handleMenuOptionClick(option) {
      this.$emit("menu-option-clicked", option)
    },
  },
}
</script>

<style scoped>
.height-sizer {
  height: 110px;
  overflow: hidden;
}

#hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 380px;
  margin: 3em;
  background: rgba(24, 24, 24, 0.9);
  border: none;
  z-index: 1000;
}

#hover dl {
  color: rgb(200, 186, 175);
}

.sub-title {
  font-style: italic;
  font-size: 11px;
}

.product {
  font-size: 11px;
}

.upper-right {
  position: absolute;
  top: 15px;
  right: 15px;
  color: rgb(200, 186, 175);
  text-align: right;
  width: 75%;
}

.data-row {
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: rgb(200, 186, 175);
}

.data-row > div > div:first-of-type {
  font-size: 11px;
}

.data-row > div > div:last-of-type {
  font-size: 13px;
}

.unit {
  font-size: 11px;
  font-style: unset;
}

.agt {
  color: #fff;
  border-radius: 20px;
  font-size: 12px;
  width: 65px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.profit {
  font-size: 11px;
  font-weight: 500;
}

.profit-pos {
  color: green;
}

.profit-neg {
  color: rgba(200, 50, 50, 255);
}

.map-context-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1000;
}

.map-context-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.map-context-menu li {
  padding: 5px 10px;
  cursor: pointer;
}

.map-context-menu li:hover {
  background-color: #f5f5f5;
}
</style>
