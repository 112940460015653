<template>
  <ArvaModal name="dataImportModal" :title="`${confirmation} Upload`" @close-modal="$emit('close-modal')">
    <!-- Upload View -->
    <div v-if="state == 'initial'" class="row modal-pad">
      <div class="col-12 text-center">
        <div class="upload-box" @click="$emit('open-upload')">
          <i class="fa fa-upload" />
          <h2>Drop files here to upload</h2>
          <div><i class="fa fa-file" />.zip, .csv, .jpg, .jpeg, .png, .pdf</div>
          <div>These files may include information for multiple fields</div>
        </div>
      </div>
    </div>

    <!-- Uploading Progress View -->
    <div v-if="state == 'uploading'" class="row modal-pad file-upload-pad">
      <div class="col-12">
        <small class="form-text text-muted">Please don't close your browser window, or your upload will be interrupted.</small>
      </div>
    </div>

    <!-- Complete View -->
    <div v-if="state == 'complete'" class="row modal-pad complete-pad text-center">
      <div class="col-12 complete">
        <div><i class="fa fa-check-circle complete-check" /></div>
        <h2>Upload Complete!</h2>
        <button class="btn btn-primary" @click="$emit('close-modal')">Close</button>
      </div>
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import { mapState } from "vuex";

export default {
  name: "ConfirmationsUploadModal",
  props: { confirmation: String, confirmationNumber: String, isUploading: Boolean },
  components: { ArvaModal },
  data() {
    return {
      state: 'initial'
    }
  },
  computed: {
    ...mapState({
      uploadPercentage: state => state.Uploads.uploadPercentage,
    })
  },
  watch: {
    isUploading() {
      if (this.isUploading) this.state = "uploading"
    },
    uploadPercentage() {
      if (this.uploadPercentage === 100) this.state = "complete"
    }
  }
}
</script>
  
<style scoped>
.modal-pad {
  padding-bottom: 35px;
}

.info-text {
  text-align: center;
  max-width: 430px;
  margin: 20px auto;
  color: #777;
}

.selection-box {
  padding: 20px;
  border: 1pt solid #e5e5e5;
  min-width: 200px;
  text-align: center;
  cursor: pointer;
}

.selection-box:hover {
  background: #f8f8f8;
  border: 1pt solid #d5d5d5;
}

.selection-box i {
  font-size: 60px;
}

.select-container {
  width: 320px;
}

.selection-text {
  font-size: 13px;
  font-weight: 500;
  color: #666;
}

.form-group {
  margin: 20px auto 90px auto;
  padding: 0px 100px;
}

.form-group>button {
  margin-top: 20px;
  margin-right: 0px;
  float: right;
}

.upload-box {
  width: 450px;
  background: #f0f0f0;
  border: 1pt dotted black;
  margin: 20px auto;
  padding: 30px;
  cursor: pointer;
}

.upload-box:hover {
  background: #eaeaea;
}

.dropping {
  background: rgba(89, 255, 140, 0.35);
}

.upload-box .fa-upload {
  font-size: 30px;
}

.upload-box .green {
  color: green;
}

.upload-box h2 {
  font-size: 20px;
  margin-top: 10px;
  font-weight: 500;
}

.upload-box .fa-file {
  margin-right: 5px;
}

.file-upload-pad {
  padding: 40px;
  text-align: center;
}

.upload-title {
  font-size: 20px;
  font-weight: 500;
}

.upload-title i {
  font-size: 20px;
  margin-right: 5px;
}

.text-muted {
  margin-bottom: 10px;
}

.complete-pad {
  margin-top: 40px;
}

.complete h2 {
  margin-top: 10px;
  margin-bottom: 30px;
}

.complete i {
  font-size: 55px;
  color: green;
}

.logo-john-deere {
  width: 60px;
  height: 60px;
  background-image: url("/assets/images/logos/john-deere-logo.png");
  background-size: contain;
  filter: grayscale(100%);
  margin-left: auto;
  margin-right: auto;
}

.climate-text {
  font-weight: 400;
  font-size: 18px;
}

.confirmation-client-spec-modal {
  min-height: 535.5px;
  position: relative;
}

.confirmation-client-spec-modal label {
  font-size: 13px;
  line-height: 32px;
}

.confirmation-client-spec-modal /deep/ .confirmation-number input {
  text-transform: uppercase;
}

.confirmation-client-spec-modal /deep/ .confirmation-number .v-input__append-inner {
  margin-top: 8px;
}

.confirmation-client-spec-modal /deep/ .v-input .v-input__slot {
  min-height: 41px;
}

.disabled-label {
  opacity: 0.5;
}

.confirmation-modal {
  min-height: 550px;
}

.confirmation-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.confirmation-client-farm-wrapper {
  display: flex;
  justify-content: space-between;
}

.confirmation-client-farm-wrapper>div {
  width: 48%;
}
</style>
  