import axios from "axios"
import UserAPI from "@/api/UserAPI"
import { BASE_URL } from "@/constants"
import router from "@/router"
import { Map, Organization, User } from "@/store/modules"

const token = localStorage.getItem("arvaToken")
const state = {
  token,
  user: {},
  authErrors: [],
  packageVersion: import.meta.env.VITE_PACKAGE_VERSION || "0",
  loading: false,
  dataOpen: false,
  fieldsOpen: false,
  planningOpen: false,
  insightsOpen: false,
  imageryOpen: false,
  sustainOpen: false,
  zonesOpen: false,
  acceptedDataAgreement: false,
  userManagement: [],
  orgSharingTransactions: [],
  userRole: null,
  userAssignedOrg: [],
  userSharedOrg: [],
}

const getters = {}

const mutations = {
  [User.Mutations.setToken](state, val) {
    state.token = val
    localStorage.setItem("arvaToken", val)
    localStorage.setItem("dataImportSource", null)
    localStorage.setItem("initialLoading", "yes")
  },

  [User.Mutations.setUser](state, val) {
    state.user = { ...val }
  },

  [User.Mutations.setAuthErrors](state, val) {
    state.authErrors = val
  },

  [User.Mutations.setLoading](state, val) {
    state.loading = val
  },

  [User.Mutations.setDataOpen](state, val) {
    state.dataOpen = val
  },

  [User.Mutations.setFieldsOpen](state, val) {
    state.fieldsOpen = val
  },

  [User.Mutations.setPlanningOpen](state, val) {
    state.planningOpen = val
  },

  [User.Mutations.setInsightsOpen](state, val) {
    state.insightsOpen = val
  },

  [User.Mutations.setImageryOpen](state, val) {
    state.imageryOpen = val
  },

  [User.Mutations.setSustainOpen](state, val) {
    state.sustainOpen = val
  },

  [User.Mutations.setZonesOpen](state, val) {
    state.zonesOpen = val
  },

  [User.Mutations.setUserManagement](state, val) {
    state.userManagement = val
  },

  [User.Mutations.setOrgSharingTransactions](state, val) {
    state.orgSharingTransactions = val
  },

  [User.Mutations.setUserRole](state, val) {
    state.userRole = val
  },

  [User.Mutations.setUserAssignedOrg](state, val) {
    state.userAssignedOrg = val
  },

  [User.Mutations.setUserSharedOrg](state, val) {
    state.userSharedOrg = val
  },

  [User.Mutations.removeSelectedAssignedOrg](state, payload) {
    const userId = payload.selected_user_id
    const assignedOrgId = payload.assigned_org.id
    state.userManagement.forEach(record => {
      if (record.user.id === userId) {
        for (var idx = 0; idx < record.assigned_org.length; idx++) {
          if (record.assigned_org[idx].id === assignedOrgId) {
            record.assigned_org.splice(idx, 1)
          }
        }
        record.total_assigned_orgs = record.total_assigned_orgs - 1
      }
    })
  },

  [User.Mutations.addSelectedAssignedOrg](state, payload) {
    const userId = payload.userId
    const newAssignedOrg = payload.assignedOrg
    state.userManagement.forEach(record => {
      if (record.user.id === userId) {
        record.assigned_org.push(newAssignedOrg)
        record.total_assigned_orgs = record.total_assigned_orgs + 1
      }
    })
  },

  [User.Mutations.updateSelectedAssignedOrg](state, payload) {
    const userId = payload.userId
    const updatedAssignedOrg = payload.assignedOrg
    state.userManagement.forEach(record => {
      if (record.user.id === userId) {
        for (var idx = 0; idx < record.assigned_org.length; idx++) {
          if (record.assigned_org[idx].id === updatedAssignedOrg.id) {
            record.assigned_org.splice(idx, 1, updatedAssignedOrg)
          }
        }
      }
    })
  },

  [User.Mutations.logout](state) {
    state.orgNodeId = null
    state.token = null
    localStorage.clear()
  },
}

const actions = {
  [User.Actions.authenticate]({ commit, dispatch }, payload) {
    commit(User.Mutations.setLoading, true)
    axios
      .post(`${BASE_URL}api/v1/signin/`, payload)
      .then(response => {
        commit(User.Mutations.setToken, response.data.token)
        dispatch(User.Actions.fetchUser)
        commit(User.Mutations.setLoading, false)
        router.push("/")
      })
      .catch(error => {
        commit(User.Mutations.setLoading, false)
        commit(User.Mutations.setAuthErrors, error.response.data)
      })
  },

  [User.Actions.logInToAdmin]({ commit }) {
    return new Promise(resolve => {
      axios
        .get(`${BASE_URL}api/v1/signin/get-signature/`, {
          headers: { Authorization: `Token ${state.token}` },
        })
        .then(response => {
          const signature = response.data.qs
          window.open(
            `${BASE_URL}api/v1/signin/backend/?${signature}`,
            "_blank"
          )
          resolve()
        })
        .catch(error =>
          commit(User.Mutations.setAuthErrors, error.response.data)
        )
    })
  },

  [User.Actions.fetchUser]({ commit, dispatch }) {
    commit(Map.Mutations.setIsFetching, true)
    return new Promise(resolve => {
      UserAPI.fetchUser()
        .then(({ data }) => {
          const {
            user,
            user_management,
            org_sharing_transactions,
            user_assigned_organization,
            user_hash,
            org_node,
            org_tree,
            fields,
            farms,
            clients,
            years_with_data,
            field_crop_years,
            agt_crops,
          } = data
          org_node["org_tree"] = org_tree
          org_node["fieldsForUser"] = fields
          org_node["farmsForUser"] = farms
          org_node["clientsForUser"] = clients
          org_node["years"] = years_with_data.sort((a, b) => b - a)
          org_node["field_crop_years"] = field_crop_years.sort((a, b) => b - a)

          commit(User.Mutations.setAuthErrors, [])
          commit(User.Mutations.setUser, user)
          commit(User.Mutations.setUserManagement, JSON.parse(user_management))
          commit(
            User.Mutations.setOrgSharingTransactions,
            org_sharing_transactions
          )
          commit(Organization.Mutations.setOrganization, org_node)
          commit(Map.Mutations.setOrgAgtCrops, agt_crops)

          const isCorpAdmin = user_assigned_organization.filter(row =>
            "Corporation Admin".includes(row.role)
          ).length
          const isOrgAdmin = user_assigned_organization.filter(row =>
            "Organization Admin".includes(row.role)
          ).length

          if (isCorpAdmin)
            commit(User.Mutations.setUserRole, "Corporation Admin")
          else if (isOrgAdmin)
            commit(User.Mutations.setUserRole, "Organization Admin")
          else commit(User.Mutations.setUserRole, "Member")

          const { date_joined, email, first_name, last_name } = user

          window.Intercom("boot", {
            app_id: "pch0g7he",
            name: `${first_name} ${last_name}`,
            email: `${email}`,
            user_hash: `${user_hash}`, // HMAC using SHA-256
            user_id: `${user.id}`,
            org_node: `${org_node.id}`,
            hide_default_launcher: false,
            created_at: new Date(date_joined).getTime() / 1000,
          })

          if (org_node["years"].length > 0) {
            commit(Organization.Mutations.setYear, {
              year: org_node["years"][0],
              onlyIfNull: true,
            })
          }
          dispatch(Organization.Actions.initialFetch)
          resolve()
        })
        .catch(() => {
          commit(User.Mutations.logout)
          router.push("/")
        })
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
