<template>
  <div>
    <CarbonFlowSteppers :step="1" />
    <div
      v-if="loading"
      class="my-3 d-flex justify-content-center"
      justify="center"
    >
      <v-progress-circular indeterminate color="green"></v-progress-circular>
    </div>

    <div v-if="fieldRows.length > 0" class="sustainability-table">
      <v-card class="mt-6 mb-6 pb-3 pt-3">
        <div class="d-flex">
          <!-- <div class="col"> -->
          <div class="nameSearchBar-header mb-1 mr-3 ml-2">
            <SearchBar :searchString="searchString" @searching="handleSearch" />
          </div>
          <v-btn
            class="ml-auto mr-1"
            @click="handleAdjustCarbonCreditModalClick"
          >
            {{ carbonCreditDollarValueLabel }}
            <v-tooltip top max-width="340"
              ><template v-slot:activator="{ on, attrs }"
                ><v-icon v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                ></template
              ><span
                >CO2e use the users $/Tonne input to calculate the carbon credit
                dollar value. By default, we will use $20/Tonne.
              </span></v-tooltip
            >
          </v-btn>
          <!-- </div> -->

          <v-btn
            class="ml-auto mr-0"
            v-if="checkedCount > 0"
            @click="clearFieldSelection"
          >
            Clear Data
            <v-tooltip top max-width="340"
              ><template v-slot:activator="{ on, attrs }"
                ><v-icon v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                ></template
              ><span
                >Caution! Clicking this will clear all set practices for all
                your fields in selection.</span
              ></v-tooltip
            >
          </v-btn>

          <v-btn class="ml-auto" @click="reloadFields">
            Load Data
            <v-tooltip top max-width="340"
              ><template v-slot:activator="{ on, attrs }"
                ><v-icon v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                ></template
              ><span
                >Load the saved field sustainability settings from the Arva
                database.</span
              ></v-tooltip
            >
          </v-btn>

          <v-btn
            v-if="!isReadOnly"
            class="ml-auto mr-2"
            @click="downloadFieldSelection"
          >
            Download
            <v-icon right>
              mdi-download
            </v-icon>
          </v-btn>

          <v-btn
            class="ml-auto mr-1"
            color="red lighten-1"
            v-if="showSaveBtn && !isReadOnly"
            :loading="showSaveBtnLoader"
            :disabled="showSaveBtnLoader"
            @click="saveFieldSelection"
          >
            Save & Run
            <v-icon right>
              mdi-content-save
            </v-icon>
          </v-btn>
        </div>
      </v-card>
      <v-simple-table fixed-header height="78vh" :key="tableKey">
        <thead>
          <tr>
            <th>
              <input
                @click="selectAllCheckBox"
                type="checkbox"
                name="selected"
                ref="fieldSelectAllCheckbox"
              />
            </th>
            <th></th>
            <th>Field</th>
            <th class="text-center" @click="handleSortAcreage">
              Acreage
              <i
                class="fa hover"
                :class="sortIcon(sortByAcreage)"
                @click="handleSortAcreage"
              />
            </th>
            <th class="ghg text-center">
              <input
                @click="selectAllTillCheckbox"
                type="checkbox"
                name="selected"
                ref="noTillSelectAllCheckbox"
              />
              <span class="ml-2 mr-1">No-Till</span>
              <v-tooltip top max-width="340"
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon v-bind="attrs" v-on="on"
                    >mdi-information</v-icon
                  ></template
                ><span
                  >Check the box to include enrollment for the “No-Till”
                  regenerative practice. The number next to the selection box
                  indicates an estimated yearly Carbon increase/decrease in
                  “Tonnes” of carbon per acre per year for this field when
                  included as a regenerative practice.</span
                ></v-tooltip
              >
            </th>
            <th class="ghg text-center">
              <input
                @click="selectAllCoverCropCheckbox"
                type="checkbox"
                name="selected"
                ref="coverCropSelectAllCheckbox"
              />
              <span class="ml-2 mr-1">Cover Crop</span>
              <v-tooltip top max-width="340"
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon v-bind="attrs" v-on="on"
                    >mdi-information</v-icon
                  ></template
                ><span
                  >Check the box to include enrollment for the “Cover-Crop”
                  regenerative practice. The number next to the enrolment
                  selection box indicates an estimated yearly Carbon
                  increase/decrease in “Tonnes” of carbon per acre per year for
                  this field when included as a regenerative practice.</span
                ></v-tooltip
              >
            </th>
            <th class="ghg text-center">
              <input
                @click="selectAllInhibitorCheckbox"
                type="checkbox"
                name="selected"
                ref="inhibitorSelectAllCheckbox"
              />
              <span class="ml-2 mr-1">N Inhibitor</span>
              <v-tooltip top max-width="340"
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon v-bind="attrs" v-on="on"
                    >mdi-information</v-icon
                  ></template
                ><span
                  >Check the box to include enrollment for adding “Nitrogen
                  Inhibitors” as a regenerative practice. Practice guidelines
                  are under VM0042 scope to improve soil sustainability by
                  avoiding Nitrogen run-off and other GHG emissions. The
                  estimated yearly Carbon Equivalent (CO2e) per acre per year
                  for this field are based on N-Inhibitor application and
                  N-reduction avoidance practices.</span
                ></v-tooltip
              >
            </th>
            <th class="ghg text-center">
              <input
                @click="selectAllAWDCheckbox"
                type="checkbox"
                name="selected"
                ref="awdSelectAllCheckbox"
              />
              <!-- selectAllAWDCheckbox -->
              <span class="ml-2 mr-1">AWD (Rice Only)</span>
              <v-tooltip top max-width="340"
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon v-bind="attrs" v-on="on"
                    >mdi-information</v-icon
                  ></template
                ><span
                  >Alternate Wetting and Drying is a practice for Rice Growers
                  that reduces CH4 gas emissions. The value indicated is is the
                  amount of CH4 converted to CO2 equivalents per acre per year.
                  The CO2e is used for grower revenue purposes due to reduction
                  in emissions of CH4. This practice also reduces the amount of
                  water required which helps the bottom line.</span
                ></v-tooltip
              >
            </th>
            <th class="organic-carbon text-center">
              Est. Co2e/Yr
              <v-tooltip top max-width="340"
                ><template v-slot:activator="{ on, attrs }"
                  ><v-icon v-bind="attrs" v-on="on"
                    >mdi-information</v-icon
                  ></template
                ><span
                  >Total possible CO2 Equivalent represent a per field per year
                  estimate for the amount of GHG emission reduction due to
                  practice(s) selected. The types of green-house gases are then
                  converted to CO2 equivalents so the market can put a price
                  (carbon credits) in preparation for trade on one or more
                  carbon exchanges. Note: this is not additive to each
                  regenerative practice selected, Arva's predictive modeling
                  takes in account all practices selected and some offset or
                  enhance the values of others.</span
                ></v-tooltip
              >
            </th>
            <th>
              <span class="ml-2 mr-1" @click="handleSortEstimatedCredit"
                >Estimated Credit</span
              >
              <i
                class="fa hover mr-1"
                :class="sortIcon(sortByEstimatedCredit)"
                @click="handleSortEstimatedCredit"
              />
            </th>
            <th class="text-center">
              <v-btn
                dense
                v-if="checkedCount > 0"
                @click="setAllCropFields"
                :disabled="isReadOnly"
              >
                Model Status
                <v-icon>
                  mdi-restart
                </v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <SustainabilityRow
            v-for="field in paginatedRows"
            :key="field.id"
            :field="field"
            :nextCropSelection="nextCropSelections[field.id]"
            @detail_field="getSimulationModal"
            @checked="checked"
            @unchecked="unchecked"
            ref="sustainabilityRows"
          />
        </tbody>
        <!-- total row -->
        <tfoot class="sticky-foot">
          <td></td>
          <td></td>
          <td class="text-body-1 text-bold nowrap pl-4">Estimated Totals</td>
          <td class="text-body-1 text-bold text-center">
            {{ totalAcreage | prettyInteger }} Acres
          </td>
          <td class="ghg text-body-1 text-bold text-center ml-1">
            {{ totalNoTill | prettyInteger }} Tonnes
          </td>
          <td class="ghg text-body-1 text-bold cover-crop">
            {{ totalCoverCrop | prettyInteger }} Tonnes
          </td>
          <td class="organic-carbon text-body-1 text-bold nitro-inhib">
            {{ totalNitrogenInhibitor | prettyInteger }} Tonnes
          </td>
          <td class="organic-carbon text-body-1 text-bold awd-total">
            {{ totalAWD | prettyInteger }} Tonnes
          </td>
          <td class="ghg text-body-1 text-bold text-center">
            <div class="pt-3">{{ totalCo2Equivalent | prettyInteger }}</div>
            <div class="text-subtitle-1 pb-3">Total Tonnes CO2</div>
          </td>
          <td class="text-body-1 text-bold text-center">
            <v-chip color="green" text-color="white">
              {{ totalCreditAmount | currency }}
            </v-chip>
          </td>
          <td></td>
        </tfoot>
      </v-simple-table>
      <div class="text-center" v-if="paginationLength > 1">
        <div class="mt-4">
          <span class="text-subtitle-2"
            >Showing {{ paginationIndices["first"] }} -
            {{ paginationIndices["last"] }}</span
          >
          <v-pagination
            v-model="page"
            :length="paginationLength"
            total-visible="6"
          ></v-pagination>
        </div>
      </div>
    </div>

    <DaycentSimulationModal
      v-if="daycentSimulationModalOpen"
      :field="current_field"
      :is_complete="is_complete"
      @close-template-modal="closeDaycentSimulationModal"
      @success="success"
    />

    <DaycentSimulationModal
      v-if="setAllCropFieldsModalOpen"
      :sustainabilityRowIds="updateAllRowIds"
      :selectedFieldIds="updateAllFieldIds"
      @close-template-modal="closeSetAllCropFieldsModal"
      @success="success"
    />

    <SaveLoaderModal
      v-if="saveLoaderModalOpen"
      :loading="saveLoaderModalLoading"
      :loadComplete="saveLoaderModalLoadComplete"
      loadCompleteText="Saving Complete!"
      savingText="Saving Sustainability Settings"
      titleText="Saving Sustainability Settings"
      @close-template-modal="closeSaveLoaderModal"
    />

    <SaveLoaderModal
      v-if="reloadFieldsModalOpen"
      :loading="reloadFieldsModalLoading"
      :loadComplete="reloadFieldsModalLoadComplete"
      loadCompleteText="Loaded Sustainability Settings!"
      savingText="Loading Sustainability Settings"
      titleText="Loading Sustainability Settings"
      @close-template-modal="closeSaveLoaderModal"
    />

    <AdjustCarbonCreditModal
      v-if="adjustCarbonCreditModalOpen"
      :carbonCreditDollarValue="Number(carbonCreditDollarValue)"
      @update-carbon-credit="updateNewDollarPerTon"
      @close-template-modal="closeAdjustCarbonCreditModal"
    />

    <div
      v-if="fieldRows.length < 1 && !finishedLoading"
      class="my-3 d-flex justify-content-center"
      justify="center"
    >
      <div class="text-bold mb-0 mt-1">Fetching Data</div>
      <div class="spinner-border ml-4" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      v-if="fieldRows.length < 1 && finishedLoading"
      class="my-3 d-flex justify-content-center"
      justify="center"
    >
      <div class="text-bold mb-0 mt-1">
        Request Daycent Model Run to See Results
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import _ from "lodash"
import CarbonFlowSteppers from "@/views/CarbonFlowSteppers"
import SustainabilityRow from "@/components/sustainability/SustainabilityRow"
import { Filter, Fields, Dashboard } from "@/store/modules"
import DaycentSimulationModal from "@/components/modals/DaycentSimulationModal"
import SaveLoaderModal from "@/components/modals/SaveLoaderModal"
import AdjustCarbonCreditModal from "@/components/modals/AdjustCarbonCreditModal"
//import SetAllCropFieldsModal from "@/components/modals/SetAllCropFieldsModal"
import SearchBar from "@/components/misc/SearchBar"

export default {
  name: "Sustainability",

  components: {
    CarbonFlowSteppers,
    SustainabilityRow,
    DaycentSimulationModal,
    SaveLoaderModal,
    AdjustCarbonCreditModal,
    //SetAllCropFieldsModal,
    SearchBar,
  },
  data() {
    return {
      rowsToEdit: [],
      selectedFieldIds: [],
      daycentSimulationModalOpen: false,
      setAllCropFieldsModalOpen: false,
      saveLoaderModalOpen: false,
      saveLoaderModalLoading: false,
      saveLoaderModalLoadComplete: false,
      reloadFieldsModalOpen: false,
      reloadFieldsModalLoading: false,
      reloadFieldsModalLoadComplete: false,
      current_field: null,
      all_fields: null,
      searchString: "",
      finishedLoading: false,
      sortByAcreage: null,
      sortByEstimatedCredit: null,
      showSaveBtn: false,
      showSaveBtnLoader: null,
      updateAllRowIds: [], // List of all sustainability ids from selected fields on Filter drop down list
      updateAllFieldIds: [], // List of all field ids from selected fields on Filter drop down list
      // copy of initially loaded data to compare against for save data logic
      dataCopy: null,
      page: 1,
      paginationSize: 100,
      adjustCarbonCreditModalOpen: false,
      loading: false,
      tableKey: 1,
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
      activeCrops: Filter.Getters.getDashboardCrops,
    }),

    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      selectedFields: state => state.Filter.selectedFields,
      cropDesign: state => state.Dashboard.cropDesign,
      nextCropSelections: state => state.Dashboard.nextCropSelections,
      carbonCreditDollarValue: state => state.Dashboard.carbonCreditDollarValue,
      userSustainabilityBeforeRefresh: state =>
        state.Fields.userSustainabilityBeforeRefresh,
    }),

    carbonCreditDollarValueLabel() {
      if (this.carbonCreditDollarValue)
        return "Carbon Price: $" + this.carbonCreditDollarValue + "/Tonne"
      return "$$/Tonne"
    },

    paginatedRows() {
      if (this.searchString) {
        return this.searchFilteredItems
      }
      const firstIndex = (this.page - 1) * this.paginationSize
      const maxIndex = this.paginationSize * this.page
      return this.fieldRows.slice(firstIndex, maxIndex)
    },

    paginationLength() {
      return Math.ceil(this.fieldRows.length / this.paginationSize)
    },

    paginationIndices() {
      const firstIndex = (this.page - 1) * this.paginationSize + 1
      const maxIndex = this.paginationSize * this.page
      const lastIndex =
        this.fieldRows.length < maxIndex ? this.fieldRows.length : maxIndex
      return { first: firstIndex, last: lastIndex }
    },

    searchFilteredItems() {
      if (!this.searchString) {
        return this.fieldRows
      }

      const searchString = this.searchString.toLocaleLowerCase()
      return this.fieldRows.filter(function(field) {
        return field.name.toLowerCase().includes(searchString)
      })
    },

    fieldRows() {
      const sortValues = {
        acreage: this.sortByAcreage,
        creditAmount: this.sortByEstimatedCredit,
      }
      let sortColumns = []
      let sortDirections = []
      for (const column in sortValues) {
        if (sortValues[column] !== null) {
          sortColumns.push(column)
          sortDirections.push(sortValues[column])
        }
      }

      let rows = []

      //alert("wait")

      this.activeFields.forEach(field => {
        //this should only list fields that are not in a currently active enrollment?
        field.daycent.map(d => {
          const equiv =
            (parseFloat(d.sysc) * 3.666 * 4046.86 +
            parseFloat(d.ch4) * -1 * 24 * 4046.86 + //this is wrong?? should be 24...
              parseFloat(d.n20) * -1 * 298 * 4046.86) /
            1000000

          //clean this up
          let any_selected = false

          let no_till = this.getPracticeSetting(d, "no-till")
          let cover_crop = this.getPracticeSetting(d, "cover-crop")
          let inhibitor = this.getPracticeSetting(d, "nitrogen-inhibitor")
          let awd = this.getPracticeSetting(d, "awd")

          if (inhibitor.is_selected) any_selected = true
          if (cover_crop.is_selected) any_selected = true
          if (no_till.is_selected) any_selected = true
          if (awd.is_selected) any_selected = true

          if (any_selected) {
            const index = this.rowsToEdit.indexOf(d.id)
            if (index == -1) {
              this.rowsToEdit.push(d.id)
              this.selectedFieldIds.push(field.id)
            }
          }

          let creditAmt = 0
          let co2EquivalentSelected = 0
          if (any_selected) {
            creditAmt =
              equiv.toFixed(2) *
              this.carbonCreditDollarValue *
              field.acreage.toFixed(0)
            co2EquivalentSelected = creditAmt / this.carbonCreditDollarValue
          }

          return Object.assign(d, {
            fieldId: field.id,
            name: field.name,
            acreage: field.acreage,
            boundary_image: field.boundary_image,
            co2Equivalent: equiv,
            status: d.status,
            no_till: no_till,
            cover_crop: cover_crop,
            inhibitor: inhibitor,
            awd: awd,
            is_complete: false,
            //no-till: d.
            //updated: field.daycent.updated_at,
            //settings: field.daycent.scenario_settings,
            co2EquivalentSelected: co2EquivalentSelected,
            creditAmount: creditAmt, //add field_id,last simulation options here, last simulation time, processing status
          })
        })
        rows.push(...field.daycent)
      })

      const res = _.orderBy(rows, sortColumns, sortDirections)
      return res
    },

    totalAcreage() {
      const totalAcreage = this.fieldRows
        .map(r => r.acreage)
        .reduce((prev, curr) => prev + curr, 0)
      return totalAcreage
    },

    totalSysc() {
      const weighted = this.fieldRows
        .map(r => parseFloat(r.sysc) * parseFloat(r.acreage))
        .reduce((prev, curr) => prev + curr, 0)

      return weighted / this.totalAcreage
    },

    totalNoTill() {
      return this.totalByPracticeHelper("no-till")
    },
    totalCoverCrop() {
      return this.totalByPracticeHelper("cover-crop")
    },
    totalNitrogenInhibitor() {
      return this.totalByPracticeHelper("nitrogen-inhibitor")
    },
    totalAWD() {
      return this.totalByPracticeHelper("awd")
    },

    totalMethane() {
      const weighted = this.fieldRows
        .map(r => parseFloat(r.ch4) * parseFloat(r.acreage))
        .reduce((prev, curr) => prev + curr, 0)

      return weighted / this.totalAcreage
    },

    totalNitrous() {
      const weighted = this.fieldRows
        .map(r => parseFloat(r.n20) * parseFloat(r.acreage))
        .reduce((prev, curr) => prev + curr, 0)

      return weighted / this.totalAcreage
    },

    totalCo2Equivalent() {
      const weighted = this.fieldRows
        .map(r => {
          let equiv
          if (
            typeof r.co2EquivalentSelected == "number" &&
            isNaN(r.co2EquivalentSelected) == false
          ) {
            equiv = r.co2EquivalentSelected
          } else if (isNaN(r.co2EquivalentSelected)) {
            equiv = 0
          } else {
            equiv = 0
          }
          return parseFloat(equiv)
        })
        .reduce((prev, curr) => prev + curr, 0)
      return weighted // / this.totalAcreage
    },

    totalCreditAmount() {
      const totalCredit = this.fieldRows
        .map(r => (parseFloat(r.creditAmount) ? parseFloat(r.creditAmount) : 0))
        .reduce((prev, curr) => {
          return curr < 0 ? prev : prev + curr
        }, 0)
      return totalCredit
    },

    checkedCount() {
      return this.rowsToEdit.length
    },
  },

  watch: {
    all_fields() {
      this.finishedLoading = true
    },
    // compares practice selection values to show save button
    fieldRows(newRows) {
      const arrayMapToCheck = newRows.map(
        f =>
          `${f.awd.is_selected} ${f.cover_crop.is_selected} ${f.inhibitor.is_selected} ${f.no_till.is_selected}`
      )
      this.showSaveBtn = !(arrayMapToCheck.join("") == this.dataCopy.join(""))
    },
    // updates practice selection values to compare against (user selects new fields)
    selectedFields() {
      this.dataCopy = this.fieldRows.map(
        f =>
          `${f.awd.is_selected} ${f.cover_crop.is_selected} ${f.inhibitor.is_selected} ${f.no_till.is_selected}`
      )
    },
  },

  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFieldsSkinny,
      updateRegenPractice: Fields.Actions.updateRegenPractice,
      updateUserSustainability: Fields.Actions.updateUserSustainability,
      clearUserSustainability: Fields.Actions.clearUserSustainability,
      updateCarbonCreditDollarValue:
        Dashboard.Actions.updateCarbonCreditDollarValue,
    }),

    ...mapMutations({
      setSelectedFields: Filter.Mutations.setSelectedFields,
      setCarbonCreditDollarValue:
        Dashboard.Mutations.setCarbonCreditDollarValue,
    }),

    updateNewDollarPerTon(value) {
      this.loading = true
      this.updateCarbonCreditDollarValue(value)
      this.closeAdjustCarbonCreditModal()
      this.loading = false
      this.tableKey += 1 //Refreshing the Sustainability table to update the carbon credit dollar value
    },

    getPracticeSetting(d, practice_name) {
      let practiceSetting = d.scenario.filter(x => {
        return x.regen_practice.name == practice_name
      })
      if (practiceSetting.length == 1) {
        practiceSetting = {
          value: practiceSetting[0].value,
          is_selected: Boolean(practiceSetting[0].is_selected),
          is_locked: Boolean(practiceSetting[0].is_locked),
        }
      } else {
        practiceSetting = { value: "Unavailable", is_selected: false }
      }
      return practiceSetting
    },

    totalByPracticeHelper(practice_id) {
      let weighted = this.fieldRows
        .map(r =>
          r.scenario
            .map(r1 => {
              if (
                r1.is_selected == true &&
                r1.regen_practice.name == practice_id
              ) {
                return parseFloat(r1.value) * parseFloat(r.acreage)
              } else {
                return 0
              }
            })
            .reduce((prev, curr) => prev + curr, 0)
        )
        .reduce((prev, curr) => prev + curr, 0)

      return weighted
    },

    getSimulationModal(d, is_complete) {
      this.daycentSimulationModalOpen = true
      this.is_complete = is_complete
      this.current_field = d
    },
    closeDaycentSimulationModal() {
      this.daycentSimulationModalOpen = false
    },

    closeSetAllCropFieldsModal() {
      this.setAllCropFieldsModalOpen = false
    },

    closeSaveLoaderModal() {
      this.saveLoaderModalOpen = false
    },

    success() {
      if (this.current_field) {
        this.current_field.status = "pending"
      } else {
        this.fieldRows.forEach(row => {
          if (this.rowsToEdit.includes(row.id)) {
            row.status = "pending"
          }
        })
      }
      this.setAllCropFieldsModalOpen = false
      this.daycentSimulationModalOpen = false
      this.current_field = null
    },

    handleSearch(searchString) {
      this.searchString = searchString
    },

    setAllCropFields() {
      this.updateAllRowIds = []
      this.updateAllFieldIds = []

      this.fieldRows.forEach(row => {
        this.updateAllRowIds.push(row.id)
        this.updateAllFieldIds.push(row.fieldId)
      })

      this.setAllCropFieldsModalOpen = true
    },

    selectAllCheckBox() {
      const checked = this.$refs.fieldSelectAllCheckbox.checked
      this.$refs.sustainabilityRows.forEach(sustainabilityRow =>
        sustainabilityRow.setFieldSelectCheckbox(checked)
      )

      if (!checked) {
        this.rowsToEdit = []
        this.selectedFieldIds = []
      }
    },

    async unselectAllCheckBox() {
      this.$refs.fieldSelectAllCheckbox.checked = false
      // this.$refs.sustainabilityRows.forEach(sustainabilityRow =>
      //   sustainabilityRow.setCheckbox(false)
      // )
      this.$refs.sustainabilityRows.forEach(sustainabilityRow =>
        sustainabilityRow.setFieldSelectCheckbox(false)
      )

      this.rowsToEdit = []
      this.selectedFieldIds = []
    },

    selectAllTillCheckbox() {
      const checked = this.$refs.noTillSelectAllCheckbox.checked
      // this.$refs.sustainabilityRows.forEach(sustainabilityRow => {
      //   if (!sustainabilityRow.field.no_till.is_locked)
      //     sustainabilityRow.setNoTillCheckbox(checked)
      // })
      let name = "no-till"
      this.fieldRows.forEach(field => {
        let loop_name = ["no-till"]
        let scenario = field.scenario

        for (let name_idx in loop_name) {
          name = loop_name[name_idx]
          let temp = scenario.filter(x => {
            return x.regen_practice.name == name
          })
          if (temp.length > 0) {
            temp.forEach(this_practice => (this_practice.is_selected = checked))
          } else {
            scenario.push({
              is_selected: checked,
              regen_practice: { name: name },
              value: 0,
            })
          }

          this.updateRegenPractice({
            field_id: field.fieldId,
            scenario: scenario,
          })

          this.fieldSettingHelper(field, name, checked)
        }
      })
    },
    selectAllCoverCropCheckbox() {
      const checked = this.$refs.coverCropSelectAllCheckbox.checked
      // this.$refs.sustainabilityRows.forEach(sustainabilityRow => {
      //   if (!sustainabilityRow.field.cover_crop.is_locked)
      //     sustainabilityRow.setCoverCropCheckbox(checked)
      // })
      let name = "cover-crop"
      this.fieldRows.forEach(field => {
        let loop_name = ["cover-crop"]
        let scenario = field.scenario

        for (let name_idx in loop_name) {
          name = loop_name[name_idx]
          let temp = scenario.filter(x => {
            return x.regen_practice.name == name
          })
          if (temp.length > 0) {
            temp.forEach(this_practice => (this_practice.is_selected = checked))
          } else {
            scenario.push({
              is_selected: checked,
              regen_practice: { name: name },
              value: 0,
            })
          }

          this.updateRegenPractice({
            field_id: field.fieldId,
            scenario: scenario,
          })

          this.fieldSettingHelper(field, name, checked)
        }
      })
    },
    selectAllInhibitorCheckbox() {
      const checked = this.$refs.inhibitorSelectAllCheckbox.checked
      // this.$refs.sustainabilityRows.forEach(sustainabilityRow => {
      //   if (!sustainabilityRow.field.inhibitor.is_locked)
      //     sustainabilityRow.setInhibitorCheckbox(checked)
      // })
      let name = "nitrogen-inhibitor"
      this.fieldRows.forEach(field => {
        let loop_name = ["nitrogen-inhibitor"]
        let scenario = field.scenario

        for (let name_idx in loop_name) {
          name = loop_name[name_idx]
          let temp = scenario.filter(x => {
            return x.regen_practice.name == name
          })
          if (temp.length > 0) {
            temp.forEach(this_practice => (this_practice.is_selected = checked))
          } else {
            scenario.push({
              is_selected: checked,
              regen_practice: { name: name },
              value: 0,
            })
          }

          this.updateRegenPractice({
            field_id: field.fieldId,
            scenario: scenario,
          })

          this.fieldSettingHelper(field, name, checked)
        }
      })
    },
    selectAllAWDCheckbox() {
      const checked = this.$refs.awdSelectAllCheckbox.checked
      // this.$refs.sustainabilityRows.forEach(sustainabilityRow => {
      //   if (!sustainabilityRow.field.awd.is_locked)
      //     sustainabilityRow.setAWDCheckbox(checked)
      // })
      let name = "awd"
      this.fieldRows.forEach(field => {
        let loop_name = ["awd"]
        let scenario = field.scenario

        for (let name_idx in loop_name) {
          name = loop_name[name_idx]
          let temp = scenario.filter(x => {
            return x.regen_practice.name == name
          })
          if (temp.length > 0) {
            temp.forEach(this_practice => (this_practice.is_selected = checked))
          } else {
            scenario.push({
              is_selected: checked,
              regen_practice: { name: name },
              value: 0,
            })
          }

          this.updateRegenPractice({
            field_id: field.fieldId,
            scenario: scenario,
          })

          this.fieldSettingHelper(field, name, checked)
        }
      })
    },

    // same as method in SustainabilityRow
    fieldSettingHelper(field, name, e) {
      if (name == "no-till") {
        if (e == true) {
          field.scenario_settings["tillage"] = "no-till"
        } else {
          field.scenario_settings["tillage"] = "conventional"
        }
      }
      if (name == "cover-crop") {
        if (e == true) {
          field.scenario_settings["cover_crop"] = true
        } else {
          field.scenario_settings["cover_crop"] = false
        }
      }
      if (name == "nitrogen-inhibitor") {
        if (e == true) {
          field.scenario_settings["inhibitor"] = 1
        } else {
          field.scenario_settings["inhibitor"] = 0
        }
      }
      if (name == "awd") {
        if (e == true) {
          field.scenario_settings["awd"] = true
        } else {
          field.scenario_settings["awd"] = false
        }
      }
    },

    checked(id, fieldId) {
      if (!this.rowsToEdit.includes(id)) {
        this.rowsToEdit.push(id)
        this.selectedFieldIds.push(fieldId)
      }
      // this.showSaveBtn = true
    },

    unchecked(id, fieldId) {
      const index = this.rowsToEdit.indexOf(id)
      if (index > -1) this.rowsToEdit.splice(index, 1)

      const index2 = this.selectedFieldIds.indexOf(fieldId)
      if (index2 > -1) this.selectedFieldIds.splice(index, 1)
    },

    async saveFieldSelection() {
      this.showSaveBtnLoader = true
      this.saveLoaderModalOpen = true
      this.saveLoaderModalLoading = true
      /*
      const selFields = this.selectedFields

      console.log(selFields)
      console.log(this.rowsToEdit)

      for (const fieldId in selFields) {
        if (this.selectedFieldIds.includes(Number(fieldId))) {
          selFields[fieldId] = true
        } else {
          selFields[fieldId] = false
        }
      }
      */

      let filterFieldSave = this.searchFilteredItems.filter(x =>
        this.selectedFieldIds.includes(x.fieldId)
      )

      let payloads = []
      for (let fieldIdx in filterFieldSave) {
        let thisFieldSave = filterFieldSave[fieldIdx]
        let daycent_scenario_id = thisFieldSave.id
        let field_id = thisFieldSave.fieldId

        for (let scenarioIDX in thisFieldSave.scenario) {
          let name = thisFieldSave.scenario[scenarioIDX].regen_practice.name
          let is_selected = thisFieldSave.scenario[scenarioIDX].is_selected
          let payload = {
            daycent_scenario_id: daycent_scenario_id,
            field_id: field_id,
            practice_name: name,
            is_selected: is_selected,
          }
          payloads.push(payload)
        }
      }

      this.showSaveBtn = false

      await CarbonReadyAPI.postPracticePreference({
        practice_array: payloads,
      }).then(async () => {
        this.showSaveBtnLoader = null
        this.dataCopy = this.fieldRows.map(
          f =>
            `${f.awd.is_selected} ${f.cover_crop.is_selected} ${f.inhibitor.is_selected} ${f.no_till.is_selected}`
        )
        this.showSaveBtn = false
        this.saveLoaderModalLoading = false
        this.saveLoaderModalLoadComplete = true
        await this.fetchFields()
        const delay = ms => new Promise(res => setTimeout(res, ms))
        await delay(2000)
        this.saveLoaderModalOpen = false
        this.saveLoaderModalLoadComplete = false
      })
    },

    clearFieldSelection() {
      // let saveFieldIds = this.activeFields.map(x => x.id)
      // this.fetchFields()
      //   .then(() => this.setSelectedFields(this.activeFields))
      //   .then(() => this.setSelectedFields(saveFieldIds))
      // this.showSaveBtn = false

      this.$refs.sustainabilityRows.forEach(sustainabilityRow =>
        sustainabilityRow.setCheckbox(false)
      )

      this.rowsToEdit = []
      this.selectedFieldIds = []

      //postPracticePreference()
      //TODO we'd need to extend this to remove all checks and make a bunch of api calls to update db, woul dbe some work
    },

    navigateToEnrollmentWizard() {
      const selFields = this.selectedFields
      for (const fieldId in selFields) {
        if (this.selectedFieldIds.includes(Number(fieldId))) {
          selFields[fieldId] = true
        } else {
          selFields[fieldId] = false
        }
      }

      this.setSelectedFields(this.selectedFieldIds)
      this.$router.push("/enroll-fields/")
    },

    downloadFieldSelection() {
      const fieldInfo = {}
      this.fieldRows.forEach(row => {
        row.no_till.value =
          row.no_till.value === "Unavailable" ? "0.00" : row.no_till.value
        row.cover_crop.value =
          row.cover_crop.value === "Unavailable" ? "0.00" : row.cover_crop.value
        row.inhibitor.value =
          row.inhibitor.value === "Unavailable" ? "0.00" : row.inhibitor.value
        row.awd.value = row.awd.value === "Unavailable" ? "0.00" : row.awd.value

        fieldInfo[row.fieldId] = {
          name: row.name,
          acreage: row.acreage,
          notill: row.no_till,
          coverCrop: row.cover_crop,
          nitrogenInhibitor: row.inhibitor,
          awd: row.awd,
          totalCo2e: row.co2Equivalent,
        }
      })
      const pdfData = {
        field_info: fieldInfo,
      }
      CarbonReadyAPI.fieldSelectionPdf(pdfData).then(response => {
        const url = response.data
        window.open(url, "_blank")
      })
    },

    async reloadFields() {
      this.reloadFieldsModalOpen = true
      this.reloadFieldsModalLoading = true
      await this.fetchFields().then(async () => {
        this.reloadFieldsModalLoading = false
        this.reloadFieldsModalLoadComplete = true
        const delay = ms => new Promise(res => setTimeout(res, ms))
        await delay(2000)
        this.reloadFieldsModalOpen = false
        this.reloadFieldsModalLoadComplete = false
      })
    },

    handleAdjustCarbonCreditModalClick() {
      this.adjustCarbonCreditModalOpen = true
    },

    closeAdjustCarbonCreditModal() {
      this.adjustCarbonCreditModalOpen = false
    },

    handleHPClick() {
      this.$router.push("/historical-practices")
    },

    sortIcon(sortValue) {
      if (sortValue === "asc") return "fa-sort-up"
      else if (sortValue === "desc") return "fa-sort-down"
      return "fa-sort"
    },

    handleSortAcreage() {
      if (!this.sortByAcreage) this.sortByAcreage = "asc"
      else if (this.sortByAcreage === "asc") this.sortByAcreage = "desc"
      else if (this.sortByAcreage === "desc") this.sortByAcreage = null
    },

    handleSortEstimatedCredit() {
      if (!this.sortByEstimatedCredit) this.sortByEstimatedCredit = "asc"
      else if (this.sortByEstimatedCredit === "asc")
        this.sortByEstimatedCredit = "desc"
      else if (this.sortByEstimatedCredit === "desc")
        this.sortByEstimatedCredit = null
    },

    // for saving local/unsaved sustainability settings before refresh
    saveBeforeRefresh() {
      const dictToReturn = {}
      this.activeFields.forEach(field => {
        let daycent = field.daycent[0]
        dictToReturn[field.id] = {
          scenario: _.cloneDeep(daycent.scenario),
        }
      })

      this.updateUserSustainability(dictToReturn)
    },

    // load local/unsaved user settings from userSustainabilityBeforeRefresh
    loadAfterRefresh() {
      if (_.isEmpty(this.userSustainabilityBeforeRefresh)) {
        return
      }

      for (const [key, value] of Object.entries(
        this.userSustainabilityBeforeRefresh
      )) {
        this.updateRegenPractice({
          field_id: key,
          scenario: value.scenario,
        })
      }
      this.clearUserSustainability()
    },

    // setInterval fetch fields and re-input user selected values
    async intervalFunc() {
      this.saveBeforeRefresh()
      await this.fetchFields()
      this.loadAfterRefresh()
    },
  },

  mounted() {
    this.all_fields = setInterval(this.intervalFunc, 120000)
    const { $ } = window
    $(function() {
      $('[data-toggle="tooltip"]').tooltip()
    })

    // for show save button functionality, look at watchers for logic
    this.dataCopy = this.fieldRows.map(
      f =>
        `${f.awd.is_selected} ${f.cover_crop.is_selected} ${f.inhibitor.is_selected} ${f.no_till.is_selected}`
    )
  },
  destroyed() {
    if (this.all_fields) clearInterval(this.all_fields)
  },
}
</script>

<style scoped>
/*.carbon-ball {
  background: rgba(227, 126, 87, 1);
  width: 12px;
  height: 12px;
  border-radius: 12px;
}

.ghg-ball {
  background: rgba(154, 195, 91, 1);
  width: 12px;
  height: 12px;
  border-radius: 12px;
}*/

.top-container {
  min-height: 14vh;
}

.table-label {
  margin-left: 5px;
  margin-right: 20px;
  font-size: 12px;
  font-weight: 500;
}

.sustainability-table {
  margin-top: 25px;
}

/*.organic-carbon {
  background: rgba(227, 126, 87, 1) !important;
  color: white !important;
}

.ghg {
  background: rgba(154, 195, 91, 1) !important;
  color: white !important;
}*/

thead > tr > th {
  white-space: nowrap;
}

table thead {
  inset-block-start: 0; /* "top" */
}
table tfoot {
  inset-block-end: 0; /* "bottom" */
}

.sticky-foot {
  position: sticky;
  background: #d3efd1;
  height: 64px;
  font-weight: 600;
}

/* total row spacing */
.cover-crop {
  text-align: center;
  padding-right: 36px;
}

.nitro-inhib {
  text-align: center;
  padding-right: 36px;
}

.awd-total {
  text-align: center;
  padding-right: 60px;
}
</style>
