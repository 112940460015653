<template>
  <div>
    <CarbonFlowSteppers :step="3" />

    <v-card class="mt-2 mb-4 pb-1 pt-1 pl-2 pr-2">
      <div class="mb-4">
        <div class="d-flex">
          <div class="d-flex flex-column-reverse">
            <div class="d-flex">
              <div class="available-ball" />
              <div class="table-label mr-4">Available</div>
              <div class="requested-ball" />
              <div class="table-label mr-4">Requested</div>
              <div class="approved-ball" />
              <div class="table-label mr-4">Approved</div>
              <div class="confirmed-ball" />
              <div class="table-label mr-4">Confirmed</div>
            </div>
          </div>
          <div class="ml-auto d-flex flex-column">
            <div>
              <!-- Show Approve Button only when user selects "Show Requested Enrollment(s)" from dropdownlist on top left -->
              <v-btn
                v-if="showApproveBtn"
                class="ma-1 float-right mt-3"
                :class="{ disabled: numCheckedFields === 0 }"
                @click="handleOpenEnrollmentModal"
              >
                <span>Approve Enrollment</span>
              </v-btn>

              <!-- Show Confirm Button only when user selects "Show Approved Enrollment(s)" from dropdownlist on top left -->
              <v-btn
                v-else-if="showConfirmBtn"
                class="ma-1 float-right mt-3"
                :class="{ disabled: numCheckedFields === 0 }"
                @click="handleOpenEnrollmentModal"
              >
                <span>Confirm Enrollment</span>
              </v-btn>

              <!-- Show Confirm Button only when user selects "Show All Enrollment(s)"
            Or not select anything by default from dropdownlist on top left -->
              <v-btn
                v-else
                class="ma-1 float-right mt-3"
                :class="{ disabled: numCheckedFields === 0 }"
                @click="handleOpenEnrollmentModal"
              >
                <span>Request Enrollment</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-card>
      <v-simple-table
        v-if="enrolledFields != null"
        class="selector-table"
        fixed-header
        height="calc(100vh - 250px)"
      >
        <thead>
          <tr>
            <th>
              <v-simple-checkbox
                v-model="fieldSelectAllCheckbox"
                @click="selectAllCheckBox"
                name="selected"
                ref="fieldSelectAllCheckbox"
              />
            </th>
            <th width="120" />
            <th class="hover" @click="handleSortFieldName">
              Field Name
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Select one or more fields from the list of fields below for Enrollment."
              />
              <i
                class="fa hover"
                :class="sortIcon(sortByFieldName)"
                @click="handleSortFieldName"
              />
            </th>
            <th>
              Data Completion
            </th>
            <th>
              Potential Credit/Yr
            </th>
            <th width="80" class="hover">
              Prioritize Control
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="If a carbon enrollment includes soil ammendments or otherwise
              requires the use of control plots, optionally indicate which fields (if any)
              for which you would like to preference control creation creation.
              Control Plot fields will NOT use any new regenerative practices or soil ammendments for duration
              of the project."
              />
            </th>
            <th class="hover">
              Add Ownership
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="If this field practice is for CO2 sequestration then select Add+ to set ownership for each field to be enrolled."
              />
            </th>
            <th class="hover">
              Regenerative Practice
              <i
                class="fa fa-info-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Select to set one or more regeneration practices for each field to be enrolled."
              />
            </th>
            <th>Enrollment Start Date</th>
            <th class="hover">
              Status
              <i class="fa hover" />
            </th>
            <th>Agreement PDF</th>
          </tr>
        </thead>
        <tbody>
          <CarbonReadyFieldRow
            v-for="field in paginatedFields"
            :key="field.id"
            :field="field"
            :carbonReadyEnrolledFields="enrolledFields"
            :regenPracticeOptions="regenPracticeOptions"
            :newEnrollmentStartDate="newEnrollmentStartDate"
            :dataCompletion="dataCompletionMaster"
            ref="fieldListRows"
            @checked="checked"
            @unchecked="unchecked"
            @ownership-choice="setFieldOwnership"
            @set-prioritize-control="setPrioritizeControl"
            @handle-field-clicked="handleFieldClicked"
          />
        </tbody>
      </v-simple-table>
      <div v-else class="my-3 d-flex justify-content-center" justify="center">
        <div>
          <div class="text-bold mb-0 mt-1">Fetching Data</div>
          <div class="spinner-border ml-4" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <!-- pagination -->
      <div v-if="paginationLength > 1" class="mt-4 text-center">
        <span class="text-subtitle-2"
          >Showing {{ paginationIndices["first"] }} -
          {{ paginationIndices["last"] }}</span
        >
        <v-pagination
          v-model="page"
          :length="paginationLength"
          total-visible="6"
        ></v-pagination>
      </div>
    </v-card>
    <CarbonReadyFieldModal
      v-if="enrollmentModalOpen"
      :totalAcres="totalAcres"
      :fields="fieldsToEnroll"
      :user="user"
      :newEnrollmentStartDate="newEnrollmentStartDate"
      :autoApproveEnrollment="autoApproveEnrollment"
      @close-modal="closeEnrollmentModal"
      @success="refreshEnrollmentPage"
    />

    <EnrollmentHistoryModal
      v-if="enrollmentHistoryModalOpen"
      :field="currentSelectedField"
      :enrollmentField="currentSelectedEnrollment"
      @close-modal="enrollmentHistoryModalOpen = false"
    />

    <AlertErrorModal
      v-if="alertErrorModalOpen"
      titleText="Failed To Enroll Field(s)"
      :errorMessage="errorMessage"
      @close-modal="alertErrorModalOpen = false"
    />

    <SetLandOwnerModal
      v-if="setLandOwnerOpen"
      :fieldId="currentLeasedFieldId"
      :landOwnerNameList="landOwnerNameList"
      @close-modal="closeSetLandOwnerModal"
      @set-landowner-name="setLandOwnerName"
    />
  </div>
</template>

<script>
import _ from "lodash"
import { mapGetters, mapState, mapActions } from "vuex"

import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import DefaultsAPI from "@/api/DefaultsAPI"
import CarbonReadyFieldModal from "@/components/carbonReadyField/CarbonReadyFieldModal"
import CarbonReadyFieldRow from "@/components/carbonReadyField/CarbonReadyFieldRow"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import SetLandOwnerModal from "@/components/modals/SetLandOwnerModal"
import EnrollmentHistoryModal from "@/components/enrollmentHistory/EnrollmentHistoryModal"
import { Filter, Fields } from "@/store/modules"
import CarbonFlowSteppers from "@/views/CarbonFlowSteppers"
import router from "@/router"

export default {
  name: "EnrollFieldsView",
  components: {
    CarbonFlowSteppers,
    CarbonReadyFieldModal,
    CarbonReadyFieldRow,
    AlertErrorModal,
    SetLandOwnerModal,
    EnrollmentHistoryModal,
  },

  data() {
    return {
      fieldSelectAllCheckbox: false,
      prioritizeControlSelectAllCheckbox: false,
      currentLeasedFieldId: null,
      errorMessage: [],
      alertErrorModalOpen: false,
      setLandOwnerOpen: false,
      sortByAcreage: null,
      sortByFarm: null,
      sortByFieldName: null,
      enrollmentModalOpen: false,
      fieldsToEnroll: {},
      ownershipByField: {},
      landOwnerNameByField: {},
      landOwnerNameList: [],
      prioritizeControlByField: {},
      enrolledFields: null,
      regenPracticeOptions: [],
      showApproveBtn: false,
      showConfirmBtn: false,
      enrollmentHistoryModalOpen: false,
      currentSelectedField: null,
      currentSelectedEnrollment: null,
      // field level defaults data completion
      dataCompletionMaster: null,
      // credit price per ton
      creditPrice: 20,
      // pagination
      page: 1,
      paginationSize: 100,
    }
  },

  computed: {
    ...mapState({
      user: state => state.User.user,
    }),
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),

    paginatedFields() {
      const firstIndex = (this.page - 1) * this.paginationSize
      const maxIndex = this.paginationSize * this.page + 1
      return this.sortedFields.slice(firstIndex, maxIndex)
    },

    paginationLength() {
      return Math.ceil(this.sortedFields.length / this.paginationSize)
    },

    paginationIndices() {
      const firstIndex = (this.page - 1) * this.paginationSize + 1
      const maxIndex = this.paginationSize * this.page
      const lastIndex =
        this.sortedFields.length < maxIndex
          ? this.sortedFields.length
          : maxIndex
      return { first: firstIndex, last: lastIndex }
    },

    sortedFields() {
      const sortValues = {
        acreage: this.sortByAcreage,
        "farm.name": this.sortByFarm,
        name: this.sortByFieldName,
      }
      let sortColumns = []
      let sortDirections = []
      for (const column in sortValues) {
        if (sortValues[column] !== null) {
          sortColumns.push(column)
          sortDirections.push(sortValues[column])
        }
      }

      this.activeFields.forEach(field => {
        //this should only list fields that are not in a currently active enrollment?
        field.daycent.map(d => {
          const equiv =
            (parseFloat(d.sysc) * 3.666 * 4046.86 +
            parseFloat(d.ch4) * -1 * 24 * 4046.86 + //this is wrong?? should be 24...
              parseFloat(d.n20) * -1 * 298 * 4046.86) /
            1000000

          //clean this up
          let any_selected = false

          let no_till = this.getPracticeSetting(d, "no-till")
          let cover_crop = this.getPracticeSetting(d, "cover-crop")
          let inhibitor = this.getPracticeSetting(d, "nitrogen-inhibitor")
          let awd = this.getPracticeSetting(d, "awd")

          if (inhibitor.is_selected) any_selected = true
          if (cover_crop.is_selected) any_selected = true
          if (no_till.is_selected) any_selected = true
          if (awd.is_selected) any_selected = true

          // if (any_selected) {
          //   const index = this.rowsToEdit.indexOf(d.id)
          //   if (index == -1) {
          //     this.rowsToEdit.push(d.id)
          //     this.selectedFieldIds.push(field.id)
          //   }
          // }

          let creditAmt = 0
          let co2EquivalentSelected = 0
          if (any_selected) {
            creditAmt =
              equiv.toFixed(2) * this.creditPrice * field.acreage.toFixed(0)
            co2EquivalentSelected = creditAmt / this.creditPrice
          }

          return Object.assign(d, {
            fieldId: field.id,
            name: field.name,
            acreage: field.acreage,
            boundary_image: field.boundary_image,
            co2Equivalent: equiv,
            status: d.status,
            no_till: no_till,
            cover_crop: cover_crop,
            inhibitor: inhibitor,
            awd: awd,
            is_complete: false,
            //no-till: d.
            //updated: field.daycent.updated_at,
            //settings: field.daycent.scenario_settings,
            co2EquivalentSelected: co2EquivalentSelected,
            creditAmount: creditAmt, //add field_id,last simulation options here, last simulation time, processing status
          })
        })
      })

      const res = _.orderBy(this.activeFields, sortColumns, sortDirections)
      return res
    },

    numCheckedFields() {
      return Object.keys(this.fieldsToEnroll).length
    },

    totalAcres() {
      let fieldIds = Object.keys(this.fieldsToEnroll)
      let tempFields = this.activeFields.filter(obj => {
        if (fieldIds.includes(obj.id.toString())) {
          return obj
        }
      })
      let acresArray = tempFields.map(obj => {
        return obj.acreage
      })
      let acres = _.sum(acresArray)
      return acres
    },

    newEnrollmentStartDate() {
      return new Date()
    },

    autoApproveEnrollment() {
      return this.user.auto_approve_enrollment
    },
  },

  methods: {
    ...mapActions({
      fetchFields: Fields.Actions.fetchFieldsSkinny,
    }),

    handleOpenEnrollmentModal() {
      this.resetErrorMessage()

      if (this.numCheckedFields > 0) {
        let fieldIds = Object.keys(this.fieldsToEnroll)
        let payload = {}

        try {
          fieldIds.forEach(id => {
            if (!(id in this.ownershipByField)) {
              this.errorMessage.push(
                "Ownership must be selected for all selected field(s)."
              )
              throw "Break"
            }

            //TODO: We might need to add logic to handle a scenario where
            // the user enrolls a field with no practice selected
            var landOwnerName = ""
            var prioritizeControlFlag = this.prioritizeControlByField[id]

            if (this.ownershipByField[id] === "leased")
              landOwnerName = this.landOwnerNameByField[id]
            if (!prioritizeControlFlag) prioritizeControlFlag = false

            payload[id] = {
              ownership: this.ownershipByField[id],
              landOwnerName: landOwnerName,
              prioritizeControl: prioritizeControlFlag,
            }
          })

          this.fieldsToEnroll = payload
          this.enrollmentModalOpen = true
        } catch (e) {
          if (e === "Break") {
            this.alertErrorModalOpen = true
          }
        }
      }
    },

    resetErrorMessage() {
      this.errorMessage = []
    },

    closeSetLandOwnerModal(fieldId) {
      this.setFieldOwnership(fieldId, null)

      this.$refs.fieldListRows.forEach(fieldListRow => {
        if (fieldListRow.field.id === fieldId) {
          fieldListRow.ownershipChoice = null
        }
      })

      this.setLandOwnerOpen = false
    },

    closeEnrollmentModal() {
      this.enrollmentModalOpen = false
    },

    refreshEnrollmentPage() {
      this.fetchFields()
      this.fetchEnrollments()
      this.deselectAllCheckbox()
    },

    checked(id) {
      const fieldsToEnroll = {
        ...this.fieldsToEnroll,
      }
      fieldsToEnroll[id] = true
      this.fieldsToEnroll = fieldsToEnroll
    },

    unchecked(id) {
      const fieldsToEnroll = {
        ...this.fieldsToEnroll,
      }
      delete fieldsToEnroll[id]
      this.fieldsToEnroll = fieldsToEnroll
    },

    setFieldOwnership(fieldId, ownershipChoice) {
      const ownershipByField = {
        ...this.ownershipByField,
      }

      if (ownershipChoice) {
        ownershipByField[fieldId] = ownershipChoice
        this.ownershipByField = ownershipByField
      } else {
        delete this.ownershipByField[fieldId]
        delete this.landOwnerNameByField[fieldId]
      }

      if (ownershipChoice === "leased") {
        this.currentLeasedFieldId = fieldId
        this.setLandOwnerOpen = true
      }
    },

    setPrioritizeControl(fieldId, prioritizeControlFlag) {
      const prioritizeControlByField = {
        ...this.prioritizeControlByField,
      }

      if (prioritizeControlFlag) {
        prioritizeControlByField[fieldId] = prioritizeControlFlag
        this.prioritizeControlByField = prioritizeControlByField
      } else {
        delete this.prioritizeControlByField[fieldId]
      }
    },

    setLandOwnerName(fieldId, landOwnerName) {
      const landOwnerNameByField = {
        ...this.landOwnerNameByField,
      }

      if (landOwnerName) {
        landOwnerNameByField[fieldId] = landOwnerName
        this.landOwnerNameByField = landOwnerNameByField

        if (!this.landOwnerNameList.includes(landOwnerName))
          this.landOwnerNameList.push(landOwnerName)
      } else {
        delete this.landOwnerNameByField[fieldId]
      }

      this.setLandOwnerOpen = false
    },

    selectAllCheckBox() {
      this.$refs.fieldListRows.forEach(fieldListRow =>
        fieldListRow.setCheckbox(this.fieldSelectAllCheckbox)
      )

      if (!this.fieldSelectAllCheckbox) this.fieldsToEnroll = []
    },

    deselectAllCheckbox() {
      this.$refs.fieldListRows.forEach(fieldListRow =>
        fieldListRow.setCheckbox(false)
      )
      this.fieldSelectAllCheckbox = false
    },

    sortIcon(sortValue) {
      if (sortValue === "asc") return "fa-sort-up"
      else if (sortValue === "desc") return "fa-sort-down"
      return "fa-sort"
    },

    handleSortAcreage() {
      if (!this.sortByAcreage) this.sortByAcreage = "asc"
      else if (this.sortByAcreage === "asc") this.sortByAcreage = "desc"
      else if (this.sortByAcreage === "desc") this.sortByAcreage = null
    },

    handleSortFarm() {
      if (!this.sortByFarm) this.sortByFarm = "asc"
      else if (this.sortByFarm === "asc") this.sortByFarm = "desc"
      else if (this.sortByFarm === "desc") this.sortByFarm = null
    },

    handleSortFieldName() {
      if (!this.sortByFieldName) this.sortByFieldName = "asc"
      else if (this.sortByFieldName === "asc") this.sortByFieldName = "desc"
      else if (this.sortByFieldName === "desc") this.sortByFieldName = null
    },

    handleFieldClicked(field, enrollmentField) {
      this.currentSelectedField = field
      if (enrollmentField) this.currentSelectedEnrollment = enrollmentField
      this.enrollmentHistoryModalOpen = true
    },

    async fetchEnrollments() {
      await CarbonReadyAPI.getEnrolledFields().then(resp => {
        this.enrolledFields = resp.data
      })
    },

    fetchRegenPracticeOptions() {
      CarbonReadyAPI.getRegenPractice().then(
        resp => (this.regenPracticeOptions = resp.data)
      )
    },

    handleHistoricalsClick() {
      this.$router.push("/historical-practices")
    },

    handleManagerClick() {
      this.$router.push("/enroll-manager")
    },

    async handleDataCompleteAPI() {
      let yearList = [2018, 2019, 2020, 2021]

      let x = await DefaultsAPI.getCarbonDataCompletion({
        fieldIds: this.activeFields.map(x => x.id),
        yearList: yearList,
        fieldListView: true,
      })
      this.dataCompletionMaster = JSON.parse(x.data)
    },

    // adding in for co2e price calculations from sustainability page
    getPracticeSetting(d, practice_name) {
      let practiceSetting = d.scenario.filter(x => {
        return x.regen_practice.name == practice_name
      })
      if (practiceSetting.length == 1) {
        practiceSetting = {
          value: practiceSetting[0].value,
          is_selected: Boolean(practiceSetting[0].is_selected),
          is_locked: Boolean(practiceSetting[0].is_locked),
        }
      } else {
        practiceSetting = { value: "Unavailable", is_selected: false }
      }
      return practiceSetting
    },
  },

  async mounted() {
    await this.fetchEnrollments()
    await this.handleDataCompleteAPI()
    this.fetchRegenPracticeOptions()

    if (router.history.router.prevRoute.path.includes("/data-spreadsheet/")) {
      const fieldId = router.history.router.prevRoute.path.split("/")[2]

      const selectedField = this.sortedFields.find(field => field.id == fieldId)
      const selectedEnrolledField = this.enrolledFields.filter(
        field => field.field_id == fieldId
      )

      this.handleFieldClicked(selectedField, selectedEnrolledField)
    }

    const {
      first_name,
      last_name,
      email,
      mobile_phone_number,
      job_title,
    } = this.user
    this.initialFirstName = first_name
    this.initialLastName = last_name
    this.initialEmail = email
    this.initialPhone = mobile_phone_number
    this.initialTitle = job_title
  },

  watch: {
    activeFields() {
      this.handleDataCompleteAPI()
    },
  },
}
</script>

<style scoped>
.available-ball {
  background: #000000;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.requested-ball {
  background: #850000;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.approved-ball {
  background: #ccb33b;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.confirmed-ball {
  background: #72cc6b;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}
</style>
