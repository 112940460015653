<template>
  <div>
    <v-stepper v-model="el" non-linear elevation="0">
      <!-- Header Progress Bar -->
      <v-stepper-header>
        <v-stepper-step :complete="el > 1" step="1" @click="el = 1">
          Historical Data
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 2" step="2" @click="el = 2">
          No. Plantings
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 3" step="3" @click="el = 3">
          Crop #1
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 10" step="10" @click="el = 10">
          Crop #2
          <small>Double Cropping Only</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="el > 17" step="17" @click="el = 17">
          Summary
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Prior Info -->
        <v-stepper-content step="1">
          <v-card>
            <v-card-text class="step-height overflow-auto">
              <b class="text-h4">Historical Crop Information</b>
              <div class="mb-4">
                Our records show that you have the following crops planted by
                field:
              </div>
              <div class="d-flex flex-wrap justify-space-between">
                <v-card
                  class="mb-4 overflow-auto"
                  v-for="sharedCropPlan in historicalPlantings"
                  :key="sharedCropPlan.idx"
                  min-width="650"
                  max-width="650"
                  min-height="200"
                  max-height="200"
                >
                  <v-card-title>
                    In {{ sharedCropPlan.fieldIds.length }} fields planted
                    {{ cropDisplay[sharedCropPlan.crops] }}
                    <v-spacer></v-spacer>
                    <v-btn
                      class="ml-3 white--text"
                      color="green"
                      rounded
                      @click="handleClickFilterFields(sharedCropPlan)"
                    >
                      Select these fields
                      <v-icon>mdi-arrow-right-circle</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="d-flex flex-wrap">
                    <div
                      class="mx-2 my-2"
                      v-for="fieldName in sharedCropPlan.fieldNames"
                      :key="fieldName.idx"
                    >
                      <v-chip>
                        {{ fieldName }}
                      </v-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn class="white--text" color="green" @click="startWizard">
                Start Wizard for Selected<v-icon>mdi-arrow-right-circle</v-icon>
              </v-btn>
              <v-btn color="primary" @click="handleFullReset">
                Reset All Wizard Info <v-icon>mdi-restart</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- Number of Plantings -->
        <v-stepper-content step="2">
          <v-card class="my-auto mx-auto">
            <v-card-text class="step-height text-center">
              <div>
                <b class="text-h5"
                  >How many plantings did you have this year?</b
                >
              </div>
              <v-row class="pt-4">
                <v-col md="4" offset-md="4">
                  <div class="d-flex justify-space-around">
                    <v-btn
                      rounded
                      outlined
                      text
                      color="blue accent-4"
                      @click="
                        numPlantings = 1
                        el = 3
                      "
                      :input-value="numPlantings == 1"
                      >One</v-btn
                    >
                    <v-btn
                      rounded
                      outlined
                      text
                      color="blue accent-4"
                      @click="
                        numPlantings = 2
                        el = 3
                      "
                      :input-value="numPlantings == 2"
                      >Two</v-btn
                    >
                    <!-- <v-btn
                      rounded
                      outlined
                      text
                      color="blue accent-4"
                      @click="
                        numPlantings = 3
                        el = 3
                      "
                      :input-value="numPlantings == 3"
                      >Three</v-btn
                    >
                    <v-btn
                      rounded
                      outlined
                      text
                      color="red accent-4"
                      @click="el = 3"
                      >None
                      <v-icon class="pl-1"
                        >mdi-close-circle-outline</v-icon
                      ></v-btn
                    > -->
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 1">Back</v-btn>
              <v-btn @click="el = 3">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- Describe First Planting Crop -->
        <v-stepper-content step="3">
          <v-card class="my-auto mx-auto">
            <v-card-text class="text-center step-height">
              <div>
                <b class="text-h5">What did you plant first?</b>
              </div>
              <div class="pt-2">
                <v-row>
                  <v-col md="4" offset-md="4">
                    <v-select
                      :items="cropSelect"
                      v-model="initialCrop"
                      label="Crop"
                      dense
                      outlined
                    />
                  </v-col>
                </v-row>
              </div>
              <div class="second-q" v-if="initialCrop">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="initialCrop = null"
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  :disabled="initialCrop == null"
                  @click="el = 4"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
              <!-- <v-row v-if="numPlantings > 0">
              <v-col md="6">
                <v-card class="mt-3" elevation="6" rounded>
                  <v-card-title
                    >Crop
                    <div class="ml-auto select-container">
                      <v-select
                        :items="cropSelect"
                        v-model="initialCrop"
                        label="Crop"
                        dense
                        outlined
                      /></div
                  ></v-card-title>
                </v-card>
                <v-card class="mt-3" elevation="6" rounded>
                  <v-card-title
                    >Variety
                    <div class="ml-auto">
                      <v-select v-model="initialVariety" dense outlined /></div
                  ></v-card-title>
                </v-card>
                <v-card class="mt-3" elevation="6" rounded>
                  <v-card-title
                    >Seeds Per Bag
                    <div class="ml-auto">
                      <v-text-field
                        v-model="initialSeedsPerBag"
                        label="Cost"
                        dense
                        outlined
                      ></v-text-field></div
                  ></v-card-title>
                </v-card>
                <v-card class="mt-3" elevation="6" rounded>
                  <v-card-title
                    >Biological
                    <div class="ml-auto">
                      <v-select
                        v-model="initialBiological"
                        dense
                        outlined
                      /></div
                  ></v-card-title>
                </v-card>
              </v-col>
              <v-col md="6">
                <v-card class="mt-3" elevation="6" rounded>
                  <v-card-title
                    >Planting Date
                    <div class="ml-auto"><v-select dense outlined /></div
                  ></v-card-title>
                </v-card>
                <v-card class="mt-3" elevation="6" rounded>
                  <v-card-title
                    >Seeding Rate
                    <div class="ml-auto d-flex">
                      <v-text-field
                        v-model="initialSeedingRate"
                        label="Rate"
                        dense
                        outlined
                      ></v-text-field>
                      <v-select
                        v-model="initialSeedingRateUnits"
                        label="Units"
                        dense
                        outlined
                      />
                    </div>
                  </v-card-title>
                </v-card>
                <v-card class="mt-3" elevation="6" rounded>
                  <v-card-title
                    >Cost Per Bag
                    <div class="ml-auto">
                      <v-text-field
                        v-model="initialCostPerBag"
                        label="Cost"
                        dense
                        outlined
                      ></v-text-field></div
                  ></v-card-title>
                </v-card>
                <v-card class="mt-3" elevation="6" rounded>
                  <v-card-title
                    >Monitor Type
                    <div class="ml-auto">
                      <v-select
                        v-model="initialMonitorType"
                        dense
                        outlined
                      /></div
                  ></v-card-title>
                </v-card>
              </v-col>
            </v-row> -->
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 2">Back</v-btn>
              <v-btn @click="el = 4">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- First Planting Variety -->
        <v-stepper-content step="4">
          <v-card class="my-auto mx-auto">
            <v-card-text class="text-center step-height">
              <div>
                <b class="text-h5">What variety did you plant? </b>
                <v-tooltip bottom
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon v-bind="attrs" v-on="on"
                      >mdi-information</v-icon
                    ></template
                  ><span>You may input more than one variety.</span></v-tooltip
                >
              </div>
              <div class="pt-2">
                <v-row>
                  <v-col md="4" offset-md="4">
                    <v-text-field
                      v-model="initialVariety"
                      label="Variety"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="second-q">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="initialVariety = null"
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  :disabled="initialVariety == null"
                  @click="el = 5"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 3">Back</v-btn>
              <v-btn @click="el = 5">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- First Planting Date -->
        <v-stepper-content step="5">
          <v-card class="my-auto mx-auto" height="100%" :elevation="0">
            <v-card-text class="text-center step-height">
              <b class="text-h5">When did you plant?</b>
              <div class="pt-2">
                <v-row>
                  <v-col md="4" offset-md="4">
                    <v-menu
                      v-model="showInitialPlantingCal"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="initialPlantingDate"
                          label="Planting Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="initialPlantingDate"
                        @input="showInitialPlantingCal = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
              <div class="second-q">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="initialPlantingDate = null"
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  :disabled="initialPlantingDate == null"
                  @click="el = 6"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 4">Back</v-btn>
              <v-btn @click="el = 6">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- First Planting Rate -->
        <v-stepper-content step="6">
          <v-card class="my-auto mx-auto" height="100%" :elevation="0">
            <v-card-text class="text-center step-height">
              <b class="text-h5">What was your planting rate?</b>
              <div class="pt-2">
                <v-row>
                  <v-col md="4" offset-md="4">
                    <div class="d-flex justify-space-around">
                      <div class="select-container">
                        <v-text-field
                          v-model="initialSeedingRate"
                          label="Rate"
                          :rules="[numberValidator]"
                          type="number"
                          dense
                          outlined
                        ></v-text-field>
                      </div>
                      <div class="select-container">
                        <v-select
                          :items="seedingRateUnits"
                          v-model="initialSeedingRateUnits"
                          label="Units"
                          dense
                          outlined
                        >
                        </v-select>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="second-q">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="
                    initialSeedingRate = null
                    initialSeedingRateUnits = null
                  "
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  :disabled="
                    initialSeedingRate == null ||
                      initialSeedingRateUnits == null
                  "
                  @click="el = 7"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 5">Back</v-btn>
              <v-btn @click="el = 7">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- First Biological Amendment -->
        <v-stepper-content step="7">
          <v-card class="my-auto mx-auto" height="100%" :elevation="0">
            <v-card-text class="text-center step-height">
              <b class="text-h5">Did you have a biological amendment?</b>
              <div class="pt-2">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="red accent-4"
                  @click="
                    initialBiological = null
                    showInitialBioSelect = false
                    el = 8
                  "
                >
                  No <v-icon class="pl-1">mdi-close-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  :class="showInitialBioSelect ? 'green accent-2' : ''"
                  outlined
                  rounded
                  text
                  color="green accent-4"
                  @click="showInitialBioSelect = true"
                >
                  Yes <v-icon>mdi-check-circle</v-icon>
                </v-btn>
              </div>
              <div class="second-q" v-if="showInitialBioSelect">
                <b class="text-h5">What was the biological amendment?</b>
                <v-row class="pt-2">
                  <v-col md="4" offset-md="4">
                    <v-text-field
                      v-model="initialBiological"
                      label="Biological"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="pt-4" v-if="showInitialBioSelect">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="initialBiological = null"
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  :disabled="
                    initialBiological == '' || initialBiological == null
                  "
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  @click="el = 8"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 6">Back</v-btn>
              <v-btn @click="el = 8">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- First Monitor Type -->
        <v-stepper-content step="8">
          <v-card class="my-auto mx-auto" height="100%" :elevation="0">
            <v-card-text class="text-center step-height">
              <b class="text-h5">Did you use a planting monitor?</b>
              <div class="pt-2">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="red accent-4"
                  @click="
                    initialMonitorType = null
                    showInitialMonitorSelect = false
                    el = 9
                  "
                >
                  No <v-icon class="pl-1">mdi-close-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  :class="showInitialMonitorSelect ? 'green accent-2' : ''"
                  outlined
                  rounded
                  text
                  color="green accent-4"
                  @click="showInitialMonitorSelect = true"
                >
                  Yes <v-icon>mdi-check-circle</v-icon>
                </v-btn>
              </div>
              <div class="second-q" v-if="showInitialMonitorSelect">
                <b class="text-h5">What monitor type did you use?</b>
                <v-row class="pt-2">
                  <v-col md="4" offset-md="4">
                    <v-text-field
                      v-model="initialMonitorType"
                      label="Monitor"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="pt-4" v-if="showInitialMonitorSelect">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="initialMonitorType = null"
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  :disabled="
                    initialMonitorType == null || initialMonitorType == ''
                  "
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  @click="el = 9"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 7">Back</v-btn>
              <v-btn @click="el = 9">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- First Seeds and Cost per Bag -->
        <v-stepper-content step="9">
          <v-card class="my-auto mx-auto" height="100%" :elevation="0">
            <v-card-text class="text-center step-height">
              <b class="text-h5">Describe your seed bags:</b>
              <v-row class="pt-4">
                <v-col md="4" offset-md="4">
                  <div class="d-flex">
                    <span class="text-h6">Cost per bag: </span>
                    <div class="text-container ml-auto">
                      <v-text-field
                        v-model="initialCostPerBag"
                        label="Cost"
                        :rules="[numberValidator]"
                        type="number"
                        dense
                        outlined
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="text-h6">Seeds per bag: </span>
                    <div class="text-container ml-auto">
                      <v-text-field
                        v-model="initialSeedsPerBag"
                        label="Seeds"
                        :rules="[numberValidator]"
                        type="number"
                        dense
                        outlined
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="pt-2">
                    <v-btn
                      :disabled="
                        initialCostPerBag == null || initialSeedsPerBag == null
                      "
                      class="ml-4"
                      outlined
                      rounded
                      text
                      color="blue accent-4"
                      @click="handleFirstPlantingComplete"
                    >
                      Continue <v-icon>mdi-arrow-right-circle</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 8">Back</v-btn>
              <v-btn @click="el = 10">Next</v-btn>
              <v-btn @click="el = 17">Skip to Summary</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- Describe Second Planting Crop -->
        <v-stepper-content step="10">
          <v-card class="my-auto mx-auto">
            <v-card-text class="text-center step-height">
              <div>
                <b class="text-h5"
                  >What was your second planting for the year?</b
                >
              </div>
              <div class="pt-2">
                <v-row>
                  <v-col md="4" offset-md="4">
                    <v-select
                      :items="cropSelect"
                      v-model="secondCrop"
                      label="Crop"
                      dense
                      outlined
                    />
                  </v-col>
                </v-row>
              </div>
              <div class="second-q" v-if="secondCrop">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="secondCrop = null"
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  :disabled="secondCrop == null"
                  @click="el = 11"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 9">Back</v-btn>
              <v-btn @click="el = 11">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- Second Planting Variety -->
        <v-stepper-content step="11">
          <v-card class="my-auto mx-auto">
            <v-card-text class="text-center step-height">
              <div>
                <b class="text-h5">What variety did you plant? </b>
                <v-tooltip bottom
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-icon v-bind="attrs" v-on="on"
                      >mdi-information</v-icon
                    ></template
                  ><span>You may input more than one variety.</span></v-tooltip
                >
              </div>
              <div class="pt-2">
                <v-row>
                  <v-col md="4" offset-md="4">
                    <v-text-field
                      v-model="secondVariety"
                      label="Variety"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="second-q">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="secondVariety = null"
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  :disabled="secondVariety == null"
                  @click="el = 12"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 10">Back</v-btn>
              <v-btn @click="el = 12">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- Second Planting Date -->
        <v-stepper-content step="12">
          <v-card class="my-auto mx-auto" height="100%" :elevation="0">
            <v-card-text class="text-center step-height">
              <b class="text-h5">When did you plant?</b>
              <div class="pt-2">
                <v-row>
                  <v-col md="4" offset-md="4">
                    <v-menu
                      v-model="showSecondPlantingCal"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="secondPlantingDate"
                          label="Planting Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="secondPlantingDate"
                        @input="showSecondPlantingCal = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
              <div class="second-q">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="secondPlantingDate = null"
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  :disabled="secondPlantingDate == null"
                  @click="el = 13"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 11">Back</v-btn>
              <v-btn @click="el = 13">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- Second Planting Rate -->
        <v-stepper-content step="13">
          <v-card class="my-auto mx-auto" height="100%" :elevation="0">
            <v-card-text class="text-center step-height">
              <b class="text-h5">What was the planting rate?</b>
              <div class="pt-2">
                <v-row>
                  <v-col md="4" offset-md="4">
                    <div class="d-flex justify-space-around">
                      <div class="select-container">
                        <v-text-field
                          v-model="secondSeedingRate"
                          label="Rate"
                          :rules="[numberValidator]"
                          type="number"
                          dense
                          outlined
                        ></v-text-field>
                      </div>
                      <div class="select-container">
                        <v-select
                          :items="seedingRateUnits"
                          v-model="secondSeedingRateUnits"
                          label="Units"
                          dense
                          outlined
                        >
                        </v-select>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="second-q">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="
                    secondSeedingRate = null
                    secondSeedingRateUnits = null
                  "
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  :disabled="
                    secondSeedingRate == null || secondSeedingRateUnits == null
                  "
                  @click="el = 14"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>

              <v-btn @click="el = 12">Back</v-btn>
              <v-btn @click="el = 14">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- Second Biological Amendment -->
        <v-stepper-content step="14">
          <v-card class="my-auto mx-auto" height="100%" :elevation="0">
            <v-card-text class="text-center step-height">
              <b class="text-h5">Did you have a biological amendment?</b>
              <div class="pt-2">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="red accent-4"
                  @click="
                    secondBiological = null
                    showSecondBioSelect = false
                    el = 15
                  "
                >
                  No <v-icon class="pl-1">mdi-close-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  :class="showSecondBioSelect ? 'green accent-2' : ''"
                  outlined
                  rounded
                  text
                  color="green accent-4"
                  @click="showSecondBioSelect = true"
                >
                  Yes <v-icon>mdi-check-circle</v-icon>
                </v-btn>
              </div>
              <div class="second-q" v-if="showSecondBioSelect">
                <b class="text-h5">What was the biological amendment?</b>
                <v-row class="pt-2">
                  <v-col md="4" offset-md="4">
                    <v-text-field
                      v-model="secondBiological"
                      label="Biological"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="pt-4" v-if="showSecondBioSelect">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="secondBiological = null"
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  :disabled="secondBiological == '' || secondBiological == null"
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  @click="el = 15"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 13">Back</v-btn>
              <v-btn @click="el = 15">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- Second Monitor Type -->
        <v-stepper-content step="15">
          <v-card class="my-auto mx-auto" height="100%" :elevation="0">
            <v-card-text class="text-center step-height">
              <b class="text-h5">Did you use a planting monitor?</b>
              <div class="pt-2">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="red accent-4"
                  @click="
                    secondMonitorType = null
                    showSecondMonitorSelect = false
                    el = 16
                  "
                >
                  No <v-icon class="pl-1">mdi-close-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  class="ml-4"
                  :class="showSecondMonitorSelect ? 'green accent-2' : ''"
                  outlined
                  rounded
                  text
                  color="green accent-4"
                  @click="showSecondMonitorSelect = true"
                >
                  Yes <v-icon>mdi-check-circle</v-icon>
                </v-btn>
              </div>
              <div class="second-q" v-if="showSecondMonitorSelect">
                <b class="text-h5">What monitor type did you use?</b>
                <v-row class="pt-2">
                  <v-col md="4" offset-md="4">
                    <v-text-field
                      v-model="secondMonitorType"
                      label="Monitor"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="pt-4" v-if="showSecondMonitorSelect">
                <v-btn
                  class="mr-4"
                  outlined
                  rounded
                  text
                  color="purple accent-4"
                  @click="secondMonitorType = null"
                >
                  Reset <v-icon class="pl-1">mdi-restart</v-icon>
                </v-btn>
                <v-btn
                  :disabled="secondMonitorType == null"
                  class="ml-4"
                  outlined
                  rounded
                  text
                  color="blue accent-4"
                  @click="el = 16"
                >
                  Continue <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 14">Back</v-btn>
              <v-btn @click="el = 16">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <!-- Second Seeds and Cost per Bag -->
        <v-stepper-content step="16">
          <v-card class="my-auto mx-auto" height="100%" :elevation="0">
            <v-card-text class="text-center step-height">
              <b class="text-h5">Describe your seed bags:</b>
              <v-row class="pt-4">
                <v-col md="4" offset-md="4">
                  <div class="d-flex">
                    <span class="text-h6">Cost per bag: </span>
                    <div class="text-container ml-auto">
                      <v-text-field
                        v-model="secondCostPerBag"
                        label="Cost"
                        type="number"
                        dense
                        outlined
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="text-h6">Seeds per bag: </span>
                    <div class="text-container ml-auto">
                      <v-text-field
                        v-model="secondSeedsPerBag"
                        label="Seeds"
                        type="number"
                        dense
                        outlined
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="pt-2">
                    <v-btn
                      :disabled="
                        secondCostPerBag == null || secondSeedsPerBag == null
                      "
                      class="ml-4"
                      outlined
                      rounded
                      text
                      color="blue accent-4"
                      @click="handleSecondPlantingComplete"
                    >
                      Continue <v-icon>mdi-arrow-right-circle</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-btn @click="el = 15">Back</v-btn>
            <v-btn @click="el = 17">Next</v-btn>
          </v-card-actions>
        </v-stepper-content>

        <!-- Planting Summary Page -->
        <v-stepper-content step="17">
          <v-card class="my-auto mx-auto" :elevation="0">
            <v-card-text class="step-height">
              <v-row>
                <v-btn
                  class="ml-auto"
                  @click="
                    handleFullReset()
                    el = 1
                  "
                >
                  Return to Beginning
                </v-btn>
                <v-btn @click="handleSave()">Save and Continue</v-btn>
              </v-row>
              <div class="text-center">
                <b class="text-h5">Your Plantings for {{ year }}</b>
              </div>
              <!-- Single Planting Layout -->
              <v-row class="pt-4" v-if="numPlantings == 1">
                <v-col md="6" offset-md="3">
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Crop<span class="ml-auto">{{
                        initialCrop
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Variety<span class="ml-auto">{{
                        initialVariety
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Planting Date<span class="ml-auto">{{
                        initialPlantingDate
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Seeds Per Bag<span class="ml-auto">{{
                        initialSeedsPerBag
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Seeding Rate<span class="ml-auto"
                        >{{ initialSeedingRate }}
                        {{ initialSeedingRateUnits }}</span
                      ></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Cost Per Bag<span class="ml-auto">{{
                        initialCostPerBag
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Biological Amendment<span class="ml-auto">{{
                        initialBiological
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Monitor Type<span class="ml-auto">{{
                        initialMonitorType
                      }}</span></v-card-title
                    >
                  </v-card>
                </v-col>
              </v-row>
              <!-- Double Planting Layout -->
              <v-row class="pt-4" v-if="numPlantings == 2">
                <v-col md="6">
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Crop<span class="ml-auto">{{
                        initialCrop
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Variety<span class="ml-auto">{{
                        initialVariety
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Planting Date<span class="ml-auto">{{
                        initialPlantingDate
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Seeds Per Bag<span class="ml-auto">{{
                        initialSeedsPerBag
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Seeding Rate<span class="ml-auto"
                        >{{ initialSeedingRate
                        }}{{ initialSeedingRateUnits }}</span
                      ></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Cost Per Bag<span class="ml-auto">{{
                        initialCostPerBag
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Biological Amendment<span class="ml-auto">{{
                        initialBiological
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Monitor Type<span class="ml-auto">{{
                        initialMonitorType
                      }}</span></v-card-title
                    >
                  </v-card>
                </v-col>
                <v-col md="6">
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Crop<span class="ml-auto">{{
                        secondCrop
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Variety<span class="ml-auto">{{
                        secondVariety
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Planting Date<span class="ml-auto">{{
                        secondPlantingDate
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Seeds Per Bag<span class="ml-auto">{{
                        secondSeedsPerBag
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Seeding Rate<span class="ml-auto"
                        >{{ secondSeedingRate }}
                        {{ secondSeedingRateUnits }}</span
                      ></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Cost Per Bag<span class="ml-auto">{{
                        secondCostPerBag
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Biological Amendment<span class="ml-auto">{{
                        secondBiological
                      }}</span></v-card-title
                    >
                  </v-card>
                  <v-card class="mt-2" elevation="6" rounded>
                    <v-card-title class="text-body-1"
                      >Monitor Type<span class="ml-auto">{{
                        secondMonitorType
                      }}</span></v-card-title
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="el = 16">Back</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- Field Filtering Table -->
    <v-row class="pt-3">
      <v-col>
        <v-card>
          <v-card-title>
            <span class="mr-2">Selected Fields </span>
            |
            <span
              class="ml-2 cursor"
              @click="showSelectedFields = !showSelectedFields"
              >{{ showSelectedFields ? "Collapse" : "Expand" }}</span
            >
          </v-card-title>
          <v-card-subtitle>
            <span
              >Selected {{ plantingSettingsbyFieldFiltered.length }} of
              {{ plantingSettingsByField.length }} total fields</span
            >
          </v-card-subtitle>
          <v-card-text v-if="showSelectedFields">
            <div class="d-flex">
              <div class="text-container">
                <v-text-field
                  outlined
                  dense
                  v-model="plantingFilterText"
                  label="Filter Fields"
                ></v-text-field>
              </div>
              <div class="text-container ml-4">
                <v-select
                  outlined
                  dense
                  multiple
                  v-model="filterCrops"
                  :items="filterCropList"
                  label="Farms"
                >
                </v-select>
              </div>
              <div class="text-container ml-4">
                <v-select
                  outlined
                  dense
                  multiple
                  v-model="filterFarms"
                  :items="fieldFarms"
                  label="Farms"
                >
                </v-select>
              </div>
              <div class="ml-4">
                <v-btn
                  @click="
                    plantingFilterFields = null
                    plantingFilterText = ''
                    filterCrops = []
                    filterFarms = []
                  "
                  >Reset All Filters</v-btn
                >
              </div>
            </div>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="ten">
                    Name
                  </th>
                  <th class="ten">Farm</th>
                  <th class="ten">
                    Crops
                  </th>
                  <th class="twenty">Planting Date</th>
                  <th class="twenty">Variety</th>
                  <th>Planting Rate</th>
                  <th>Copy</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="field in plantingSettingsbyFieldFiltered"
                  :key="field.idx"
                >
                  <td>{{ field.fieldName }}</td>
                  <td>{{ field.farmName }}</td>
                  <td>
                    <div v-for="crop in field.cropNames" :key="crop">
                      {{ crop }}
                    </div>
                  </td>
                  <td>
                    <div
                      v-for="planting in field.plantings"
                      :key="planting.idx"
                    >
                      {{
                        planting.date.value ? planting.date.value : "No Date"
                      }}
                      ({{ planting.date.source }})
                    </div>
                  </td>
                  <td>
                    <div
                      v-for="planting in field.plantings"
                      :key="planting.idx"
                    >
                      {{ tableVariety(planting.variety.value) }}
                    </div>
                  </td>
                  <td>
                    <div
                      v-for="planting in field.plantings"
                      :key="planting.idx"
                    >
                      {{
                        planting.rate.value
                          ? planting.rate.value
                          : "--" | integer
                      }}
                      {{
                        planting.unit.value
                          ? planting.unit.value
                          : "-no units found-"
                      }}
                    </div>
                  </td>
                  <td class="cursor" @click="handleCopy(field)">
                    Copy
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapMutations, mapState } from "vuex"
import { Defaults } from "@/store/modules"
import {
  CROPS,
  CROP_DISPLAY_NAME,
  CROP_KEY_DISPLAY_NAME,
  CROP_NAMES_SELECT,
  CROP_KEY,
} from "@/constants"

export default {
  name: "PlantingWizard",
  props: {
    carbonSettingsByField: { type: Array },
    selectedFields: { type: Array },
    year: { type: String },
    wizardProgress: { type: Object },
    isWizardShowing: { type: Boolean },
  },
  data() {
    return {
      el: 1,
      //   options
      cropSelect: CROP_NAMES_SELECT,
      cropDisplay: CROP_DISPLAY_NAME,
      seedingRateUnits: ["seeds / acre", "lbs / acre"],
      // initial question toggles
      showInitialPlantingCal: false,
      showInitialBioSelect: false,
      showInitialMonitorSelect: false,
      // second question toggles
      showSecondPlantingCal: false,
      showSecondBioSelect: false,
      showSecondMonitorSelect: false,
      //   multiple plantings data
      numPlantings: null,
      //   initialPlanting
      initialCrop: null,
      initialVariety: null,
      initialSeedsPerBag: null,
      initialBiological: "",
      initialPlantingDate: null,
      initialSeedingRate: null,
      initialSeedingRateUnits: null,
      initialCostPerBag: 0,
      initialMonitorType: "",
      // secondPlanting
      secondCrop: null,
      secondVariety: null,
      secondSeedsPerBag: null,
      secondBiological: "",
      secondPlantingDate: null,
      secondSeedingRate: null,
      secondSeedingRateUnits: null,
      secondCostPerBag: 0,
      secondMonitorType: "",
      // table related
      showSelectedFields: false,
      filterCrops: [],
      filterCropsReflection: [],
      filterFarms: [],
      filterFarmsReflection: [],
      plantingFilterText: "",
      plantingFilterFields: null,
    }
  },
  computed: {
    ...mapState({
      plantingSettings: state => state.Defaults.plantingSettings,
    }),
    plantingSettingsByField() {
      // let plantingArr = []
      let plantingDict = {}
      Object.entries(this.plantingSettings).forEach(plantingDefault => {
        const fieldKey = plantingDefault[0]

        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        plantingDict[fieldKey] = {}
        const fieldSettingObj = plantingDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          plantingId: fieldSettingObj.planting_id,
        }

        fieldSettingObj.year_data.forEach(yearData => {
          if (yearData.year == this.year) {
            // if (fieldKey in plantingDict) {

            // } else {
            plantingDict[fieldKey] = idSettings
            plantingDict[fieldKey]["crops"] = []
            plantingDict[fieldKey]["cropNames"] = []
            plantingDict[fieldKey]["cropSearch"] = ""
            plantingDict[fieldKey]["plantings"] = []

            const cultivations = yearData.cultivations
            cultivations.forEach(cultivation => {
              plantingDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              plantingDict[fieldKey]["cropNames"].push(
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              )
              plantingDict[fieldKey]["cropSearch"] =
                plantingDict[fieldKey]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              plantingDict[fieldKey]["plantings"] = plantingDict[fieldKey][
                "plantings"
              ].concat(cultivation.plantings)

              plantingDict[fieldKey]["crops"].sort()
              plantingDict[fieldKey]["cropNames"].sort()
            })
          }
          // const cultivations = yearData.cultivations
          // cultivations.forEach(cultivation => {
          //   const cultiv = Object.assign(cultivation, idSettings, {
          //     year: String(yearData.year),
          //   })

          //   plantingDict[fieldKey][String(yearData.year)] = cultiv
          //   plantingArr.push(cultiv)
          // })
        })
      })

      // console.log("plantingDict: ", Object.values(plantingDict))

      return Object.values(plantingDict)
    },
    plantingSettingsbyFieldFiltered() {
      let arrayToReturn = _.cloneDeep(this.plantingSettingsByField)

      if (this.plantingFilterFields) {
        arrayToReturn = arrayToReturn.filter(field =>
          this.plantingFilterFields.includes(field.fieldId)
        )
      }
      if (this.filterFarmsReflection.length > 0) {
        arrayToReturn = arrayToReturn.filter(field =>
          this.filterFarms.includes(field.farmName)
        )
      }

      if (this.filterCropsReflection.length > 0) {
        arrayToReturn = arrayToReturn.filter(field =>
          this.filterCropsReflection.includes(field.cropNames.join(", "))
        )
      }

      if (this.plantingFilterText) {
        const searchString = this.plantingFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      return arrayToReturn
    },
    historicalPlantings() {
      const something = _(this.plantingSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
        }))
        .value()
      return something
    },
    fieldFarms() {
      return [...new Set(this.plantingSettingsByField.map(f => f.farmName))]
    },
    filterCropList() {
      return [
        ...new Set(
          this.plantingSettingsByField.map(f => f.cropNames.join(", "))
        ),
      ]
    },
  },
  methods: {
    ...mapActions({
      fetchPlantingSettings: Defaults.Actions.fetchPlantingSettings,
    }),
    ...mapMutations({
      updatePlanting: Defaults.Mutations.updatePlanting,
    }),
    startWizard() {
      this.el = 2;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleCopy(field) {
      // console.log("field: ", field)

      this.numPlantings = field.plantings.length
      if (this.numPlantings >= 1) {
        this.initialCrop = CROP_KEY[field.plantings[0].crop_id.value]
        this.initialBiological = field.plantings[0].biological.value
        this.initialCostPerBag = field.plantings[0].cost_per_bag.value
        this.initialPlantingDate = field.plantings[0].date.value
        this.initialMonitorType = field.plantings[0].monitor_type.value
        this.initialSeedingRate = field.plantings[0].rate.value
        this.initialSeedsPerBag = field.plantings[0].seeds_per_bag.value
        this.initialSeedingRateUnits = field.plantings[0].unit.value
        this.initialVariety = field.plantings[0].variety.value
      }
      if (this.numPlantings >= 2) {
        this.secondCrop = CROP_KEY[field.plantings[1].crop_id.value]
        this.secondBiological = field.plantings[1].biological.value
        this.secondCostPerBag = field.plantings[1].cost_per_bag.value
        this.secondPlantingDate = field.plantings[1].date.value
        this.secondMonitorType = field.plantings[1].monitor_type.value
        this.secondSeedingRate = field.plantings[1].rate.value
        this.secondSeedsPerBag = field.plantings[1].seeds_per_bag.value
        this.secondSeedingRateUnits = field.plantings[1].unit.value
        this.secondVariety = field.plantings[1].variety.value
      }

      this.el = 17
    },
    handleClickFilterFields(sharedCropPlan) {
      this.plantingFilterFields = sharedCropPlan.fieldIds

      const cropIdArray = sharedCropPlan.crops.split(",")
      this.numPlantings = cropIdArray.length

      if (cropIdArray.length == 2) {
        this.initialCrop = CROP_KEY[cropIdArray[0]]
        this.secondCrop = CROP_KEY[cropIdArray[1]]
      } else if (cropIdArray.length == 1) {
        this.initialCrop = CROP_KEY[cropIdArray[0]]
      }

      this.el = 2
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleFirstPlantingComplete() {
      if (this.numPlantings > 1) {
        this.el = 10
      } else {
        this.el = 17
      }
    },
    handleSecondPlantingComplete() {
      if (this.numPlantings > 2) {
        // do something
      } else {
        this.el = 17
      }
    },
    async handleSave() {
      const plantingData = {
        numPlantings: this.numPlantings,
        plantings: [],
        planting_crops: [],
      }

      if (this.numPlantings >= 1) {
        const firstPlant = {
          biological: { source: "User Input", value: this.initialBiological },
          cost_per_bag: { source: "User Input", value: this.initialCostPerBag },
          crop_id: {
            source: "User Input",
            value: CROPS[CROP_KEY_DISPLAY_NAME[this.initialCrop]],
          },
          date: { source: "User Input", value: this.initialPlantingDate },
          manufacturer: { source: "User Input", value: "" },
          monitor_type: {
            source: "User Input",
            value: this.initialMonitorType,
          },
          rate: { source: "User Input", value: this.initialSeedingRate },
          seeds_per_bag: {
            source: "User Input",
            value: this.initialSeedsPerBag,
          },
          unit: { source: "User Input", value: this.initialSeedingRateUnits },
          variety: { source: "User Input", value: this.initialVariety },
        }
        plantingData.plantings.push(firstPlant)
        plantingData.planting_crops.push(this.initialCrop)
      }

      if (this.numPlantings >= 2) {
        const secondPlant = {
          biological: { source: "User Input", value: this.secondBiological },
          cost_per_bag: { source: "User Input", value: this.secondCostPerBag },
          crop_id: {
            source: "User Input",
            value: CROPS[CROP_KEY_DISPLAY_NAME[this.secondCrop]],
          },
          date: { source: "User Input", value: this.secondPlantingDate },
          manufacturer: { source: "User Input", value: null },
          monitor_type: {
            source: "User Input",
            value: this.secondMonitorType,
          },
          rate: { source: "User Input", value: this.secondSeedingRate },
          seeds_per_bag: {
            source: "User Input",
            value: this.secondSeedsPerBag,
          },
          unit: { source: "User Input", value: this.secondSeedingRateUnits },
          variety: { source: "User Input", value: this.secondVariety },
        }
        plantingData.plantings.push(secondPlant)
        plantingData.planting_crops.push(this.secondCrop)
      }

      const payload = plantingData.plantings
      const fieldIds = [
        ...new Set(this.plantingSettingsbyFieldFiltered.map(f => f.fieldId)),
      ]

      // switch to loading view
      this.$emit("submit-planting")

      // update and put info
      fieldIds.forEach(fieldId => {
        this.updatePlanting({
          fieldId: fieldId,
          plantingData: payload,
          year: this.year,
          fieldIds: [fieldId],
        })
      })

      // const totalFieldIds = this.plantingSettingsByField.map(f =>
      //   Number(f.fieldId)
      // )
      // refetch planting data and reset to
      this.handleFullReset()
      this.$emit("show-load-step")
      // this.$emit("show-planting", totalFieldIds)
    },
    resetPlantingWiz() {
      this.el = 1
      this.numPlantings = null
      this.initialVariety = null
      this.secondVariety = null
    },
    handleFullReset() {
      this.numPlantings = null

      this.initialCrop = null
      this.initialVariety = null
      this.initialSeedsPerBag = null
      this.initialBiological = null
      this.initialPlantingDate = null
      this.initialSeedingRate = null
      this.initialSeedingRateUnits = null
      this.initialCostPerBag = null
      this.initialMonitorType = ""

      this.secondCrop = null
      this.secondVariety = null
      this.secondSeedsPerBag = null
      this.secondBiological = null
      this.secondPlantingDate = null
      this.secondSeedingRate = null
      this.secondSeedingRateUnits = null
      this.secondCostPerBag = null
      this.secondMonitorType = ""
    },
    // specific value table styling
    tableVariety(variety) {
      if (!variety) return "--"
      if (Array.isArray(variety)) return variety.join(", ")

      return variety
    },
  },
  watch: {
    isWizardShowing: {
      handler() {
        if (this.wizardProgress.planting == "unvisited") {
          this.$emit("visited-planting")
        }
      },
      immediate: true,
    },
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)
      },
    },
    filterFarms: {
      deep: true,
      handler(myArray) {
        this.filterFarmsReflection = _.cloneDeep(myArray)
      },
    },
  },
}
</script>
<style scoped>
.second-q {
  padding-top: 12px;
}

.select-container {
  width: 200px;
}

.small-container {
  width: 60px;
}

.text-container {
  width: 240px;
}

.step-height {
  min-height: 600px;
  max-height: 600px;
  height: 600px;
}

.fixed-table {
  table-layout: fixed;
}

.ten {
  width: 10%;
}

.twenty {
  width: 20%;
}

.cursor {
  cursor: pointer;
}
</style>
