<template>
  <b-tr>
    <!-- date -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(tillage.date)"
    >
      <v-menu
        v-model="showDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="date"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="
                  tillage.date.source !== 'User Input' &&
                  tillage.date.value !== null
                    ? 'mdi-check-circle-outline'
                    : ''
                "
                @click:append="handleClickSubmit('date', date)"
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          :min="yearPriorOne + '-01-01'"
          :max="yearExtendedOne + '-12-31'"
          @input="showDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- depth -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(tillage.depth)"
    >
      <v-text-field
        clearable
        dense
        v-model="depth"
        hide-details="true"
        suffix="in"
        :append-icon="
          tillage.depth.source !== 'User Input' && tillage.depth.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 'depth')"
        @click:append="handleClickSubmit('depth', depth)"
      >
      </v-text-field>
    </b-td>
    <!-- method -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(tillage.method)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="tillageMethodChoices"
        item-text="display_name"
        item-value="value"
        v-model="method"
        :append-icon="
          tillage.method.source !== 'User Input' &&
          tillage.method.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('method', method)"
      />
    </b-td>
    <!-- implement -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(tillage.implement)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="tillageImplementChoices"
        item-text="display_name"
        item-value="value"
        v-model="implement"
        :append-icon="
          tillage.implement.source !== 'User Input' &&
          tillage.implement.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('implement', implement)"
      />
    </b-td>
    <b-td class="cell-table black">
      <v-btn icon x-small color="white" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="white" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations } from "vuex"
import { CROP_DROPDOWN_OPTIONS } from "@/constants"
import {
  TILLAGE_IMPLEMENT_CHOICES,
  TILLAGE_PRACTICE_CHOICES,
} from "@/constants/defaults"
import { Defaults } from "@/store/modules"
import { getClassForCell } from "../utils"

export default {
  name: "TillageRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    tillage: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  data() {
    return {
      cropChoices: CROP_DROPDOWN_OPTIONS,
      tillageImplementChoices: TILLAGE_IMPLEMENT_CHOICES,
      tillageMethodChoices: TILLAGE_PRACTICE_CHOICES,
      showDate: false,
      getClassForCell
    }
  },
  computed: {
    crop: {
      get() {
        return this.tillage.crop_id.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "tillages",
          dataSetting: "crop_id",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    date: {
      get() {
        const dateReturn = this.tillage.date.value
          ? this.tillage.date.value
          : `${this.year}-01-01`
        return dateReturn
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "tillages",
          dataSetting: "date",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    depth: {
      get() {
        return this.tillage.depth.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "tillages",
            dataSetting: "depth",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    method: {
      get() {
        // hotfix for tillage naming conventions issues
        if (typeof this.tillage.method.value === "string") {
          if (this.tillage.method.value.match(/conventional/i)) {
            return "conventional"
          }
          if (this.tillage.method.value.match(/minimal[_\s-]?till/i)) {
            return "minimal-till"
          }
          if (this.tillage.method.value.match(/no[_\s-]?till/i)) {
            return "no-till"
          }
          if (this.tillage.method.value.match(/reduced[_\s-]?till/i)) {
            return "reduced-till"
          }
        }
        return this.tillage.method.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "tillages",
          dataSetting: "method",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    implement: {
      get() {
        return this.tillage.implement.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "tillages",
          dataSetting: "implement",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.tillage)
    },
    handleAdd() {
      const payloadObj = {
        crop_id: { source: "User Input", value: this.cropId },
        date: { source: "User Input", value: null },
        depth: { source: "User Input", value: null },
        implement: { source: "User Input", value: null },
        method: { source: "User Input", value: null },
        residue: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "tillages",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.tillage)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "tillages",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24, range = null) {
      function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return (
          !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ) // ...and ensure strings of whitespace fail
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          if (range !== null) {
            if (val >= range[0] && val <= range[1]) {
              return true
            } else {
              this.$emit(
                "bad-input",
                `Look out! Only a value between ${range[0]} and ${range[1]} can be entered here.`
              )
              return false
            }
          }
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      }
    },
    filter(event, fieldInput) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let range = null
      if (fieldInput === "depth") range = [0, 16]

      let validVal = this.handleValidateValue(expect, "number", 24, range)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.arva-generated {
  background-color: #fedb80;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-table {
  /* padding: 0; */
  padding: 2px 6px;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.machine {
  background-color: #c0dce2;
}
.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.v-input {
  font-size: 18px;
}
</style>
