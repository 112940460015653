<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <!-- Cover Crop Info -->
      <div id="input-card-container">
        <v-card class="my-auto mx-auto" v-if="showCoverCropInfo">
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-3">
              <v-btn
                class="mr-2"
                :input-value="noCoverCrop == false"
                depressed
                @click="handleYesCoverCropClick"
              >
                Cover Crop Planted <v-icon>mdi-check-bold</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                :input-value="noCoverCrop == true"
                depressed
                @click="handleNoCoverCropClick"
              >
                No Cover Crop <v-icon>mdi-close-thick</v-icon>
              </v-btn>
              <v-btn class="ml-auto" @click="handleInfoReset()">
                Reset Cover Crops
              </v-btn>
              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="allCoverCropsComplete().length > 0"
                @click="handleSave()"
              >
                Save and Continue
              </v-btn>
            </v-row>
            <v-row v-if="noCoverCrop == false">
              <v-col md="12">
                <v-card
                  v-for="cc in coverCropData"
                  :key="cc.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                    @click="cc.showAllInfo = !cc.showAllInfo"
                  >
                    Cover Crop #{{ coverCropData.indexOf(cc) + 1 }}
                    <v-icon
                      v-if="!cc.showAllInfo"
                      @click="cc.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="cc.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isCoverCropComplete(cc)
                              ? 'green accent-4'
                              : 'red darken-1'
                          "
                        >
                          {{
                            isCoverCropComplete(cc)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isCoverCropComplete(cc)
                          ? "Application is good to go!"
                          : "You're missing a few fields, please complete this application to save and continue!"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click="handleRemoveCC(coverCropData.indexOf(cc))"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="cc.showAllInfo">
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >On which crop was this applied?<span
                              class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Crop"
                            :disabled="
                              cropSelect ? cropSelect.length == 1 : false
                            "
                            :items="cropChoicesFiltered"
                            v-model="cc.crop_id"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >Cover Crop Planted Date<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="cc.startMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="cc.cover_crop_start_date"
                                label="Planted Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="cc.cover_crop_start_date"
                              :picker-date.sync="cc.startPicker"
                              :min="yearPriorOne + '-01-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="cc.startMenu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >Cover Crop Removal Date<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="cc.endMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="cc.cover_crop_end_date"
                                label="Removal Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="cc.cover_crop_end_date"
                              :picker-date.sync="cc.endPicker"
                              :min="yearPriorOne + '-01-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="cc.endMenu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7"
                            >Cover Crop Family<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Family"
                            :items="familyChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="cc.family"
                            clearable
                          />
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7"
                            >Cover Crop Type<span class="red--text">*</span></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Type"
                            :items="typesByFamily(cc.family)"
                            item-text="display_name"
                            item-value="value"
                            v-model="cc.type"
                            clearable
                          />
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7"
                            >Did you interseed?<span class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="pt-2">
                          <v-btn
                            rounded
                            outlined
                            @click="cc.interseed_cover = true"
                            :input-value="cc.interseed_cover === true"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="cc.interseed_cover = false"
                            :input-value="cc.interseed_cover === false"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn class="mt-4" block @click="handleAddEventClick">
                  Add Cover Crop <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="noCoverCrop == true">
              <v-card class="mx-auto mt-4" elevation="0">
                <v-card-text>
                  <div class="text-center">
                    <b class="text-h5 text-bold"
                      >No Cover Cropping for {{ this.year }}</b
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your cover crop information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddMoreCC">
                Add More Cover Crops
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Cover Crop Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in summaryData" :key="data.idx">
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplayName[data[key].value]
                      }}</span>
                      <span v-else>
                        {{
                          data[key].value
                            ? data[key].value
                            : "None" | cleanSnake
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <!-- Fields, Data Comp, and Add Practices -->
      <v-row class="mt-4 mb-2 row-margin-correct">
        <div>
          <span class="text-h5 ml-1"
            >{{ opsSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              class="mx-1"
              text
              v-for="y in yearListModified"
              :key="y.idx"
              @click="handleSeasonSelection(y)"
              :color="year == y ? 'green' : ''"
              >{{ y }}</v-btn
            >
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn
              :class="showCoverCropInfo ? 'black--text' : 'white--text'"
              :color="showCoverCropInfo ? 'white' : 'green'"
              x-large
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>

      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn
          class="mr-3 all-button"
          @click="handleSelectAll(true, true)"
          text
        >
          <span>All</span>
          <span>({{ opsSettingsByField.length }})</span>
        </v-btn>
        <v-btn
          class="mr-3"
          text
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          :color="
            JSON.stringify(cropSelect) ==
            JSON.stringify(sharedCropPlan.crops.split(','))
              ? 'green'
              : ''
          "
          @click="handleCropFilterButton(sharedCropPlan)"
        >
          <span>{{ mapCropNames(sharedCropPlan.crops) }}</span>
          <span>({{ sharedCropPlan.fieldIds.length }})</span>
        </v-btn>
      </v-row>

      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        ></v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th>Cover Cropping</th>
                    <th class="three">
                      <div>
                        <span>
                          Copy
                          <!-- <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on"
                              >mdi-information</v-icon
                            ></template
                          ><span
                            >Click copy to copy a field's settings quickly to
                            set multiple fields at once.</span
                          ></v-tooltip
                        > -->
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in opsSettingsbyFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="
                          disableCheckbox(field.noYearData) || field.fieldLocked
                        "
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>{{ field.fieldName }}</td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td v-if="'cropNames' in field">
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <td v-else>
                      <div>No Crop</div>
                    </td>
                    <td class="py-2" v-if="!field.noYearData">
                      <div
                        v-for="opsSingle in field.opsInfo"
                        :key="opsSingle.idx"
                      >
                        <v-row
                          class="mx-0"
                          :class="
                            opsSingle.cover_crop.indexOf(covercropInfo) !=
                            opsSingle.length - 1
                              ? 'row-bottom'
                              : ''
                          "
                          dense
                          v-for="covercropInfo in opsSingle.cover_crop.sort(
                            (a, b) => a.crop_id.value - b.crop_id.value
                          )"
                          :key="covercropInfo.idx"
                        >
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <span
                              :class="
                                covercropInfo.cover_crop_start_date.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                covercropInfo.cover_crop_start_date.value
                                  ? covercropInfo.cover_crop_start_date.value
                                  : "No Start"
                              }}
                            </span>
                          </v-col>
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <span
                              :class="
                                covercropInfo.cover_crop_end_date.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                covercropInfo.cover_crop_end_date.value
                                  ? covercropInfo.cover_crop_end_date.value
                                  : "No End"
                              }}
                            </span>
                          </v-col>
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <span
                              :class="
                                covercropInfo.family.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                covercropInfo.family.value
                                  ? covercropInfo.family.value
                                  : "No Family" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <span
                              :class="
                                covercropInfo.type.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                covercropInfo.type.value
                                  ? covercropInfo.type.value
                                  : "No Type" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <span
                              :class="
                                covercropInfo.interseed_cover.value
                                  ? 'black--text'
                                  : 'red--text font-weight-bold'
                              "
                            >
                              {{
                                covercropInfo.interseed_cover.value
                                  ? covercropInfo.interseed_cover.value
                                  : "No Interseed" | cleanSnake
                              }}
                            </span>
                          </v-col>
                          <v-col
                            md="2"
                            v-if="covercropInfo.cover_crop_exists.value == true"
                          >
                            <!-- Data Complete -->
                            <div
                              class="d-flex"
                              v-if="coverComplete(covercropInfo)"
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="success"
                                    >mdi-check-circle</v-icon
                                  ></template
                                ><span
                                  >You've filled out enough information for Arva
                                  data models to run. Thank you!</span
                                ></v-tooltip
                              >
                              <span class="ml-2">Data Complete</span>
                            </div>
                            <!-- Needs Confirmation -->
                            <div
                              class="d-flex"
                              v-else-if="coverNeedsConfirm(covercropInfo)"
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="yellow"
                                    >mdi-alert-rhombus</v-icon
                                  ></template
                                ><span
                                  >This information is coming from a public
                                  source. Please include this field when setting
                                  information to confirm it's data!</span
                                ></v-tooltip
                              >
                              <span
                                class="ml-2 cursor blue--text lighten-1 text-bold"
                                @click="handleConfirm(field)"
                                >Confirm Data</span
                              >
                            </div>
                            <!-- Missing Data -->
                            <div
                              class="d-flex"
                              v-else-if="coverMissingData(covercropInfo)"
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon v-bind="attrs" v-on="on" color="red"
                                    >mdi-alert-circle</v-icon
                                  ></template
                                ><span
                                  >This field is missing data. Please fill in
                                  its data using the wizard!</span
                                ></v-tooltip
                              >
                              <span class="ml-2">Missing Data</span>
                            </div>
                          </v-col>
                          <v-col
                            md="10"
                            v-if="
                              covercropInfo.cover_crop_exists.value == false ||
                              covercropInfo.cover_crop_exists.value == null
                            "
                            >No Cover Cropping</v-col
                          >
                          <v-col
                            md="2"
                            v-if="
                              covercropInfo.cover_crop_exists.value == false ||
                              covercropInfo.cover_crop_exists.value == null
                            "
                          >
                            <div
                              class="d-flex"
                              v-if="
                                covercropInfo.cover_crop_exists.value ==
                                  false &&
                                covercropInfo.cover_crop_exists.source ==
                                  'User Input'
                              "
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="success"
                                    >mdi-check-circle</v-icon
                                  ></template
                                ><span
                                  >You've filled out enough information for Arva
                                  data models to run. Thank you!</span
                                ></v-tooltip
                              >
                              <span class="ml-2">Data Complete</span>
                            </div>
                            <!-- Needs Confirmation -->
                            <div
                              class="d-flex"
                              v-else-if="
                                covercropInfo.cover_crop_exists.value ==
                                  false &&
                                covercropInfo.cover_crop_exists.source !=
                                  'User Input'
                              "
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="yellow"
                                    >mdi-alert-rhombus</v-icon
                                  ></template
                                ><span
                                  >This information is coming from a public
                                  source. Please include this field when setting
                                  information to confirm it's data!</span
                                ></v-tooltip
                              >
                              <span
                                class="ml-2 cursor blue--text lighten-1 text-bold"
                                @click="handleConfirm(field)"
                                >Confirm Data</span
                              >
                            </div>
                            <!-- Missing Data -->
                            <div
                              class="d-flex"
                              v-else-if="
                                covercropInfo.cover_crop_exists.value == null &&
                                covercropInfo.cover_crop_exists.source !=
                                  'User Input'
                              "
                            >
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon v-bind="attrs" v-on="on" color="red"
                                    >mdi-alert-circle</v-icon
                                  ></template
                                ><span
                                  >This field is missing data. Please fill in
                                  its data using the wizard!</span
                                ></v-tooltip
                              >
                              <span class="ml-2">Missing Data</span>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </td>
                    <td class="py-2" v-else>
                      <div @click="handleShowRedirect(field)">
                        <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on" color="red"
                              >mdi-alert-circle</v-icon
                            ></template
                          ><span
                            >This field doesn't have data for this year. Add
                            another year in the data spreadsheet or upload data
                            from this year.</span
                          ></v-tooltip
                        ><span class="ml-2 cursor red--text text-bold"
                          >No Data for Year</span
                        >
                      </div>
                    </td>
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                    <!-- <td>
                      <span>Data Complete Col</span>
                    </td> -->
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <!-- Wizard Confirm Modal -->
      <WizardConfirmModal
        v-if="showConfirmModal"
        :confirmationField="confirmationField"
        :confirmationData="confirmationCoverCrops"
        :wantedKeys="[
          'crop_id',
          'cover_crop_exists',
          'cover_crop_start_date',
          'cover_crop_end_date',
          'family',
          'type',
          'interseed_cover',
        ]"
        :year="year"
        :loadState="loadState"
        @close-template-modal="handleCloseConfirm"
        @confirm-data="handleModalConfirm"
      />

      <!-- Redirect -->
      <WizardRedirectModal
        v-if="showRedirectModal"
        :redirectField="redirectField"
        :year="year"
        @close-template-modal="handleCloseRedirect"
        @redirect-confirm="handleRedirectConfirm"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Organization } from "@/store/modules"
import {
  CROPS,
  CROP_KEY_DISPLAY_NAME,
  CROP_KEY,
  CROP_DISPLAY_NAME,
  YES_NO_OPTIONS,
} from "@/constants"
import {
  COVER_CROP_FAMILY_CHOICES,
  COVER_CROP_GRASS_CHOICES,
  COVER_CROP_LEGUME_CHOICES,
  COVER_CROP_PERENNIAL_CHOICES,
  MAX_DEFAULT_FIELDS,
} from "@/constants/defaults"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardConfirmModal from "@/components/modals/WizardConfirmModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"

export default {
  name: "NewCoverCropWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletion: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    WizardConfirmModal,
    WizardRedirectModal,
  },
  data() {
    return {
      el: 1,
      // constants
      cropDisplay: CROP_DISPLAY_NAME,
      // data values
      noCoverCrop: false,
      numCoverCrops: 0,
      coverCropData: [],
      // options
      cropChoices: Object.values(CROP_KEY),
      familyChoices: COVER_CROP_FAMILY_CHOICES,
      grassChoices: COVER_CROP_GRASS_CHOICES,
      legumeChoices: COVER_CROP_LEGUME_CHOICES,
      perennialChoices: COVER_CROP_PERENNIAL_CHOICES,
      yesNoOptions: YES_NO_OPTIONS,
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      filterFarms: [],
      filterFarmsReflection: [],
      tableFilterText: "",
      tableFilterFields: null,
      selectAllBox: true,
      // full validation for cover crop completeness
      showIncompleteModal: false,
      incompleteCoverCrops: null,
      alertErrorModalOpen: false,
      // confirmation
      confirmationField: null,
      confirmationCoverCrops: null,
      showConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // select crop type UX
      cropSelect: null,
      //   new UI
      showCoverCropInfo: false,
      showPracticesView: true,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      summaryKeys: null,
      summaryData: null,
      cropDisplayName: CROP_DISPLAY_NAME,
    }
  },
  computed: {
    ...mapState({
      opsSettings: state => state.Defaults.newFieldSettings,
    }),
    ...mapGetters({
      recentWizardData: Defaults.Getters.getRecentWizardData,
    }),
    cropChoicesFiltered() {
      if (this.cropSelect != null) {
        const filteredCropList = Object.entries(CROP_KEY)
          .filter(o => {
            if (this.cropSelect.length > 1) {
              return this.cropSelect.includes(o[0])
            } else {
              return true
            }
          })
          .map(list => list[1])
        return filteredCropList
      } else {
        const selectedOpSettings = this.opsSettingsByField.filter(field =>
          this.fieldsInSelection.includes(parseInt(field.fieldId))
        )
        const selectedCrops = new Set(
          selectedOpSettings.flatMap(field => field.crops)
        )
        const selectedCropNames = Array.from(selectedCrops).map(
          c => CROP_KEY[c]
        )
        return selectedCropNames.filter(i => i != undefined)
      }
    },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      let yearDataComps = this.dataCompletionMaster["Cover Cropping"].map(o => {
        if ("by_year" in o.data_comp) {
          let temp = o.data_comp.by_year.find(
            yearObj => yearObj.year == this.year
          )
          if (temp) {
            return temp.avg_for_year
          }
        } else {
          return 0
        }
      })
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.ceil(average(yearDataComps))
      } else {
        return 0
      }
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.opsSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    opsSettingsByField() {
      let opsDict = {}
      let yearInConsderation = this.year

      Object.entries(this.opsSettings).forEach(opsDefault => {
        const fieldKey = opsDefault[0]

        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        opsDict[fieldKey] = {}
        const fieldSettingObj = opsDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
        }

        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsderation
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = ["No Data"]
            opsDict[fieldKey]["cropNames"] = ["No Data"]
            opsDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsderation) {
            opsDict[fieldKey] = idSettings
            opsDict[fieldKey]["crops"] = []
            opsDict[fieldKey]["cropNames"] = []
            opsDict[fieldKey]["cropSearch"] = ""
            opsDict[fieldKey]["plantings"] = []
            opsDict[fieldKey]["opsInfo"] = []

            opsDict[fieldKey]["isSelected"] = this.fieldsInSelection.includes(
              parseInt(fieldKey)
            )
              ? true
              : false

            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            cultivations.forEach(cultivation => {
              opsDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              opsDict[fieldKey]["cropNames"].push(
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              )
              opsDict[fieldKey]["cropSearch"] =
                opsDict[fieldKey]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              opsDict[fieldKey]["plantings"] = opsDict[fieldKey][
                "plantings"
              ].concat(cultivation.plantings)
              opsDict[fieldKey]["opsInfo"].push(cultivation.operations)

              opsDict[fieldKey]["crops"].sort()
              opsDict[fieldKey]["cropNames"].sort()
              opsDict[fieldKey]["fieldLocked"] = cultivation.year_data_locked
              opsDict[fieldKey]["noYearData"] = false
            })
          }
        })
      })
      return Object.values(opsDict)
    },
    opsSettingsbyFieldFiltered() {
      // returns sorted list for table, only reduces list when filtering by name
      let arrayToReturn = _.cloneDeep(this.opsSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["isSelected", "fieldName"]
      const sortDirections = ["desc", "asc"]
      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const something = _(this.opsSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
        }))
        .value()
      return something
    },
    fieldFarms() {
      return [...new Set(this.opsSettingsByField.map(f => f.farmName))]
    },
    filterCropList() {
      return [
        ...new Set(this.opsSettingsByField.map(f => f.cropNames.join(", "))),
      ]
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateCoverCropping: Defaults.Actions.updateCoverCropping,
    }),
    ...mapMutations({
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    handleCopy(field) {
      this.coverCropData = []

      const fieldCoverCropping = []
      field.opsInfo.forEach(opsData => {
        opsData.cover_crop.forEach(cc => fieldCoverCropping.push(cc))
      })
      const numCoverCrops = fieldCoverCropping.length
      this.numCoverCrops = numCoverCrops

      fieldCoverCropping.forEach(cc => {
        if (cc.cover_crop_exists.value == false) {
          this.noCoverCrop = true
        } else {
          this.noCoverCrop = false
          this.coverCropData.push({
            type: cc.type.value,
            family: cc.family.value,
            crop_id: CROP_KEY[cc.crop_id.value],
            grass_cover: cc.grass_cover.value,
            legume_cover: cc.legume_cover.value,
            removal_cover: cc.removal_cover.value,
            interseed_cover: cc.interseed_cover.value,
            cover_crop_exists: cc.cover_crop_exists.value,
            cover_crop_end_date: cc.cover_crop_end_date.value,
            cover_crop_start_date: cc.cover_crop_start_date.value,
            showAllInfo: true,
            startMenu: false,
            startPicker: this.year + "-01",
            endMenu: false,
            endPicker: this.year + "-01",
          })
        }
      })

      this.showCoverCropInfo = true
      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },
    handleNumCoverCrops(num) {
      // reset the number of cover crops then push data
      this.numCoverCrops = 0
      this.coverCropData = []
      const difference = num - this.numCoverCrops
      this.numCoverCrops = num
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null

      for (let i = 0; i < difference; i++) {
        this.coverCropData.push({
          type: null,
          family: null,
          crop_id: cropValue,
          grass_cover: null,
          legume_cover: null,
          removal_cover: null,
          interseed_cover: false,
          cover_crop_exists: null,
          cover_crop_end_date: null,
          cover_crop_start_date: null,
          // for UI control
          showAllInfo: false,
          startMenu: false, // boolean for v-menu
          startPicker: this.year + "-01",
          endMenu: false,
          endPicker: this.year + "-01",
        })
      }

      this.el = 4
    },
    isCoverCropComplete(cc) {
      return (
        cc.type &&
        cc.family &&
        cc.crop_id &&
        cc.interseed_cover != null &&
        cc.cover_crop_end_date &&
        cc.cover_crop_start_date
      )
    },
    allCoverCropsComplete() {
      if (!this.coverCropData) return [0]
      const covercropCompleteness = this.coverCropData.map(cc =>
        this.isCoverCropComplete(cc)
      )
      const covercropsMissing = covercropCompleteness.map((value, index) =>
        value == null ? index : ""
      )
      const indexOfMissing = covercropsMissing.filter(String)
      return indexOfMissing
    },
    handleRemoveCC(ccIndex) {
      this.coverCropData.splice(ccIndex, 1)
      if (this.coverCropData.length == 0) {
        this.noCoverCrop = true
      }
    },
    handleInfoReset() {
      this.noCoverCrop = false
      this.coverCropData = []
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null

      this.coverCropData.push({
        type: null,
        family: null,
        crop_id: cropValue,
        grass_cover: null,
        legume_cover: null,
        removal_cover: null,
        interseed_cover: false,
        cover_crop_exists: null,
        cover_crop_end_date: null,
        cover_crop_start_date: null,
        // for UI control
        showAllInfo: false,
        startMenu: false, // boolean for v-menu
        startPicker: this.year + "-01",
        endMenu: false,
        endPicker: this.year + "-01",
      })
    },
    async handleSave() {
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }
      let ccPayload = null
      if (this.noCoverCrop == true) {
        const fieldsBeingSaved = this.opsSettingsbyFieldFiltered.filter(f =>
          this.fieldsInSelection.includes(Number(f.fieldId))
        )
        const cropsBeingSaved = fieldsBeingSaved
          .map(f => f.crops)
          .reduce((acc, arr) => acc.concat(arr), [])
          .filter((item, index, self) => self.indexOf(item) === index)
        ccPayload = []
        cropsBeingSaved.forEach(cropId => {
          ccPayload.push({
            cover_crop_exists: { value: false, source: "User Input" },
            crop_id: { value: cropId, source: "User Input" },
            grass_cover: { value: null, source: "User Input" },
            legume_cover: { value: null, source: "User Input" },
            interseed_cover: { value: false, source: "User Input" },
            cover_crop_start_date: {
              value: `${this.year}-01-01`,
              source: "User Input",
            },
            cover_crop_end_date: {
              value: `${this.year}-01-01`,
              source: "User Input",
            },
            perennial_cover: { value: null, source: "User Input" },
            removal_cover: { value: null, source: "User Input" },
            type: { value: "none", source: "User Input" },
            family: { value: "none", source: "User Input" },
          })
        })
      } else {
        const covercropCompleteness = this.coverCropData.map(cc =>
          this.isCoverCropComplete(cc)
        )
        const ccMissing = covercropCompleteness.map((value, index) =>
          value == null ? index : ""
        )
        const indexOfMissing = ccMissing.filter(String)

        // which cc missing info
        if (indexOfMissing.length > 0) {
          this.incompleteCoverCrops = indexOfMissing
          this.showIncompleteModal = true
          return
        } else {
          if (this.coverCropData.length > 0) {
            let dataClone = _.cloneDeep(this.coverCropData)
            ccPayload = dataClone.map(cc => {
              delete cc["showAllInfo"]
              delete cc["startMenu"]
              delete cc["startPicker"]
              delete cc["endMenu"]
              delete cc["endPicker"]

              Object.keys(cc).forEach(ccKey => {
                if (ccKey == "cover_crop_exists") {
                  // check for non null/None family value
                  if (cc["family"] != null && cc["family"] != "None") {
                    cc[ccKey] = { value: true, source: "User Input" }
                  } else {
                    cc[ccKey] = { value: false, source: "User Input" }
                  }
                } else if (ccKey == "crop_id") {
                  cc[ccKey] = {
                    value: CROPS[CROP_KEY_DISPLAY_NAME[cc[ccKey]]],
                    source: "User Input",
                  }
                } else {
                  cc[ccKey] = {
                    value: cc[ccKey],
                    source: "User Input",
                  }
                }
              })
              return cc
            })
          }
        }
      }

      this.showPracticesView = false
      this.showSaveView = true

      let coverCroppingUpdates = []
      this.opsSettingsbyFieldFiltered.forEach(async field => {
        if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
          if (!field.noYearData && !field.fieldLocked) {
            coverCroppingUpdates.push(
              this.updateCoverCropping({
                fieldId: field.fieldId,
                year: this.year,
                coverCropping: ccPayload,
                noCoverCrop: this.noCoverCrop ? true : false,
              })
            )
          } else {
            console.log("no year data for ", field)
          }
        }
      })
      await Promise.all(coverCroppingUpdates)
      this.$emit("update-data-complete")

      // end loading view, return to home
      const fieldNames = this.opsSettingsbyFieldFiltered
        .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
        .map(f => f.fieldName)
      const coverCropWantedKeys = [
        "crop_id",
        "cover_crop_exists",
        "cover_crop_start_date",
        "cover_crop_end_date",
        "family",
        "type",
        "interseed_cover",
      ]
      this.setRecentWizardData({
        wizard: "covercrop",
        data: this.coverCropData,
      })

      this.summaryKeys = coverCropWantedKeys
      this.summaryData = ccPayload
      this.summaryFields = fieldNames
      this.showSaveView = false
      this.showSummaryView = true
    },
    handleConfirm(field) {
      this.confirmationField = _.cloneDeep(field)
      this.confirmationCoverCrops = []
      field.opsInfo.forEach(ops => {
        ops.cover_crop.forEach(cc => {
          this.confirmationCoverCrops.push(_.cloneDeep(cc))
        })
      })
      this.confirmationCoverCrops.forEach(cc => {
        for (const key in cc) {
          cc[key].source = "User Input"
        }
      })
      this.showConfirmModal = true
    },
    async handleModalConfirm() {
      this.loadState = "loading"
      const noCoverCropConfirm =
        !this.confirmationCoverCrops[0]["cover_crop_exists"]["value"]
      if (noCoverCropConfirm == true) {
        let confirmLength = this.confirmationCoverCrops.length
        this.confirmationCoverCrops = []
        for (let i = 0; i < confirmLength; i++) {
          this.confirmationCoverCrops.push({
            cover_crop_exists: { value: false, source: "User Input" },
            crop_id: {
              value: this.confirmationField.crops[i],
              source: "User Input",
            },
            grass_cover: { value: null, source: "User Input" },
            legume_cover: { value: null, source: "User Input" },
            interseed_cover: { value: false, source: "User Input" },
            cover_crop_start_date: {
              value: `${this.year}-01-01`,
              source: "User Input",
            },
            cover_crop_end_date: {
              value: `${this.year}-01-01`,
              source: "User Input",
            },
            perennial_cover: { value: null, source: "User Input" },
            removal_cover: { value: null, source: "User Input" },
            type: { value: "none", source: "User Input" },
            family: { value: "none", source: "User Input" },
          })
        }
      }
      await this.updateCoverCropping({
        fieldId: this.confirmationField.fieldId,
        year: this.year,
        coverCropping: this.confirmationCoverCrops,
        noCoverCrop: noCoverCropConfirm,
      })
      this.loadState = "confirmed"
      this.$emit("update-data-complete")
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.handleCloseConfirm()
    },
    handleCloseConfirm() {
      this.showConfirmModal = false
      this.confirmationField = null
      this.confirmationCoverCrops = null
      this.loadState = null
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (e == true) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    coverComplete(cover) {
      if (!cover) return null
      return (
        cover.cover_crop_start_date.source == "User Input" &&
        cover.cover_crop_start_date.value != null &&
        cover.cover_crop_end_date.source == "User Input" &&
        cover.cover_crop_end_date.value != null &&
        cover.family.source == "User Input" &&
        cover.family.value != null &&
        cover.type.source == "User Input" &&
        cover.type.value != null &&
        cover.interseed_cover.source == "User Input" &&
        cover.interseed_cover.value != null
      )
    },
    coverNeedsConfirm(cover) {
      if (!cover) return null
      return (
        cover.cover_crop_start_date.source != "User Input" &&
        cover.cover_crop_start_date.value != null &&
        cover.cover_crop_end_date.source != "User Input" &&
        cover.cover_crop_end_date.value != null &&
        cover.family.source != "User Input" &&
        cover.family.value != null &&
        cover.type.source != "User Input" &&
        cover.type.value != null &&
        cover.interseed_cover.source != "User Input" &&
        cover.interseed_cover.value != null
      )
    },
    coverMissingData(cover) {
      if (!cover) return null
      return (
        cover.cover_crop_start_date.source != "User Input" ||
        cover.cover_crop_start_date.value == null ||
        cover.cover_crop_end_date.source != "User Input" ||
        cover.cover_crop_end_date.value == null ||
        cover.family.source != "User Input" ||
        cover.family.value == null ||
        cover.type.source != "User Input" ||
        cover.type.value == null ||
        cover.interseed_cover.source != "User Input" ||
        cover.interseed_cover.value == null
      )
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    typesByFamily(family) {
      if (family == "grass") {
        return this.grassChoices
      }
      if (family == "legume") {
        return this.legumeChoices
      }
      if (family == "perennial") {
        return this.perennialChoices
      }
      return []
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
        if (this.coverCropData.length > 0) {
          const cropValue =
            this.cropSelect != null && this.cropSelect.length == 1
              ? CROP_KEY[parseInt(this.cropSelect[0])]
              : null
          this.coverCropData.forEach(o => {
            o.crop_id = cropValue
          })
        }
      }
    },
    handleAddPracticesClick() {
      if (this.showCoverCropInfo == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        this.showCoverCropInfo = true
        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        this.coverCropData = [
          {
            type: null,
            family: null,
            crop_id: cropValue,
            grass_cover: null,
            legume_cover: null,
            removal_cover: null,
            interseed_cover: false,
            cover_crop_exists: null,
            cover_crop_end_date: null,
            cover_crop_start_date: null,
            // for UI control
            showAllInfo: true,
            startMenu: false, // boolean for v-menu
            startPicker: this.year + "-01",
            endMenu: false,
            endPicker: this.year + "-01",
          },
        ]
      } else {
        this.showCoverCropInfo = false
        this.coverCropData = []
      }
    },
    handleAddEventClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.coverCropData.push({
        type: null,
        family: null,
        crop_id: cropValue,
        grass_cover: null,
        legume_cover: null,
        removal_cover: null,
        interseed_cover: false,
        cover_crop_exists: null,
        cover_crop_end_date: null,
        cover_crop_start_date: null,
        // for UI control
        showAllInfo: false,
        startMenu: false, // boolean for v-menu
        startPicker: this.year + "-01",
        endMenu: false,
        endPicker: this.year + "-01",
      })
    },
    handleNoCoverCropClick() {
      this.coverCropData = []
      this.noCoverCrop = true
    },
    handleYesCoverCropClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null

      this.coverCropData = [
        {
          type: null,
          family: null,
          crop_id: cropValue,
          grass_cover: null,
          legume_cover: null,
          removal_cover: null,
          interseed_cover: false,
          cover_crop_exists: null,
          cover_crop_end_date: null,
          cover_crop_start_date: null,
          // for UI control
          showAllInfo: false,
          startMenu: false, // boolean for v-menu
          startPicker: this.year + "-01",
          endMenu: false,
          endPicker: this.year + "-01",
        },
      ]
      this.noCoverCrop = false
    },
    handleAddMoreCC() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
  },
  mounted() {
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f])
    if (this.recentWizardData.wizard == "covercrop") {
      this.numCoverCrops = this.recentWizardData.data.length
      this.coverCropData = _.cloneDeep(this.recentWizardData.data)
    }
  },
  watch: {
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.opsSettingsByField)
        if (this.filterFarmsReflection.length > 0) {
          arrayToFilter = arrayToFilter.filter(field =>
            this.filterFarms.includes(field.farmName)
          )
        }
        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }
        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
    filterFarms: {
      deep: true,
      handler(myArray) {
        this.filterFarmsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.opsSettingsByField)
        if (this.filterFarmsReflection.length > 0) {
          arrayToFilter = arrayToFilter.filter(field =>
            this.filterFarms.includes(field.farmName)
          )
        }
        if (this.filterCropsReflection.length > 0) {
          arrayToFilter = arrayToFilter.filter(field =>
            this.filterCropsReflection.includes(field.cropNames.join(", "))
          )
        }
        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
    el(newEl) {
      if (newEl == 1) {
        this.cropSelect = null
      }
    },
  },
}
</script>
<style scoped>
.second-q {
  padding-top: 48px;
}

.input-date-text {
  font-weight: 700;
  font-size: 22px;
}

.step-height {
  min-height: 400px;
  max-height: 400px;
  height: 400px;
}

.min-step-height {
  min-height: 400px;
}

.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.text-container {
  width: 200px;
}

.fixed-table {
  table-layout: fixed;
}

.three {
  width: 3%;
}

.five {
  width: 5%;
}

.ten {
  width: 10%;
}

.twenty {
  width: 20%;
}

.twenty {
  width: 20%;
}

.cursor {
  cursor: pointer;
}
</style>
