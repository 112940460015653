<template>
  <div class="row reverse-margin">
    <div class="col scroll-col">
      <!-- button container -->
      <div class="d-flex mb-3">
        <DashboardTitle
          title="Soil Sampling Without Carbon Enrollment"
          faClass="fa-globe"
        />
      </div>

      <v-card>
        <v-toolbar flat>
          <template v-slot:extension>
            <v-tabs grow v-model="tab" align-with-title>
              <v-tabs-slider color="green"></v-tabs-slider>
              <v-tab v-for="item in tabTitles" :key="item.index">
                {{ item.name }}
              </v-tab>
            </v-tabs>
          </template>

          <v-btn class="ml-auto" @click="handleHideShowMap"
            >{{ showMap ? "Hide Map " : "Show Map " }}
            <v-icon v-if="showMap">mdi-map-minus</v-icon
            ><v-icon v-else>mdi-map-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabTitles" :key="item.index">
            <v-card flat>
              <div v-if="item.name === 'Fields'" class="pt-3">
                <div class="d-flex">
                  <v-btn class="ml-auto mr-4" @click="viewCreatePlan">
                    Create New Plan
                  </v-btn>
                </div>

                <FieldImageryListView
                  :soilSamplingView="true"
                  :tabView="true"
                  :zonesView="true"
                  @row-clicked="handleFieldListClick"
                />
              </div>
              <div v-if="item.name === 'Plans'" class="pt-3">
                <!-- yield zone controller -->
                <EnrollController
                  :eligibleCollectors="eligibleCollectors"
                  :eligibleLabs="eligibleLabs"
                  :activeFields="activeFields"
                  :tab="tab"
                  :samplingPlans="samplingPlans"
                  :samplingControllerView="samplingControllerView"
                  :finishedLoading="finishedLoading"
                  :selectedEnrollment="selectedEnrollment"
                  :showPlansByEnrollment="showPlansByEnrollment"
                  @create-plan-for-enroll="handleCreateEnrollPlan"
                  @select-fields="toggleZoneFields"
                  @set-view-list="setControllerView"
                  @update-list="fetchSamplingPlans"
                  @update-zones="updateZoneGeoJSON"
                  @reset-zones="resetZoneGeoJSON"
                  @update-map-params="updateMapParams"
                  @hide-switch="hideSwitch"
                  @map-zoom="mapZoom"
                />
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <transition name="slide" v-if="mapShowCounter > 0">
      <div v-show="showMap" class="col map-col">
        <div class="map-wrapper" oncontextmenu="return false">
          <LeafletMap
            :boundaries="fieldBoundaries"
            :fields="fields"
            :isYield="isYield"
            :zoneGeoJSON="zoneGeoJSON"
            :mapParams="mapParams"
            :mapStart="mapStart"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
//import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import LeafletMap from "@/components/map/LeafletMap"
import DashboardTitle from "@/components/dashboard/DashboardTitle"

//import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import SamplingAPI from "@/api/SamplingAPI"
// import CarbonReadyFieldRow from "@/components/carbonReadyField/CarbonReadyFieldRow"
import FieldImageryListView from "@/views/FieldImageryListView"
import EnrollController from "@/components/sampling/EnrollController"

import { Enrollment, Fields, Filter, SoilSampling } from "@/store/modules"

export default {
  name: "EnrollmentManagement",
  props: ["isYield"],
  components: {
    // CarbonReadyFieldRow,
    FieldImageryListView,
    EnrollController,
    LeafletMap,
    DashboardTitle,
  },
  data() {
    return {
      tab: 0,
      tabTitles: [
        {
          index: 0,
          name: "Fields",
        },
        {
          index: 1,
          name: "Plans",
        },
      ],
      samplingControllerView: "list",
      zoneGeoJSON: null,
      eligibleCollectors: [],
      eligibleLabs: [],
      order_id: null,
      fetchPlansInterval: null,
      finishedLoading: false,
      showMap: false,
      // enrollment changes
      enrollments: null,
      enrolledFields: [],
      regenPracticeOptions: [],
      newEnrollmentStartDate: new Date(),
      selectedEnrollment: null,
      showPlansByEnrollment: false,
      confirmEnrollModalOpen: false,
      // enrollment sorting
      sortById: null,
      sortByName: null,
      sortByFields: null,
      sortByAcres: null,
      sortByDate: null,
      sortByStatus: null,
      deleteEnrollmentModalOpen: false,
      enrollmentToDelete: {},
      hideSwitchBool: false,
      mapShowCounter: 0,
      mapStart: null,
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      organization: state => state.Organization.organization,
      samplingPlans: state => state.SoilSampling.samplingPlans,
      selectedFields: state => state.Filter.selectedFields,
      fieldBoundaries: state => state.Map.fieldBoundaries,
      previousEnrollment: state => state.Enrollment.previousEnrollment,
      user: state => state.User.user,
    }),

    mapParams() {
      let tempParams = {}
      tempParams["order_id"] = this.order_id
      tempParams["category"] = "soil_samples"

      if (this.order_id != null) {
        tempParams["useMap"] = true
      } else {
        tempParams["useMap"] = false
      }
      return tempParams
    },

    fields() {
      const activeFieldIds = this.activeFields.map(field => field.id)
      const filteredBounds =
        activeFieldIds.length > 0
          ? this.fieldBoundaries.filter(row =>
              activeFieldIds.includes(row.properties.field.id)
            )
          : this.fieldBoundaries
      return filteredBounds
    },

    canApprove() {
      return this.user.can_approve_enrollment
    },
  },
  methods: {
    ...mapActions({
      fetchSamplingPlans: SoilSampling.Actions.fetchSamplePlans,
      fetchFields: Fields.Actions.fetchFields,
    }),

    ...mapMutations({
      setSelectedFields: Filter.Mutations.setSelectedFields,
      setPreviousEnrollment: Enrollment.Mutations.setPreviousEnrollment,
      clearPreviousEnrollment: Enrollment.Mutations.clearPreviousEnrollment,
    }),

    fetchSamplingPlansForInterval() {
      this.fetchSamplingPlans(this.organization.id)
    },

    resetZoneGeoJSON() {
      this.zoneGeoJSON = []
      this.order_id = null
    },

    updateZoneGeoJSON(d) {
      this.zoneGeoJSON = d
    },

    updateMapParams(id) {
      this.order_id = id
    },

    setControllerView(view) {
      this.samplingControllerView = view
    },

    handleCreateEnrollPlan() {
      this.viewCreatePlan()
    },

    async viewCreatePlan() {
      this.samplingControllerView = "create"
      this.tab = 1
      const payload = {
        field_ids: this.activeFields.map(field => field.id),
      }
      await SamplingAPI.postSoilLabs(payload).then(response => {
        this.eligibleLabs = response.data
        this.eligibleLabs.forEach(function(obj) {
          obj.short_name = obj.name

          obj.name = obj.name + " (" + obj.address + ")"
        })
      })
      await SamplingAPI.postSoilCollectors(payload).then(response => {
        this.eligibleCollectors = response.data
        this.eligibleCollectors.forEach(function(obj) {
          obj.short_name = obj.name

          obj.name = obj.name + " (" + obj.address + ")"
        })
      })
    },

    hideSwitch(val) {
      this.hideSwitchBool = val
    },

    toggleZoneFields(fieldIds) {
      const selFields = this.selectedFields
      for (const fieldId in selFields) {
        if (fieldIds.includes(Number(fieldId))) {
          selFields[fieldId] = true
        } else {
          selFields[fieldId] = false
        }
      }

      this.setSelectedFields(fieldIds)
    },

    handleConfirm(rolment) {
      this.selectedEnrollment = rolment
      this.confirmEnrollModalOpen = true
    },

    handleFieldListClick(fieldId) {
      if (this.selectedEnrollment) {
        const enrollmentFieldIds = this.selectedEnrollment[
          "enrollment_fields"
        ].map(ef => ef.field_id)
        if (!enrollmentFieldIds.includes(fieldId)) {
          this.selectedEnrollment = null
        }
      }
    },

    // enrollment row coloring
    rowColor(enrollment) {
      if (enrollment) {
        if (enrollment.status == "requested") {
          return "request-color"
        }
        if (enrollment.status == "approved") {
          return "approved-color"
        }
        if (enrollment.status == "confirmed") {
          return "confirmed-color"
        }
      }
      return ""
    },

    backColor(enrollment) {
      if (enrollment) {
        if (enrollment.status == "requested") {
          return "request-color-back"
        }
        if (enrollment.status == "approved") {
          return "approved-color-back"
        }
        if (enrollment.status == "confirmed") {
          return "confirmed-color-back"
        }
      }
      return ""
    },

    // sorting enrollments
    sortIcon(sortValue) {
      if (sortValue === "asc") return "fa-sort-up"
      else if (sortValue === "desc") return "fa-sort-down"
      return "fa-sort"
    },

    handleSortId() {
      if (!this.sortById) this.sortById = "asc"
      else if (this.sortById === "asc") this.sortById = "desc"
      else if (this.sortById === "desc") this.sortById = null
    },

    handleSortName() {
      if (!this.sortByName) this.sortByName = "asc"
      else if (this.sortByName === "asc") this.sortByName = "desc"
      else if (this.sortByName === "desc") this.sortByName = null
    },

    handleSortFields() {
      if (!this.sortByFields) this.sortByFields = "asc"
      else if (this.sortByFields === "asc") this.sortByFields = "desc"
      else if (this.sortByFields === "desc") this.sortByFields = null
    },

    handleSortAcres() {
      if (!this.sortByAcres) this.sortByAcres = "asc"
      else if (this.sortByAcres === "asc") this.sortByAcres = "desc"
      else if (this.sortByAcres === "desc") this.sortByAcres = null
    },

    handleSortDate() {
      if (!this.sortByDate) this.sortByDate = "asc"
      else if (this.sortByDate === "asc") this.sortByDate = "desc"
      else if (this.sortByDate === "desc") this.sortByDate = null
    },

    handleSortStatus() {
      if (!this.sortByStatus) this.sortByStatus = "asc"
      else if (this.sortByStatus === "asc") this.sortByStatus = "desc"
      else if (this.sortByStatus === "desc") this.sortByStatus = null
    },
    cancelEnrollment(enrollment) {
      this.enrollmentToDelete = enrollment
      this.deleteEnrollmentModalOpen = true
    },
    closeDeleteEnrollmentModal() {
      this.deleteEnrollmentModalOpen = false
    },
    handleHideShowMap() {
      this.showMap = !this.showMap
      this.mapShowCounter = this.mapShowCounter + 1
    },
    mapZoom(resp) {
      this.mapStart = resp
      this.showMap = true
      this.mapShowCounter = this.mapShowCounter + 1
    },
  },
  async mounted() {
    // await this.fetchEnrollmentFields()
    if (
      this.$router.history.router.prevRoute.path.includes("/data-spreadsheet")
    ) {
      this.selectedEnrollment = this.previousEnrollment
      this.confirmEnrollModalOpen = true
      this.clearPreviousEnrollment()
    }

    const soilCollecorsPayload = {
      field_ids: this.activeFields.map(field => field.id),
    }
    await SamplingAPI.postSoilCollectors(soilCollecorsPayload).then(response => {
      this.eligibleCollectors = response.data
      this.eligibleCollectors.forEach(function(obj) {
        obj.short_name = obj.name

        obj.name = obj.name + " (" + obj.address + ")"
      })
    })

    /*await this.fetchEnrollments()
    this.fetchPlansInterval = setInterval(
      this.fetchSamplingPlansForInterval,
      90000
    )*/
  },

  destroyed() {
    if (this.fetchPlansInterval) clearInterval(this.fetchPlansInterval)
  },

  watch: {
    tab(newTab, oldTab) {
      if (oldTab === 1 || newTab !== 1) {
        this.zoneGeoJSON = []
      }
    },
    fetchPlansInterval() {
      this.finishedLoading = true
    },
    activeFields() {
      if (this.eligibleCollectors.length < 1) {
        const payload = {
          field_ids: this.activeFields.map(field => field.id),
        }
        SamplingAPI.postSoilLabs(payload).then(response => {
          this.eligibleLabs = response.data
          this.eligibleLabs.forEach(function(obj) {
            obj.short_name = obj.name

            obj.name = obj.name + " (" + obj.address + ")"
          })
        })
        SamplingAPI.postSoilCollectors(payload).then(response => {
          this.eligibleCollectors = response.data
          this.eligibleCollectors.forEach(function(obj) {
            obj.short_name = obj.name

            obj.name = obj.name + " (" + obj.address + ")"
          })
        })
      }
    },
  },
}
</script>

<style scoped>
h2 {
  font-weight: bold;
  border-bottom: 1pt solid #bbb;
  padding-bottom: 9px;
  margin-bottom: 20px;
}

.pointer {
  cursor: pointer;
}

.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}

.map-col {
  padding-left: 0px;
}

.map-wrapper {
  height: calc(100vh - 65px);
  width: 100%;
  margin-top: -30px;
  margin-bottom: -17px;
  position: relative;
  background: #1b1b1d;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scroll-col {
  max-height: 85vh;
  overflow: scroll;
  padding-right: 40px;
}

.reverse-margin {
  margin-bottom: -60px;
}

.single {
  font-weight: bold;
  color: green;
}

.comparison {
  font-weight: bold;
  color: purple;
}

.loading-text {
  font-weight: bold;
  font-size: 16px;
}

.info {
  color: #666666;
}

.thirty {
  width: 30%;
}

.sel-enroll {
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
}

.available-ball {
  background: #000000;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.requested-ball {
  background: #850000;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.approved-ball {
  background: #ccb33b;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.confirmed-ball {
  background: rgb(11, 217, 39);
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.request-color {
  font-weight: 500;
  color: #850000;
}

.approved-color {
  font-weight: 500;
  color: #ccb33b;
}

.confirmed-color {
  font-weight: 500;
  color: #72cc6b;
}

.date-chip {
  width: 125px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  margin: 5px 0px;
}

.request-color-back {
  background-color: #850000;
}

.approved-color-back {
  background-color: #ccb33b;
}

.confirmed-color-back {
  background-color: #72cc6b;
}

.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
</style>
