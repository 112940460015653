import Vue from "vue"
import Router from "vue-router"

import AccountView from "@/views/AccountView"
import SuperUserAdminView from "@/views/SuperUserAdminView"
import CustomerAdminView from "@/views/CustomerAdminView"
import DataSharingView from "@/views/DataSharingView"
import SwitchOrgsView from "@/views/SwitchOrgsView"
import AgtView from "@/views/AgtView"
import CropDesigner from "@/views/CropDesigner"
import DataSpreadsheet from "@/views/DataSpreadsheet"
import DashboardSummary from "@/views/DashboardSummary"
import AgtPerformance from "@/views/AgtPerformance"
import DefaultsList from "@/views/DefaultsList"
import DefaultsSettings from "@/views/DefaultsSettings"
import EmptyRouterView from "@/views/EmptyRouterView"
import FieldImageryView from "@/views/FieldImageryView"
import FieldImageryListView from "@/views/FieldImageryListView"
import FieldList from "@/views/FieldList"
import FieldReportsList from "@/views/FieldReportsList"
import FieldView from "@/views/FieldView"
import HistoricalProfitAndLoss from "@/views/HistoricalProfitAndLoss"
import HybridPlacementView from "@/views/HybridPlacementView"
import IndividualFieldImageryView from "@/views/IndividualFieldImageryView"
import JohnDeereIntegration from "@/views/JohnDeereIntegration"
import Login from "@/views/Login"
import MapView from "@/views/MapView"
import BoundaryEditorView from "@/views/BoundaryEditorView"
import OrderImageryView from "@/views/OrderImageryView"
import OrderTrialReport from "@/views/OrderTrialReport"
import PDFGeneratorView from "@/views/PDFGeneratorView"
import PDFTrialReportView from "@/views/PDFTrialReportView"
import PDFSoilLabelView from "@/views/PDFSoilLabel"

import ProfitAndLoss from "@/views/ProfitAndLoss"
import SeedPlacementsList from "@/views/SeedPlacementsList"
import SeedPlacementPlan from "@/views/SeedPlacementPlan"
import SeedSelectorView from "@/views/SeedSelectorView"

import SoilSampling from "@/views/SoilSampling"
import EnrollmentManagement from "@/views/EnrollmentManagement"

import HistoricalPracticesLandingView from "@/views/HistoricalPracticesLandingView"
import EnrollFields from "@/views/EnrollFields"

import Sustainability from "@/views/Sustainability"
import Uploads from "@/views/Uploads"
import ZonesYield from "@/views/ZonesYield"

import PracticeConfirmationsView from "@/views/PracticeConfirmationsView"
import PracticeConfirmationsUploader from "@/views/PracticeConfirmationsUploader"

const routes = [
  {
    path: "/",
    redirect: { name: "MapView" },
  },
  {
    path: "/map",
    name: "MapView",
    component: MapView,
  },
  {
    path: "/boundary-editor",
    name: "boundary-editor",
    component: BoundaryEditorView,
  },
  {
    path: "/fields",
    name: "fields",
    component: FieldList,
  },
  {
    path: "/field-reports",
    name: "field-reports",
    component: FieldReportsList,
  },
  {
    path: "/uploads",
    name: "uploads",
    component: Uploads,
  },
  {
    path: "/settings/",
    component: EmptyRouterView,
    children: [
      {
        path: "defaults",
        name: "defaults-list",
        component: DefaultsList,
      },
      {
        path: "defaults/:defaultsTemplateId",
        name: "defaults-settings",
        component: DefaultsSettings,
        props: true,
      },
    ],
  },
  {
    path: "/field/:fieldId",
    name: "field",
    component: FieldView,
    props: true,
  },
  {
    path: "/dashboard-summary",
    name: "dashboard-summary",
    component: DashboardSummary,
  },
  {
    path: "/seed-placements",
    name: "seed-placements-list",
    component: SeedPlacementsList,
  },
  {
    path: "/seed-placements/:placementPlanId",
    name: "seed-placement-plan",
    component: SeedPlacementPlan,
    props: true,
  },
  {
    path: "/seed-selector",
    name: "seed-selector",
    component: SeedSelectorView,
  },
  {
    path: "/agt-performance",
    name: "agt-performance",
    component: AgtPerformance,
  },
  {
    path: "/agts",
    name: "agts",
    component: AgtView,
  },
  {
    path: "/arva-crop-designer",
    name: "arva-crop-designer",
    component: CropDesigner,
  },
  {
    path: "/profit-and-loss",
    name: "profit-and-loss",
    component: ProfitAndLoss,
  },
  {
    path: "/sustainability",
    name: "sustainability",
    component: Sustainability,
  },
  {
    path: "/historical-profit-and-loss",
    name: "historical-profit-and-loss",
    component: HistoricalProfitAndLoss,
  },
  {
    path: "/hybrid-placement",
    name: "hybrid-placement",
    component: HybridPlacementView,
  },
  {
    path: "/field-imagery",
    name: "field-imagery",
    component: FieldImageryView,
  },
  {
    path: "/field-satellite/:fieldId",
    name: "field-satellite",
    component: IndividualFieldImageryView,
    props: true,
  },
  {
    path: "/order-imagery",
    name: "order-imagery",
    component: OrderImageryView,
  },
  {
    path: "/imagery-list",
    name: "imagery-list",
    component: FieldImageryListView,
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
  },
  {
    path: "/superuser-admin",
    name: "superuser-admin",
    component: SuperUserAdminView,
  },
  {
    path: "/customer-admin",
    name: "customer-admin",
    component: CustomerAdminView,
  },
  {
    path: "/data-sharing",
    name: "data-sharing",
    component: DataSharingView,
  },
  {
    path: "/switch-orgs",
    name: "switch-orgs",
    component: SwitchOrgsView,
  },
  {
    path: "/arva-pdf-generator/:reportHash",
    name: "arva-pdf-generator",
    component: PDFGeneratorView,
    props: true,
  },
  {
    path: "/trial-title-page/:reportHash",
    name: "trial-title-page",
    component: PDFTrialReportView,
    props: true,
  },
  {
    path: "/trial-report-generator/:page/:reportHash",
    name: "trial-report-generator",
    component: PDFTrialReportView,
    props: true,
  },
  {
    path: "/trial-env-generator/:pageNum/:reportHash",
    name: "trial-env-generator",
    component: PDFTrialReportView,
    props: true,
  },
  {
    path: "/trial-rollup-generator/:reportHash",
    name: "trial-rollup-generator",
    component: PDFTrialReportView,
    props: true,
  },
  {
    path: "/trial-microclimate/:reportHash",
    name: "trial-microclimate",
    component: PDFTrialReportView,
    props: true,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/integrations/john-deere",
    name: "john-deere",
    component: JohnDeereIntegration,
  },
  {
    path: "/data-spreadsheet",
    name: "data-spreadsheet",
    component: DataSpreadsheet,
  },
  {
    path: "/data-spreadsheet/:fieldId",
    name: "data-spreadsheet-field",
    component: DataSpreadsheet,
    props: true,
  },
  {
    path: "/zones-agt",
    name: "zones-agt",
    component: ZonesYield,
    props: { isYield: false },
  },
  {
    path: "/zones-yield",
    name: "zones-yield",
    component: ZonesYield,
    props: { isYield: true },
  },
  {
    path: "/order-trial-report",
    name: "order-trial-report",
    component: OrderTrialReport,
  },
  {
    path: "/soil-sample-ui",
    name: "soil-sample-ui",
    component: SoilSampling,
  },
  {
    path: "/enroll-manager",
    name: "enroll-manager",
    component: EnrollmentManagement,
  },
  {
    path: "/enroll-fields",
    name: "enroll-fields",
    component: EnrollFields,
  },
  {
    path: "/soil-label-generator/:reportHash",
    name: "soil-label-generator",
    component: PDFSoilLabelView,
    props: true,
  },
  {
    path: "/historical-practices",
    name: "historical-practices",
    component: HistoricalPracticesLandingView,
  },
  {
    path: "/practice-confirmations",
    name: "practice-confirmations",
    component: PracticeConfirmationsView,
  },
  {
    path: "/practice-confirmations-uploader",
    name: "practice-confirmations-uploader",
    component: PracticeConfirmationsUploader,
  },
]

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes,
  prevRoute: null,
})

router.beforeEach((_, from, next) => {
  window.Intercom("update")
  router.prevRoute = from
  next()
})

export default router
