<template>
  <b-tr>
    <!-- fertilizer date -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(fertilizer.date)"
    >
      <v-menu
        v-model="showDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="date"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="
                  fertilizer.date.source !== 'User Input' &&
                  fertilizer.date.value !== null
                    ? 'mdi-check-circle-outline'
                    : ''
                "
                @click:append="handleClickSubmit('date', date)"
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          :min="yearPriorOne + '-01-01'"
          :max="yearExtendedOne + '-12-31'"
          @input="showDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- method type -->
    <b-td
      class="cell-table large-column"
      :class="getClassForCell(fertilizer.method)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="allMethodChoices"
        item-text="display_name"
        item-value="value"
        v-model="methodType"
        :append-icon="
          fertilizer.method.source !== 'User Input' &&
          fertilizer.method.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('method', methodType)"
      />
    </b-td>
    <!-- products -->
    <b-td
      class="cell-table large-column"
      :class="getClassForCell(fertilizer.product)"
    >
      <v-select
        v-if="isFertIncludedInChoices(product)"
        clearable
        dense
        hide-details="true"
        :disabled="fertilizer.method.value == null ? true : false"
        :label="fertilizer.method.value == null ? 'Select a Method' : ''"
        :items="productChoices"
        item-text="display_name"
        item-value="value"
        v-model="product"
        :append-icon="
          fertilizer.product.source !== 'User Input' &&
          fertilizer.product.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('product', product)"
      />

      <v-text-field
        v-else
        clearable
        dense
        hide-details="true"
        :disabled="fertilizer.method.value == null ? true : false"
        v-model="product"
        :append-icon="
          fertilizer.product.source !== 'User Input' &&
          fertilizer.product.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('product', product)"
      />
    </b-td>
    <!-- rate -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(fertilizer.amount)"
    >
      <v-text-field
        clearable
        dense
        v-model="fertRate"
        hide-details="true"
        :append-icon="
          fertilizer.amount.source !== 'User Input' &&
          fertilizer.amount.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 'rate')"
        @click:append="handleClickSubmit('amount', fertRate)"
      >
      </v-text-field>
    </b-td>
    <!-- unit choices -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(fertilizer.unit)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="rateUnitChoices"
        item-text="display_name"
        item-value="value"
        v-model="unit"
        :append-icon="
          fertilizer.unit.source !== 'User Input' &&
          fertilizer.unit.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('unit', unit)"
      />
    </b-td>
    <!-- cost -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(fertilizer.cost)"
    >
      <v-text-field
        clearable
        dense
        v-model="cost"
        prefix="$"
        suffix="USD"
        hide-details="true"
        :append-icon="
          fertilizer.cost.source !== 'User Input' &&
          fertilizer.cost.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 'cost')"
        @click:append="handleClickSubmit('cost', cost)"
      >
      </v-text-field>
    </b-td>
    <!-- N -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(fertilizer.N)"
    >
      <v-text-field
        clearable
        dense
        v-model="nAmount"
        hide-details="true"
        :append-icon="
          fertilizer.N.source !== 'User Input' && fertilizer.N.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 'n-amount')"
        @click:append="handleClickSubmit('nAmount', nAmount)"
      >
      </v-text-field>
    </b-td>
    <!-- P -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(fertilizer.P)"
    >
      <v-text-field
        clearable
        dense
        v-model="pAmount"
        hide-details="true"
        :append-icon="
          fertilizer.P.source !== 'User Input' && fertilizer.P.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 'p-amount')"
        @click:append="handleClickSubmit('pAmount', pAmount)"
      >
      </v-text-field>
    </b-td>
    <!-- K -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(fertilizer.K)"
    >
      <v-text-field
        clearable
        dense
        v-model="kAmount"
        hide-details="true"
        :append-icon="
          fertilizer.K.source !== 'User Input' && fertilizer.K.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 'k-amount')"
        @click:append="handleClickSubmit('kAmount', kAmount)"
      >
      </v-text-field>
    </b-td>
    <!-- S -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(fertilizer.S)"
    >
      <v-text-field
        clearable
        dense
        v-model="sAmount"
        hide-details="true"
        :append-icon="
          fertilizer.S.source !== 'User Input' && fertilizer.S.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 's-amount')"
        @click:append="handleClickSubmit('sAmount', sAmount)"
      >
      </v-text-field>
    </b-td>
    <!-- slow release -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(fertilizer.slow_release)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="slowRelease"
        :append-icon="
          fertilizer.slow_release.source !== 'User Input' &&
          fertilizer.slow_release.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('slow_release', slowRelease)"
      />
    </b-td>
    <!-- inhibitor -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(fertilizer.inhibitor)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="inhibitor"
        :append-icon="
          fertilizer.inhibitor.source !== 'User Input' &&
          fertilizer.inhibitor.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('inhibitor', inhibitor)"
      />
      <!-- <v-select-old
        class="style-chooser text-bold mb-0"
        :clearable="false"
        label="display_name"
        :options="yesNoOptions"
        :reduce="o => o.value"
        v-model="inhibitor"
      /> -->
    </b-td>
    <!-- vrt -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(fertilizer.vrt)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="vrt"
        :append-icon="
          fertilizer.vrt.source !== 'User Input' &&
          fertilizer.vrt.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('vrt', vrt)"
      />
    </b-td>
    <!-- managgement plan -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(fertilizer.management_plan)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="managementPlan"
        :append-icon="
          fertilizer.management_plan.source !== 'User Input' &&
          fertilizer.management_plan.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('management_plan', managementPlan)"
      />
    </b-td>
    <b-td class="cell-table white">
      <v-btn icon x-small color="black" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="black" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </b-td>
  </b-tr>
</template>
<script>
import { mapGetters, mapMutations } from "vuex"
import { CROP_DROPDOWN_OPTIONS, YES_NO_OPTIONS } from "@/constants"
import {
  DRY_FERT_PRODUCT_CHOICES,
  WET_FERT_PRODUCT_CHOICES,
  MANURE_PRODUCT_CHOICES,
  RATE_UNIT_CHOICES,
} from "@/constants/defaults"
import { Defaults, Fertilizer } from "@/store/modules"
import { getClassForCell } from "../utils"

export default {
  name: "FertilizerRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    fertilizer: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  data() {
    return {
      cropChoices: CROP_DROPDOWN_OPTIONS,
      yesNoOptions: YES_NO_OPTIONS,
      yesNoMapper: { Yes: true, No: false },
      rateUnitChoices: RATE_UNIT_CHOICES,
      dryFertProductChoices: DRY_FERT_PRODUCT_CHOICES,
      wetFertProductChoices: WET_FERT_PRODUCT_CHOICES,
      manureProductChoices: MANURE_PRODUCT_CHOICES,
      showDate: false,
      getClassForCell,
    }
  },
  computed: {
    ...mapGetters({
      fertilizerOptions: Fertilizer.Getters.getFertilizers,
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    allMethodChoices() {
      return this.practiceChoices['fertilizer_method']
    },
    crop: {
      get() {
        return this.fertilizer.crop_id.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "fert_applications",
          dataSetting: "crop",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    date: {
      get() {
        const dateReturn = this.fertilizer.date.value
          ? this.fertilizer.date.value
          : `${this.year}-01-01`
        return dateReturn
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "fert_applications",
          dataSetting: "date",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    methodType: {
      get() {
        return this.fertilizer.method.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "fert_applications",
          dataSetting: "method",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    product: {
      get() {
        return this.fertilizer.product.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "fert_applications",
          dataSetting: "product",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
        this.handleSetFertAndRate(
          val,
          this.fertilizer.amount.value,
          this.fertilizer.unit.value
        )
      },
    },
    fertRate: {
      get() {
        return this.fertilizer.amount.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "fert_applications",
            dataSetting: "amount",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
          this.handleSetFertAndRate(
            this.fertilizer.product.value,
            val,
            this.fertilizer.unit.value
          )
        }
      },
    },
    unit: {
      get() {
        if (this.fertilizer.unit.value == "lb") {
          return "lb/ac"
        } else if (this.fertilizer.unit.value == "gal") {
          return "gal/acre"
        }
        return this.fertilizer.unit.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "fert_applications",
          dataSetting: "unit",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
        this.handleSetFertAndRate(
          this.fertilizer.product.value,
          this.fertilizer.amount.value,
          val
        )
      },
    },
    cost: {
      get() {
        return this.fertilizer.cost.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "fert_applications",
            dataSetting: "cost",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    nAmount: {
      get() {
        return this.fertilizer.N.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "fert_applications",
            dataSetting: "N",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    pAmount: {
      get() {
        return this.fertilizer.P.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "fert_applications",
            dataSetting: "P",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    kAmount: {
      get() {
        return this.fertilizer.K.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "fert_applications",
            dataSetting: "K",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    sAmount: {
      get() {
        return this.fertilizer.S.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "fert_applications",
            dataSetting: "S",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    ammoniumPct: {
      get() {
        return this.fertilizer.ammonium_pct.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "fert_applications",
            dataSetting: "ammonium_pct",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    slowRelease: {
      get() {
        if (typeof this.fertilizer.slow_release.value == "string") {
          return this.yesNoMapper[this.fertilizer.slow_release.value]
        }
        return this.fertilizer.slow_release.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "fert_applications",
          dataSetting: "slow_release",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    inhibitor: {
      get() {
        if (typeof this.fertilizer.inhibitor.value == "string") {
          return this.yesNoMapper[this.fertilizer.inhibitor.value]
        }
        return this.fertilizer.inhibitor.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "fert_applications",
          dataSetting: "inhibitor",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    managementPlan: {
      get() {
        if (typeof this.fertilizer.management_plan.value == "string") {
          return this.yesNoMapper[this.fertilizer.management_plan.value]
        }
        return this.fertilizer.management_plan.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "fert_applications",
          dataSetting: "management_plan",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    vrt: {
      get() {
        if (typeof this.fertilizer.vrt.value == "string") {
          return this.yesNoMapper[this.fertilizer.vrt.value]
        }
        return this.fertilizer.vrt.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "fert_applications",
          dataSetting: "vrt",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    productChoices() {
      const otherNoneOptions = [
        { display_name: "Other", value: "other" },
        { display_name: "None", value: "none" },
      ]
      if (this.methodType) {
        if (
          this.methodType === "broadcast" ||
          this.methodType === "incorporated" ||
          this.methodType === "strip till" ||
          this.methodType === "banded"
        ) {
          const dryOptions = this.fertilizerOptions.filter(
            f => f.fert_application == "dry"
          )
          return dryOptions.concat(otherNoneOptions)
        }
        if (this.methodType === "injected" || this.methodType === "sprayed") {
          const liquidOptions = this.fertilizerOptions.filter(
            f => f.fert_application == "liquid"
          )
          return liquidOptions.concat(otherNoneOptions)
        }
        if (this.methodType === "manure") {
          const manureOptions = this.fertilizerOptions.filter(
            f => f.fert_application == "manure"
          )
          return manureOptions.concat(otherNoneOptions)
        }
      }
      return otherNoneOptions
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    isFertIncludedInChoices(productName) {
      const foundFert = this.productChoices.find(
        ({ fert }) => fert == productName
      )
      return foundFert != undefined
    },
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.fertilizer)
    },
    handleAdd() {
      const payloadObj = {
        N: { source: "User Input", value: null },
        P: { source: "User Input", value: null },
        K: { source: "User Input", value: null },
        S: { source: "User Input", value: null },
        ammonium_pct: { source: "User Input", value: null },
        amount: { source: "User Input", value: null },
        cost: { source: "User Input", value: null },
        crop_id: { source: "User Input", value: this.cropId },
        date: { source: "User Input", value: null },
        fert_rate: { source: "User Input", value: null },
        inhibitor: { source: "User Input", value: null },
        management_plan: { source: "User Input", value: null },
        method: { source: "User Input", value: null },
        product: { source: "User Input", value: null },
        slow_release: { source: "User Input", value: null },
        unit: { source: "User Input", value: null },
        vrt: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "fert_applications",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.fertilizer)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "fert_applications",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleSetFertAndRate(fert, rate, units) {
      if ([fert, rate, units].includes(null)) {
        return
      } else {
        const fertGettingSet = this.fertilizerOptions.find(f => f.fert == fert)
        if (fertGettingSet == undefined) {
          this.nAmount = 0
          this.pAmount = 0
          this.kAmount = 0
          this.sAmount = 0
        } else {
          const chemistryVals = { N: 0, P: 0, K: 0, S: 0 }
          let gallonConversion = 1

          if (
            (this.methodType === "injected" || this.methodType === "sprayed") &&
            units.includes("gal")
          ) {
            gallonConversion = fertGettingSet.lbs_per_gallon
          }

          chemistryVals["N"] = fertGettingSet.pct_n * rate * gallonConversion
          chemistryVals["P"] = fertGettingSet.pct_p2o5 * rate * gallonConversion
          chemistryVals["K"] = fertGettingSet.pct_k2o * rate * gallonConversion
          chemistryVals["S"] = fertGettingSet.pct_s * rate * gallonConversion

          this.nAmount = chemistryVals["N"].toFixed(3)
          this.pAmount = chemistryVals["P"].toFixed(3)
          this.kAmount = chemistryVals["K"].toFixed(3)
          this.sAmount = chemistryVals["S"].toFixed(3)
        }
      }
    },
    handleValidateValue(val, type, length = 24, range = null) {
      function isNumeric(str) {
        if (typeof str == "number") return true
        return (
          !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ) // ...and ensure strings of whitespace fail
      }

      function isFloat(str) {
        return !Number.isInteger(str)
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          if (range !== null) {
            if (val >= range[0] && val <= range[1]) {
              return true
            } else {
              this.$emit(
                "bad-input",
                `Look out! Only a value between ${range[0]} and ${range[1]} can be entered here.`
              )
              return false
            }
          }
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      } else if (type == "float") {
        if (isFloat(val)) {
          if (range !== null) {
            if (val >= range[0] && val <= range[1]) {
              return true
            } else {
              this.$emit(
                "bad-input",
                `Look out! Only a value between ${range[0]} and ${range[1]} can be entered here.`
              )
              return false
            }
          }
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      }
    },
    filter(event, fieldInput) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let range = null
      let inputType = "number"
      if (fieldInput === "rate") range = [0, 500]
      else if (fieldInput === "n-amount") {
        range = [0, 300]
        inputType = "float"
      } else if (fieldInput === "p-amount" || fieldInput === "k-amount") {
        range = [0, 150]
        inputType = "float"
      } else if (fieldInput === "s-amount") {
        range = [0, 50]
        inputType = "float"
      }

      let validVal = this.handleValidateValue(expect, inputType, 24, range)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.cell-table {
  padding: 2px 6px 2px 6px;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.machine {
  background-color: #c0dce2;
}
.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

/* column sizing */
.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.v-input {
  font-size: 18px;
}

.cell-table >>> .v-input__slot::before {
  border-style: none !important;
}

/* hide arrows/spin button for number input */
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */
</style>
