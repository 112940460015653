import {
  Dashboard,
  Defaults,
  Fertilizer,
  Fields,
  Filter,
  Map,
  Placements,
  SoilSampling,
  Organization,
} from "@/store/modules"

const year = localStorage.getItem("arvaYear")
const state = {
  organization: { corporation: { name: "" } },
  year,
}

const getters = {
  [Organization.Getters.getYear]: state => state.year,
}

const mutations = {
  [Organization.Mutations.setOrganization](state, organization) {
    const resetView = true
    state.organization = { ...organization }
    this.commit(Filter.Mutations.initialize, { organization, resetView })
  },

  [Organization.Mutations.setYear](state, { year, onlyIfNull }) {
    if (onlyIfNull && localStorage.getItem("arvaYear")) return

    state.year = year
    localStorage.setItem("arvaYear", year)

    this.commit(Filter.Mutations.initialize, {
      organization: state.organization,
      force: true,
      resetView: false,
    })
  },
}

const actions = {
  [Organization.Actions.updateYear]({ commit, dispatch }, newYear) {
    commit(Organization.Mutations.setYear, { year: newYear })
    //dispatch(Organization.Actions.initialFetch, false)
    dispatch(Map.Actions.fetchMapData) //I think we only need to do this
  },

  [Organization.Actions.initialFetch]({ commit, dispatch, state }) {
    // dispatch(Defaults.Actions.fetchDefaults)
    dispatch(Defaults.Actions.fetchPracticeChoices);
    dispatch(Fertilizer.Actions.fetchFertilizers)
    dispatch(Fields.Actions.fetchFields)
    dispatch(Dashboard.Actions.fetchAgtPerformance)
    dispatch(Dashboard.Actions.fetchAgtSummary)
    // dispatch(Defaults.Actions.fetchFieldSettings)
    dispatch(Dashboard.Actions.fetchSeedCompanies)
    dispatch(Dashboard.Actions.fetchAgts)
    dispatch(SoilSampling.Actions.fetchSamplePlans, state.organization.id)
    //dispatch(Map.Actions.fetchMapData, resetView)
    dispatch(Placements.Actions.fetchSeedPlacementPlans)
    dispatch(Placements.Actions.fetchCropsWithTrials)
    commit(Map.Mutations.setIsFetching, false)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
