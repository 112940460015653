<template>
  <tr>
    <td v-if="zonesView && tabView">
      <input type="checkbox" v-model="checkValue" />
    </td>
    <td v-if="!tabView">
      <img :src="field.boundary_image" style="width: 100px" />
    </td>
    <td>
      <!-- <div
        class="default-name"
        @click="() => $router.push(`/field-satellite/${field.id}`)"
      > -->
      <div
        :class="{
          'default-name': !zonesView,
        }"
        @click="handleRoute"
      >
        {{ field.name }}
      </div>
    </td>
    <td v-if="soilSamplingView">{{ cropForYear | crop }}</td>
    <td>{{ field.acreage | prettyInteger }}</td>
    <td>{{ field.farm.name }}</td>
  </tr>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { Filter } from "@/store/modules"
export default {
  name: "FieldImageryListRow",
  props: ["field", "soilSamplingView", "tabView", "zonesView", "year"],

  computed: {
    ...mapGetters({
      fieldForCheck: Filter.Getters.getFieldForCheck,
    }),

    checkValue: {
      get() {
        return this.fieldForCheck(this.field.id)
      },
      set() {
        const id = this.field.id
        this.toggleItem({ id, dropdownType: "field" })
        this.$emit("field-clicked", this.field.id)
      },
    },

    cropForYear() {
      const fieldcrop = this.field.field_crops.find(fc => fc.year == this.year)
      if (typeof fieldcrop === "undefined") {
        return "None"
      }
      return fieldcrop.crop_id
    }
  },

  methods: {
    ...mapMutations({
      toggleItem: Filter.Mutations.toggleItem,
    }),

    handleRoute() {
      if (!this.zonesView) {
        this.$router.push(`/field-satellite/${this.field.id}`)
      }
    },
  },
}
</script>

<style scoped>
.data-type {
  width: 85px;
}

.pct {
  font-weight: 500;
}

.default-name {
  color: #1979f1;
  font-weight: bold;
}

.default-name:hover {
  color: #333;
  cursor: pointer;
}

.confidence-box {
  display: flex;
  align-items: center;
}

.check {
  margin-right: 5px;
}

.confident-check-green {
  color: green;
}

.confident-check-yellow {
  color: orange;
}

.confident-check-red {
  color: red;
}

.data-import {
  font-weight: 500;
  font-size: 12px;
  flex: 1;
  text-align: right;
}
</style>
