<template>
  <tr>
    <td><img :src="field.boundary_image" style="width:50px" /></td>
    <td class="nowrap">{{ field.name }}</td>
    <td class="text-right">{{ field.acreage | prettyInteger }}</td>
    <!--<td>{{ field.region_name }}</td>-->
    <td>
      <v-select-old
        label="display_name"
        :style="{ width: '138px' }"
        :options="cropOptions"
        :reduce="o => o.value"
        v-model="nextCrop"
        @input="cropCleared"
      />
    </td>
    <td>
      <v-select-old
        label="display_name"
        :style="{ width: '123px' }"
        :options="irrigationOptions"
        :reduce="o => o.value"
        v-model="irrigationType"
      />
    </td>
    <td>
      <v-slider
        class="risk-slider"
        v-model="riskTolerance"
        :step="100"
        :min="0"
        :max="100"
        :ticks="false"
        :thumb-size="75"
        thumb-label-always-show
      >
        <template v-slot:thumb-label="props">
         {{ props.value == 0 ? 'Workhorse' : 'Racehorse' }}
        </template>
      </v-slider>
    </td>
    <td>
      <div class="py-2">
        <div v-if="fetching" class="nowrap">
          <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <span class="fetching-text">Calculating</span>
        </div>
        <div v-if="!fetching && hybrids && hybrids.length === 0">
          <span class="fetching-text nowrap">No results for this region</span>
        </div>
        <div v-if="!fetching && hybrids && hybrids.length > 0">
          <div
            v-for="hybrid in recommendedHybrids"
            :key="hybrid.rank"
            class="hybrids"
          >
            <div
              class="nowrap d-flex pointer"
              @click="handleHybridClick(hybrid)"
            >
              <div class="hybrid-select d-flex align-items-center">
                <div>
                  <div v-if="hybrid.display === selectedSeedDisplayName" />
                </div>
              </div>
              <div class="rank">{{ hybrid.rank }}.</div>
              <div>{{ hybrid.display }}</div>
              <div
                v-if="
                  hybrid.relativeMaturity && hybrid.relativeMaturity !== 'None'
                "
                class="ml-2"
              >
                RM: {{ hybrid.relativeMaturity | prettyInteger }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!fetching && hybrids && hybrids.length > 3 && !showAllResults" class="hybrids expand-prompt mb-1" @click="toggleShowAll">Showing 3 of {{ hybrids.length }}<font-awesome-icon class="ml-1" icon="caret-down" /></div>
      <div v-if="!fetching && hybrids && hybrids.length > 3 && showAllResults" class="hybrids expand-prompt mb-1" @click="toggleShowAll">Hide Results<font-awesome-icon class="ml-1" icon="caret-up" /></div>
    </td>
    <td>
      <div v-if="!fetching" :class="{'column-spacer': !fetching && hybrids && hybrids.length > 3}">
        <div
          v-for="hybrid in recommendedHybrids"
          :key="hybrid.rank"
          class="hybrids"
        >
          <div class="nowrap">
            <i
              :class="
                hybrid.avgYieldIncrease >= 0
                  ? 'fa fa-arrow-up'
                  : 'fa fa-arrow-down'
              "
            />{{ hybrid.avgYieldIncrease | percentFloat }}
          </div>
        </div>
      </div>
    </td>
    <td>
      <div v-if="!fetching" :class="{'column-spacer': !fetching && hybrids && hybrids.length > 3}">
        <div
          v-for="hybrid in recommendedHybrids"
          :key="hybrid.rank"
          class="hybrids"
        >
          <div class="nowrap">
            <i
              :class="
                hybrid.lowRiskChangeFromAverage >= 0
                  ? 'fa fa-arrow-up'
                  : 'fa fa-arrow-down'
              "
            />{{ hybrid.lowRiskChangeFromAverage | percentFloat }}
            --
            <i
              :class="
                hybrid.highRiskChangeFromAverage >= 0
                  ? 'fa fa-arrow-up'
                  : 'fa fa-arrow-down'
              "
            />{{ hybrid.highRiskChangeFromAverage | percentFloat }}
          </div>
        </div>
      </div>
    </td>
    <td class="text-right">
      <div v-if="!fetching" :class="{'column-spacer': !fetching && hybrids && hybrids.length > 3}">
        <div
          v-for="hybrid in recommendedHybrids"
          :key="hybrid.rank"
          class="hybrids"
        >
          {{ hybrid.numTrials | prettyInteger }}
        </div>
      </div>
    </td>
    <td class="text-right">
      <div v-if="!fetching" :class="{'column-spacer': !fetching && hybrids && hybrids.length > 3}">
        <div
          v-for="hybrid in recommendedHybrids"
          :key="hybrid.rank"
          class="hybrids"
        >
          {{ hybrid.trialDistMean | prettyInteger }}
        </div>
      </div>
    </td>
    <td>{{ totalBags | floatTenth }}</td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex"

import SeedSelectorAPI from "@/api/SeedSelectorAPI"
import {
  CROP_KEY,
  CROP_DISPLAY_NAME,
  CROP_DROPDOWN_OPTIONS,
  CROP_SEEDS_PER_BAG,
  CROPS,
} from "@/constants"
import { Filter } from "@/store/modules"

export default {
  name: "SeedSelectorRow",
  props: [
    "field",
    "companyFocus",
    "filterResults",
    "relativeMaturity",
    "seedingRate",
    "seedingRates",
  ],

  data() {
    return {
      nextCrop: null,
      irrigationType: null,
      riskTolerance: 0,
      cropOptions: CROP_DROPDOWN_OPTIONS.filter(({ value }) =>
        [CROPS.Corn, CROPS.Soybeans, CROPS.Wheat].includes(value)
      ),
      irrigationOptions: [
        { display_name: "Irr.", value: "irrigated" },
        { display_name: "Non-Irr.", value: "non-irrigated" },
      ],
      fetching: false,
      hybrids: null,
      selectedSeedDisplayName: null,
      showAllResults: false,
    }
  },

  computed: {
    ...mapGetters({
      clients: Filter.Getters.getClients,
      farms: Filter.Getters.getFarms,
    }),

    recommendedHybrids() {
      if (!this.hybrids) return null
      const mappedHybrids = this.hybrids.map(hybrid => ({
        company: hybrid.company,
        rank: hybrid.rank,
        seedProductId: hybrid.seed_product_id,
        avgYieldIncrease: hybrid.avgYieldIncrease,
        maxYieldIncrease: hybrid.maxYieldIncrease,
        lowRiskChangeFromAverage: hybrid.lowRiskChangeFromAverage,
        highRiskChangeFromAverage: hybrid.highRiskChangeFromAverage,
        trialDistMean: hybrid.trialDistMean,
        numTrials: hybrid.numTrials,
        product: hybrid.product,
        display: `${hybrid.company} ${hybrid.product} ${
          hybrid.technology !== "None" ? hybrid.technology : ""
        }`,
        relativeMaturity: hybrid.relativeMaturity,
      }))

      console.log("show all: ", this.showAllResults)

      if (!this.showAllResults) {
        console.log("if statement")
        return mappedHybrids.slice(0, 3)
      }

      return mappedHybrids
      // return this.hybrids.map(hybrid => ({
      //   company: hybrid.company,
      //   rank: hybrid.rank,
      //   seedProductId: hybrid.seed_product_id,
      //   avgYieldIncrease: hybrid.avgYieldIncrease,
      //   maxYieldIncrease: hybrid.maxYieldIncrease,
      //   lowRiskChangeFromAverage : hybrid.lowRiskChangeFromAverage,
      //   highRiskChangeFromAverage: hybrid.highRiskChangeFromAverage,
      //   trialDistMean: hybrid.trialDistMean,
      //   numTrials: hybrid.numTrials,
      //   product: hybrid.product,
      //   display: `${hybrid.company} ${hybrid.product} ${
      //     hybrid.technology !== "None" ? hybrid.technology : ""
      //   }`,
      //   relativeMaturity: hybrid.relativeMaturity,
      // }))
    },

    totalBags() {
      const { acreage = 0 } = this.field
      if (!this.seedingRates) return "--"
      const totalSeeds = this.seedingRates[CROP_KEY[this.nextCrop]] * acreage
      return totalSeeds / CROP_SEEDS_PER_BAG[this.nextCrop]
    },
  },

  methods: {
    cropCleared(val) {
      if (val === null) {
        this.$emit("crop-cleared", this.field)
      }
    },

    async fetchSeedSelection() {
      const fieldId = this.field.id
      const cropId = this.nextCrop
      const irrigationType = this.irrigationType
      const riskTolerance = this.riskTolerance
      const filterResults = this.filterResults

      if (!fieldId || !cropId) {
        this.hybrids = null
        return
      }

      this.fetching = true
      const resp = await SeedSelectorAPI.fetchSeedSelection(
        this.field.id,
        cropId,
        irrigationType,
        riskTolerance,
        this.companyFocus,
        filterResults,
        this.relativeMaturity
      )
      this.hybrids = resp.data
      this.fetching = false

      if (this.hybrids.length > 0) {
        const firstHybrid = this.hybrids[0]
        const firstDisplayName = `${firstHybrid.company} ${
          firstHybrid.product
        } ${firstHybrid.technology !== "None" ? firstHybrid.technology : ""}`
        this.selectedSeedDisplayName = firstDisplayName

        const fieldFarm = this.farms.find(x => x.id === this.field.farm.id)
        const fieldClient = this.clients.find(
          client => client.id === fieldFarm.clientId
        )
        const selectedHybrid = this.recommendedHybrids.find(
          hybrid => hybrid.display === this.selectedSeedDisplayName
        )
        this.$emit(
          "update-pdf-post",
          this.field,
          selectedHybrid.product,
          selectedHybrid.company,
          this.typeCheckBags(this.totalBags),
          CROP_DISPLAY_NAME[this.nextCrop],
          this.riskTolerance,
          fieldClient.name
        )
      }
    },

    handleHybridClick(hybrid) {
      this.selectedSeedDisplayName = hybrid.display
      const fieldFarm = this.farms.find(x => x.id === this.field.farm.id)
      const fieldClient = this.clients.find(
        client => client.id === fieldFarm.clientId
      )
      const selectedHybrid = this.recommendedHybrids.find(
        hybrid => hybrid.display === this.selectedSeedDisplayName
      )
      this.$emit(
        "update-pdf-post",
        this.field,
        selectedHybrid.product,
        selectedHybrid.company,
        this.typeCheckBags(this.totalBags),
        CROP_DISPLAY_NAME[this.nextCrop],
        this.riskTolerance,
        fieldClient.name
      )
    },

    toggleShowAll() {
      this.showAllResults = !this.showAllResults
    },

    typeCheckBags(bags) {
      if (typeof bags === "string") return 0
      return bags
    },
  },

  watch: {
    nextCrop() {
      this.fetchSeedSelection()
    },

    irrigationType() {
      this.fetchSeedSelection()
    },

    riskTolerance() {
      this.fetchSeedSelection()
    },

    companyFocus() {
      this.fetchSeedSelection()
    },

    filterResults() {
      this.fetchSeedSelection()
    },

    relativeMaturity() {
      this.fetchSeedSelection()
    },

    totalBags(newBags) {
      if (this.hybrids !== null && this.hybrids.length > 0) {
        const fieldFarm = this.farms.find(x => x.id === this.field.farm.id)
        const fieldClient = this.clients.find(
          client => client.id === fieldFarm.clientId
        )
        const selectedHybrid = this.recommendedHybrids.find(
          hybrid => hybrid.display === this.selectedSeedDisplayName
        )
        this.$emit(
          "update-pdf-post",
          this.field,
          selectedHybrid.product,
          selectedHybrid.company,
          this.typeCheckBags(newBags),
          CROP_DISPLAY_NAME[this.nextCrop],
          this.riskTolerance,
          fieldClient.name
        )
      }
    },
  },
}
</script>

<style scoped>
.spinner-grow {
  height: 16px;
  width: 16px;
}

.column-spacer {
  padding-bottom: 1.65rem;
}

.expand-prompt {
  cursor: pointer;
  text-align: center;
}

.fetching-text {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
}

.hybrids {
  font-size: 12px;
  font-weight: 500;
}

.hybrids:hover {
  color: #555;
}

.hybrids > div:first-of-type {
  margin-right: 10px;
}

.hybrids .fa-arrow-up {
  color: #00ca00;
  margin-right: 5px;
}

.hybrids .fa-arrow-down {
  color: red;
  margin-right: 5px;
}

.hybrids .rank {
  margin-right: 2px;
  width: 25px;
}

.nowrap {
  white-space: nowrap;
}

.hybrid-select {
  width: 24px;
}

.hybrid-select > div {
  background: #dadada;
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.hybrid-select > div > div {
  background: #7574e0;
  width: 9px;
  height: 9px;
  border-radius: 9px;
  margin-top: 3px;
  margin-left: 3px;
}
</style>