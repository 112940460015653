<template>
  <div class="page row">
    <div v-show="clickedLayer" class="col-lg-4">
      <h3 class="font-weight-bold">Boundary editor</h3>

      <v-card v-if="clickedEditFieldNameOption">
        <v-card class="mb-3">
          <v-card-title class="d-flex justify-content-left">
            <span class="text-h6" @click="showInfoSection = !showInfoSection">{{
              tabName
            }}</span>
            <v-icon @click="showInfoSection = !showInfoSection">
              {{ showInfoSection ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-card-title>

          <div v-show="showInfoSection">
            <div class="row ml-3">
              <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                <span class="default-input-title">Organization</span>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model.trim="selectedOrgName"
                />
              </div>
            </div>

            <div class="row ml-3">
              <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                <span class="default-input-title">Client</span>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model.trim="selectedClient.name"
                />
              </div>
            </div>

            <div class="row ml-3">
              <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                <span class="default-input-title">Farm</span>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model.trim="selectedFarm.name"
                />
              </div>
            </div>

            <div class="row ml-3">
              <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                <span class="default-input-title">{{ fieldLabel }}</span>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :disabled="disableFieldInfo"
                    v-model.trim="fieldName"
                  />
                </div>
              </div>
            </div>

            <div class="row ml-3">
              <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                <span class="default-input-title"
                  >Total Area (In {{ selectedUnits }})</span
                >
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    v-model.trim="totalAreaBySelectedUnit"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-card>

        <v-card>
          <v-card-title class="d-flex justify-content-left">
            <span class="text-h6" @click="showEditSection = !showEditSection">{{
              editFieldLabel
            }}</span>
            <v-icon @click="showEditSection = !showEditSection">
              {{ showEditSection ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-card-title>

          <div v-show="showEditSection">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newFieldName"
                      :label="
                        isRangeForce ? 'New Pasture Name' : 'New Field Name'
                      "
                      :rules="newFieldNameRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="resetLeftPanel"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue-darken-1"
                variant="text"
                :disabled="!newFieldName"
                @click="editFieldName"
              >
                Save
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-card>

      <v-card v-else>
        <v-toolbar flat>
          <v-tabs grow v-model="tab" active-class="active-tab font-weight-bold">
            <v-tabs-slider color="white"></v-tabs-slider>
            <v-tab v-for="item in tabTitles" disabled :key="item.index">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabTitles" :key="item.index">
            <v-card flat>
              <div v-if="item.name === 'Create'" class="py-3">
                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-select
                      v-model="selectedOrg"
                      :items="organizationOptions"
                      :disabled="disableFieldInfo"
                      item-text="name"
                      item-value="id"
                      label="Organization"
                    ></v-select>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-combobox
                      v-model="selectedClient"
                      dense
                      outlined
                      label="Client"
                      :items="clientOptions"
                      :disabled="disableFieldInfo"
                      @input="handleInputForClient"
                      @blur="handleBlurForClient"
                      item-text="name"
                      item-value="id"
                    >
                    </v-combobox>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-combobox
                      dense
                      outlined
                      :label="isRangeForce ? 'Range' : 'Farm'"
                      :items="farmOptions"
                      v-model="selectedFarm"
                      :disabled="disableFieldInfo"
                      @input="handleInputForFarm"
                      @blur="handleBlurForFarm"
                      item-text="name"
                      item-value="id"
                    >
                    </v-combobox>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title"> {{ fieldLabel }}</span>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        :disabled="disableFieldInfo"
                        v-model.trim="fieldName"
                      />
                    </div>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title"
                      >Total Area (In {{ selectedUnits }})</span
                    >
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        v-model.trim="totalAreaBySelectedUnit"
                      />
                    </div>
                  </div>
                </div>

                <div v-if="showClearChangesBtn" class="row ml-1">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-btn
                      small
                      plain
                      color="red"
                      @click="confirmBeforeClearingChanges = true"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                      Clear changes
                    </v-btn>
                  </div>
                </div>

                <div v-if="showDeleteBoundaryBtn" class="row ml-1">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-btn
                      small
                      plain
                      color="red"
                      @click="confirmBeforeDeletingBoundary = true"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                      Delete boundary
                    </v-btn>
                  </div>
                </div>
              </div>

              <div v-if="item.name === 'Edit'" class="py-3">
                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-select
                      v-model="selectedOrg"
                      :items="organizationOptions"
                      :disabled="disableFieldInfo"
                      item-text="name"
                      item-value="id"
                      label="Organization"
                    ></v-select>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-combobox
                      v-model="selectedClient"
                      dense
                      outlined
                      label="Client"
                      :items="clientOptions"
                      :disabled="disableFieldInfo"
                      @input="handleInputForClient"
                      @blur="handleBlurForClient"
                      item-text="name"
                      item-value="id"
                    >
                    </v-combobox>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-combobox
                      dense
                      outlined
                      :label="isRangeForce ? 'Range' : 'Farm'"
                      :items="farmOptions"
                      v-model="selectedFarm"
                      :disabled="disableFieldInfo"
                      @input="handleInputForFarm"
                      @blur="handleBlurForFarm"
                      item-text="name"
                      item-value="id"
                    >
                    </v-combobox>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title"> {{ fieldLabel }}</span>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        :disabled="disableFieldInfo"
                        v-model.trim="fieldName"
                      />
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="openEditFieldName"
                            class="ml-2"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Click to edit</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>

                <div class="row ml-3">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <span class="default-input-title"
                      >Total Area (In {{ selectedUnits }})</span
                    >
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        v-model.trim="totalAreaBySelectedUnit"
                      />
                    </div>
                  </div>
                </div>

                <div v-if="showClearChangesBtn" class="row ml-1">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-btn
                      small
                      plain
                      color="red"
                      @click="confirmBeforeClearingChanges = true"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                      Clear changes
                    </v-btn>
                  </div>
                </div>

                <div v-if="showDeleteBoundaryBtn" class="row ml-1">
                  <div class="col-lg-11 col-md-4 col-sm-6 col-xs-12">
                    <v-btn
                      small
                      plain
                      color="red"
                      @click="confirmBeforeDeletingBoundary = true"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                      Delete boundary
                    </v-btn>
                  </div>
                </div>
              </div>

              <div v-if="item.name === 'Subdivide'" class="pt-3">
                <div class="ml-2">
                  <span class="text-h6 red--text"
                    >Under Construction, more features are coming soon!</span
                  >
                </div>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <div
        v-show="(tab === 0 || tab === 1) && !clickedEditFieldNameOption"
        class="row footer-row justify-content-end"
      >
        <div class="col-lg-11 btn-row d-flex justify-content-end">
          <v-btn class="ma-1" color="grey" @click="cancelChanges">
            Cancel
          </v-btn>

          <v-btn
            :disabled="disableUploadChangesBtn"
            class="ma-1"
            color="success"
            @click="confirmChanges"
          >
            Save Changes
          </v-btn>
        </div>
      </div>
    </div>

    <v-snackbar
      top
      :class="clickedLayer ? 'snack-bar-half-map' : 'snack-bar-full-map'"
      :color="color"
      :timeout="timeout"
      v-model="showSnack"
    >
      <span v-if="isSnackTextArray">
        {{ errorMessage }}
        <ul>
          <li v-for="text in snackText" :key="text">
            {{ text }}
          </li>
        </ul>
      </span>
      <span v-else>
        {{ snackText }}
      </span>
    </v-snackbar>

    <div id="mapContainer" class="col map-wrapper">
      <Tooltip @menu-option-clicked="handleMenuOptionClicked" />
    </div>

    <ConfirmModal
      v-if="confirmBeforeDeletingBoundary"
      titleText="Confirm Delete Boundary"
      confirmText="You're about to delete a field boundary with layers of data associated with it. Are you sure you'd like to continue?"
      @confirm="
        clickedLayer.isNewBoundary ? deleteUnsavedBoundary() : deleteBoundary()
      "
      @close-modal="confirmBeforeDeletingBoundary = false"
    />

    <ConfirmModal
      v-if="confirmBeforeClearingChanges"
      titleText="Confirm Clear Changes"
      confirmText="You're about to clear all changes made to this boundary. Are you sure you'd like to continue?"
      @confirm="clearUnsavedBoundary"
      @close-modal="confirmBeforeClearingChanges = false"
    />
  </div>
</template>
<script>
import "leaflet/dist/leaflet.css"
import L from "leaflet"
import "leaflet-boundary-canvas"
import "leaflet-draw/dist/leaflet.draw.css"
import "leaflet-draw"
import "leaflet-easybutton/src/easy-button.css"
import "leaflet-easybutton"
import "leaflet-control-geocoder/dist/Control.Geocoder.css"
import "leaflet-control-geocoder"
import "leaflet-editable"
import { multiPolygon, point, polygon } from "@turf/helpers"
import turfArea from "@turf/area"
import booleanPointInPolygon from "@turf/boolean-point-in-polygon"
import booleanOverlap from "@turf/boolean-overlap"
import booleanIntersects from "@turf/boolean-intersects"
import bbox from "@turf/bbox"
import booleanEqual from "@turf/boolean-equal"
import ConfirmModal from "@/components/modals/ConfirmModal"
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import { Filter, Map, Uploads } from "@/store/modules"
import { MAPBOX_TOKEN } from "@/constants/map"
import Tooltip from "@/components/map/Tooltip"
import moment from "moment"
import shpWrite from "shp-write"
import FieldsAPI from "@/api/FieldsAPI"

export default {
  name: "BoundaryEditorView",
  components: {
    Tooltip,
    ConfirmModal,
  },

  data() {
    return {
      map: null,
      drawnFeatures: null,
      clickedLayer: null,
      isClickedLayerEqualOrgPolygon: null,
      legend: null,
      componentKey: 0,
      totalAreaBySelectedUnit: null,
      noDataAvailableText: "No Data Available",
      selectedOrg: null,
      selectedClient: null,
      selectedFarm: null,
      fieldName: null,
      disableFieldInfo: false,
      confirmBeforeDeletingBoundary: false,
      confirmBeforeClearingChanges: false,
      color: "blue",
      timeout: -1,
      showSnack: false,
      snackText: null,
      errorMessage: null,
      mapViewOption: "satellite",
      clickedEditFieldNameOption: false,
      tab: 0,
      tabTitles: [
        {
          index: 0,
          name: "Create",
        },
        {
          index: 1,
          name: "Edit",
        },
        {
          index: 2,
          name: "Subdivide",
        },
      ],
      newFieldName: null,
      showInfoSection: false,
      showEditSection: false,
      newFieldNameRules: [
        v => !!v || "Field name is required",
        v =>
          (v && v.length <= 255) ||
          "Field name must be less than 255 characters",
      ],
      isRangeForce: false,
      selectedUnits: "acres",
    }
  },

  computed: {
    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
      allFields: Filter.Getters.getFields,
    }),

    ...mapState({
      organizationOptions: state => state.Filter.orgNodes,
      clients: state => state.Filter.clients,
      farms: state => state.Filter.farms,
      boundaries: state => state.Map.fieldBoundaries,
      //selectedUnits: state => state.Filter.selectedUnits,
      rightClickData: state => state.Map.rightClickData,
      //isRangeForce: state => state.User.user.application === "rangeforce",
    }),

    isSnackTextArray() {
      return Array.isArray(this.snackText)
    },

    tabName() {
      return this.isRangeForce ? "Pasture Information" : "Field Information"
    },

    fieldLabel() {
      return this.isRangeForce ? "Pasture" : "Field"
    },

    editFieldLabel() {
      return this.isRangeForce ? "Edit Pasture Name" : "Edit Field Name"
    },

    clientOptions() {
      if (this.selectedOrg)
        return this.clients.filter(
          client => client.orgNodeId === this.selectedOrg
        )
      return this.clients
    },

    farmOptions() {
      if (this.selectedClient)
        return this.farms.filter(
          farm => farm.clientId === this.selectedClient.id
        )
      return this.farms
    },

    disableUploadChangesBtn() {
      return (
        !this.selectedOrg ||
        !this.selectedClient ||
        !this.selectedFarm ||
        !this.fieldName ||
        this.isClickedLayerEqualOrgPolygon
      )
    },

    fields() {
      const selectedFieldIds = this.selectedFields.map(field => field.id)
      const filteredBounds =
        selectedFieldIds.length > 0
          ? this.boundaries.filter(row =>
              selectedFieldIds.includes(row.properties.field.id)
            )
          : this.boundaries
      return filteredBounds
    },

    bounds() {
      const geometry = {
        type: "MultiPolygon",
        coordinates: [],
      }
      for (const field of this.fields) {
        geometry.coordinates = geometry.coordinates.concat(
          field.geometry.coordinates
        )
      }
      return geometry
    },

    showDeleteBoundaryBtn() {
      if (this.clickedLayer)
        return (
          this.clickedLayer.isNewBoundary && !this.isClickedLayerEqualOrgPolygon
        )
      return false
    },

    showClearChangesBtn() {
      if (this.clickedLayer)
        return !this.clickedLayer.isNewBoundary && !this.disableUploadChangesBtn
      return false
    },

    selectedOrgName() {
      if (this.selectedOrg)
        return this.organizationOptions.find(org => org.id === this.selectedOrg)
          .name
      return null
    },
  },

  watch: {
    bounds() {
      this.updateMap()

      if (this.lastMapAction === "click") {
        this.lastMapAction = null
        return
      }
      this.zoomToBounds()
    },

    boundaries() {
      this.getBoundLayers()
    },

    clickedLayer() {
      if (this.clickedLayer) {
        this.disableFieldInfo = !this.clickedLayer.isNewBoundary
        if (this.clickedLayer.isNewBoundary) this.tab = 0
        else this.tab = 1
      }
    },

    selectedOrg() {
      if (this.clickedLayer)
        this.clickedLayer.toGeoJSON().properties.field.farm.org_node_id = this.selectedOrg
    },

    fieldName() {
      if (this.clickedLayer && this.clickedLayer.isNewBoundary)
        this.clickedLayer.toGeoJSON().properties.field.name = this.fieldName
    },

    selectedUnits() {
      if (this.totalAreaBySelectedUnit)
        this.totalAreaBySelectedUnit = this.convertAreaToSelectedUnit(
          this.totalAreaBySelectedUnit
        )
    },
  },

  methods: {
    ...mapActions({
      addNewBoundary: Uploads.Actions.addNewBoundary,
      editExistingBoundary: Uploads.Actions.editExistingBoundary,
    }),

    ...mapMutations({
      setHoverData: Map.Mutations.setHoverData,
      setRightClickData: Map.Mutations.setRightClickData,
      toggleField: Filter.Mutations.toggleItem,
    }),

    fillFunction(d) {
      if (this.fields.find(field => field.geometry === d.geometry)) {
        return true
      }
      return false
    },

    boundsStyle(feature) {
      return {
        color: "#FFCC00",
        weight: 1,
        opacity: 0.85,
        fill: this.fillFunction(feature),
      }
    },

    openEditFieldName() {
      this.clickedEditFieldNameOption = true
      this.showEditSection = true
    },

    getBoundLayerByFieldId(fieldId) {
      for (let boundsLayerIdx in this.boundaries) {
        if (fieldId === this.boundaries[boundsLayerIdx].properties.field.id) {
          const multiPolygon = this.boundaries[boundsLayerIdx]
          multiPolygon.geometry.coordinates.forEach(coordinates => {
            const polygonLayer = this.multiPolygonToPolygon(
              coordinates,
              multiPolygon.centroid,
              multiPolygon.properties
            )
            new L.GeoJSON(polygonLayer, {
              style: this.boundsStyle,
              onEachFeature: (feature, layer) => {
                layer.id = L.stamp(layer)
                layer.isNewBoundary = false
                this.drawnFeatures.addLayer(layer)
              },
            })

            return
          })
        }
      }
    },

    getBoundLayers() {
      for (let boundsLayerIdx in this.boundaries) {
        const multiPolygon = this.boundaries[boundsLayerIdx]
        multiPolygon.geometry.coordinates.forEach(coordinates => {
          const polygonLayer = this.multiPolygonToPolygon(
            coordinates,
            multiPolygon.centroid,
            multiPolygon.properties
          )
          new L.GeoJSON(polygonLayer, {
            style: this.boundsStyle,
            onEachFeature: (feature, layer) => {
              layer.id = L.stamp(layer)
              layer.isNewBoundary = false
              this.drawnFeatures.addLayer(layer)
            },
          })
        })
      }
    },

    getOsmLayer() {
      let osmLayer = L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
        {
          attribution:
            '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          minZoom: 4,
          maxZoom: 20,
        }
      )

      if (this.mapViewOption === "openstreetmap") {
        osmLayer = L.tileLayer(
          `https://tile.openstreetmap.org/{z}/{x}/{y}.png`,
          {
            attribution:
              '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            minZoom: 4,
            maxZoom: 20,
            className: "map-tiles",
          }
        )
      }

      return osmLayer
    },

    updateMap() {
      let osmLayer = this.getOsmLayer()
      if (this.map) {
        this.map.eachLayer(layer => {
          if (layer.myTag) {
            if (layer.myTag == "boundsGeoJSON") {
              layer.setStyle({
                fill: this.fillFunction(layer.feature),
              })
            }
          }
        })
      } else {
        this.map = L.map(document.querySelector("#mapContainer"))
        osmLayer.addTo(this.map)

        // If no fields, set map to center of US
        if (this.bounds.coordinates.length === 0) {
          this.map.setView([39.8283, -98.5795], 5)
        }

        this.map.on("mousemove", this.handleMapHover)
        this.map.on("click", this.handleMapClick)
        this.map.on("dblclick", this.handleMapDoubleClick)
        this.map.on("contextmenu", this.handleMapRightClick)

        this.map.features = {}

        // FeatureGroup is to store editable layers
        this.drawnFeatures = new L.FeatureGroup().addTo(this.map)
        this.getBoundLayers()

        var drawControl = new L.Control.Draw({
          draw: {
            marker: false,
            line: false,
            rectangle: false,
            circle: false,
            circlemarker: false,
            polyline: false,
          },
        })
        this.map.addControl(drawControl)

        this.map.addControl(L.Control.geocoder())

        // Switch between satellite and street view
        this.viewBtn = L.easyButton("fa-globe", this.switchView, "Switch View")
        this.viewBtn.addTo(this.map)
        this.viewBtn.setPosition("topright")

        // Reset Zoom View
        this.resetZoomBtn = L.easyButton(
          "fa-crosshairs",
          this.resetZoom,
          "Reset zoom"
        )
        this.resetZoomBtn.addTo(this.map)
      }

      // Draw Created Event Handler
      this.map.on("draw:created", function(e) {
        var layer = e.layer
        this.recentlyCreatedLayer = layer
        this.recentlyCreatedLayer.id = L.stamp(layer)
        this.recentlyCreatedLayer.isNewBoundary = true
        this.recentlyCreatedLayer.feature = {
          type: "Feature",
          properties: {
            field: {
              acreage: null,
              id: null,
              farm: {
                client_id: null,
                client_name: null,
                id: null,
                name: null,
                org_node_id: null,
              },
              name: null,
            },
          },
        }
      })

      this.map.on("draw:created", this.drawCreated)
      this.map.on("draw:editvertex", this.saveChanges)

      this.showSnackBar(
        true,
        "blue",
        5000,
        "Select an existing field or click the polygon icon on the top left to create a new boundary."
      )
      osmLayer = null
    },

    zoomToBounds() {
      if (this.bounds.coordinates.length === 0 || !this.map) return
      const geoJSON = L.geoJson(this.bounds)
      this.map.fitBounds(geoJSON.getBounds())
    },

    zoomToSelectedField(selectedField) {
      if (selectedField.geometry.coordinates.length === 0 || !this.map) return
      const geoJSON = L.geoJson(selectedField)
      this.map.fitBounds(geoJSON.getBounds())
    },

    findFieldFromLatLng(latlng) {
      const { lat, lng } = latlng
      const pt = point([lng, lat])

      const drawnLayers = this.drawnFeatures.getLayers()
      return drawnLayers.find(layer => {
        const layerGeoJson = layer.toGeoJSON()
        const fieldMPoly = polygon(layerGeoJson.geometry.coordinates)
        return booleanPointInPolygon(pt, fieldMPoly)
      })
    },

    findSelectedFieldFromLatLng(latlng) {
      const { lat, lng } = latlng
      const pt = point([lng, lat])
      return this.fields.find(field => {
        const fieldMPoly = multiPolygon(field.geometry.coordinates)
        return booleanPointInPolygon(pt, fieldMPoly)
      })
    },

    multiPolygonToPolygon(coordinates, centroid, properties) {
      return {
        centroid: centroid,
        geometry: {
          type: "Polygon",
          coordinates: coordinates,
        },
        properties: properties,
        type: "Feature",
      }
    },

    handleMapDoubleClick(e) {
      const { latlng } = e
      this.setRightClickData()
      this.clickedLayer = this.findFieldFromLatLng(latlng)
      if (!this.clickedLayer) {
        this.resetZoom()
        this.resetPastureInfo()
      }
    },

    handleMapClick(e) {
      const { latlng } = e

      if (this.rightClickData) {
        this.setRightClickData()
        return
      }

      this.resetLeftPanel()

      this.clickedLayer = this.findFieldFromLatLng(latlng)
      if (this.clickedLayer) {
        this.drawnFeatures.eachLayer(layer => {
          if (layer.id !== this.clickedLayer.id) layer.editing.disable()
        })

        this.clickedLayer.editing.enable()

        const clickedField = this.clickedLayer.toGeoJSON()
        if (clickedField.properties.field) {
          const field = clickedField.properties.field
          this.selectedOrg = field.farm.org_node_id
            ? this.organizationOptions.find(
                org => org.id === field.farm.org_node_id
              ).id
            : null
          this.selectedClient = field.farm.client_id
            ? this.clientOptions.find(
                client => client.id === field.farm.client_id
              )
            : field.farm.client_name
          this.selectedFarm = field.farm.id
            ? this.farmOptions.find(farm => farm.id === field.farm.id)
            : field.farm.name
          this.fieldName = field.name

          if (field.acreage)
            this.totalAreaBySelectedUnit =
              this.selectedUnits === "acres"
                ? field.acreage
                : this.convertAreaToSelectedUnit(field.acreage)
          else this.calculateTotalArea(clickedField)

          let showText =
            "Drag points to adjust existing boundary. Tap between points to add a point."
          if (this.clickedLayer.isNewBoundary) {
            showText =
              "Drag points to adjust new boundary. Tap between points to add a point."
          }
          this.showSnackBar(true, "blue", -1, showText)
        }

        this.zoomToSelectedField(clickedField)
        this.isClickedLayerEqualOrgPolygon = this.isEqualPolygon(
          this.clickedLayer
        )
      } else {
        this.drawnFeatures.eachLayer(layer => {
          layer.editing.disable()
        })
        this.resetPastureInfo()
        this.showSnackBar(false, "blue", -1, null)
      }
    },

    handleMapRightClick(e) {
      const { latlng } = e
      const rightClickedLayer = this.findFieldFromLatLng(latlng)

      const { x, y } = e.containerPoint
      if (this.clickedLayer === rightClickedLayer) {
        if (this.clickedLayer.isNewBoundary) {
          this.setRightClickData({
            x,
            y,
            options: [{ text: "Delete Boundary", value: "delete-boundary" }],
          })
        } else {
          this.setRightClickData({
            x,
            y,
            options: [
              { text: this.editFieldLabel, value: "edit-field-name" },
              { text: "Delete Boundary", value: "delete-boundary" },
            ],
          })
        }
      } else {
        this.setRightClickData()
      }
    },

    handleMenuOptionClicked(option) {
      if (option === "delete-boundary")
        this.confirmBeforeDeletingBoundary = true
      else if (option === "edit-field-name") {
        this.clickedEditFieldNameOption = true
        this.showEditSection = true
      }
    },

    deleteUnsavedBoundary() {
      const fieldName = this.clickedLayer.feature.properties.field.name
      delete this.map.features[this.clickedLayer.id]
      this.drawnFeatures.removeLayer(this.clickedLayer)
      this.clickedLayer = null
      this.confirmBeforeDeletingBoundary = false
      this.showSnackBar(
        true,
        "green",
        2500,
        `Successfully deleted boundary [${fieldName}]`
      )
    },

    async deleteBoundary() {
      const fieldId = this.clickedLayer.feature.properties.field.id
      await FieldsAPI.fieldDelete(fieldId)
        .then(() => {
          delete this.map.features[this.clickedLayer.id]
          this.drawnFeatures.removeLayer(this.clickedLayer)
          this.clickedLayer = null
          this.confirmBeforeDeletingBoundary = false
          this.showSnackBar(
            true,
            "green",
            3500,
            `Field Deletion Request Received. Please Refresh The Application In A Few Minutes.`
          )
        })
        .catch(error => {
          this.errorMessage = "Unable to delete field:"
          this.showSnackBar(true, "red", 5000, error.response.data.message)
          this.confirmBeforeDeletingBoundary = false
        })
    },

    clearUnsavedBoundary() {
      if (this.clickedLayer.feature.properties.field.id) {
        const fieldId = this.clickedLayer.feature.properties.field.id
        const fieldName = this.clickedLayer.feature.properties.field.name
        delete this.map.features[this.clickedLayer.id]
        this.drawnFeatures.removeLayer(this.clickedLayer)
        this.clickedLayer = null
        this.getBoundLayerByFieldId(fieldId)
        this.confirmBeforeClearingChanges = false
        this.showSnackBar(
          true,
          "green",
          2500,
          `Successfully cleared unsaved changes for Field [${fieldName}]`
        )
      }
    },

    handleMapHover(e) {
      const { latlng } = e
      const hoveredField = this.findSelectedFieldFromLatLng(latlng)
      if (hoveredField) {
        const { field } = hoveredField.properties
        const { x, y } = e.containerPoint
        const totalArea =
          this.selectedUnits === "acres"
            ? field.acreage
            : this.convertAreaToSelectedUnit(field.acreage)
        this.setHoverData({
          x,
          y,
          fieldName: field.name,
          farmName: field.farm.name,
          acreage: totalArea,
        })
      } else {
        this.setHoverData()
      }
    },

    cancelChanges() {
      // Remove the new layer previously added from the map
      this.clickedLayer.editing.disable()
      this.clickedLayer = null
      this.resetZoom()
      this.resetPastureInfo()
      this.showSnackBar(false, "blue", -1, null)
    },

    // validateUpdatedFieldName() {
    //   console.log("this.selectedFarm.name: ", this.selectedFarm.name)
    //   this.allFields.forEach(field => {
    //     console.log("field: ", field)
    //     const farmName = this.farmOptions.find(farm => farm.id === field.farmId) ? this.farmOptions.find(farm => farm.id === field.farmId).name : null
    //     console.log("farm name: ", farmName)
    //     if (field.name === this.fieldName && this.selectedFarm.name === farmName) {
    //       this.showSnackBar(true, "red", 5000, "Field name already exists. Please enter a different name.")
    //       return false
    //     }
    //   })
    //   return true
    // },

    resetLeftPanel() {
      this.clickedEditFieldNameOption = false
      this.showEditSection = false
      this.showInfoSection = false
    },

    async editFieldName() {
      const payload = {
        fieldId: this.clickedLayer.feature.properties.field.id,
        farmId: this.clickedLayer.feature.properties.field.farm.id,
        fieldName: this.newFieldName,
      }

      await FieldsAPI.updateFieldName(payload)
        .then(() => {
          this.clickedLayer.feature.properties.field.name = this.newFieldName
          this.fieldName = this.newFieldName
          this.showSnackBar(
            true,
            "blue",
            5000,
            "Field name updated successfully."
          )
          this.resetLeftPanel()
        })
        .catch(error => {
          this.showSnackBar(true, "red", 5000, error.response.data.message)
        })
    },

    confirmChanges() {
      this.map.features[this.clickedLayer.id] = this.clickedLayer.toGeoJSON()

      // var leafletId = this.clickedLayer.id
      Object.keys(this.map.features).forEach(key => {
        const feature = this.map.features[key]
        const field = feature.properties.field
        const properties = {
          organization_id: field.farm.org_node_id,
          client: field.farm.client_name
            ? field.farm.client_name
            : this.clientOptions.find(
                client => client.id === field.farm.client_id
              ).name,
          farm: field.farm.name,
          field: field.name,
          fieldId: field.id,
        }
        feature.properties = properties
      })

      this.uploadChanges()
    },

    saveChanges() {
      var editedPolygon = this.clickedLayer.toGeoJSON()
      var isOverlapping = this.isEditedPolygonOverlapping(editedPolygon)
      if (isOverlapping) {
        this.showSnackBar(
          true,
          " red",
          2500,
          "The new boundary is overlapping with other field(s). Please redraw the boundary."
        )
        this.clearChanges()
      } else {
        this.map.features[this.clickedLayer.id] = this.clickedLayer.toGeoJSON()
        this.map.features[
          this.clickedLayer.id
        ].properties.field.acreage = this.calculateTotalArea(
          this.map.features[this.clickedLayer.id]
        )
        this.isClickedLayerEqualOrgPolygon = this.isEqualPolygon(
          this.clickedLayer
        )
      }
    },

    resetZoom() {
      this.zoomToBounds()
    },

    switchView() {
      // Remove the current tile layer from the map
      let currentOsmLayer = this.getOsmLayer()
      this.map.removeLayer(currentOsmLayer)

      // Update the tile layer based on the map view option
      if (this.mapViewOption === "openstreetmap")
        this.mapViewOption = "satellite"
      else this.mapViewOption = "openstreetmap"
      let osmLayer = this.getOsmLayer()
      osmLayer.addTo(this.map)
    },

    clearChanges() {
      // existing field
      if (this.clickedLayer.feature.properties.field.id) {
        const fieldId = this.clickedLayer.feature.properties.field.id
        delete this.map.features[this.clickedLayer.id]
        this.drawnFeatures.removeLayer(this.clickedLayer)
        this.clickedLayer = null
        this.getBoundLayerByFieldId(fieldId)
      }

      // newly created field but not yet saved in the system
      else {
        delete this.map.features[this.clickedLayer.id]
        this.drawnFeatures.removeLayer(this.clickedLayer)
        this.clickedLayer = null
      }
    },

    uploadChanges() {
      var featureGroup = []
      if (Object.keys(this.map.features)) {
        featureGroup.push()
      }
      Object.keys(this.map.features).forEach(id => {
        featureGroup.push(this.map.features[id])
      })

      // Extract GeoJson from featureGroup
      // Iterate through each and generate the shapefile for each field
      //  and run it through our upload process pipeline
      if (featureGroup.length > 0) {
        featureGroup.forEach(feature => {
          const properties = feature.properties

          const todayDate = moment().format("YYYY-MM-DD_h:mm:ss")
          const folderName = `Field_${properties.field}_CreatedBy_BoundaryEditor_${todayDate}`

          const content = shpWrite.zip(
            {
              type: "FeatureCollection",
              features: [feature],
            },
            {
              folder: folderName,
              types: {
                point: "mypoints",
                polygon: "mypolygons",
                line: "mylines",
              },
            }
          )

          // Decode the base64 string to a binary string
          var binaryString = atob(content)

          // Create an array of bytes from the binary string
          var bytes = new Uint8Array(binaryString.length)
          for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i)
          }

          // Create a zip file blob from the array of bytes
          var zipFileBlob = new Blob([bytes.buffer], {
            type: "application/zip",
          })

          // Create a zip file object from the zip file blob
          var zipFileObject = new File([zipFileBlob], folderName + ".zip")

          if (this.clickedLayer.isNewBoundary) {
            this.addNewBoundary({
              orgId: properties.organization_id,
              datasetType: "boundary",
              file: zipFileObject,
              fieldName: properties.field,
            })

            let message =
              "Field boundary has been saved. However, the newly created Field will not appear in the application until after the data aggregation and AI processing pipelines have completed."
            if (this.isRangeForce) {
              message =
                "Pasture boundary has been saved. However, the newly created Pasture will not appear in the application until after the data aggregation and AI processing pipelines have completed."
            }

            this.showSnackBar(true, "green", 5000, message)
          } else {
            this.editExistingBoundary({
              orgId: properties.organization_id,
              datasetType: "boundary",
              file: zipFileObject,
              fieldId: properties.fieldId,
            })

            let message =
              "Field boundary has been updated. However, the newly updated Field will not appear in the application until after the data aggregation and AI processing pipelines have completed."
            if (this.isRangeForce) {
              message =
                "Pasture boundary has been updated. However, the newly updated Pasture will not appear in the application until after the data aggregation and AI processing pipelines have completed."
            }

            this.showSnackBar(true, "green", 5000, message)
          }
        })
      }

      this.map.features = {}
      this.isClickedLayerEqualOrgPolygon = true
    },

    drawCreated() {
      var createdPolygon = this.map.recentlyCreatedLayer.toGeoJSON()
      var isOverlapping = this.isCreatedPolygonOverlapping(createdPolygon)
      if (isOverlapping) {
        this.map.removeLayer(this.map.recentlyCreatedLayer)
        delete this.map.features[this.map.recentlyCreatedLayer.id]
        delete this.map.recentlyCreatedLayer
      } else {
        this.calculateTotalArea(this.map.recentlyCreatedLayer.toGeoJSON())
        this.map.recentlyCreatedLayer.feature.properties.field.acreage = this.totalAreaBySelectedUnit
        this.clickedLayer = this.map.recentlyCreatedLayer
        this.map.features[this.clickedLayer.id] = this.clickedLayer.toGeoJSON()
        this.drawnFeatures.addLayer(this.map.recentlyCreatedLayer)
        this.isClickedLayerEqualOrgPolygon = false
      }
    },

    resetPastureInfo() {
      this.selectedOrg = null
      this.selectedClient = null
      this.selectedFarm = null
      this.fieldName = null
      this.totalAreaBySelectedUnit = null
    },

    isEqualPolygon() {
      if (this.clickedLayer.feature.geometry) {
        var originalPolygon = this.clickedLayer.feature.geometry
        var modifiedPolygon = this.clickedLayer.toGeoJSON().geometry
        return booleanEqual(modifiedPolygon, originalPolygon)
      }
      return false
    },

    isCreatedPolygonOverlapping(createdPolygon) {
      for (let boundsLayerIdx in this.boundaries) {
        var boundary = this.boundaries[boundsLayerIdx]
        var existingPolygon = polygon(boundary.geometry.coordinates[0])
        var isIntersecting = booleanIntersects(createdPolygon, existingPolygon)
        var isOverlapping = booleanOverlap(createdPolygon, existingPolygon)

        if (isIntersecting || isOverlapping) {
          return true
        }
      }
      return false
    },

    isEditedPolygonOverlapping(editedPolygon) {
      const editedBbox = bbox(editedPolygon)

      const existingBboxes = this.boundaries.map(boundary => {
        if (
          boundary.properties.field.id !== editedPolygon.properties.field.id
        ) {
          return {
            fieldId: boundary.properties.field.id,
            bbox: bbox(boundary),
          }
        }
      })
      const bboxesOverlap = (a, b) => {
        // a.minX < b.maxX && a.maxX > b.minX && a.minY < b.maxY && a.maxY > b.minY
        return a[0] <= b[2] && a[2] >= b[0] && a[1] <= b[3] && a[3] >= b[1]
      }
      const overlappingBboxes = existingBboxes.filter(
        bbox => bbox && bboxesOverlap(bbox.bbox, editedBbox)
      )

      const boundariesToCheck = this.boundaries.filter(boundary =>
        overlappingBboxes
          .map(bbox => bbox.fieldId)
          .includes(boundary.properties.field.id)
      )
      for (let boundsLayerIdx in boundariesToCheck) {
        var boundary = boundariesToCheck[boundsLayerIdx]
        var existingPolygon = polygon(boundary.geometry.coordinates[0])
        if (
          editedPolygon.properties.field.id !== boundary.properties.field.id
        ) {
          var isIntersecting = booleanIntersects(editedPolygon, existingPolygon)
          var isOverlapping = booleanOverlap(editedPolygon, existingPolygon)

          if (isIntersecting || isOverlapping) {
            return true
          }
        }
      }
      return false
    },

    calculateTotalArea(featureGroup) {
      var data = {
        features: [featureGroup],
        type: "FeatureCollection",
      }

      const area = turfArea(data)
      // Restrict the area to 2 decimal points.
      const totalAreaInSquareMeters = Math.round(area * 100) / 100
      this.totalAreaBySelectedUnit = this.convertAreaToSelectedUnit(
        Math.round(totalAreaInSquareMeters / 4047)
      )
    },

    convertAreaToSelectedUnit(totalAreaInAcres) {
      // If selected unit is in hectares, then convert the area from acres to hectares
      // Else return the area in acres
      if (this.selectedUnits == "hectares") {
        return Math.round(totalAreaInAcres * 0.4046856422 * 100) / 100
      }
      return totalAreaInAcres
    },

    showSnackBar(showFlag, color, displayTime, text) {
      this.snackText = text
      this.showSnack = showFlag
      this.timeout = displayTime
      this.color = color
    },

    handleInputForClient(newValue) {
      if (this.clickedLayer) {
        if (typeof newValue === "object") newValue = newValue.name
        this.clickedLayer.toGeoJSON().properties.field.farm.client_name = newValue
        if (this.clickedLayer.isNewBoundary)
          this.map.features[
            this.clickedLayer.id
          ].properties.field.farm.client_name = newValue
      }
    },

    handleBlurForClient(event) {
      if (this.clickedLayer) {
        this.clickedLayer.toGeoJSON().properties.field.farm.client_name =
          event.target.value
        if (this.clickedLayer.isNewBoundary)
          this.map.features[
            this.clickedLayer.id
          ].properties.field.farm.client_name = event.target.value
      }
    },

    handleInputForFarm(newValue) {
      if (this.clickedLayer) {
        if (typeof newValue === "object") newValue = newValue.name
        this.clickedLayer.toGeoJSON().properties.field.farm.name = newValue
        if (this.clickedLayer.isNewBoundary)
          this.map.features[
            this.clickedLayer.id
          ].properties.field.farm.name = newValue
      }
    },

    handleBlurForFarm(event) {
      if (this.clickedLayer) {
        this.clickedLayer.toGeoJSON().properties.field.farm.name =
          event.target.value
        if (this.clickedLayer.isNewBoundary)
          this.map.features[this.clickedLayer.id].properties.field.farm.name =
            event.target.value
      }
    },
  },

  mounted() {
    let paywayScript = document.createElement("script")
    let self = this
    paywayScript.onload = () => {
      self.updateMap()
      if (self.lastMapAction === "click") {
        self.lastMapAction = null
        return
      }
      self.zoomToBounds()
    }
    paywayScript.setAttribute(
      "src",
      "https://unpkg.com/leaflet.vectorgrid@1.3.0"
    )
    document.body.appendChild(paywayScript)
  },
}
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
}

.page {
  position: relative;
}

#map {
  height: calc(100vh - 65px);
  position: relative;
  background: #1b1b1d;
  margin: -17px -29px -17px -29px;
}
#modalMap {
  height: calc(40vh - 25px);
  width: calc(40vw - 25px);
  position: relative;
  background: #1b1b1d;
  margin: 10px;
}

.map-wrapper {
  height: calc(100vh - 65px);
  width: 100%;
  position: relative;
  background: #1b1b1d;
}

.leafletToolTip {
  margin: 2em;
  background: rgba(24, 24, 24, 0.9);
  color: rgb(200, 186, 175);
  border: none;
  /*z-index: 1000;*/
}

.leaflet-fade-anim .leaflet-tile,
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: auto !important;
}

.legend_info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.legend_info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend_info {
  line-height: 18px;
  color: #555;
}

.legend_info i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.leaflet-top {
  z-index: 400;
}

.footer-row {
  border-top: 1px solid #e9ecef;
  margin: 0px;
}

.btn-row {
  padding: 10px 40px;
}

.snack-bar-full-map {
  margin-top: 100px;
}

.snack-bar-half-map {
  margin-top: 100px;
  margin-left: 300px !important;
}

.active-tab {
  color: black !important;
}

:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg)
    saturate(0.3) brightness(0.7);
}

@media (prefers-color-scheme: dark) {
  .map-tiles {
    filter: var(--map-tiles-filter, none);
  }
}
</style>
