<template>
  <b-tr>
    <!-- dates -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(grazing.date_start)"
    >
      <!-- <div class="d-flex pl-2"> -->
      <v-menu
        v-model="showStartDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="startDate"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="
                  grazing.date_start.source !== 'User Input' &&
                  grazing.date_start.value !== null
                    ? 'mdi-check-circle-outline'
                    : ''
                "
                @click:append="handleClickSubmit('date_start', startDate)"
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="startDate"
          :min="year + '-01-01'"
          :max="year + '-12-31'"
          @input="showDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
      <!-- </div> -->
    </b-td>
    <!-- grazing end date -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(grazing.date_end)"
    >
      <v-menu
        v-model="showEndDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="endDate"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="
                  grazing.date_end.source !== 'User Input' &&
                  grazing.date_end.value !== null
                    ? 'mdi-check-circle-outline'
                    : ''
                "
                @click:append="handleClickSubmit('date_end', endDate)"
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="endDate"
          :min="year + '-01-01'"
          :max="year + '-12-31'"
          @input="showDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- animal type -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(grazing.animal_type)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="animalChoices"
        item-text="display_name"
        item-value="value"
        v-model="animalType"
        :append-icon="
          grazing.animal_type.source !== 'User Input' &&
          grazing.animal_type.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @click:append="handleClickSubmit('animal_type', animalType)"
      />
    </b-td>
    <!-- stocking rate -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(grazing.stocking_rate)"
    >
      <v-text-field
        clearable
        dense
        v-model="stockingRate"
        hide-details="true"
        :append-icon="
          grazing.stocking_rate.source !== 'User Input' &&
          grazing.stocking_rate.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 'stocking-rate')"
        @click:append="handleClickSubmit('stocking_rate', stockingRate)"
      >
      </v-text-field>
    </b-td>
    <!-- rest period -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(grazing.rest_period)"
    >
      <v-text-field
        clearable
        dense
        v-model="restPeriod"
        hide-details="true"
        suffix="days"
        :append-icon="
          grazing.rest_period.source !== 'User Input' &&
          grazing.rest_period.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 'rest-period')"
        @click:append="handleClickSubmit('rest_period', restPeriod)"
      >
      </v-text-field>
    </b-td>
    <!-- utilization percentage -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(grazing.utilization_pct)"
    >
      <v-text-field
        clearable
        dense
        v-model="utilizationPct"
        hide-details="true"
        suffix="%"
        :append-icon="
          grazing.utilization_pct.source !== 'User Input' &&
          grazing.utilization_pct.value !== null
            ? 'mdi-check-circle-outline'
            : ''
        "
        @keypress="filter($event, 'utilization-pct')"
        @click:append="handleClickSubmit('utilization_pct', utilizationPct)"
      >
      </v-text-field>
    </b-td>
    <b-td class="cell-table white">
      <v-btn icon x-small color="black" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="black" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations } from "vuex"
import { CROP_DROPDOWN_OPTIONS } from "@/constants"
import { GRAZING_ANIMAL_CHOICES } from "@/constants/defaults"
import { Defaults } from "@/store/modules"
import { getClassForCell } from "../utils"

export default {
  name: "GrazingRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    grazing: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  data() {
    return {
      cropChoices: CROP_DROPDOWN_OPTIONS,
      animalChoices: GRAZING_ANIMAL_CHOICES,
      showStartDate: false,
      showEndDate: false,
      getClassForCell
    }
  },
  computed: {
    crop: {
      get() {
        return this.grazing.crop_id.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "grazings",
          dataSetting: "crop_id",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    startDate: {
      get() {
        const dateReturn = this.grazing.date_start.value
          ? this.grazing.date_start.value
          : `${this.year}-01-01`
        return dateReturn
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "grazings",
          dataSetting: "date_start",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    endDate: {
      get() {
        const dateReturn = this.grazing.date_end.value
          ? this.grazing.date_end.value
          : `${this.year}-01-01`
        return dateReturn
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "grazings",
          dataSetting: "date_end",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    grazingDates: {
      get() {
        return {
          start: this.grazing.date_start.value,
          end: this.grazing.date_end.value,
        }
      },
      set(val) {
        // this.grazing.date_start.value = val.start
        const startPayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "grazings",
          dataSetting: "date_start",
          value: val.start,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(startPayload)

        const endPayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "grazings",
          dataSetting: "date_end",
          value: val.end,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(endPayload)
        // this.grazing.date_end.value = val.end

        // this.grazing.date_start.source = "User Input"
        // this.grazing.date_end.source = "User Input"
      },
    },
    animalType: {
      get() {
        return this.grazing.animal_type.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "grazings",
          dataSetting: "animal_type",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    stockingRate: {
      get() {
        return this.grazing.stocking_rate.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "grazings",
            dataSetting: "stocking_rate",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    restPeriod: {
      get() {
        return this.grazing.rest_period.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "grazings",
            dataSetting: "rest_period",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    utilizationPct: {
      get() {
        return this.grazing.utilization_pct.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "grazings",
            dataSetting: "utilization_pct",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    handleClickSubmit(setting, val) {
      this[setting] = val
    },
    handleCopy() {
      this.$emit("handle-copy", this.grazing)
    },
    handleAdd() {
      const payloadObj = {
        animal_type: { source: "User Input", value: null },
        crop_id: { source: "User Input", value: null },
        date_start: { source: "User Input", value: null },
        date_end: { source: "User Input", value: null },
        rest_period: { source: "User Input", value: null },
        stocking_rate: { source: "User Input", value: null },
        utilization_pct: {
          source: "User Input",
          value: null,
        },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "grazings",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.grazing)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "grazings",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24, range = null) {
      function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return (
          !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ) // ...and ensure strings of whitespace fail
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          if (range !== null) {
            if (val >= range[0] && val <= range[1]) {
              return true
            } else {
              this.$emit(
                "bad-input",
                `Look out! Only a value between ${range[0]} and ${range[1]} can be entered here.`
              )
              return false
            }
          }
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      }
    },
    filter(event, fieldInput) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let range = null
      let inputType = "number"

      if (fieldInput === "stocking-rate") range = [0, 10000]
      else if (fieldInput === "utilization-pct") range = [0, 100]

      let validVal = this.handleValidateValue(expect, inputType, 24, range)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.arva-generated {
  background-color: #fedb80;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-table {
  /* padding: 0; */
  padding: 2px 6px;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.machine {
  background-color: #dbe6d6;
}
.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.copy-col {
  min-width: 44px;
}

.del-col {
  min-width: 40px;
}

.v-input {
  font-size: 18px;
}
</style>
