<template>
  <div class="px-3">
    <SampleLocationTestModal
      v-if="sampleLocationTestModalOpen"
      :location="current_location"
      :selectedPlan="selectedPlan"
      @close-template-modal="closeSampleLocationTestModal"
    />

    <SampleLocationCollectorModal
      v-if="sampleLocationCollectorModalOpen"
      :selectedPlan="selectedPlan"
      :samplesLocationsToList="samplesLocationsToList"
      @close-template-modal="closeSampleLocationCollectorModal"
    />

    <DeleteSoilPlanModal
      v-if="deleteSoilPlanModalOpen"
      titleText="Delete this soil plan?"
      :planName="planToDelete.name"
      @close-modal="closeDeleteSoilPlanModal"
      @confirm-delete="handleConfirmDeletePlan"
    />

    <!-- list of sampling plans -->
    <div v-if="view === 'list'">
      <div v-if="samplingPlansFiltered.length > 0">
        <v-card>
          <v-card-text>
            <div
              v-if="isPlanLoading"
              class="mt-3 d-flex justify-content-center"
            >
              <div class="text-bold mb-0">Fetching Data</div>
              <div class="spinner-border ml-4" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <v-row>
              <v-col class="d-flex" cols="3">
                <v-select
                  v-model="collectorFilter"
                  :items="eligibleCollectors"
                  item-text="short_name"
                  item-value="id"
                  label="COLLECTORS"
                  placeholder="COLLECTORS"
                  chips
                  deletable-chips
                  solo
                  multiple
                ></v-select>
              </v-col>
            </v-row>
            <v-data-table
              :headers="planListHeaders"
              :items="samplingPlansSortedVDataTable"
              :search="searchPlan"
              :sort-by="['id']"
              :sort-desc="true"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="searchPlan"
                  label="Search"
                  class="mx-4"
                ></v-text-field>
              </template>

              <template slot="item.name" slot-scope="props">
                <span
                  class="hover blue--text font-weight-bold"
                  @click="handleSamplingPlanSelect(props.item)"
                  style="max-width:100px"
                >
                  {{ props.item.name.replace(/_/g, " ") }}
                  <v-icon color="blue">
                    mdi-arrow-top-right-bold-box-outline
                  </v-icon>
                </span>
              </template>
              <!-- -->
              <template slot="item.collection_enabled" slot-scope="props">
                <i
                  v-if="!props.item.collection_enabled"
                  class="fa fa-times-circle"
                  @click="handleOpenDeletePlanModal(props.item)"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>

      <div
        v-else-if="
          samplingPlansFiltered.length < 1 &&
            reallyFinishedLoading &&
            !selectedEnrollment &&
            showPlansByEnrollment
        "
        class="my-3 d-flex justify-content-center"
        justify="center"
      >
        <div class="text-h6 mb-0 my-3">
          <span>Please Select an Enrollment to View Plans</span>
        </div>
      </div>
      <div
        v-else-if="
          samplingPlans.length < 1 &&
            reallyFinishedLoading &&
            !selectedEnrollment
        "
        class="my-3 d-flex justify-content-center"
        justify="center"
      >
        <div class="text-h6 mb-0 my-3">
          <span>No Plans Found</span>
          <v-btn class="ml-3 flex" @click="handleCreateEnrollPlan"
            >Create New Plan</v-btn
          >
        </div>
      </div>
      <div
        v-else-if="
          samplingPlans.length < 1 &&
            reallyFinishedLoading &&
            selectedEnrollment
        "
        class="my-3 d-flex justify-content-center"
        justify="center"
      >
        <div class="text-h6 mb-0 my-3">
          <span
            >No Plans Found, create a plan for
            {{ selectedEnrollment.name }}?</span
          >
          <v-btn class="ml-3" @click="handleCreateEnrollPlan"
            >Create New Plan</v-btn
          >
        </div>
      </div>
      <div
        v-else-if="
          samplingPlansFiltered.length < 1 &&
            collectorFilter.length > 0 &&
            reallyFinishedLoading
        "
        class="my-3 d-flex justify-content-center"
        justify="center"
      >
        <div class="text-h6 mb-0 my-3">
          <h4>No Plans Found Matching Selected Field and Collector Filters</h4>
          <div>
            <v-select
              v-model="collectorFilter"
              :items="eligibleCollectors"
              item-text="short_name"
              item-value="id"
              label="COLLECTORS"
              placeholder="COLLECTORS"
              chips
              deletable-chips
              solo
              multiple
            ></v-select>
          </div>
        </div>
      </div>

      <div v-else class="my-3 d-flex justify-content-center" justify="center">
        <div class="text-bold mb-0 mt-1">Fetching Data</div>
        <div class="spinner-border ml-4" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <!-- create soil sample plans -->
    <div v-if="view === 'create'">
      <div>
        <!-- create view -->
        <div class="font-weight-bold">
          Create New Sampling Plan
        </div>
        <div>
          <div>Name</div>
          <input
            class="w-100 form-control"
            type="text"
            v-model="createPlanName"
          />
        </div>
        <div class="my-3">
          <div>Select Soil Test Type</div>
          <v-select-old
            :appendToBody="true"
            label="display_name"
            :options="testTypeOption"
            :reduce="o => o.value"
            v-model="testType"
          />
        </div>
        <div class="my-3">
          <div>Assign Soil Collector</div>
          <v-select-old
            :appendToBody="true"
            label="name"
            :options="eligibleCollectors"
            :reduce="o => o.id"
            v-model="collectorID"
          />
        </div>
        <div class="my-3">
          <div>Assign Soil Lab</div>
          <v-select-old
            :appendToBody="true"
            label="name"
            :options="eligibleLabs"
            :reduce="o => o.id"
            v-model="labID"
          />
        </div>
        <div class="my-3">
          Sample Depth Start/Stop
          <v-range-slider
            class="pb-3"
            strict
            v-model="depthRange"
            :min="0"
            :max="48"
            :ticks="true"
            :tick-labels="[...Array(49).keys()].map(n => n % 6 ? '' : n)"
            :interval="1"
            thumb-label
          />
        </div>
        <br />
        <div class="my-3">
          <v-switch
            v-model="usePreviousLocations"
            inset
            :label="
              `Reuse previously generated sample locations: ${usePreviousLocations.toString()}`
            "
          ></v-switch>
        </div>

        <!-- Also add collector id and sample id -->
        <br />
        <div class="d-flex mb-4">
          <v-btn class="mr-3" @click="viewList(true)">
            <v-icon>mdi-arrow-left-circle</v-icon>
            Plans List
          </v-btn>
          <v-btn @click="handleSamplingPlanSubmit">
            Submit Plan
            <div
              v-if="isPlanLoading"
              class="spinner-border spinner-border-sm text-light ml-1"
            ></div>
          </v-btn>
        </div>

        <div v-if="createPlanError">
          <div class="text-danger mb-4">
            <div>
              {{ errorText }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- individual plan view -->
    <div v-if="view === 'plan'">
      <div v-if="planLocations.length > 0">
        <!-- Plan General Info Card -->
        <v-card>
          <v-card-text>
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column mr-auto">
                <v-col>
                  <div class="flex-row">
                    <h4>{{ selectedPlan.name }} ({{ selectedPlan.id }})</h4>
                    <h6>{{ planLocations.length }} Locations</h6>
                  </div>
                </v-col>
              </div>
              <div
                class="toggle-checkbox toggle-sm toggle-success flex-row justify-content-between"
                v-if="selectedPlan.sampling_algorithm != 'autogenerate'"
              >
                <!--
                <input
                  type="checkbox"
                  class="checked"
                  id="enable_collection_checkbox"
                  v-model="enable_collection_checkbox"
                  value="enable_collection_checkbox"
                  @input="handlePlanStatusUpdate"
                />-->
                <!--<label for="enable_collection_checkbox"></label>
                <span class="ml-2">Enable Collection</span>-->
                <v-col>
                  <v-btn
                    class="ma-2 m-auto float-right"
                    @click="getSampleLocationCollectorModal"
                    small
                  >
                    Assign Sample Collector
                  </v-btn>
                  <v-switch
                    v-model="enable_collection_checkbox"
                    inset
                    :label="
                      `Collection Enabled: ${enable_collection_checkbox.toString()}`
                    "
                    @change="handlePlanStatusUpdate"
                    class="ma-2 m-auto float-right"
                  ></v-switch>
                </v-col>
              </div>
            </div>

            <div class="mt-1 pt-1 d-flex justify-content-between">
              <!-- <div class="mt-1 pt-1 d-flex"> -->
              <v-col>
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <v-btn class="mb-1 mr-3" @click="viewList(true)" small>
                      <v-icon>mdi-arrow-left-circle</v-icon>
                      Return to List
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col>
                <div class="d-flex flex-column">
                  <div class="flex-row-reverse ml-auto float-right">
                    <v-btn
                      class="mb-1 mr-1 float-right"
                      @click="handleExport('shapefile')"
                      small
                    >
                      <v-icon>mdi-download</v-icon>
                      Shapefile
                      <div
                        v-if="isZipExportLoading"
                        class="spinner-border spinner-border-sm text-light ml-1"
                        role="status"
                      ></div>
                    </v-btn>

                    <v-btn
                      class="mb-1 mr-1 float-right"
                      @click="handleExport('csv')"
                      small
                    >
                      <v-icon>mdi-download</v-icon>
                      Results
                      <div
                        v-if="isZipExportLoading"
                        class="spinner-border spinner-border-sm text-light ml-1"
                        role="status"
                      ></div>
                    </v-btn>

                    <v-btn
                      v-if="selectedPlan.label_pdf == null"
                      class="mb-1 mr-1 green float-right"
                      @click="handleExport('pdf')"
                      small
                    >
                      Generate New QR Code PDF
                    </v-btn>

                    <v-btn
                      v-else-if="selectedPlan.label_pdf == '-'"
                      class="mb-1 mr-1 float-right"
                      color="error"
                      text
                      small
                    >
                      Generate New QR Code Labels
                    </v-btn>

                    <v-btn
                      class="mb-1 mr-1 float-right"
                      @click="handlePDF"
                      small
                      v-else
                    >
                      <v-icon>mdi-download</v-icon>
                      QR Code PDF
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </div>
          </v-card-text>
        </v-card>
        <br />
        <!-- Sample Locations Card -->
        <v-card>
          <v-data-table
            :headers="locationsListHeaders"
            :items="samplesLocationsToListVDataTable"
            :search="searchLocations"
            :key="updateKey"
            :sort-by="['id']"
            :sort-desc="true"
            dense
          >
            <template v-slot:top>
              <v-text-field
                v-model="searchLocations"
                label="Search"
                class="mx-4"
              ></v-text-field>
            </template>

            <!-- -->
            <template slot="item.test_type" slot-scope="props">
              <v-btn plain @click="getSampleLocationTestModal(props.item)"
                ><span class="hover blue--text font-weight-bold">{{
                  displayTestType(props.item)
                }}</span></v-btn
              >
            </template>
            <template slot="item.zoom_to_location" slot-scope="props">
              <v-btn plain @click="zoomToMapLocation(props.item)">
                <span class="hover blue--text font-weight-bold"
                  ><v-icon>mdi-map-marker</v-icon></span
                >
              </v-btn>
            </template>
            <template slot="item.is_assigned" slot-scope="props">
              <a @click="getSampleLocationCollectorModal"
                ><span class="hover blue--text font-weight-bold">{{
                  props.item.is_assigned | capitalize
                }}</span></a
              >
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div v-else>
        <v-card class="mb-3">
          <v-card-title>
            <h5>Plan has been submitted for processing</h5>
          </v-card-title>
          <v-card-text>
            <v-btn @click="viewList(true)">
              <v-icon>mdi-arrow-left-circle</v-icon>
              Soil Plans List
            </v-btn>
          </v-card-text>
        </v-card>
      </div>

      <div
        v-if="isZipExportLoading"
        class="spinner-border spinner-border-sm text-light ml-1"
        role="status"
      ></div>
    </div>
  </div>
</template>
<script>
//import _ from "lodash"
import moment from "moment"

import { mapState, mapMutations } from "vuex"
import { Filter } from "@/store/modules"

import DeleteSoilPlanModal from "@/components/modals/DeleteSoilPlanModal"
import SampleLocationTestModal from "@/components/modals/SampleLocationTestModal"
import SampleLocationCollectorModal from "@/components/modals/SampleLocationCollectorModal"
import SamplingAPI from "@/api/SamplingAPI"

export default {
  name: "EnrollController",
  props: [
    "activeFields",
    "tab",
    "samplingPlans",
    "samplingControllerView",
    "eligibleCollectors",
    "eligibleLabs",
    "finishedLoading",
    "selectedEnrollment",
    "showPlansByEnrollment",
  ],
  components: {
    DeleteSoilPlanModal,
    SampleLocationTestModal,
    SampleLocationCollectorModal,
  },

  data() {
    return {
      depthRange: [0, 12],
      deleteSoilPlanModalOpen: false,
      sampleLocationTestModalOpen: false,
      sampleLocationCollectorModalOpen: false,
      current_location: null,
      actualCoordinateLayer: [],
      targetCoordinateLayer: [],
      samplingLocationDisplayTypeOptions: [
        {
          display_name: "Actual Sample Locations",
          value: "actual",
        },
        {
          display_name: "Requested Sample Locations",
          value: "target",
        },
      ],
      samplingLocationDisplayType: "target",
      planLocations: [],
      createPlanError: false,
      createPlanName: null,
      testTypeOption: [
        {
          display_name: "Bulk Density",
          value: "Bulk Density",
        },
        {
          display_name: "SOC",
          value: "SOC",
        },
        {
          display_name: "BD + SOC",
          value: "BD_SOC",
        },
        {
          display_name: "Haney Test",
          value: "Haney Test",
        },
        {
          display_name: "SOC & Haney",
          value: "Haney_SOC",
        },
        {
          display_name: "SOC & Haney + BD",
          value: "Haney_SOC_BD",
        },
      ],
      testType: null,
      isPlanLoading: false,
      isUpdatePlanLoading: false,
      isZipExportLoading: false,
      errorText: "",
      labID: null,
      collectorID: null,
      enable_collection_checkbox: false,
      current_field: null,
      planToDelete: null,
      //   sorting
      sortByFields: null,
      sortByDate: null,
      sortByName: null,
      sortById: null,
      sortByStatus: null,
      usePreviousLocations: false,
      planListHeaders: [
        { text: "ID", value: "id" },
        { text: "Plan Name", value: "name" },
        { text: "Date", value: "date" },
        { text: "Status", value: "status" },
        { text: "Fields", value: "num_fields" },
        { text: "Collector", value: "collector_name" },
        { text: "", value: "collection_enabled" },
      ],
      locationsListHeaders: [
        { text: "ID", value: "id" },
        { text: "Field", value: "field_name" },
        { text: "Status", value: "status" },
        { text: "Assigned", value: "is_assigned" },
        { text: "Test Type", value: "test_type" },
        { text: "Notes", value: "notes" },
        { text: "", value: "zoom_to_location" },
      ],
      searchPlan: "",
      searchLocations: "",
      updateKey: 0,
      collectorFilter: [],
    }
  },
  computed: {
    ...mapState({
      orgAgtCrops: state => state.Map.orgAgtCrops,
      years: state => state.Organization.organization.years,
      organization: state => state.Organization.organization,
      reallyFinishedLoading: state =>
        state.SoilSampling.finishedLoadingSamplePlans,
    }),

    samplingPlansSortedVDataTable() {
      let plansMapped = this.samplingPlansFiltered
      plansMapped.map(o => (o.num_fields = o.fields.length))
      plansMapped.map(o => (o.date = moment(o.created_at).format("YYYY-MM-DD")))
      plansMapped.map(o => (o.status = o.status.replace("_", " ")))

      if (this.eligibleCollectors.length > 0) {
        plansMapped.map(o => {
          let collector_obj = this.eligibleCollectors.find(e => {
            return e.id == o.collector
          })
          if (collector_obj != null) {
            o.collector_name = collector_obj.short_name
          } else {
            o.collector_name = ""
          }
        })
      }
      //need to load a list of eligible collectors/labs and add them to this
      return plansMapped
    },

    samplesLocationsToListVDataTable() {
      let locationsMapped = this.samplesLocationsToList
      locationsMapped.map(
        o => (o.is_assigned = (o.collector_user_id != null).toString())
      )
      locationsMapped.map(
        o => (o.field_name = this.selectedPlanFieldsMap[o.field])
      )
      locationsMapped.map(o => (o.test_type = this.displayTestType(o)))
      locationsMapped.map(o => (o.zoom_to_location = true))

      //will need to think about doing a multi note popup/edit here
      return locationsMapped
    },

    samplesLocationsToList() {
      return this.planLocations.filter(location =>
        this.activeFields.map(field => field.id).includes(location.field)
      )
    },

    samplingPlansFiltered() {
      const filtered = this.samplingPlans.filter(
        i =>
          i.status != "cancelled" &&
          i.fields
            .map(f => f.id)
            .some(r => this.activeFields.map(field => field.id).includes(r))
      )
      // collectorFilter
      if (this.collectorFilter.length > 0) {
        const filteredByCollector = filtered.filter(x => {
          return this.collectorFilter.includes(x.collector)
        })
        return filteredByCollector
      }

      // enrollment filter
      if (this.showPlansByEnrollment) {
        if (this.selectedEnrollment) {
          const secondFilter = filtered.filter(x => {
            if (!x.enrollment) return false
            return x.enrollment.uuid == this.selectedEnrollment.uuid
          })

          if (this.collectorFilter.length > 0) {
            const thirdFilter = secondFilter.filter(x => {
              return this.collectorFilter.includes(x.collector_name)
            })

            return thirdFilter
          }

          return secondFilter
        } else {
          return []
        }
      }
      return filtered
    },

    view() {
      return this.samplingControllerView
    },
  },

  methods: {
    ...mapMutations({
      toggleItem: Filter.Mutations.toggleItem,
      clearAll: Filter.Mutations.clearAll,
    }),

    displayTestType(d) {
      let lut = ["Empty", "BD", "SOC", "Haney"] //make sure this aligns with DB!!!, keept empty first column
      const ex = d.sample_tests.map(test => lut[test.id]).join(",")
      return ex
    },

    getSampleLocationTestModal(d) {
      this.current_location = d
      this.sampleLocationTestModalOpen = true
    },

    closeDeleteSoilPlanModal() {
      this.deleteSoilPlanModalOpen = false
    },

    closeSampleLocationTestModal() {
      this.sampleLocationTestModalOpen = false
      this.updateKey = this.updateKey + 1
    },

    getSampleLocationCollectorModal() {
      //this.sample_ids

      this.sampleLocationCollectorModalOpen = true
    },

    closeSampleLocationCollectorModal(resp) {
      if (resp != null) {
        this.selectedPlan.collector_id = resp.id
        // Commented out because prop's value should not be mutated
        // If we have to update this, maybe we will consider using a computed property
        // this.samplingPlans = this.samplingPlans.filter(e => {
        //   if (e.id == this.selectedPlan.id) {
        //     e.collector = resp.id
        //   }
        //   return e
        // })
      }
      this.sampleLocationCollectorModalOpen = false
      this.updateKey = this.updateKey + 1
    },

    viewList(reset) {
      if (reset) {
        this.selectedZone = null
        this.$emit("reset-zones")
      }
      this.createPlanError = false
      this.$emit("set-view-list", "list")
      this.$emit("hide-switch", false)
    },

    viewPlan() {
      this.$emit("set-view-list", "plan")
    },

    async handleConfirmDeletePlan() {
      await this.handleDeletePlan(this.planToDelete.id)
      this.planToDelete = null
      this.closeDeleteSoilPlanModal()
    },

    handleCreateEnrollPlan() {
      this.$emit("create-plan-for-enroll")
    },

    async handleDeletePlan(planId) {
      const payload = {
        id: planId,
        status: "cancelled",
      }
      this.samplingPlans.find(plan => plan.id == planId).status = "cancelled"
      await SamplingAPI.updateSamplingZone(payload)
      this.$emit("update-list")
    },

    handleOpenDeletePlanModal(plan) {
      this.deleteSoilPlanModalOpen = true
      this.planToDelete = plan
    },

    async handlePlanStatusUpdate() {
      let planId = this.selectedPlan.id
      const payload = {
        id: planId,
        status: this.selectedPlan.status,
        collection_enabled: this.enable_collection_checkbox,
      }

      this.selectedPlan.collection_enabled = this.enable_collection_checkbox
      await SamplingAPI.updateSamplingZone(payload)
      this.$emit("update-list")
    },

    async handleSamplingPlanSelect(selectedPlan) {
      this.errorText = ""
      this.isPlanLoading = true

      const payload = {
        id: selectedPlan.id,
      }
      this.selectedPlan = null
      this.$emit("update-map-params", selectedPlan.id)
      this.$emit("hide-switch", true)

      //get map info as seperate api call that hapens in parallel
      await SamplingAPI.getSamplingLocations(payload).then(response => {
        this.isZipExportLoading = true

        this.selectedPlan = selectedPlan
        this.planLocations = response.data

        this.enable_collection_checkbox = this.selectedPlan.collection_enabled

        this.isZipExportLoading = false
        this.viewPlan()
        this.isPlanLoading = false
        this.clearAll()
        for (let i in selectedPlan.fields) {
          const id = selectedPlan.fields[i].id
          this.toggleItem({ id, dropdownType: "field" })
        }

        if (!this.selectedPlan) {
          this.selectedPlanFieldsMap = null
        } else {
          const fieldsDict = {}
          this.selectedPlan.fields.forEach(
            field => (fieldsDict[field.id] = field.name)
          )
          this.selectedPlanFieldsMap = fieldsDict
        }
      })
    },

    async handleSamplingPlanSubmit() {
      this.isPlanLoading = true
      const payload = {
        name: this.createPlanName,
        field_ids: this.activeFields.map(field => field.id),
        test_type: this.testType,
        org_node_id: this.organization.id,
        collector_id: this.collectorID,
        lab_id: this.labID,
        start_depth: this.depthRange[0],
        end_depth: this.depthRange[1],
        use_previous_locations: this.usePreviousLocations,
        enrollment_id: this.selectedEnrollment
          ? this.selectedEnrollment.id
          : null,
      }
      //TODO make these elements required
      await SamplingAPI.postSamplingPlan(payload)
        .then(response => {
          if (response.status == 200) {
            this.createPlanError = false

            this.viewList(true)
            this.$emit("update-list")
          } else {
            if (response.status == 405) {
              this.errorText =
                "A plan of that name and type already exists with active samples. Create plan with unique name."
            } else {
              this.errorText =
                "An error occurred when creating the sampling plan."
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.createPlanError = true
          this.errorText = "An error occurred when creating the sampling plan."
        })
      this.isPlanLoading = false
      this.createPlanName = null
    },

    async handleExport(export_type) {
      this.isZipExportLoading = true
      if (export_type == "pdf") {
        this.selectedPlan.label_pdf = "-"
      }
      this.errorText = ""
      const payload = {
        sample_location_ids: [],
        sample_order_id: this.selectedPlan.id,
        export_type: export_type,
      }
      await SamplingAPI.fetchSampleLocationExport(payload)
        .then(response => {
          if (response.status == 200) {
            if (response.data == "PDF Order is being generated") {
              this.errorText = response.data
            } else {
              const link = document.createElement("a")
              link.href = response.data.signed_url
              link.setAttribute("download", "file.zip")
              link.click()
              link.remove()
            }
          } else {
            this.errorText = "Error creating this export"
            this.createPlanError = true
          }
        })
        .catch(err => {
          console.log(err)
          this.createPlanError = true
          this.errorText = "Error creating this export"
        })

      this.isZipExportLoading = false
    },

    async handlePDF() {
      window.open(this.selectedPlan.label_pdf, "_blank")
    },

    zoomToMapLocation(location) {
      let popupContents = "QR Code ID:" + location.qr_code
      var geojsonFeature = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              name: location.id,
              popupContent: popupContents,
              value: location.id,
            },
            geometry: {
              type: "Point",
              coordinates: [
                location.geom_target.coordinates[0],
                location.geom_target.coordinates[1],
              ],
            },
          },
        ],
      }
      let resp = { geojson: geojsonFeature, geom: location.geom_target }
      this.$emit("map-zoom", resp)
    },
  },
}
</script>
<style scoped>
.default-input-title {
  font-size: 14px;
}

.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}

.hover {
  cursor: pointer;
}

.info-accent {
  color: #757575;
  font-size: 12px;
  font-weight: bold;
}

.select-style .vs__dropdown-toggle {
  height: 37px;
}

.wide-btn {
  width: 100%;
  margin: 0;
  margin-top: 20px;
  padding-bottom: 8px;
}

.w-35 {
  width: 35%;
}

.w-65 {
  width: 65%;
}

.amt {
  font-size: 16px;
  font-weight: bold;
}

.units {
  font-size: 10px;
}

.sel-enroll {
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
}
</style>
