import { GeoJsonLayer, IconLayer } from "@deck.gl/layers"
import * as d3 from "d3"

import { MVTLayer } from "@deck.gl/geo-layers"

import {
  CROP_ID_TO_ICON_URL,
  CROPS,
  MAP_LAYER,
  //MICROCLIMATE_COLOR_FACTOR,
} from "@/constants"
import {
  arrayAverage,
  getAgtColorRGB,
  standardDeviation,
  findMinMax,
} from "@/utility"

const HARVEST_YIELD_COLORS = [
  [255, 0, 0, 255],
  [255, 92, 0, 255],
  [255, 130, 0, 255],
  [255, 213, 0, 255],
  [255, 255, 0, 255],
  [200, 230, 50, 255],
  [180, 250, 0, 255],
  [64, 255, 0, 255],
  [0, 255, 0, 255],
]

const ELEVATION_COLORS = [
  [7, 17, 3],
  [26, 60, 10],
  [45, 104, 18],
  [64, 147, 26],
  [83, 190, 33],
  [107, 219, 55],
  [139, 227, 98],
  [171, 235, 142],
  [203, 242, 185],
]

const PBFMaxZoom = 13
const PBFMinZoom = 0 //turns out this setting was more or less doing the opposite of what I intended

const normalizeData = (value, min, max, scale) => {
  let delta = max - min
  return Math.round(((value - min) / delta) * scale)
}

const mapElevation = 200

const getRangesBasedOnStdDeviationYield = data => {
  const rangesByCrop = {}
  const valuesByCrop = {}
  data.forEach(row => {
    const { cropId } = row.properties
    if (!valuesByCrop[cropId]) valuesByCrop[cropId] = []
    const { yieldPerAcre } = row.properties
    if (typeof yieldPerAcre === "number" && yieldPerAcre > 0)
      valuesByCrop[cropId].push(yieldPerAcre)
  })

  for (const cropId in valuesByCrop) {
    const values = valuesByCrop[cropId]
    const stdDeviation = standardDeviation(values)

    const maxValue = values.reduce((prev, curr) => Math.max(prev, curr), 0)
    const minValue = values.reduce((prev, curr) => Math.min(prev, curr), 0)

    /*const minValue = Math.min(...values)
    const maxValue = Math.max(...values)*/
    const avgValue = arrayAverage(values)

    const ranges = [minValue, 0, 0, 0, avgValue, 0, 0, 0, maxValue]
    ranges[2] = (ranges[0] + ranges[4]) / 2
    ranges[1] = (ranges[0] + ranges[2]) / 2
    ranges[3] = (ranges[2] + ranges[4]) / 2
    ranges[6] = (ranges[4] + ranges[8]) / 2
    ranges[5] = (ranges[4] + ranges[6]) / 2
    ranges[7] = (ranges[6] + ranges[8]) / 2

    const finalRanges = ranges.map(val => stdDeviation / 4 + val)
    rangesByCrop[cropId] = finalRanges
  }

  return rangesByCrop
}

const getRangesBasedOnStdDeviationYieldNew = data => {
  const rangesByCrop = {}
  const valuesByCrop = {}
  data.forEach(row => {
    const { cropId } = row.properties
    if (!valuesByCrop[cropId]) valuesByCrop[cropId] = []
    const { yieldPerAcreNew } = row.properties
    if (typeof yieldPerAcreNew === "number" && yieldPerAcreNew > 0) {
      valuesByCrop[cropId].push(yieldPerAcreNew)
    } else if (Array.isArray(yieldPerAcreNew)) {
      const objWithValue = yieldPerAcreNew.find(o =>
        Object.keys(o).includes(cropId.toString())
      )
      valuesByCrop[cropId].push(objWithValue[cropId])
    }
  })

  for (const cropId in valuesByCrop) {
    const values = valuesByCrop[cropId]
    const stdDeviation = standardDeviation(values)

    const maxValue = values.reduce((prev, curr) => Math.max(prev, curr), 0)
    const minValue = values.reduce((prev, curr) => Math.min(prev, curr), 0)

    //const minValue = Math.min(...values)
    //const maxValue = Math.max(...values)
    const avgValue = arrayAverage(values)

    const ranges = [minValue, 0, 0, 0, avgValue, 0, 0, 0, maxValue]
    ranges[2] = (ranges[0] + ranges[4]) / 2
    ranges[1] = (ranges[0] + ranges[2]) / 2
    ranges[3] = (ranges[2] + ranges[4]) / 2
    ranges[6] = (ranges[4] + ranges[8]) / 2
    ranges[5] = (ranges[4] + ranges[6]) / 2
    ranges[7] = (ranges[6] + ranges[8]) / 2

    const finalRanges = ranges.map(val => stdDeviation / 4 + val)
    rangesByCrop[cropId] = finalRanges
  }

  return rangesByCrop
}

const getRangeBasedOnStdDeviationGeneric = (data, useDefaults, layerType) => {
  const rangesByProduct = {}
  const valuesByProduct = { All: [] }

  let layerKey = null
  switch (useDefaults) {
    case true:
      switch (layerType) {
        case "fert":
          layerKey = "fertAmountPerAcreDefaultsNew"
          break
        case "planting":
          layerKey = "plantingSeedsPerAcreDefaultsNew"
          break
        case "harvest":
          layerKey = "yieldPerAcre" //this is due to some preprocessing selection shenanigans on store/Map.js
          break
      }
      break
    case false:
      switch (layerType) {
        case "fert":
          layerKey = "fertAmountPerAcreNew"
          break
        case "planting":
          layerKey = "plantingSeedsPerAcreNew"
          break
        case "harvest":
          layerKey = "yieldPerAcre"
          break
      }
      break
  }

  if (layerKey == null) {
    return []
  }

  data.forEach(row => {
    let fertAmount = row.properties[layerKey]

    if (fertAmount == null) {
      return
    }
    if (fertAmount.length < 1) {
      return
    }
    let thisTotal = 0
    for (let fertAmountArrIdx in fertAmount) {
      let fertAmountArr = fertAmount[fertAmountArrIdx]
      for (const [key, thisFertAmount] of Object.entries(fertAmountArr)) {
        if (!valuesByProduct[key]) valuesByProduct[key] = []
        if (typeof thisFertAmount === "number" && thisFertAmount > 0) {
          valuesByProduct[key].push(thisFertAmount)
        }
        thisTotal += thisFertAmount
      }
    }
    valuesByProduct["All"].push(thisTotal)
  })

  for (const cropId in valuesByProduct) {
    const values = valuesByProduct[cropId.toString()]
    const stdDeviation = standardDeviation(values)
    //const minValue = Math.min(...values)
    //const maxValue = Math.max(...values)

    const maxValue = values.reduce((prev, curr) => Math.max(prev, curr), 0)
    const minValue = values.reduce((prev, curr) => Math.min(prev, curr), 0)

    const avgValue = arrayAverage(values)

    const ranges = [minValue, 0, 0, 0, avgValue, 0, 0, 0, maxValue]
    ranges[2] = (ranges[0] + ranges[4]) / 2
    ranges[1] = (ranges[0] + ranges[2]) / 2
    ranges[3] = (ranges[2] + ranges[4]) / 2
    ranges[6] = (ranges[4] + ranges[8]) / 2
    ranges[5] = (ranges[4] + ranges[6]) / 2
    ranges[7] = (ranges[6] + ranges[8]) / 2

    const finalRanges = ranges.map(val => stdDeviation / 4 + val)
    rangesByProduct[cropId] = finalRanges
  }

  return rangesByProduct
}

const getRangesBasedOnStdDeviationElevation = data => {
  const elevations = []
  data.forEach(row => {
    const { elevation } = row.properties
    if (typeof elevation === "number" && elevation > 0)
      elevations.push(elevation)
  })

  const stdDeviation = standardDeviation(elevations)
  var minmax = findMinMax(elevations)
  const minValue = minmax[0]
  const maxValue = minmax[1]

  //let minValueAlt = Math.min(...elevations)

  const avgValue = arrayAverage(elevations)

  const ranges = [minValue, 0, 0, 0, avgValue, 0, 0, 0, maxValue]
  ranges[2] = (ranges[0] + ranges[4]) / 2
  ranges[1] = (ranges[0] + ranges[2]) / 2
  ranges[3] = (ranges[2] + ranges[4]) / 2
  ranges[6] = (ranges[4] + ranges[8]) / 2
  ranges[5] = (ranges[4] + ranges[6]) / 2
  ranges[7] = (ranges[6] + ranges[8]) / 2

  const finalRanges = ranges.map(val => stdDeviation / 4 + val)

  return finalRanges
}

class BoundaryLayer extends GeoJsonLayer {
  constructor(props) {
    props.pickable = true
    props.stroked = true
    props.filled = true
    props.extruded = false
    props.autoHighlight = false
    props.pointRadiusScale = 3
    props.getFillColor = () => [255, 204, 0, 0]
    props.getLineColor = () => [255, 204, 0, 255]
    props.lineWidthMinPixels = 2
    super(props)
  }
}

class SelectedLayer extends GeoJsonLayer {
  constructor(props) {
    props.pickable = true
    props.stroked = false
    props.filled = true
    props.extruded = false
    props.autoHighlight = false
    props.pointRadiusScale = 3
    props.getFillColor = () => [255, 204, 0, 128]
    props.lineWidthMinPixels = 2
    super(props)
  }
}

class Harvest extends MVTLayer {
  constructor(props) {
    const valueRanges = getRangeBasedOnStdDeviationGeneric(
      props.dataset.features,
      props.includeDefaults,
      "harvest"
    )

    props.pickable = true
    props.stroked = false
    props.filled = true
    props.extruded = false
    props.autoHighlight = false
    props.pointRadiusScale = 0

    //props.fetch = fetchFunc

    props.minZoom = PBFMinZoom
    props.maxZoom = PBFMaxZoom
    props.getFillColor = feat =>
      this.getColor(
        feat.properties,
        valueRanges,
        props.includeDefaults,
        props.targetCropId,
        props.activeFieldIds
      )
    props.getLineColor = () => [0, 0, 0, 255]
    props.lineWidthMinPixels = 0
    super(props)
  }

  getColor(
    properties,
    valueRanges,
    includeDefaults,
    targetCropId,
    activeFieldIds
  ) {
    const {
      //cropId,
      yieldPerAcreNew,
      yieldPerAcreDefaultsNew,
      fieldId,
    } = properties

    let yieldPerAcre = null

    if (includeDefaults) {
      if (yieldPerAcreDefaultsNew == undefined) return [0, 0, 0, 0]
      yieldPerAcre = JSON.parse(yieldPerAcreDefaultsNew)
    } else {
      if (yieldPerAcreNew == undefined) return [0, 0, 0, 0]

      yieldPerAcre = JSON.parse(yieldPerAcreNew)
    }

    if (yieldPerAcre <= 0 || yieldPerAcre === null || yieldPerAcre == undefined)
      return [0, 0, 0, 0]

    let thisTargetCropId = targetCropId
    if (targetCropId == "All" || targetCropId == undefined) {
      thisTargetCropId = Object.keys(yieldPerAcre[0])[0] //String(cropId)
    } else {
      thisTargetCropId = String(targetCropId)
    }

    yieldPerAcre = yieldPerAcre
      .filter(e => {
        if (Object.keys(e).includes(thisTargetCropId)) {
          return e
        }
      })
      .map(j => {
        return j[thisTargetCropId]
      })

    if (yieldPerAcre.length > 0) {
      yieldPerAcre = yieldPerAcre[0]
    } else {
      return [0, 0, 0, 0]
    }

    if (yieldPerAcre <= 0 || yieldPerAcre === null || yieldPerAcre == undefined)
      return [0, 0, 0, 0]

    const valueRange = valueRanges[thisTargetCropId]

    if (valueRange == undefined) {
      return [0, 0, 0, 0]
    }
    let colorIdx =
      valueRange.length -
      valueRange.filter(cutoffVal => yieldPerAcre <= cutoffVal).length

    if (colorIdx > 8) {
      return [0, 0, 0, 0]
    }

    let harvestColor = HARVEST_YIELD_COLORS[colorIdx]
    let opacity = 255
    if (activeFieldIds.length) {
      if (!activeFieldIds.includes(fieldId)) {
        opacity = 0
      }
    }
    harvestColor[3] = opacity
    return harvestColor
  }
}

class Planting extends MVTLayer {
  constructor(props) {
    props.pickable = true
    props.stroked = false
    props.filled = true
    props.extruded = false
    props.autoHighlight = false
    props.pointRadiusScale = 0
    props.getFillColor = feat =>
      this.getColor(
        feat.properties,
        props.includeDefaults,
        props.targetCropId,
        props.activeFieldIds
      )

    //props.fetch = fetchFunc

    props.minZoom = PBFMinZoom
    props.maxZoom = PBFMaxZoom
    props.getLineColor = () => [0, 0, 0, 255]
    props.lineWidthMinPixels = 0
    super(props)
  }

  getColor(properties, includeDefaults, targetCropId, activeFieldIds) {
    const {
      //cropId,
      fieldId,
      plantingSeedsPerAcreNew,
      plantingSeedsPerAcreDefaultsNew,
    } = properties

    let featValue = null

    if (includeDefaults) {
      if (
        plantingSeedsPerAcreDefaultsNew == undefined ||
        plantingSeedsPerAcreDefaultsNew == null
      ) {
        return [0, 0, 0, 0]
      }
      featValue = JSON.parse(plantingSeedsPerAcreDefaultsNew)
    } else {
      if (
        plantingSeedsPerAcreNew == undefined ||
        plantingSeedsPerAcreNew == null
      ) {
        return [0, 0, 0, 0]
      }
      featValue = JSON.parse(plantingSeedsPerAcreNew)
    }

    let thisTargetCropId = targetCropId
    if (targetCropId == "All" || targetCropId == undefined) {
      thisTargetCropId = Object.keys(featValue[0])[0]
    } else {
      thisTargetCropId = String(targetCropId)
    }
    let cropId = thisTargetCropId

    featValue = featValue
      .filter(e => {
        if (Object.keys(e).includes(thisTargetCropId)) {
          return e
        }
      })
      .map(j => {
        return j[thisTargetCropId]
      })

    if (featValue.length > 0) {
      featValue = featValue[0]
    }

    if (featValue <= 0 || featValue === null || featValue == undefined)
      return [0, 0, 0, 0]

    const CUTOFFS = {
      [CROPS.Corn]: [20000, 23000, 26000, 28000, 30000, 32000, 35000, 38000],
      [CROPS.Cotton]: [32000, 33250, 34500, 35750, 37000, 38250, 39500, 42000],
      [CROPS.Wheat]: [0.76, 0.93, 1.15, 1.29, 1.47, 1.79, 2.11, 2.43],
      // [CROPS.Rice]: [33, 43, 52, 61, 71, 86, 102, 117],
      [CROPS.Rice]: [
        200000,
        300000,
        400000,
        450000,
        500000,
        600000,
        700000,
        800000,
      ],
      [CROPS.Soybeans]: [
        130000,
        142000,
        153000,
        165000,
        176000,
        186000,
        197000,
        207000,
      ],
      [CROPS.BlackBeans]: [
        65000,
        75000,
        85000,
        95000,
        105000,
        120000,
        135000,
        150000,
      ],
    }

    if (!(cropId in CUTOFFS)) return [0, 0, 0, 0]

    const COLORS = [
      [255, 0, 0, 255],
      [255, 92, 0, 255],
      [255, 130, 0, 255],
      [255, 213, 0, 255],
      [255, 255, 0, 255],
      [200, 230, 50, 255],
      [180, 250, 0, 255],
      [64, 255, 0, 255],
      [0, 255, 0, 255],
    ]

    let cutoff = CUTOFFS[cropId]

    if (cropId === CROPS.Rice && featValue < 2000) {
      cutoff = [15, 18, 22, 25, 28, 32, 38, 42]
    }

    const colorIdx =
      cutoff.length - cutoff.filter(cutoffVal => featValue <= cutoffVal).length

    if (colorIdx > 8) {
      return [0, 0, 0, 0]
    }

    let plantingColor = COLORS[colorIdx]
    let opacity = 255
    if (activeFieldIds.length) {
      if (!activeFieldIds.includes(fieldId)) {
        opacity = 0
      }
    }
    plantingColor[3] = opacity
    return plantingColor
  }
}

class Fertilizer extends MVTLayer {
  constructor(props) {
    const valueRanges = getRangeBasedOnStdDeviationGeneric(
      props.dataset.features,
      props.includeDefaults,
      "fert"
    )

    props.pickable = true
    props.stroked = false
    props.filled = true
    props.extruded = false
    props.autoHighlight = false
    props.pointRadiusScale = 0

    //props.fetch = fetchFunc

    props.minZoom = PBFMinZoom
    props.maxZoom = PBFMaxZoom
    props.getFillColor = feat =>
      this.getColor(
        feat.properties,
        valueRanges,
        props.includeDefaults,
        props.targetCropId,
        props.activeFieldIds
      )
    props.getLineColor = () => [0, 0, 0, 255]
    props.lineWidthMinPixels = 0
    super(props)
  }

  getColor(
    properties,
    valueRanges,
    includeDefaults,
    targetCropId,
    activeFieldIds
  ) {
    const {
      fertAmountPerAcreNew,
      fertAmountPerAcreDefaultsNew,
      fieldId,
    } = properties

    let fertPerAcre = null

    if (includeDefaults) {
      if (fertAmountPerAcreDefaultsNew == undefined) return [0, 0, 0, 0]
      fertPerAcre = JSON.parse(fertAmountPerAcreDefaultsNew)
    } else {
      if (fertAmountPerAcreNew == undefined) return [0, 0, 0, 0]

      fertPerAcre = JSON.parse(fertAmountPerAcreNew)
    }

    if (fertPerAcre <= 0 || fertPerAcre === null || fertPerAcre == undefined)
      return [0, 0, 0, 0]

    let thisTargetCropId = targetCropId
    if (targetCropId == "All" || targetCropId == null) {
      thisTargetCropId = "All"
    } else {
      thisTargetCropId = String(targetCropId)
    }

    if (thisTargetCropId != "All") {
      fertPerAcre = fertPerAcre
        .filter(e => {
          if (Object.keys(e).includes(thisTargetCropId)) {
            return e
          }
        })
        .map(j => {
          return j[thisTargetCropId]
        })

      if (fertPerAcre.length > 0) {
        fertPerAcre = fertPerAcre[0]
      }
    } else {
      fertPerAcre = fertPerAcre.map(e => {
        return Object.values(e)[0]
      })
      if (fertPerAcre.length > 0) {
        fertPerAcre = fertPerAcre.reduce((a, b) => a + b, 0)
      }
    }
    if (fertPerAcre <= 0 || fertPerAcre === null || fertPerAcre == undefined)
      return [0, 0, 0, 0]

    const valueRange = valueRanges[thisTargetCropId]

    if (valueRange == undefined) {
      return [0, 0, 0, 0]
    }

    const colorIdx =
      valueRange.length -
      valueRange.filter(cutoffVal => fertPerAcre <= cutoffVal).length

    const FERT_COLORS = [
      [255, 255, 255, 255],
      [232, 234, 255, 255],
      [209, 213, 255, 255],
      [181, 188, 255, 255],
      [157, 167, 255, 255],
      [134, 146, 255, 255],
      [97, 113, 255, 255],
      [70, 89, 255, 255],
      [42, 64, 255, 255],
    ]

    if (colorIdx > 8) {
      return [0, 0, 0, 0]
    }

    let fertColor = FERT_COLORS[colorIdx]
    let opacity = 255
    if (activeFieldIds.length) {
      if (!activeFieldIds.includes(fieldId)) {
        opacity = 0
      }
    }
    fertColor[3] = opacity

    return fertColor
  }
}

class AGTByCrop extends GeoJsonLayer {
  constructor(props) {
    props.pickable = true
    props.stroked = false
    props.filled = true
    props.extruded = false
    props.autoHighlight = false
    props.pointRadiusScale = 0
    props.getFillColor = feat => this.getColor(feat.properties)
    props.getLineColor = () => [0, 0, 0, 255]
    props.lineWidthMinPixels = 0
    super(props)
  }

  getColor(properties) {
    const { agt } = properties
    return [...getAgtColorRGB(agt), 255]
  }
}

class ProfitByAgt extends MVTLayer {
  constructor(props) {
    const profitList = props.dataset.features.map(d => {
      if (typeof d.properties.profit === "number") {
        return d.properties.profit
      }
      return 0
    })
    var minmax = findMinMax(profitList)
    const minProfit = minmax[0]
    const maxProfit = minmax[1]

    props.pickable = false
    props.stroked = false
    props.filled = true
    props.extruded = true //this controls if 3d
    props.autoHighlight = false
    props.pointRadiusScale = 0

    props.getElevation = feat =>
      normalizeData(feat.properties.roiProfit, minProfit, maxProfit, maxProfit)

    //props.fetch = fetchFunc

    props.minZoom = PBFMinZoom
    props.maxZoom = PBFMaxZoom
    props.getFillColor = feat =>
      this.getColor(feat.properties, props.activeFieldIds)
    props.getLineColor = () => [0, 0, 0, 255]
    props.lineWidthMinPixels = 0

    super(props)
  }

  getColor(properties, activeFieldIds) {
    let opacity = 255

    const { agts, fieldId } = properties
    let agt = JSON.parse(agts)["27"]

    if (!activeFieldIds.includes(fieldId)) {
      opacity = 0
    }
    if (agt == undefined) {
      return [0, 0, 0, 0]
    }
    return [...getAgtColorRGB(agt), opacity]
  }
}

class AGTGlobal extends MVTLayer {
  constructor(props) {
    props.pickable = false
    props.stroked = false
    props.filled = true
    props.extruded = false //this controls if 3d
    props.autoHighlight = false
    props.pointRadiusScale = 0

    //props.fetch = fetchFunc

    props.minZoom = PBFMinZoom
    props.maxZoom = PBFMaxZoom
    props.getFillColor = feat =>
      this.getColor(feat.properties, props.activeFieldIds)
    props.getLineColor = () => [0, 0, 0, 255]
    props.lineWidthMinPixels = 0

    super(props)
  }

  getColor(properties, activeFieldIds) {
    let opacity = 255
    const { agts, fieldId } = properties
    let agt = JSON.parse(agts)["27"]
    if (agt == undefined) {
      return [0, 0, 0, 0]
    }
    if (!activeFieldIds.includes(fieldId)) {
      opacity = 0
    }

    return [...getAgtColorRGB(agt), opacity]
  }
}

class Elevation extends MVTLayer {
  constructor(props) {
    let elevations = props.dataset.features
      .filter(
        feature =>
          typeof feature.properties.elevation === "number" &&
          feature.properties.elevation
      )
      .map(feature => feature.properties.elevation)

    const stdDev = standardDeviation(elevations)
    const avg = arrayAverage(elevations)
    elevations = elevations.filter(
      val => val > avg - stdDev * 2 && val < avg + stdDev * 2
    )

    const minElevation = elevations.reduce(
      (prev, curr) => Math.min(prev, curr),
      Number.MAX_VALUE
    )
    const maxElevation = elevations.reduce(
      (prev, curr) => Math.max(prev, curr),
      0
    )

    const valueRange = getRangesBasedOnStdDeviationElevation(
      props.dataset.features
    )

    //props.fetch = fetchFunc
    props.pickable = false
    props.stroked = false
    props.filled = true
    props.extruded = true
    props.autoHighlight = false
    props.pointRadiusScale = 0

    props.getElevation = feat => {
      let elevation = Math.min(maxElevation, feat.properties.elevationMeters)
      elevation = Math.max(minElevation, elevation)
      return normalizeData(elevation, minElevation, maxElevation, mapElevation)
    }

    props.minZoom = PBFMinZoom
    props.maxZoom = PBFMaxZoom

    props.getFillColor = feat =>
      this.getColor(feat.properties, valueRange, props.activeFieldIds)
    props.getLineColor = () => [0, 0, 0, 0]
    props.lineWidthMinPixels = 1
    super(props)
  }

  getColor(properties, valueRange, activeFieldIds) {
    const { fieldId, elevationMeters } = properties
    const featValue = elevationMeters

    if (
      featValue <= 0 ||
      featValue === null ||
      featValue == undefined ||
      valueRange == undefined
    )
      return [0, 0, 0, 0]

    let colorIdx =
      valueRange.length -
      valueRange.filter(cutoffVal => featValue <= cutoffVal).length

    if (colorIdx > 8) {
      return [0, 0, 0, 0]
    }

    const returnColor = ELEVATION_COLORS[colorIdx]
    let opacity = 255
    if (activeFieldIds.length) {
      if (!activeFieldIds.includes(fieldId)) {
        opacity = 0
      }
    }

    return [...returnColor, opacity]
  }
}

class YieldByElevation extends MVTLayer {
  constructor(props) {
    let elevations = props.dataset.features
      .filter(
        feature =>
          typeof feature.properties.elevation === "number" &&
          feature.properties.elevation
      )
      .map(feature => feature.properties.elevation)

    const stdDev = standardDeviation(elevations)
    const avg = arrayAverage(elevations)
    elevations = elevations.filter(
      val => val > avg - stdDev * 2 && val < avg + stdDev * 2
    )

    const minElevation = elevations.reduce(
      (prev, curr) => Math.min(prev, curr),
      Number.MAX_VALUE
    )
    const maxElevation = elevations.reduce(
      (prev, curr) => Math.max(prev, curr),
      0
    )

    //props.fetch = fetchFunc

    props.minZoom = PBFMinZoom
    props.maxZoom = PBFMaxZoom
    props.pickable = true
    props.stroked = false
    props.filled = true
    props.extruded = true
    props.autoHighlight = false
    props.pointRadiusScale = 0
    props.getElevation = feat => {
      let elevation = Math.min(maxElevation, feat.properties.elevationMeters)
      elevation = Math.max(minElevation, elevation)
      return normalizeData(elevation, minElevation, maxElevation, mapElevation)
    }

    const valueRanges = getRangesBasedOnStdDeviationYield(
      props.dataset.features
    )

    props.minZoom = PBFMinZoom
    props.maxZoom = PBFMaxZoom
    props.getFillColor = feat =>
      this.getColor(
        feat.properties,
        valueRanges,
        props.includeDefaults,
        props.targetCropId,
        props.activeFieldIds
      )
    props.getLineColor = () => [0, 0, 0, 255]
    props.lineWidthMinPixels = 0
    super(props)
  }

  getColor(
    properties,
    valueRanges,
    includeDefaults,
    targetCropId,
    activeFieldIds
  ) {
    const {
      //cropId,
      yieldPerAcreNew,
      yieldPerAcreDefaultsNew,
      fieldId,
    } = properties

    let yieldPerAcre = null

    if (includeDefaults) {
      if (yieldPerAcreDefaultsNew == undefined) return [0, 0, 0, 0]
      yieldPerAcre = JSON.parse(yieldPerAcreDefaultsNew)
    } else {
      if (yieldPerAcreNew == undefined) return [0, 0, 0, 0]

      yieldPerAcre = JSON.parse(yieldPerAcreNew)
    }

    if (yieldPerAcre <= 0 || yieldPerAcre === null || yieldPerAcre == undefined)
      return [0, 0, 0, 0]

    let thisTargetCropId = targetCropId
    if (targetCropId == "All") {
      thisTargetCropId = Object.keys(yieldPerAcre[0])[0]
      //thisTargetCropId = String(cropId)
    } else {
      thisTargetCropId = String(targetCropId)
    }

    yieldPerAcre = yieldPerAcre
      .filter(e => {
        if (Object.keys(e).includes(thisTargetCropId)) {
          return e
        }
      })
      .map(j => {
        return j[thisTargetCropId]
      })

    if (yieldPerAcre.length > 0) {
      yieldPerAcre = yieldPerAcre[0]
    }
    if (yieldPerAcre <= 0 || yieldPerAcre === null || yieldPerAcre == undefined)
      return [0, 0, 0, 0]

    const valueRange = valueRanges[thisTargetCropId]
    if (valueRange == undefined) {
      return [0, 0, 0, 0]
    }
    let colorIdx =
      valueRange.length -
      valueRange.filter(cutoffVal => yieldPerAcre <= cutoffVal).length

    if (colorIdx > 8) {
      return [0, 0, 0, 0]
    }

    let harvestColor = HARVEST_YIELD_COLORS[colorIdx]
    let opacity = 255
    if (activeFieldIds.length) {
      if (!activeFieldIds.includes(fieldId)) {
        opacity = 0
      }
    }
    harvestColor[3] = opacity
    return harvestColor
  }
}

class AGTByElevation extends MVTLayer {
  constructor(props) {
    let elevations = props.dataset.features
      .filter(
        feature =>
          typeof feature.properties.elevation === "number" &&
          feature.properties.elevation
      )
      .map(feature => feature.properties.elevation)

    const stdDev = standardDeviation(elevations)
    const avg = arrayAverage(elevations)
    elevations = elevations.filter(
      val => val > avg - stdDev * 2 && val < avg + stdDev * 2
    )

    var minmax = findMinMax(elevations)
    const minElevation = minmax[0]
    const maxElevation = minmax[1]

    //props.fetch = fetchFunc

    props.minZoom = PBFMinZoom
    props.maxZoom = PBFMaxZoom
    props.pickable = true
    props.stroked = false
    props.filled = true
    props.extruded = true
    props.autoHighlight = false
    props.pointRadiusScale = 0

    props.getElevation = feat => {
      let elevation = Math.min(maxElevation, feat.properties.elevationMeters)
      elevation = Math.max(minElevation, elevation)
      return normalizeData(elevation, minElevation, maxElevation, maxElevation)
    }
    props.getFillColor = feat =>
      this.getColor(feat.properties, props.activeFieldIds)
    props.getLineColor = () => [0, 0, 0, 0]
    props.lineWidthMinPixels = 1
    super(props)
  }

  getColor(properties, activeFieldIds) {
    let opacity = 255

    const { agts, fieldId } = properties
    let agt = JSON.parse(agts)["27"]

    if (!activeFieldIds.includes(fieldId)) {
      opacity = 0
    }
    return [...getAgtColorRGB(agt), opacity]
  }
}

class Profit extends MVTLayer {
  constructor(props) {
    let profits = props.dataset.features
      .filter(
        feature =>
          typeof feature.properties.profit === "number" &&
          feature.properties.profit
      )
      .map(feature => feature.properties.profit)

    const stdDev = standardDeviation(profits)
    const avg = arrayAverage(profits)
    profits = profits.filter(
      val => val > avg - stdDev * 2 && val < avg + stdDev * 2
    )

    const minProfit = profits.reduce(
      (prev, curr) => Math.min(prev, curr),
      Number.MAX_VALUE
    )
    const maxProfit = profits.reduce((prev, curr) => Math.max(prev, curr), 0)

    const colorScaleLinear = d3
      .scaleLinear()
      .domain([-300, 300])
      .range([0, 7])
      .clamp(true)
    props.pickable = true
    props.stroked = false
    props.filled = true
    props.extruded = true
    props.autoHighlight = false
    props.pointRadiusScale = 0

    //props.fetch = fetchFunc

    props.minZoom = PBFMinZoom
    props.maxZoom = PBFMaxZoom
    props.getElevation = feat => {
      let profit = Math.min(maxProfit, feat.properties.roiProfit)
      profit = Math.max(minProfit, profit)
      const normalized = normalizeData(
        profit,
        minProfit,
        maxProfit,
        mapElevation
      )
      return normalized
    }
    props.getFillColor = feat =>
      this.getColor(feat.properties, colorScaleLinear, props.activeFieldIds)
    props.getLineColor = () => [0, 0, 0, 0]
    props.lineWidthMinPixels = 1
    super(props)
  }

  getColor(properties, colorScaleLinear, activeFieldIds) {
    if (properties.roiProfit === null || properties.roiProfit == undefined)
      return [0, 0, 0, 0]
    const idx = colorScaleLinear(properties.roiProfit)
    let fieldId = properties.fieldId

    const colors = [
      [212, 61, 81, 255],
      [230, 106, 77, 255],
      [241, 148, 82, 255],
      [247, 227, 130, 255],
      [189, 207, 117, 255],
      [133, 185, 111, 255],
      [79, 161, 110, 255],
      [0, 135, 108, 255],
    ]

    let profitColor = colors[Math.round(idx)]
    if (profitColor == null) {
      return [0, 0, 0, 0]
    }

    let opacity = 255
    if (activeFieldIds.length) {
      if (!activeFieldIds.includes(fieldId)) {
        opacity = 0
      }
    }

    profitColor[3] = opacity
    return profitColor
  }
}

class CropIconLayer extends IconLayer {
  constructor(props, zoom) {
    const zoomToIconSize = zoom => {
      if (zoom < 10.5) return 1
      else if (zoom < 11.5) return 1
      else if (zoom < 12.2) return 2
      else if (zoom < 13) return 3
      else return 3
    }

    props.getIcon = d => {
      const { crops } = d
      const firstCrop = props.id === MAP_LAYER.Crops

      let anchorX = 50
      if (crops.length > 1) anchorX = firstCrop ? 85 : 15
      const cropIdx = firstCrop ? 0 : 1

      let url = CROP_ID_TO_ICON_URL[crops[cropIdx]]
      if (url == undefined) {
        url = CROP_ID_TO_ICON_URL["Other"]
      }
      return {
        url,
        width: 100,
        height: 100,
        anchorX,
      }
    }
    props.sizeScale = 8
    props.getPosition = d => [...d.coordinates, 120]
    props.getSize = () => zoomToIconSize(zoom)
    props.billboard = false
    super(props)
  }
}

class MicroclimateLayer extends MVTLayer {
  constructor(props) {
    props.pickable = false
    props.stroked = false
    props.filled = true
    props.extruded = false
    //props.fetch = fetchFunc
    props.minZoom = 0
    props.maxZoom = 8
    props.pointRadiusScale = 0
    props.getFillColor = feat => this.getColor(feat.properties)
    props.getLineColor = () => [255, 204, 0, 255]
    props.lineWidthMinPixels = 2
    super(props)
  }

  getColor(properties) {
    function hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
        255,
      ]
    }

    if (properties.color_id === null) return [0, 0, 0, 0]
    if (properties.color_id == undefined) return [0, 0, 0, 0]

    let color = hexToRgb(properties.color_id)
    return color
  }
}

export {
  SelectedLayer,
  BoundaryLayer,
  AGTGlobal,
  AGTByCrop,
  Elevation,
  Harvest,
  Fertilizer,
  Profit,
  ProfitByAgt,
  AGTByElevation,
  Planting,
  YieldByElevation,
  CropIconLayer,
  MicroclimateLayer,
  getRangesBasedOnStdDeviationYield,
  getRangesBasedOnStdDeviationYieldNew,
  HARVEST_YIELD_COLORS,
}
