import API from "./API"

const endpoint = "practice-confirmations"

export const getAllConfirmations = (params) =>
  API.get(`/${endpoint}/`, { params });

export const getRelevantFields = (params) => 
  API.post(`/${endpoint}/get_relevant_fields/`, params);

export const getUploaderSpec = (params) => 
  API.post(`/${endpoint}/get_uploader_spec/`, params);